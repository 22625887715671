import { FaPlayCircle } from "react-icons/fa";
import styled from "styled-components";
import hexToRgbA, { colorRandomizer, hexToRgba } from "../HexToRGBA";

export const MEDIA_SCREEN = { //Screen sizes for devices
    MOBILE: '480',
    TABLETS: '768',
    LAPTOPS: '1024',
    DESKTOPS: '1025',
    TV: '1500'
  }

export const SliderContainer = styled.div`
 display: flex;
 position: relative;
 padding: 1% 2%;
 color: white;
 justify-content: center;
 align-items: center;
 width: 100%;
 transition: 0.8s all ease;
 background: ${props => hexToRgba(props.theme.secondaryBg, 0.008)};
 backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(8px);
 min-height: 30%;
 
`

export const MainSlider = styled.div`
 display: ${props => props.height ? "grid" : "flex"};
 margin: 0px 2%;
 height: 100%;
 min-width: 100%;
 padding: 2%;
 white-space: nowrap;
 grid-template-columns: ${props => props.isEpisode ? `repeat(2, 1fr)` : `repeat(3, 1fr)`};
 gap: 1%;
 overflow-x: hidden;
 overflow-y: ${props => props.height ? "scroll" : "hidden"};
 scrollbar-width: none;
 scroll-behavior: smooth;
 -ms-overflow-style: none;
 ::-webkit-scrollbar{
     display: none; 
 }

 @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
    overflow-x: scroll;
    scrollbar-width: 0.5px;
 scroll-behavior: smooth;
 -ms-overflow-style: smooth;
 ::-webkit-scrollbar{
     display: flex; 
 }
  }
 @media screen and (max-width: ${MEDIA_SCREEN.MOBILE}px) {
    grid-template-columns: repeat(1, 1fr);
  }
 
 `

export const SliderIcon = styled.div`
display: flex;
font-size: 3rem;
border-radius: 100%;
position: absolute;
color: ${props => props.theme.secondaryBg};
cursor: pointer;
transition: 0.8s all ease;
z-index: 2;
&:hover{
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
}
box-shadow; 2px 2px 2px 2px rgb(0 0 0 / 124);
`
export const SliderCard = styled.div`
display: flex;
width: 10rem;
height: 10rem;
display: inline-block;
flex: none;
order: 1;
flex-grow: 0;
overflow: hidden;
flex-wrap: wrap;
word-wrap: break-word;
position: relative;
border-radius: ${ props => props.type === "artist" ? "50px 5px 50px 5px":  props.type === "genres" ? "50%" : "5px"};
margin: 2%;
cursor: pointer;
border: ${props => props.isSelected ? `50px solid blue`: "none" }
box-shadow: 0 1px 3px ${props => hexToRgba(props.theme.accentColor, 0.9)};

&:hover{
    background: ${props => props.theme.secondaryBg};
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
    border-radius: ${props => props.type === "genres" ?"50%" :  "5px"};;
    height: ${props => props.type === "genres" ?"10rem" :  "12rem"};
 
}

`

export const SliderCardImg = styled.div`
width: 100%;
height: 100%;
display:inline-flex;
align-items: center;
justify-content: center;
font-weight: 700;
flex-wrap: wrap;
word-wrap: break-word;
padding: 2%;
font-size: ${props => props?.text?.length >= 15 ? '0.8rem' : '1.1rem'};
background: ${props => props.id === "genres" ? props.background :  hexToRgba(props.theme.secondaryColor, 0.4)};
transition: 0.8s all ease;

${SliderCard}:hover & {
    transform: translateY(-45%);
    transition: all .9s;
}

`
export const Img = styled.img`
    width: 100%;
    height:100%;
    object-fit: cover;
    transition: 0.8s all ease;
    ${SliderCard}:hover & {
        opacity: .7;
        transition: .5s;
    }
`

export const PlayContainer = styled.div` 
display: flex;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
position: absolute;
bottom: -150%;

${SliderCard}: hover & {
    transition: all 1s ease-in-out;
    bottom: 0;
}


`
export const PlayButton = styled(FaPlayCircle)`
display: flex;
width: 3rem;
height: 3rem;
z-index: 4;
color : ${props => props.theme.accentColor};
cursor: pointer;
&:hover{
    color: ${props => props.background ? props.background : props.theme.mainColor};
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}

`

export const SliderCardDetails = styled.div`
    position: absolute;
    bottom: -400px;
    left:0;
    width: 100%;
    height: 45%;
    padding: 10px;
    transition: .6s;
    background: ${props => props.theme.secondaryBg};

    ${SliderCard}: hover & {
        bottom: 0;
    }
`


export const SliderCardTitle = styled.text`
    
    font-size: ${props => props?.text?.length >= 15 ? '0.8rem' : '1.1rem'};
    margin: 2% 3%;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display:inline-flex;
    color: ${props => props.theme.mainBg};
    bottom: 0;
    cursor: pointer;
    &:hover{
        color: ${props => props.background ? props.background : props.theme.mainColor};
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
    }
`

export const SliderCardDescription = styled.text`
    display:flex;
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 700;
    margin: 5px 0px 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.mainBg};
    cursor: pointer;
    &:hover{
        color: ${props => props.background ? props.background : props.theme.mainColor};
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        transform: scale(1.05);
        opacity: 1;
    }
`

export const WaterMark = styled.div`
    opacity: 0.2;
`




