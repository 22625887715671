import React from 'react'
import { AlbumUploadsContainer, AlbumUploadsInput, MusicUploadAlbumArt, MusicUploadContentTitle, MusicUploadInfoText, MusicUploadTitleTextWrapper, SongArtCoverWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../../../MusicUploadsForm/MusicUploadsFormElements';
import { Zoom } from 'react-reveal';
import ImageUtils from '../../../../utils/ImageUtils';



const Page1 = ({albumInfo, handleInput, artists, setCancelAlbumArtUpload}) => {
    const { defaultAlbumArt } = ImageUtils();
  return (
    <><AlbumUploadsContainer
    for={
      albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url
        ? ""
        : "albumArt"
    }
  >
    {albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url && (
      <>
        <div style={{ display: "flex", width: "50px", height: "50px" }}>
          {albumInfo?.albumArt.progress < 100
            ? `${albumInfo?.albumArt?.progress.toFixed(2)}%`
            : `Processing`}
        </div>
      </>
    )}
    {(albumInfo?.albumArt?.url || !albumInfo?.albumArt?.progress) && (
      <>
        <MusicUploadAlbumArt
          src={
            albumInfo?.albumArt?.url
              ? albumInfo?.albumArt?.url
              : defaultAlbumArt
          }
          alt={"albumName"}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
        />
      </>
    )}
  </AlbumUploadsContainer>
  <MusicUploadTitleTextWrapper>
    <MusicUploadContentTitle>
      {albumInfo?.albumName ? albumInfo?.albumName : "Album Name"}
    </MusicUploadContentTitle>
    <>
      <MusicUploadInfoText>
        {artists?.length > 0
          ? artists?.map((artist, index) => {
              if (index === artists.length - 2) {
                return artist?.artistName + " & ";
              } else if (index === artists.length - 1) {
                return artist?.artistName;
              } else if (index < artists.length - 2) {
                return artist?.artistName + ", ";
              }
            })
          : "Album Artists"}
      </MusicUploadInfoText>
    </>
    <MusicUploadInfoText>
      {albumInfo?.releaseDate
        ? new Date(albumInfo?.releaseDate).toDateString()
        : "Release Date"}
    </MusicUploadInfoText>
    <MusicUploadInfoText>
      {albumInfo?.isDownloadable && <>{albumInfo?.price
        ? `${albumInfo?.price} ${albumInfo?.currency}`
        : "Price"}</>}
    </MusicUploadInfoText>
  </MusicUploadTitleTextWrapper>
  <SongArtCoverWrapper style={{ flex: "1 1 100%" }}>
    <Zoom>
      <SongDetailsSection>
        <SongDetailsSectionTitle>Album Art</SongDetailsSectionTitle>
        <AlbumUploadsContainer
          for={
            albumInfo?.albumArt?.progress > 0 && !albumInfo?.albumArt?.url
              ? ""
              : "albumArt"
          }
        >
          <span style={{ display: "flex" }}>
            <SongDetailsText>
              {albumInfo?.albumArt?.url ? (
                `${albumInfo?.albumArt.file?.name}`
              ) : (
                <small style={{ fontStyle: "italic" }}>
                  Click here to add Album Art (Size should be at least
                  1600 x 1600 pixels){" "}
                </small>
              )}
            </SongDetailsText>
            {albumInfo?.albumArt?.progress > 0 &&
              !albumInfo?.albumArt?.url && (
                <SongDetailsText
                  onClick={() => setCancelAlbumArtUpload(true)}
                  style={{ textDecoration: "underline" }}
                >
                  Cancel
                </SongDetailsText>
              )}
            {albumInfo?.albumArt?.url && (
              <SongDetailsText style={{ textDecoration: "underline" }}>
                Replace
              </SongDetailsText>
            )}
          </span>
          <AlbumUploadsInput
            id="albumArt"
            type={"file"}
            name="albumArt"
            accept="image/x-png,image/gif,image/jpeg, image/jpg"
            multiple="false"
            onChange={(e) => {
              handleInput(e);
            }}
          />
        </AlbumUploadsContainer>
      </SongDetailsSection>
    </Zoom>
  </SongArtCoverWrapper></>
  )
}

export default Page1