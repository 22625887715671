import React from "react";
import { ARTIST_FORM_TYPES } from "../../../../utils/Enum";
import ArtistForm from "../../../MusicUploadsForm/ArtistForm";
import { ErrorWrapper, FormWrapper, SongDetailsSection, SongDetailsText } from "../../../MusicUploadsForm/MusicUploadsFormElements";

const Page2 = ({
  artists,
  featArtists,
  artistSearchRef,
  fArtistSearchRef,
  removeArtist,
  clearArtistForm,
  setCurrentArtist,
  setCurrentFArtist,
  handleArtistInput,
  setArtistEditing,
  setFArtistEditing,
  searchInput,
  currentArtist,
  currentFArtist,
  walletAddressRef,
  fWalletAddressRef,
  artistSearch,
  featArtistSearch,
  artistNameRef,
  fArtistNameRef,
  artistFullNameRef,
  fArtistFullNameRef,
  artistIdRef,
  fArtistIdRef,
  selectArtist,
  validateArtistInfo,
  artistEditng,
  fArtistEditng,
  errors,
  index
}) => {
  return (
    <>
      <SongDetailsSection>
        <FormWrapper>
          <ArtistForm
            title={"Artists"}
            type={ARTIST_FORM_TYPES.MAIN_ARTIST}
            artists={artists}
            artistSearchRef={artistSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentArtist}
            setArtistEditing={setArtistEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentArtist}
            walletAddressRef={walletAddressRef}
            artistSearch={artistSearch}
            artistNameRef={artistNameRef}
            artistFullNameRef={artistFullNameRef}
            artistIdRef={artistIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={artistEditng}
          />
        </FormWrapper>
        <ErrorWrapper>
          <SongDetailsText error>{errors?.artists}</SongDetailsText>
        </ErrorWrapper>
        <FormWrapper>
          <ArtistForm
            title={"Featured Artists"}
            type={ARTIST_FORM_TYPES.FEATURE}
            artists={featArtists}
            artistSearchRef={fArtistSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentFArtist}
            setArtistEditing={setFArtistEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentFArtist}
            walletAddressRef={fWalletAddressRef}
            artistSearch={featArtistSearch}
            artistNameRef={fArtistNameRef}
            artistFullNameRef={fArtistFullNameRef}
            artistIdRef={fArtistIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={fArtistEditng}
          />
        </FormWrapper>
      </SongDetailsSection>
    </>
  );
};

export default Page2;
