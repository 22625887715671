import React, { useEffect, useRef, useState } from "react";
import Page1 from "./Page1";
import { FormWrapper } from "../../../CustomForm/CustomFormElements";
import { useModal } from "../../../Modal/ModalContext";
import { useAlert } from "../../../Alert/AlertContext";
import { useAuth } from "../../../../contexts/AuthContext";
import useArtist from "../../../../hooks/useArtist";
import useRecordLabel from "../../../../hooks/useRecordLabel";
import { useLocation, useNavigate } from "react-router-dom";
import useMusic from "../../../../hooks/useMusic";
import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
} from "../../../../utils/Enum";
import { v4 } from "uuid";
import {
  containsObject,
  getObjectLength,
  getUniqueListBy,
  newlineFormatter,
} from "../../../../utils";
import LoadingScreen, { ModalLoadingScreen } from "../../../LoadingScreen";
import QRCodeForm from "../../../QRCodeForm";
import OptionModal from "../../../OptionModal";
import { Small } from "../../../QRCodeForm/QrCodeFormElements";
import { HoverOption } from "../../../MenuOptions/MenuOptionsElements";
import albumArtPlaceholder from "../../../../images/sm_album_art_placeholder.png";
import { AlbumArtPreviewContainer, AlbumUploadsContainer, AlbumUploadsInput, ErrorWrapper, MusicUploadsButton, MusicUploadsButtonContainer, SongArtCoverWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from "../../../MusicUploadsForm/MusicUploadsFormElements";
import NFTStorageTokenForm from "../../../MusicUploadsForm/NFTStorageTokenForm";
import NFTStorageSonarAPIKeyForm from "../../../NFTStorageSonarAPIKeyForm";
import {
  getDownloadUrl,
  storage,
  storageRef,
  uploadBytes,
} from "../../../Authorization/firebase";
import { songNameContainsFeature } from "../../../../utils/MusicUtils";
import { isrcReg } from "../../../../utils/Regex";
import Page0 from "./Page0";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page7 from "./Page7";
import Page6 from "./Page6";
import { AlbumArtPreview } from "../../../AlbumArt";
import { Zoom } from "react-reveal";
import PageIndexer from "../../../PageIndexer";
import { Theme } from "../../../WebsiteThemeElements";
import { Button1 } from "../../../ButtonElement";

const SongPreReleaseEdit = ({ info, submitChanges, immediateRelease }) => {
  const {
    modalContent,
    isOpen,
    toggleModal,
    noCloseModal,
    openModal,
    closeModal,
  } = useModal();
  const { addAlert } = useAlert();
  const { currentUser, profiles, currentProfile } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const { getRecordLabelArtists } = useRecordLabel({});
  const navigate = useNavigate();
  const location = useLocation();

  const {
    newSongId,
    createMp3File,
    searchGenres,
    uploadSong,
    getAllGenres,
    generateISRCCode,
    deleteDraft,
  } = useMusic();
  const songNameRef = useRef();
  const songUploadRef = useRef();
  const albumArtRef = useRef();
  const videoFileRef = useRef();
  const additionalFilesRef = useRef();
  const songPriceRef = useRef();
  const releaseDateRef = useRef();
  const genreRef = useRef();
  const isrcRef = useRef();
  const aboutRef = useRef();
  const lyricsRef = useRef();
  const composersRef = useRef();
  const producersRef = useRef();
  const writersRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");

  const fArtistNameRef = useRef("");
  const fArtistIdRef = useRef("");
  const fWalletAddressRef = useRef("");
  const fArtistSearchRef = useRef("");
  const fArtistFullNameRef = useRef("");

  const writerNameRef = useRef("");
  const writerIdRef = useRef("");
  const writerWalletAddressRef = useRef("");
  const writerFullNameRef = useRef("");
  const writerSearchRef = useRef("");

  const composerNameRef = useRef("");
  const composerIdRef = useRef("");
  const composerWalletAddressRef = useRef("");
  const composerFullNameRef = useRef("");
  const composerSearchRef = useRef("");

  const producerNameRef = useRef("");
  const producerIdRef = useRef("");
  const producerWalletAddressRef = useRef("");
  const producerFullNameRef = useRef("");
  const producerSearchRef = useRef("");

  const payoutArtistNameRef = useRef("");
  const payoutArtistIdRef = useRef("");
  const payoutArtistWalletAddressRef = useRef("");
  const payoutArtistFullNameRef = useRef("");
  const payoutArtistSearchRef = useRef("");
  const artistPayoutRef = useRef();

  const [percentage, setPercentage] = useState(100);
  const [userRoyalties, setUserRoyalties] = useState(
    info?.userRoyalties
      ? info?.userRoyalties
      : {
          percentage: 10,
          shares: 100,
          price: 1,
          shareReserve: 0,
          ticker: "",
          currency: "XRP",
        }
  );
  const [songInfo, setSongInfo] = useState({});
  const [currency, setCurrency] = useState(
    info?.currency ? (info?.isUserRoyalties ? "XRP" : info?.currency) : "XRP"
  );
  const [currentUserArtist, setUserArtist] = useState({
    ...info?.artists[0],
    exists: true,
  });
  const [requestRelease, setReleaseRequest] = useState(false);
  const [artistEditng, setArtistEditing] = useState(false);
  const [fArtistEditng, setFArtistEditing] = useState(false);
  const [writerEditing, setWriterEditing] = useState(false);
  const [composerEditing, setComposerEditing] = useState(false);
  const [producerEditing, setProducerEditing] = useState(false);
  const [payoutEditing, setPayoutEditing] = useState(false);
  const [songId, setSongId] = useState(info?.songId);
  const [isCancelSongUpload, setCancelSongUpload] = useState(false);
  const [isCancelImageUpload, setCancelImageUpload] = useState(false);
  const [musicGenres, setMusicGenres] = useState({});
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [albumArt, setAlbumArt] = useState({
    url: info?.albumArt,
    ref: info?.albumArtRef,
    name: info?.albumArtRef.split("/")[info?.albumArtRef.split("/").length - 1],
    file: {
      name: info?.albumArtRef.split("/")[info?.albumArtRef.split("/").length - 1],
    }
  });
  const [videoFile, setVideoFile] = useState(
    info?.videoFile ? info?.videoFile : {}
  );
  const [visualizerFile, setVisualizerFile] = useState(
    info?.visualizer ? info?.visualizer : {}
  );
  const [storageToken, setToken] = useState();
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [isExplicit, setExplicit] = useState(
    info?.explicit ? info?.explicit : false
  );
  const [isCover, setCover] = useState(info?.isCover ? info?.isCover : false);
  const [isStreamable, setStreamable] = useState(
    info?.isStreamable ? info?.isStreamable : true
  );
  const [isDownloadable, setDownloadable] = useState(
    info?.isDownloadable ? info?.isDownloadable : false
  );
  const [isSubscription, setSubscription] = useState(
    info?.isSubscription ? info?.isSubscription : false
  );
  const [isMintable, setMintable] = useState(
    info?.isMintable ? info?.isMintable : false
  );
  const [isVideo, setVideo] = useState(info?.isVideo ? info?.isVideo : false);
  const [isVisualizer, setVisualizer] = useState(
    info?.isVisualizer ? info?.isVisualizer : false
  );
  const [isUserRoyalties, setIsUserRoyalties] = useState(
    info?.isUserRoyalties ? info?.isUserRoyalties : false
  );
  const [isPreview, setPreview] = useState(info?.isPreRelease ? info?.isPreview ? info?.isPreview : false : false)
  const [isPreRelease, setPreRelease] = useState(
    info?.isPreRelease ? info?.isPreRelease : false
  );
  const [songUpload, setSong] = useState({
    ref: info?.songRef,
    url: info?.url,
    mp3Url: info?.mp3Url,
    mp3Ref: info?.mp3Ref,
    name: info?.songRef.split("/")[info?.songRef.split("/").length - 1],
    file: {
      name: info?.songRef.split("/")[info?.songRef.split("/").length - 1]

    },
  });
  const [songName, setName] = useState(info?.songName);
  const [mainGenre, setMainGenre] = useState(
    {
      ...info?.genre,
      id: info?.genre?.genreId
    }
  );
  const [genre, setGenre] = useState("");
  const [subgenre, setSubgenre] = useState("");
  const [mp3Token, setMp3Token] = useState(
    info?.mp3Token ? info?.mp3Token : v4()
  );
  const [subGenres, setSubGenres] = useState(
    info?.subGenres ? info?.subGenres.map(sg => {
      return {
      ...sg,
      id: sg?.genreId,
    }
    }) : []
  );
  const [filteredGenres, setGenreFilter] = useState([]);
  const [filteredSubGenres, setSubGenreFilter] = useState([]);
  const [duration, setDuration] = useState(
    info?.duration ? info?.duration : undefined
  );
  const [price, setPrice] = useState(
    info?.isDownloadable ? (info?.price ? info?.price : 1) : undefined
  );
  const [isrcType, setIsrcType] = useState(
    info?.isrcType ? info?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    info?.autoIsrcCode ? info?.autoIsrcCode : undefined
  );
  const [releaseDate, setReleaseDate] = useState(
    new Date(info?.displayDate).toISOString().split("T")[0]
  );
  const [artistSearch, setArtistSearch] = useState();
  const [featArtistSearch, setFArtistSearch] = useState();
  const [composerSearch, setComposerSearch] = useState();
  const [producerSearch, setProducerSearch] = useState();
  const [writerSearch, setWriterSearch] = useState();
  const [payoutSearch, setPayoutSearch] = useState();
  const [artists, setArtists] = useState(
    info?.artists.map((a) => {
      return {
        ...a,
        exists: true,
      };
    })
  );
  const [currentArtist, setCurrentArtist] = useState({});
  const [featArtists, setFeatArtists] = useState(
    info?.featuredArtists?.length > 0
      ? info?.featuredArtists.map((a) => {
          return {
            ...a,
            exists: true,
          };
        })
      : []
  );
  const [currentFArtist, setCurrentFArtist] = useState({});
  const [isrc, setISRC] = useState(info?.isrc ? info?.isrc : "");
  const [about, setAbout] = useState(info?.about ? info?.about : "");
  const [lyrics, setLyrics] = useState(info?.lyrics ? info?.lyrics : "");
  const [payoutArtists, setPayoutArtists] = useState(
    info?.royalties
      ? Object.keys(info?.royalties).map((p, index) => {
          let artists = [...info?.artists];
          if (info?.songCredits?.producers?.length > 0) {
            artists.push(...info?.songCredits?.producers);
          }
          if (info?.featuredArtists?.length > 0) {
            artists.push(...info?.featuredArtists);
          }
          if (info?.songCredits?.writers?.length > 0) {
            artists.push(...info?.songCredits?.writers);
          }
          if (info?.songCredits?.composers?.length > 0) {
            artists.push(...info?.songCredits?.composers);
          }
          artists = getUniqueListBy(artists, "artistId");
          let artist = artists.filter((a) => p === a?.artistId); // search for current payout artist from list
          return {
            ...artist[0],
            exists: true,
            payoutPercentage: info?.royalties[p],
          }; //return in payout artist format
        })
      : []
  );
  const [currentPayoutArtist, setCurrentPayoutArtist] = useState({});
  const [composers, setComposers] = useState(
    info?.songCredits?.composers
      ? info?.songCredits?.composers.map((a) => {
          return {
            ...a,
            exists: true,
          };
        })
      : []
  );
  const [currentComposer, setCurrentComposer] = useState({});
  const [writers, setWriters] = useState(
    info?.songCredits?.writers
      ? info?.songCredits?.writers.map((a) => {
          return {
            ...a,
            exists: true,
          };
        })
      : []
  );
  const [currentWriter, setCurrentWriter] = useState({});
  const [producers, setProducers] = useState(
    info?.songCredits?.producers
      ? info?.songCredits?.producers.map((a) => {
          return {
            ...a,
            exists: true,
          };
        })
      : []
  );
  const [currentProducer, setCurrentProducer] = useState({});
  const [license, setLicense] = useState(info?.licensing);
  const [prevChanges, setPrevChanges] = useState({});
  const [errors, setErrors] = useState({});
  const [pageNumber, setPageNum] = useState(1);
  const [isSubmitted, setSubmit] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });

    return () => {};
  });

  const selectArtist = (type, artist) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        setCurrentArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setArtists(a);
        setArtistSearch();
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        setCurrentFArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setFeatArtists(a);
        setFArtistSearch();
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        setCurrentComposer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setComposers(a);
        setComposerSearch();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        setCurrentProducer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setProducers(a);
        setProducerSearch();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        setCurrentWriter({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setWriters(a);
        setWriterSearch();
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        setCurrentPayoutArtist({
          ...artist,
          payoutPercentage: 0,
        });
        a.push({ ...artist, payoutPercentage: 0 });
        setPayoutArtists(a);

        setPayoutSearch();
        break;
      default:
        break;
    }
    clearArtistForm(type);
  };

  //Add specific artist type to list
  const addArtist = (type) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        a.push({ ...currentArtist, exists: false });
        setArtists(a);
        setCurrentArtist({});
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        a.push({ ...currentFArtist, exists: false });
        setFeatArtists(a);
        setCurrentFArtist({});
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        a.push({ ...currentComposer, exists: false });
        setComposers(a);
        setCurrentComposer({});
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        a.push({ ...currentProducer, exists: false });
        setProducers(a);
        setCurrentProducer({});
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        a.push({ ...currentWriter, exists: false });
        setWriters(a);
        setCurrentWriter({});
        break;
      /* case ARTIST_FORM_TYPES.PAYOUT:
      a = writers
      a.push({...currentPayoutArtist, exists: false});
      setPayoutArtists(a);
      setCurrentPayoutArtist()
        break; */
      default:
        break;
    }
  };

  const clearArtistForm = (type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        setArtistSearch();
        setCurrentArtist({});
        if (artistSearchRef?.current?.value) {
          artistSearchRef.current.value = "";
        }
        if (artistIdRef?.current?.value) {
          artistIdRef.current.value = "";
        }
        if (artistNameRef?.current?.value) {
          artistNameRef.current.value = "";
        }
        if (walletAddressRef?.current?.value) {
          walletAddressRef.current.value = "";
        }
        if (artistFullNameRef.current?.value) {
          artistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        setFArtistSearch();
        setCurrentFArtist({});
        if (fArtistSearchRef?.current?.value) {
          fArtistSearchRef.current.value = "";
        }
        if (fArtistIdRef?.current?.value) {
          fArtistIdRef.current.value = "";
        }
        if (fArtistNameRef?.current?.value) {
          fArtistNameRef.current.value = "";
        }
        if (fWalletAddressRef?.current?.value) {
          fWalletAddressRef.current.value = "";
        }
        if (fArtistFullNameRef.current?.value) {
          fArtistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        setComposerSearch();
        setCurrentComposer({});
        if (composerSearch?.current?.value) {
          composerSearch.current.value = "";
        }
        if (composerIdRef?.current?.value) {
          composerIdRef.current.value = "";
        }
        if (composerNameRef?.current?.value) {
          composerNameRef.current.value = "";
        }
        if (composerWalletAddressRef?.current?.value) {
          composerWalletAddressRef.current.value = "";
        }
        if (composerFullNameRef.current?.value) {
          composerFullNameRef.current.value = "";
        }

        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        setProducerSearch();
        setCurrentProducer({});
        if (producerSearchRef?.current?.value) {
          producerSearchRef.current.value = "";
        }
        if (producerIdRef?.current?.value) {
          producerIdRef.current.value = "";
        }
        if (producerNameRef?.current?.value) {
          producerNameRef.current.value = "";
        }
        if (producerWalletAddressRef?.current?.value) {
          producerWalletAddressRef.current.value = "";
        }
        if (producerFullNameRef.current?.value) {
          producerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        setCurrentWriter({});
        setWriterSearch();
        if (writerSearchRef?.current?.value) {
          writerSearchRef.current.value = "";
        }
        if (writerIdRef?.current?.value) {
          writerIdRef.current.value = "";
        }
        if (writerNameRef?.current?.value) {
          writerNameRef.current.value = "";
        }
        if (writerWalletAddressRef?.current?.value) {
          writerWalletAddressRef.current.value = "";
        }
        if (writerFullNameRef.current?.value) {
          writerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        setCurrentPayoutArtist();
        setPayoutSearch();
        if (payoutArtistSearchRef?.current?.value) {
          payoutArtistSearchRef.current.value = "";
        }
        if (payoutArtistIdRef?.current?.value) {
          payoutArtistIdRef.current.value = "";
        }
        if (payoutArtistNameRef?.current?.value) {
          payoutArtistNameRef.current.value = "";
        }
        if (payoutArtistWalletAddressRef?.current?.value) {
          payoutArtistWalletAddressRef.current.value = "";
        }
        if (payoutArtistFullNameRef.current?.value) {
          payoutArtistFullNameRef.current.value = "";
        }
        if (artistPayoutRef.current?.value) {
          artistPayoutRef.current.value = 0.1;
        }
        break;
      default:
        break;
    }
  };

  const validateSongInfo = () => {
    let vErrors = {};
    if ((songName && songName?.trim() === "") || !songName) {
      vErrors = {
        ...vErrors,
        songName: "You can't release a song without a name",
      };
    }
    if (
      !songUpload ||
      Object.keys(songUpload).length === 0 ||
      !songUpload?.url ||
      (songUpload?.progress > 0 && songUpload?.progress < 100)
    ) {
      vErrors = {
        ...vErrors,
        songUpload: "Upload a song before releasing",
      };
    }
    //console.log(new Date(releaseDate).getTime() <= new Date().getTime())
    if (
      (isPreRelease &&
        (new Date(releaseDate).getTime() <= new Date().getTime() ||
          !releaseDate)) ||
      (!isPreRelease && new Date(releaseDate).getTime() > new Date().getTime())
    ) {
      vErrors = {
        ...vErrors,
        releaseDate: isPreRelease
          ? "Enter valid future release date"
          : "Enter valid release date",
      };
    }

    if (
      (visualizerFile?.progress > 0 &&
        visualizerFile?.progress < 100 &&
        !visualizerFile?.url) ||
      (videoFile?.progress > 0 && videoFile?.progress < 100 && !videoFile?.url)
    ) {
      vErrors = {
        ...vErrors,
        visuals: "Visuals still uploading...",
      };
    }
    if (!albumArt || !albumArt?.url || albumArt?.url === false) {
      vErrors = {
        ...vErrors,
        albumArt: "Upload album art before releasing ",
      };
    }
    if (
      (isStreamable &&
        getObjectLength(payoutArtists) > 0 &&
        payoutArtists?.some((artist) => artist.payoutPercentage === 0)) ||
      (isStreamable && getObjectLength(payoutArtists) === 0)
    ) {
      vErrors = {
        ...vErrors,
        payoutArtists: "Please enter payout percentage for Artists",
      };
    }
    if (getObjectLength(payoutArtists) > 0 && payoutArtists?.balance > 100) {
      vErrors = {
        ...vErrors,
        payoutArtists: "payouts exceed 100%. please redistribute payouts",
      };
    }

    console.log(subGenres)
    if (!mainGenre || !mainGenre?.id) {
      vErrors = {
        ...vErrors,
        genre: "Select a main genre",
      };
    }
    if (isUserRoyalties && getObjectLength(userRoyalties) > 0) {
      let royaltyErrors = {};
      if (userRoyalties?.ticker.trim().length < 3) {
        royaltyErrors = {
          ...royaltyErrors,
          ticker: "Invalid Ticker. Please enter a valid tick. min 3 characters",
        };
      }
      if (userRoyalties.percentage < 10 || userRoyalties.percentage > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          percentage:
            "Invalid royalty share percentage. please input value between 10% - 50%",
        };
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if (getObjectLength(royaltyErrors) > 0) {
        vErrors = {
          ...vErrors,
          userRoyalty: royaltyErrors,
        };
      }
    }

    if (getObjectLength(vErrors) > 0) {
      setErrors(vErrors);
      return false;
    } else {
      return true;
    }
  };

  const getDuration = (src) => {
    var reader = new FileReader();
    let audio = new Audio();
    let duration;
    reader.readAsDataURL(src);
    reader.onload = function (e) {
      audio.src = e.target.result;
      return audio.addEventListener(
        "loadedmetadata",
        function () {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          duration = audio.duration;
          setSong((prev) => {
            return { ...prev, duration: duration };
          });
          // example 12.3234 seconds

          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
        },
        false
      );
    };
  };


  const uploadData = (type) => {
    let songData = {};
    setHideButtons(true);
    try {
      setErrors({});
      if (songName && songName?.trim()?.length > 0 && artists?.length > 0) {
        openModal();
        noCloseModal(
          <ModalLoadingScreen text={"Validating Upload. Please Wait..."} />
        );
        if (validateSongInfo()) {
          //setSongInfo();
          submitChanges(CONTENT_TYPES.SONG, {
            songName: songName.trim(),
            sender: currentUser?.uid,
            memo: `${currentUser?.uid} is submitting the song ${songName} to be released`,
            instruction: `${currentUser?.uid} is submitting the song ${songName} to be released`,
            price: price,
            isrc:
              isrcType === "custom"
                ? isrc?.trim()?.length > 0
                  ? isrc?.trim()
                  : autoIsrcCode
                : autoIsrcCode,
            artists,
            featuredArtists: featArtists,
            songCredits: {
              composers,
              producers,
              writers,
            },
            genre: mainGenre.id,
            subGenres:
              subGenres?.length > 0
                ? subGenres.map((g) => {
                    return g.id;
                  })
                : undefined,
            isStreamable,
            isDownloadable,
            isSubscription,
            isUserRoyalties,
            isCover,
            currency: isDownloadable
              ? currency
                ? currency
                : "XRP"
              : undefined,
            isMintable: isMintable,
            explicit: isExplicit ? isExplicit : undefined,
            userRoyalties: isUserRoyalties ? userRoyalties : undefined,
            royalties: payoutArtists,
            songUrl: songUpload.url,
            duration: duration ? duration : songUpload?.duration,
            songRef: songUpload.ref,
            mp3Url: songUpload?.mp3Url,
            mp3Ref: songUpload?.mp3Ref,
            albumArt: albumArt.url,
            albumArtRef: albumArt.ref,
            video:
              getObjectLength(videoFile) > 0
                ? {
                    url: videoFile?.url,
                    duration: videoFile?.duration,
                    ref: videoFile?.ref,
                  }
                : undefined,
            visualizer:
              getObjectLength(visualizerFile) > 0
                ? {
                    url: visualizerFile?.url,
                    duration: visualizerFile?.duration,
                    ref: visualizerFile?.ref,
                  }
                : undefined,
            videoResolutions:
              getObjectLength(videoFile) > 0 &&
              videoFile?.videoResolutions?.length > 0
                ? videoFile?.videoResolutions
                : undefined,
            about: about.trim(),
            uploadedBy: currentUser?.uid,
            songId: songId,
            licensing: license,
            lyrics: lyrics.trim(),
            releaseDate: releaseDate
              ? releaseDate
              : new Date()?.toISOString().split("T")[0],
            displayDate: releaseDate
              ? new Date(releaseDate).toISOString().split("T")[0]
              : new Date().toISOString().split("T")[0],
            type: "publish",
          });
        } else {
          closeModal();
          modalContent();
          setHideButtons(false);
          setErrors((prev) => {
            return {
              ...prev,
              message:
                "Unable to release song. Please review your song information",
            };
          });
        }
      } else {
        if (!artists || artists?.length === 0) {
          setErrors((prev) => {
            return { ...prev, artists: "No artist selected for song" };
          });
        }
        if (!songName || songName?.trim() === "") {
          setErrors((prev) => {
            return {
              ...prev,
              songName: "You can't release a song witout a name",
            };
          });
        }
        setErrors((prev) => {
          return {
            ...prev,
            message: "Unable to upload. Please review your song information",
          };
        });
      }
    } catch (err) {
      console.error(err);
      setHideButtons(false);
    }
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const validateArtistInfo = (id, type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        if (validateArtistInput(currentArtist)) {
          artistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }

        break;
      case ARTIST_FORM_TYPES.FEATURE:
        if (validateArtistInput(currentFArtist)) {
          fArtistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        if (validateArtistInput(currentComposer)) {
          composerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        if (validateArtistInput(currentProducer)) {
          producerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        if (validateArtistInput(currentWriter)) {
          writerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        let total = 0;
        payoutArtists.map((a) => {
          total = parseFloat(a.payoutPercentage) + parseFloat(total);
          return;
        });
        //console.log(total)
        if (
          currentPayoutArtist?.payoutPercentage <= 100 &&
          currentPayoutArtist?.payoutPercentage > 0 &&
          parseFloat(total) +
            parseFloat(currentPayoutArtist.payoutPercentage) <=
            100
        ) {
          editArtist(id, type);
          clearArtistForm(type);
        } else {
          setCurrentArtist((prev) => {
            return { ...prev, error: "Invalid payout split" };
          });
        }
        break;
      default:
        break;
    }
    setCurrentArtist({});
    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentFArtist : artist
        );

        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentComposer : artist
        );

        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentProducer : artist
        );

        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentWriter : artist
        );

        setWriters(updatedArtists);
        setWriterEditing(false);
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentPayoutArtist : artist
        );

        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        if (containsObject(currentUserArtist, updatedArtists)) {
          setArtists(updatedArtists);
        } else {
          setArtists([currentUserArtist, ...updatedArtists]);
        }
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setWriters(updatedArtists);
        setWriterEditing(false);

        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const addGenre = (musicGenre) => {
    const g = subGenres;
    setGenre();
    setSubgenre();
    if (
      g.findIndex((sG) => sG.id === musicGenre.id) === -1 &&
      g.length - 1 <= 3 &&
      musicGenre?.genreId
    ) {
      g.push(musicGenre);
      setSubGenres(g);
    } else if (!musicGenre?.genreId) {
      setMainGenre(musicGenre);
    } else if (musicGenre?.genreId && g.length - 1 === 2) {
      g[2] = musicGenre;
      setSubGenres(g);
    }
    setSubgenre();
    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
    const g = subGenres;
    if (subGenre) {
      const updatedGenres = g.filter((sGenre, index) => {
        if (id !== index && subGenre.id !== sGenre.id) return sGenre;
      });
      setSubGenres(updatedGenres);
    } else {
      setMainGenre();
    }
  };

  const setCreativeCommons = (id) => {
    setLicense(id);
  };

  const handleToggle = (type) => {
    switch (type) {
      case "explicit":
        setExplicit(!isExplicit);
        break;
      case "streamable":
        let streamable = !isStreamable;
        setStreamable(streamable);
        if (streamable) {
          setSubscription(false);
        }

        break;
      case "downloadable":
        let downloadable = !isDownloadable;
        if (downloadable) {
          setSubscription(false);
        } else {
          setPreRelease(false);
        }
        setDownloadable(downloadable);
        break;

      case "video":
        let video = !isVideo;
        setVideo(video);
        break;

      case "visualizer":
        let visualizer = !isVisualizer;
        setVisualizer(visualizer);
        break;
      case "schedule":
        let preRelease = !isPreRelease;
        setPreRelease(preRelease);
        break;

      case "cover":
        let cover = !isCover;
        setCover(cover);

        break;

        case "preview":
          let  preview = !isPreview;
             setPreview(preview);
           break;

      case "royalties":
        let royalties = !isUserRoyalties;
        if (isUserRoyalties === false) {
          openModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                closeModal();
                modalContent();
              }}
              heading={"Enable User Royalties Share"}
              additionalText={
                "Allow your supporters to invest and earn from your music sales and royalty streams."
              }
            >
              <Small>Note: Once enabled, it cannot be disabled.</Small>
              <HoverOption
                onClick={() => {
                  setIsUserRoyalties(true);
                  if (royalties) {
                    setSubscription(false);
                    setStreamable(true);
                  }
                  if (isDownloadable && royalties) {
                    setCurrency("XRP");
                  }
                  closeModal();
                  modalContent();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setIsUserRoyalties(false);
        }
        /*  let royalties = !isUserRoyalties
          setIsUserRoyalties(royalties)
          if(royalties){
          setSubscription(false);
          setStreamable(true);}
          if(isDownloadable && royalties){
            setCurrency("XRP")
          } */
        break;

      case "subscription":
        let subscription = !isSubscription;
        setSubscription(subscription);
        if (subscription) {
          setDownloadable(false);
          setStreamable(false);
          setIsUserRoyalties(false);
        }

        break;
      case "mintable":
        let mintable = !isMintable;
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  /* setMintable(true)
                      toggleModal()
                          modalContent() */

                  modalContent(
                    <OptionModal>
                      <ModalLoadingScreen
                        text={"Updating. Please Wait..."}
                        transparent={true}
                      />
                    </OptionModal>
                  );
                  return await validateNFTStorageKey(
                    currentUserArtist?.artistId
                  )
                    .then((data) => {
                      if (data) {
                        setMintable(true);
                        toggleModal();
                        modalContent();
                      } else {
                        modalContent(
                          <>
                            <OptionModal heading={"IPFS API Key Not Found"}>
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <>
                                      <NFTStorageTokenForm
                                        artistId={currentUserArtist?.artistId}
                                        currentUser={currentUser?.uid}
                                        setMintable={setMintable}
                                      />
                                    </>
                                  );
                                }}
                              >
                                Add API Key
                              </HoverOption>
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <NFTStorageSonarAPIKeyForm
                                      artistId={currentUserArtist?.artistId}
                                      currentUser={currentUser?.uid}
                                      onSuccess={() => {
                                        setMintable(true);
                                      }}
                                    />
                                  );
                                }}
                              >
                                {" "}
                                Use Sonar Muse API Key
                              </HoverOption>

                              <HoverOption
                                onClick={() => {
                                  toggleModal();
                                  modalContent();
                                }}
                              >
                                Cancel
                              </HoverOption>
                            </OptionModal>
                          </>
                        );
                      }
                    })
                    .catch((err) => {
                      setMintable(false);
                      modalContent(
                        <>
                          <OptionModal
                            heading={"Something Went Wrong. Please try again"}
                          >
                            <HoverOption
                              onClick={() => {
                                toggleModal();
                                modalContent();
                              }}
                            >
                              Close
                            </HoverOption>
                          </OptionModal>
                        </>
                      );
                    });
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setMintable(false);
        }

        break;
      default:
        break;
    }
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;
    const cFArtist = currentFArtist;
    const cWriter = currentWriter;
    const cProducer = currentProducer;
    const cComposer = currentComposer;
    const cPayout = currentPayoutArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }

            AddNEArtistsToSearch(e);
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cFArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cComposer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cProducer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cWriter?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({
              ...cPayout,
              artistName: e.target.value,
            });
            if (e.target.value.trim() !== "") {
              delete cPayout?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                  artistName: e.target.value,
                },
              });
            }
            break;
          default:
            break;
        }

        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, fullName: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, artistId: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, address: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE:
        if (e.target.value?.length > 0 && e.target.value <= 100) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: parseInt(e.target.value),
          });
          return;
        }
        if (e.target.value?.length === 0) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: 0,
          });
        }

        break;

      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0 && type !== ARTIST_FORM_TYPES.PAYOUT) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.FEATURE:
              setFArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (featArtists) {
                    if (filterExistingArtist(featArtists, artist) === false) {
                      return artist;
                    }
                  } else {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.COMPOSER:
              setComposerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(composers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PRODUCER:
              setProducerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(producers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.WRITER:
              setWriterSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(writers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PAYOUT:
              setPayoutSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(payoutArtists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }

    if (type === ARTIST_FORM_TYPES.PAYOUT && e.target.value.length > 0) {
      setPayoutSearch({
        type: e.target.name,
        artists: displaySelectedArtists().filter((artist) => {
          let a = false;
          let pArtists = payoutArtists;

          switch (e.target.name) {
            case ARTIST_SEARCH_TYPES.NAME:
              if (
                artist?.artistName &&
                artist.artistName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                pArtists.some((a) =>
                  a.artistName.includes(artist.artistName)
                ) === false
              ) {
                a = true;
              }

              break;
            case ARTIST_SEARCH_TYPES.FULL_NAME:
              if (
                artist?.fullName &&
                artist?.fullName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ADDRESS:
              if (
                artist?.address &&
                artist?.address
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ID:
              if (
                artist?.artistId &&
                artist?.artistId
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            default:
              break;
          }
          if (a === true) {
            return artist;
          }
        }),
      });
    }
    if (e.target.value.length === 0) {
      switch (type) {
        case ARTIST_FORM_TYPES.MAIN_ARTIST:
          setArtistSearch();
          break;
        case ARTIST_FORM_TYPES.FEATURE:
          setFArtistSearch();
          break;
        case ARTIST_FORM_TYPES.COMPOSER:
          setComposerSearch();
          break;
        case ARTIST_FORM_TYPES.PRODUCER:
          setProducerSearch();
          break;
        case ARTIST_FORM_TYPES.WRITER:
          setWriterSearch();
          break;
        case ARTIST_FORM_TYPES.PAYOUT:
          setPayoutSearch();
          break;
        default:
          break;
      }
    }
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [
      ...artists.filter((a) => a.exists),
      ...featArtists.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...composers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...producers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...writers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
    ];
    let allNEArtists = [
      ...artists.filter((a) => !a.exists),
      ...featArtists.filter((a) => !a.exists),
      ...composers.filter((a) => !a.exists),
      ...producers.filter((a) => !a.exists),
      ...writers.filter((a) => !a.exists),
    ];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistName"),
    ];
    return allArtists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [
      ...artists,
      ...featArtists,
      ...composers,
      ...producers,
      ...writers,
    ];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const handleInput = async (e) => {
    const currSong = songUpload;
    let prevErrors = errors;
    e.preventDefault();
    switch (e.target.name) {
      case "songUpload":
        if (e.target.value && e.target.files[0]) {
          let songFileName;
          if (!songUpload.name) {
            songFileName = `${Math.round(Math.random() * 10000000000000)}.${
              e.target.files[0].name.split(".")[
                e.target.files[0].name.split(".").length - 1
              ]
            }`;
            setSong({ file: e.target.files[0], name: songFileName });
          } else {
            songFileName = songUpload.name;
            setSong((prev) => {
              return { ...prev, file: e.target.files[0] };
            });
          }
          getDuration(e.target.files[0]);
          try {
            const uploadTask = uploadBytes(
              storageRef(storage, albumArt?.ref),
              e.target.files[0],
              {
                contentType: e.target.files[0].type,
                customMetadata: {
                  fileName: e.target.files[0].name,
                },
              }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                setSong((prev) => {
                  return { ...prev, progress: progress };
                });

                //console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    //console.log("Upload is paused");
                    break;
                  case "running":
                    //console.log("Upload is running");
                    break;
                  default:
                    //console.log("Upload is " + progress + "% done");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at

                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;

                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then(
                  async (downloadURL) => {
                    //console.log("File available at", downloadURL);
                    return await createMp3File({
                      uid: currentUser?.uid,
                      token: mp3Token,
                      ref: uploadTask.snapshot.ref.fullPath,
                      url: downloadURL,
                    }).then((mp3File) => {
                      if (mp3File) {
                        setSong((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                            mp3Url: mp3File.mp3Url,
                            mp3Ref: mp3File.mp3Ref,
                          };
                        });
                      } else {
                        setSong((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                      }
                    });
                  }
                );
              }
            );
          } catch (err) {
            return;
          }
        }
        break;
      case "name song":
        if (e.target.value.trim() === "") {
          setErrors({
            ...prevErrors,
            songName: "You can't release a song without a name",
          });
        }
        if (songNameContainsFeature(e.target.value)) {
          setErrors({
            ...prevErrors,
            songName:
              "Song name must not contain featured any artist(s). Enter featured artist(s) on page 2",
          });
        } else {
          delete prevErrors.songName;
          setErrors(prevErrors);
        }
        setName(e.target.value);
        break;
      case "main genre song":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          searchGenres(e.target.value).then((g) => {
            setGenreFilter(g.genres);
          });
        } else {
          setGenreFilter();
        }
        setGenre(e.target.value.trim());
        break;

      case "subGenre song":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          setSubGenreFilter();
          searchGenres(e.target.value).then((g) => {
            setSubGenreFilter(g.subGenres);
          });
        } else {
          setSubGenreFilter();
        }
        setSubgenre(e.target.value);
        break;
      case "price song":
        if (e.target.value[0] === "0") {
          setPrice(e.target.value.substring(1, e.target.value.length));
        } else {
          setPrice(e.target.value);
        }

        if (!isNaN(e.target.value) && e.target.value > 0) {
          delete prevErrors.price;
          setErrors(prevErrors);
          return;
        }
        if (e.target.value?.length === 0) {
          setPrice(0);
        }
        if (isNaN(e.target.value)) {
          /*   err = {
             ...err,
             price: "Invalid Price. Please enter price or leave 0"
           } */
          setPrice(parseFloat(e.target.value));
          /* setErrors(err) */
          return;
        }
        break;

      case "isrc song":
        setISRC(e.target.value.toUpperCase());
        if (isrcReg.test(e.target.value)) {
          setIsrcValid(true);
        } else {
          setIsrcValid(false);
        }
        break;

      case "isrcType":
        switch (e.target.value) {
          case "auto":
            setIsrcType("auto");
            //isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;

          case "custom":
            setISRC("");
            setIsrcType("custom");
            isrcRef.current.value = "";
            break;

          default:
            setIsrcType("auto");
            //isrcRef.current.value = autoIsrcCode;
            setISRC(autoIsrcCode);
            break;
        }
        break;

      case "storageToken":
        setToken(e.target.value);
        break;
      case "releaseDateSong song":
        setReleaseDate(e.target.value);
        break;
      case "about song":
        setAbout(newlineFormatter(e.target.value));
        break;
      case "lyrics song":
        setLyrics(newlineFormatter(e.target.value));
        break;
      case "albumArt":
        const currArt = albumArt;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setAlbumArt((prev) => {
              return { ...prev, file: e.target.files[0] };
            });
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setAlbumArt(currArt);
                return false;
              }

              let imageFileName;
              if (!albumArt.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
                
              } else {
                imageFileName = `${albumArt?.name}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
              }
              setAlbumArt((prev) => {
                  return { ...prev, name: imageFileName };
                });
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `artists/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setAlbumArt((prev) => {
                    return { ...prev, progress: progress };
                  });

                  if (isCancelSongUpload) {
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                    } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                    }
                  }
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      if (isCancelSongUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      break;
                    default:
                      if (isCancelSongUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setAlbumArt(currArt);
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setAlbumArt(currArt);
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                      setAlbumArt((prev) => {
                        return {
                          ...prev,
                          url: downloadURL,
                          file: e.target.files[0],
                          ref: uploadTask.snapshot.ref.fullPath,
                        };
                      });
                    }
                  );
                }
              );
            };
          };
        }
        break;
      case "video":
        const currVid = videoFile;
        if (e.target.value && e.target.files[0]) {
          let reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.

            //Set the Base64 string return from FileReader as source.

            setVideoFile((prev) => {
              return { ...prev, file: e.target.files[0] };
            });

            //let artBlob = convertFileToBytes(e.target.files[0])
            let videoFileName;
            if (!albumArt.name) {
              videoFileName = `${Math.round(Math.random() * 10000000000000)}.${
                e.target.files[0].name.split(".")[
                  e.target.files[0].name.split(".").length - 1
                ]
              }`;
              setAlbumArt((prev) => {
                return { ...prev, name: videoFileName };
              });
            } else {
              videoFileName = albumArt.name;
            }
            const uploadTask = uploadBytes(
              storageRef(
                storage,
                `artists/${currentUser?.uid}/${videoFileName}`
              ),
              e.target.files[0],
              { contentType: e.target.files[0].type }
            );

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setVideoFile((prev) => {
                  return { ...prev, progress: progress };
                });

                if (isCancelSongUpload) {
                  if (!uploadTask.isComplete()) {
                    //Upload is not complete yet, let's cancel
                    uploadTask.cancel();
                  } else {
                    //Upload is complete, but user wanted to cancel. Let's delete the file
                    uploadTask.snapshot.ref.delete();
                    // storageRef.delete(); // will delete all your files
                  }
                }
                //console.log("Upload is " + progress + "% done");
                switch (snapshot.state) {
                  case "paused":
                    break;
                  case "running":
                    if (isCancelSongUpload) {
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                      } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                      }
                    }
                    break;
                  default:
                    if (isCancelSongUpload) {
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                      } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                      }
                    }
                    //console.log("Upload is " + progress + "% done");
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    setVideoFile(currArt);
                    // User canceled the upload
                    break;
                  // ...
                  case "storage/unknown":
                    setVideoFile(currArt);
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                  default:
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadUrl(uploadTask.snapshot.ref).then((downloadURL) => {
                  //console.log("File available at", downloadURL);
                  setVideoFile((prev) => {
                    return {
                      ...prev,
                      url: downloadURL,
                      file: e.target.files[0],
                      ref: uploadTask.snapshot.ref.fullPath,
                    };
                  });
                });
              }
            );
          };
        }
        break;
      default:
        break;
    }
  };

  const nextPage = () => {
    const currPage = pageNumber;
    if (currPage < 7) {
      setPageNum(currPage + 1);
    }
    window.scrollTo(0, 500);
  };

  const prevPage = () => {
    const currPage = pageNumber;
    if (currPage > 0) {
      setPageNum(currPage - 1);
    }
  };


  return (
    <>
      <FormWrapper>   
       <FormWrapper row border>
              <Page0
                albumArt={albumArt}
                songName={songName}
                featArtists={featArtists}
                artists={artists}
                isExplicit={isExplicit}
                releaseDate={releaseDate}
                isDownloadable={isDownloadable}
                price={price}
                currency={currency}
                songUpload={songUpload}
                setSong={setSong}
                setDuration={setDuration}
              />
            </FormWrapper>
        <FormWrapper row border style={{alignItems: 'flex-start'}}>
          <FormWrapper>
         
          <FormWrapper border>
              {pageNumber === 1 && <Page1
                index={"song"}
                album={info?.album && {...info?.album,
                  releaseDate: info?.album?.displayDate
                }}
                handleToggle={handleToggle}
                isCover={isCover}
                isExplicit={isExplicit}
                isPreRelease={isPreRelease}
                isPreview={isPreview}
                isStreamable={isStreamable}
                isSubscription={isSubscription}
                isDownloadable={isDownloadable}
                isMintable={isMintable}
                isVideo={isVideo}
                isVisualizer={isVisualizer}
                isUserRoyalties={isUserRoyalties}
                songNameRef={songNameRef}
                songName={songName}
                handleInput={handleInput}
                errors={errors}
                setCurrency={setCurrency}
                songPriceRef={songPriceRef}
                currency={currency}
                price={price}
                genreRef={genreRef}
                genre={genre}
                mainGenre={mainGenre}
                removeGenre={removeGenre}
                filteredGenres={filteredGenres}
                addGenre={addGenre}
                subgenre={subgenre}
                subGenres={subGenres}
                filteredSubGenres={filteredSubGenres}
              />}
              {pageNumber === 2 &&<Page2
                index={"song"}
                artists={artists}
                artistSearchRef={artistSearchRef}
                removeArtist={removeArtist}
                clearArtistForm={clearArtistForm}
                setCurrentArtist={setCurrentArtist}
                setArtistEditing={setArtistEditing}
                handleInput={handleArtistInput}
                searchInput={searchInput}
                currentArtist={currentArtist}
                walletAddressRef={walletAddressRef}
                artistSearch={artistSearch}
                artistNameRef={artistNameRef}
                artistFullNameRef={artistFullNameRef}
                artistIdRef={artistIdRef}
                selectArtist={selectArtist}
                validateArtistInfo={validateArtistInfo}
                artistEditng={artistEditng}
                featArtists={featArtists}
                fArtistSearchRef={fArtistSearchRef}
                setCurrentFArtist={setCurrentFArtist}
                setFArtistEditing={setFArtistEditing}
                handleArtistInput={handleArtistInput}
                currentFArtist={currentFArtist}
                fWalletAddressRef={fWalletAddressRef}
                featArtistSearch={featArtistSearch}
                fArtistNameRef={fArtistNameRef}
                fArtistFullNameRef={fArtistFullNameRef}
                fArtistIdRef={fArtistIdRef}
                fArtistEditng={fArtistEditng}
                errors={errors}
              />}
            
              {pageNumber === 3 &&<Page3
                index={"song"}
                album={info?.album && {...info?.album,
                  releaseDate: info?.album?.displayDate
                }}
                isStreamable={isStreamable}
                isDownloadable={isDownloadable}
                isrcType={isrcType}
                isrcRef={isrcRef}
                isPreRelease={isPreRelease}
                handleInput={handleInput}
                isrc={isrc}
                errors={errors}
                aboutRef={aboutRef}
                lyricsRef={lyricsRef}
                releaseDateRef={releaseDateRef}
                about={about}
                lyrics={lyrics}
                releaseDate={releaseDate}
              />}
            
              {pageNumber === 4 &&<Page4
                index={"song"}
                composers={composers}
                composerSearchRef={composerSearchRef}
                removeArtist={removeArtist}
                clearArtistForm={clearArtistForm}
                setComposerEditing={setComposerEditing}
                setCurrentComposer={setCurrentComposer}
                handleArtistInput={handleArtistInput}
                searchInput={searchInput}
                currentComposer={currentComposer}
                composerEditing={composerEditing}
                composerWalletAddressRef={composerWalletAddressRef}
                composerSearch={composerSearch}
                composerNameRef={composerNameRef}
                composerIdRef={composerIdRef}
                composerFullNameRef={composerFullNameRef}
                selectArtist={selectArtist}
                validateArtistInfo={validateArtistInfo}
                errors={errors}
                writerEditing={writerEditing}
                writers={writers}
                writerSearchRef={writerSearchRef}
                setCurrentWriter={setCurrentWriter}
                setWriterEditing={setWriterEditing}
                currentWriter={currentWriter}
                writerWalletAddressRef={writerWalletAddressRef}
                writerSearch={writerSearch}
                writerNameRef={writerNameRef}
                writerFullNameRef={writerFullNameRef}
                writerIdRef={writerIdRef}
                producerSearchRef={producerSearchRef}
                producers={producers}
                setCurrentProducer={setCurrentProducer}
                setProducerEditing={setProducerEditing}
                currentProducer={currentProducer}
                producerWalletAddressRef={producerWalletAddressRef}
                producerSearch={producerSearch}
                producerNameRef={producerNameRef}
                producerFullNameRef={producerFullNameRef}
                producerIdRef={producerIdRef}
                producerEditing={producerEditing}
              />}
            
              {pageNumber === 5 &&<Page5
                videoFile={videoFile}
                visualizerFile={visualizerFile}
                setVideoFile={setVideoFile}
                setVisualizerFile={setVisualizerFile}
              />}
            
             {pageNumber === 6 && <Page6
                license={license}
                setCreativeCommons={setCreativeCommons}
              />}
            
              {pageNumber === 7 && <Page7
                payoutArtists={payoutArtists}
                userRoyalties={userRoyalties}
                setUserRoyalties={setUserRoyalties}
                isUserRoyalties={isUserRoyalties}
                errors={errors}
                setErrors={setErrors}
                payoutArtistSearchRef={payoutArtistSearchRef}
                removeArtist={removeArtist}
                clearArtistForm={clearArtistForm}
                setCurrentPayoutArtist={setCurrentPayoutArtist}
                setPayoutEditing={setPayoutEditing}
                handleArtistInput={handleArtistInput}
                searchInput={searchInput}
                currentPayoutArtist={currentPayoutArtist}
                payoutArtistFullNameRef={payoutArtistFullNameRef}
                payoutArtistWalletAddressRef={payoutArtistWalletAddressRef}
                payoutSearch={payoutSearch}
                payoutArtistNameRef={payoutArtistNameRef}
                payoutArtistIdRef={payoutArtistIdRef}
                artistPayoutRef={artistPayoutRef}
                selectArtist={selectArtist}
                validateArtistInfo={validateArtistInfo}
                payoutEditing={payoutEditing}
              />}
            </FormWrapper>
            </FormWrapper>
          {!info?.album && <FormWrapper>
          <SongArtCoverWrapper>
          <Zoom>
            <SongDetailsSection>
              <SongDetailsSectionTitle>Album Art</SongDetailsSectionTitle>

              <AlbumUploadsContainer
                for={albumArt?.progress > 0 && !albumArt?.url ? "" : "albumArt"}
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {albumArt?.url ? (
                      `${albumArt?.file?.name ? albumArt?.file?.name : albumArt?.name }`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add Album Art (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {albumArt?.progress > 0 && !albumArt?.url && (
                    <SongDetailsText
                      onClick={() => setCancelSongUpload(true)}
                      style={{ textDecoration: "underline" }}
                    >
                      Cancel
                    </SongDetailsText>
                  )}
                  {albumArt?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumArtPreviewContainer albumArt={albumArt?.url}>
                  {albumArt?.progress > 0 && !albumArt?.url && (
                    <>
                      <LoadingScreen
                        transparent
                        isLoaded={albumArt.url ? true : false}
                      />
                      {albumArt.progress < 100
                        ? `${albumArt?.progress.toFixed(2)}%`
                        : `Processing`}
                    </>
                  )}
                  {(albumArt?.url || !albumArt?.progress) && (
                    <>
                      <AlbumArtPreview
                        src={albumArt?.url ? albumArt.url : albumArtPlaceholder}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </>
                  )}
                </AlbumArtPreviewContainer>
                <AlbumUploadsInput
                  id="albumArt"
                  ref={albumArtRef}
                  type={"file"}
                  name="albumArt"
                  accept="image/x-png,image/gif,image/jpeg, image/jpg"
                  multiple="false"
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
              </AlbumUploadsContainer>
            </SongDetailsSection>
          </Zoom>
        </SongArtCoverWrapper>
          </FormWrapper>}
         
      
      
        </FormWrapper> 
        {errors.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{errors.message}</SongDetailsText>
        </ErrorWrapper>
      )}
        <PageIndexer pages={7} currentPage={pageNumber} onClick={setPageNum} />
        <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}>
      
        {pageNumber > 1 &&  (
          <MusicUploadsButton outline onClick={() => prevPage()}>
            Prev
          </MusicUploadsButton>
        )}
        {pageNumber < 7 && (
          <MusicUploadsButton
            outline
            onClick={() => {
              nextPage();
              return;
            }}
          >
            Next
          </MusicUploadsButton>
        )}
      </div>
      
      </FormWrapper>
      <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}>
        <Button1 onClick={() => {
        /* //console.log({
        isrc: isrc,
        lyrics: lyrics?.trim().length > 0 ? lyrics : undefined,
        about: about?.trim()?.length > 0 ? about: undefined,
        isCover,
        isDownloadable,
        explicit: isExplicit,
        isMintable,
        price: isDownloadable ? price : undefined,
        currency: isDownloadable ? currency : undefined,
        songCredits: {
          writers,
          producers,
          composers
        },
      royalties: payoutArtists}) */
      if((visualizerFile?.progress > 0 && visualizerFile?.progress < 100) || (videoFile?.progress > 0 && videoFile?.progress < 100)){
       addAlert({
            title: "Uploading Visuals",
            message: 'Visuals still uploading...',
            type: ALERT_TYPES.WARNING
          })
          return
      }
      if((validateSongInfo())){
       
          submitChanges(CONTENT_TYPES.SONG, 
            { 
              isrc: isrc,
              lyrics: lyrics?.trim().length > 0 ? lyrics : undefined,
              about: about?.trim()?.length > 0 ? about: undefined,
              isCover,
              isDownloadable,
              explicit: isExplicit,
              isMintable,
              isPreRelease: true,
              isPreview: isPreview,
              isUserRoyalties,
              userRoyalties: (!info?.isUserRoyalties && isUserRoyalties) ? userRoyalties : undefined,
              artists: artists,
              featuredArtists: featArtists,
              releaseDate: releaseDate,
              licensing: license,
              genre: mainGenre.id,
              subGenres: subGenres?.length > 0 ? subGenres.map((sg) => {
                return sg?.id
              }) : undefined,
              displayDate: new Date(releaseDate).toISOString().split('T')[0],
              video: getObjectLength(videoFile) > 0 ? {
                url: videoFile?.url,
                duration: videoFile?.duration,
                ref: videoFile?.ref
              } : undefined,
              visualizer: getObjectLength(visualizerFile) > 0 ? {
                url: visualizerFile?.url,
                duration: visualizerFile?.duration,
                ref: visualizerFile?.ref
              } : undefined,
              songUrl: songUpload.url,
                      duration: duration ? duration : songUpload?.duration,
                      songRef: songUpload.ref,
                      mp3Url: songUpload?.mp3Url,
                      mp3Ref: songUpload?.mp3Ref,
                      albumArt: albumArt.url,
                      albumArtRef: albumArt.ref,
              price: isDownloadable ? price : undefined,
              currency: isDownloadable ? currency : undefined,
              songCredits: {
                writers,
                producers,
                composers
              },
            royalties: payoutArtists})
        }
      }}>Submit Changes</Button1>
      <Button1 onClick={() => {
        if((validateSongInfo())){
          openModal()
          modalContent(<OptionModal onCancel={() => {
          closeModal() 
          modalContent()}} 
          heading={'Release Song Immediately'} 
          additionalText={'Your song will be released ahead of the pre release date. Would you like to continue?'}>

            <HoverOption onClick={() => {
              immediateRelease(CONTENT_TYPES.SONG, 
                { 
                  lyrics: lyrics?.trim().length > 0 ? lyrics : undefined,
                  about: about?.trim()?.length > 0 ? about: undefined,
                  isCover,
                  isDownloadable,
                  explicit: isExplicit,
                  isMintable,
                  isUserRoyalties,
                  userRoyalties: (!info?.isUserRoyalties && isUserRoyalties) ? userRoyalties : undefined,
                  artists: artists,
                  featuredArtists: featArtists,
                  releaseDate: new Date().toISOString().split('T')[0],
                  licensing: license,
                  genre: mainGenre.id,
                  subGenres: subGenres?.length > 0 ? subGenres.map((sg) => {
                    return sg?.id
                  }) : undefined,
                  displayDate: new Date().toISOString().split('T')[0],
                  video: getObjectLength(videoFile) > 0 ? {
                    url: videoFile?.url,
                    duration: videoFile?.duration,
                    ref: videoFile?.ref
                  } : undefined,
                  visualizer: getObjectLength(visualizerFile) > 0 ? {
                    url: visualizerFile?.url,
                    duration: visualizerFile?.duration,
                    ref: visualizerFile?.ref
                  } : undefined,
                  songUrl: songUpload.url,
                          duration: duration ? duration : songUpload?.duration,
                          songRef: songUpload.ref,
                          mp3Url: songUpload?.mp3Url,
                          mp3Ref: songUpload?.mp3Ref,
                          albumArt: albumArt.url,
                          albumArtRef: albumArt.ref,
                  price: isDownloadable ? price : undefined,
                  currency: isDownloadable ? currency : undefined,
                  songCredits: {
                    writers,
                    producers,
                    composers
                  },
                royalties: payoutArtists})
            }}>Continue</HoverOption>
          </OptionModal>)
          
        }
      }}>Release Immediately</Button1>
      <Button1
        onClick={() => {
          navigate(`/song/${info?.songId}`, {replace: true});
        }}
      >
        Cancel
      </Button1></div>
    </>
  );
};

export default SongPreReleaseEdit;
