import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CONTENT_TYPES } from '../../../utils/Enum'
import { Button1 } from '../../ButtonElement'
import ContentList from '../../ContentList'
import { DraggableSongList } from '../../ContentList/SongList'
import ContentWrapper from '../../ContentWrapper'
import { FormWrapper } from '../../CustomForm/CustomFormElements'
import LoadingScreen, { ModalLoadingScreen } from '../../LoadingScreen'
import { HoverOption } from '../../MenuOptions/MenuOptionsElements'
import { useModal } from '../../Modal/ModalContext'
import { SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements'
import NFTStorageTokenForm from '../../MusicUploadsForm/NFTStorageTokenForm'
import OptionModal from '../../OptionModal'
import Page1 from './Page1'
import NFTStorageSonarAPIKeyForm from '../../NFTStorageSonarAPIKeyForm'
import UserRoyaltyForm from '../../MusicUploadsForm/UserRoyaltyForm'
import { Small } from '../../QRCodeForm/QrCodeFormElements'
import { getObjectLength } from '../../../utils'

const AlbumEdit = ({album, submitChanges, currentUser, validateNFTStorageKey}) => {
    const location= useLocation()
    const navigate  = useNavigate()
    const [albumInfo, setAlbumInfo] = useState(album ? album : {})
    const [userRoyalties, setUserRoyalties] = useState(album?.userRoyalties ? album?.userRoyalties : {
      percentage: 10,
      shares: 100,
      price: 1,
      shareReserve: 0,
      ticker: "",
      currency: "XRP",
    })
    const [songs, setSongs] = useState(album?.songs ? album?.songs : [])
    const { modalContent, isOpen, toggleModal, openModal, closeModal } = useModal();


    const handleInput = (e) => {
      switch (e.target.name) {
        case "album price":
          setAlbumInfo((prev) => {
            return { ...prev, price: e.target.value };
          });
          if(e.target.value[0] === "0"){
            setAlbumInfo((prev) => {
              return { ...prev, price: e.target.value.substring(1,e.target.value.length) };
            })
           }else{
             setAlbumInfo((prev) => {
              return { ...prev, price: e.target.value };
            })
           }
           
  /*          if(!isNaN(e.target.value) && e.target.value > 0){
             delete errs.price
             setErrors(errs)
             return
           } */
           if(e.target.value?.length === 0){
             
             setAlbumInfo((prev) => {
              return { ...prev, price: 0 };
            })
           }
           if(isNaN(e.target.value)){
           /*   err = {
               ...err,
               price: "Invalid Price. Please enter price or leave 0"
             } */
  
             setAlbumInfo((prev) => {
              return { ...prev, price: parseFloat(e.target.value) };
            })
             /* setErrors(err) */
             return
           }
          break;
  
       
        case "album about":
          setAlbumInfo((prev) => {
            return { ...prev, about: e.target.value };
          });
          break;
        default:
          break;
      }
    };
  
    const handleToggle = (type) => {
      let streamable = albumInfo?.isStreamable ? albumInfo?.isStreamable : false;
      let downloadable = albumInfo?.isDownloadable
        ? albumInfo?.isDownloadable
        : false;
      let subscription = albumInfo?.isSubscription
        ? albumInfo?.isSubscription
        : false;
      let mintable = albumInfo?.isMintable ? albumInfo?.isMintable : false;
      let explicit = albumInfo?.isExplicit ? albumInfo?.isExplicit : false;
      switch (type) {
        case "explicit":
          explicit = !explicit;
          setAlbumInfo((prev) => {
            return { ...prev, isExplicit: explicit };
          });
          break;
        case "streamable":
          streamable = !streamable;
          if (streamable) {
            setAlbumInfo((prev) => {
              return { ...prev, isSubscription: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isStreamable: streamable };
          });
          break;
        case "downloadable":
          downloadable = !downloadable;
          if (downloadable) {
            setAlbumInfo((prev) => {
              return { ...prev, isSubscription: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isDownloadable: downloadable };
          });
          break;
        case "subscription":
          subscription = !subscription;
          if (subscription) {
            setAlbumInfo((prev) => {
              return { ...prev, isDownloadable: false, isStreamable: false };
            });
          }
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: subscription };
          });
          break;
        case "mintable":
          mintable = !mintable;
          if (mintable) {
            toggleModal();
            modalContent(
              <OptionModal
                onCancel={() => {
                  toggleModal();
                  modalContent();
                }}
                heading={"Set Mintable?"}
              >
                <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
          on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
                <HoverOption
                  onClick={async () => {
                    /*setAlbumInfo((prev) => {
                      return { ...prev, isMintable: true };
                  })
                   toggleModal()
                  modalContent() */
                    //modalContent(< UnavailableFeature/>)
                     modalContent(<>
                    <LoadingScreen transparent={true}/></>)
                    await validateNFTStorageKey(albumInfo?.artists[0]?.artistId).then(data => {
                      if(data){
                        setAlbumInfo((prev) => {
                          return { ...prev, isMintable: true };
                        });
                        toggleModal()
                            modalContent()
                      }else{
                        modalContent(<>
                        <OptionModal heading={"IPFS API Key Not Found"}>
                          <HoverOption onClick={() => {
                            modalContent(<>
                              <NFTStorageTokenForm artistId={albumInfo?.artists[0]?.artistId} currentUser={currentUser?.uid} setMintable={(value) => {
                                setAlbumInfo((prev) => {
                                  return { ...prev, isMintable: value };
                                });
                              }}/>
                            </>)
                          }}>
                            Add API Key
                          </HoverOption>
                          <HoverOption onClick={() => {
                            modalContent(<NFTStorageSonarAPIKeyForm artistId={albumInfo?.artists[0]?.artistId} currentUser={currentUser?.uid} onSuccess={() => {
                              setAlbumInfo((prev) => {
                              return { ...prev, isMintable: true };
                            })}}/>)
                          }}> Use Sonar Muse API Key</HoverOption>
                          <HoverOption onClick={() => {
                            toggleModal()
                            modalContent()
                          }}>
                           Cancel
                          </HoverOption>
                        </OptionModal>
                      </>)
                         
                      }
                    }).catch(err => {
                      setAlbumInfo((prev) => {
                        return { ...prev, isMintable: false };
                      });
                      modalContent(<>
                        <OptionModal heading={"Something Went Wrong. Please try again"}>
                          <HoverOption onClick={() => {
                            toggleModal()
                            modalContent()
                          }}>
                            Close
                          </HoverOption>
                        </OptionModal>
                      </>)
                    })
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            );
          } else {
            setAlbumInfo((prev) => {
              return { ...prev, isMintable: false };
            });
          } 
  
          break;
        
          case "royalties":
            let royalties = !albumInfo?.isUserRoyalties;
            if (albumInfo?.isUserRoyalties === false) {
              openModal();
              modalContent(
                <OptionModal
                  onCancel={() => {
                    closeModal();
                    modalContent();
                  }}
                  heading={"Enable User Royalties Share"}
                  additionalText={
                    "Allow your supporters to invest and earn from your music sales and royalty streams."
                  }
                >
                  <Small>Note: Once enabled, it cannot be disabled.</Small>
                  <HoverOption
                    onClick={() => {
                      if (royalties) {
                        setAlbumInfo(prev => {
                          return {
                            ...prev,
                            isUserRoyalties: true,
                            isSubscription: false,
                            isStreamable: true
                          }
                        })
                      }
                     
                      closeModal();
                      modalContent();
                    }}
                  >
                    OK
                  </HoverOption>
                </OptionModal>
              );
            } else {
              setAlbumInfo(prev => {
                return {
                  ...prev,
                  isUserRoyalties: false
                }
              })
            }
        
          default:
          break;
      }
      if (downloadable === false && streamable === false) {
        setAlbumInfo((prev) => {
          return { ...prev, isSubscription: true, isStreamable: false };
        });
      }
    };
  
    const setCurrency = (currency) => {
      setAlbumInfo((prev) => {
        return { ...prev, currency: currency };
      });
    };


    const validateUserRoyalties = () => {
      let e = albumInfo?.errors
      let royaltyErrors
      delete e?.userRoyalties
      setAlbumInfo(prev => {
        return {
          ...prev,
          errors: e
    }})
      if(getObjectLength(userRoyalties) > 0){
        if(userRoyalties?.ticker.trim().length < 3){
          royaltyErrors = {
            ...royaltyErrors,
            ticker: 'Invalid Ticker. Please enter a valid tick. min 3 characters'
          }
        }
        if(userRoyalties.percentage < 10 || userRoyalties.percentage > 50){
          royaltyErrors = {
            ...royaltyErrors,
            percentage: 'Invalid royalty share percentage. please input value between 10% - 50%'
          }
        }
        if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
          royaltyErrors = {
            ...royaltyErrors,
            reserve:
              "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
          };
        }
        if(getObjectLength(royaltyErrors) > 0){
          setAlbumInfo(prev => {
            return {
              ...prev,
              errors:{
                ...prev?.errors,
                userRoyalty: royaltyErrors
              }
              
            }
          })
          return false
        }
        return true
      }
      return false
    }


  return (
    <>
    <FormWrapper><Page1 albumInfo={albumInfo}
    handleToggle={handleToggle}
    handleInput={handleInput}
    setCurrency={setCurrency}
    album={album}
    /></FormWrapper>
    {!album?.isUserRoyalties && albumInfo?.isUserRoyalties && <FormWrapper>
      <UserRoyaltyForm   isUserRoyalties={albumInfo?.isUserRoyalties}
              royaltyInfo={userRoyalties}
              setRoyaltyInfo={setUserRoyalties} index={'album'} errors={albumInfo?.errors} setErrors={(err) => setAlbumInfo(prev => {
                return {
                  ...prev,
                  errors: err
                }
              })} />
      </FormWrapper>}
    <FormWrapper><ContentWrapper heading={"Songs"}>
        <DraggableSongList content={songs} setContent={setSongs} />
      </ContentWrapper></FormWrapper>
      <div style={{
        flexDirection: "row", 
      display: "flex", 
      width: "100%", 
      alignContent:"center", 
      alignItems:"center", 
      justifyContent: "space-around"}}><Button1 onClick ={ () =>{
      /* //console.log({
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        about: albumInfo?.about,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      }) */
     if(albumInfo?.isUserRoyalties && !validateUserRoyalties()){
      return 
     }
      submitChanges(CONTENT_TYPES.ALBUM, {
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        isUserRoyalties: albumInfo?.isUserRoyalties,
        about: albumInfo?.about,
        userRoyalties: (!album?.isUserRoyalties && albumInfo?.isUserRoyalties) ? userRoyalties : undefined,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      })}
    }>Submit Changes</Button1>
    <Button1 onClick={() => {
        navigate( `/album/${album?.albumId}`,{replace: true});

    }}>Cancel</Button1></div>
    </>
  )
}

export default AlbumEdit