import React from 'react'
import { AlbumDetailsSection, AlbumDetailsSectionTitle } from '../../MusicUploadsFormElements'
import UserRoyaltyForm from '../../UserRoyaltyForm'

const Page5 = ({isUserRoyalties, userRoyalties, setUserRoyalties, errors, setErrors}) => {
  return (
    <>
    <AlbumDetailsSection>
        <AlbumDetailsSectionTitle>
            User Royalties
        </AlbumDetailsSectionTitle>
        {isUserRoyalties && <UserRoyaltyForm royaltyInfo={userRoyalties} setRoyaltyInfo={setUserRoyalties} errors={errors} setErrors={setErrors} index={'album'}/>}
    </AlbumDetailsSection>
    
    </>
  )
}

export default Page5