import React, { useState, useRef, useEffect } from "react";
import { GravatarQuickEditor, GravatarQuickEditorCore } from '@gravatar-com/quick-editor';
import { ArtistContent } from ".";
import {
  ArtistContentContainer,
  ArtistContentHeading,
  ArtistContentHeadingWrapper,
  ArtistContentWrapper,
  ArtistProfileNavLink,
  BioInput,
  ErrorText,
  Input,
  InputLabel,
  SettingsWrapper,
  ToggleWrapper,
} from "./ArtistContentElements";
import { FaToggleOn, FaToggleOff, FaInfo } from "react-icons/fa";
import {MdRefresh} from "react-icons/md"
import {
  ActionButton,
  ActionButtonsContainer,
} from "../ArtistHeader/ArtistHeaderElements";
import {
  ARTIST_FORM_INPUT_TYPES,
  NFT_STORAGE_TYPES,
  PAYMENT_WALLETS,
  SERVERS,
  SONAR_MUSE_NFT_STORAGE_KEY,
  TRANSACTION_OPTIONS,
} from "../../../utils/Enum";
import ToggleButton from "../../ToggleButton";
import Modal from "../../Modal";
import QRCodeForm from "../../QRCodeForm";
import { Theme } from "../../WebsiteThemeElements";
import {
  FormButton1,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../../CustomForm/CustomFormElements";
import {
  datetoRippleEpoch,
  getObjectLength,
  newlineFormatter,
  stringFormatter,
  validateAddress,
} from "../../../utils";
import XRPL from "../../XRPL";
import ContentWrapper from "../../ContentWrapper";
import AlbumArt from "../../AlbumArt";
import { DEFAULT_IMAGE, getUserProfile, hashEmail } from "../../GravatarAPI";
import {
  getDownloadUrl,
  storage,
  storageRef,
  uploadBytes,
} from "../../Authorization/firebase";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useModal } from "../../Modal/ModalContext";
import { PushSpinner } from "react-spinners-kit";
import CurrencySelector from "../../CurrencySelector";
import ToolTip from "../../ToolTip";
import useArtist from "../../../hooks/useArtist";
import { Button1, CustomButton, CustomButton1 } from "../../ButtonElement";
import { Small, TextRoute } from "../../QRCodeForm/QrCodeFormElements";
import MicropaymentsFundsClaimer, { MicropaymentFundsDisable } from "../../MicropaymentsFundsClaimer";
import { getPaymentChannelInfo, userChannelFunds } from "../../../utils/xrpl";
import { Client } from "xrpl";
import { useNavigate } from "react-router-dom";
import { getArtistPendingMicropayments } from "../../../utils/dbUtils/artistUtils";
import axiosInstance from "../../Authorization/client";
import { Nav, NavContainer, NavLink } from "../../NavLink/NavLinkElements";
import GravatarSettings from "../../GravatarAPI/GravatarSettings";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";
import Checkbox from "../../Checkbox";

export default function ArtistSettings({
  artistId,
  artist,
  gravatarProfile,
  artistSettings,
  about,
  getProfile,
  setTab
}) {
  const ppRegex = /^[\x36]{1}.ilp.\w{5,}.\w|\d{5,}$/;
  const {themeColors}= useWebsiteTheme()
  const { toggleModal, modalContent, closeModal, openModal, noCloseModal } = useModal();
  const navigate  = useNavigate()
  const { getArtistFunds } = useArtist({});
  const storageToken = SONAR_MUSE_NFT_STORAGE_KEY;
  const [storageType, setStorageType] = useState( artist?.ipfsStorage?.type ? artist?.ipfsStorage?.type : 'default')
  const [currency, setCurrency] = useState(
    artist?.currency ? artist.currency : "XRP"
  );
  const [paymentChannel, setPaymentChannel] = useState(
    artist?.paymentChannel?.channel ? artist?.paymentChannel : undefined
  );
  const [funds, setFunds] = useState(artist?.funds ? artist?.funds : undefined); //useEffect to get funds in real time
  const [fundsCurrency, setFundsCurrency] = useState("XRP")
  const [curencyRate, setCurrencyRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(0)
  const [artistName, setName] = useState(artist?.name);
  const [donation, setDonation] = useState(getObjectLength(artist?.donation) > 0 ? artist.donation : {});
  const [donating, setDonating] = useState(getObjectLength(artist?.donation) > 0 ? true : false)
  const [fullName, setFullName] = useState(artist?.fullName);
  const [email, setEmail] = useState(artist?.email);
  const [hasGravatarProfile, setHasGravatar] = useState(artist?.email);
  const [currentTab, setCurrentTab] = useState('general')
  const [artistProfile, setProfile] = useState({});
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [tipping, setTipping] = useState(
    artistSettings?.tipping ? artistSettings?.tipping : false
  );
  const [errors, setErrors] = useState({});
  const [subscriptionFee, setFee] = useState(
    artist?.subscriptionFee ? artist.subscriptionFee : 0
  );
  const [showGallery, setShowGallery] = useState(
    artistSettings?.showGallery && artistSettings?.showGallery
  );
  const [showTrips, setTrips] = useState(artistSettings?.showTrips);
  const [headerType, setHeaderType] = useState(
    gravatarProfile?.profileBackground
      ? artistSettings?.headerType
        ? artistSettings.headerType
        : gravatarProfile.profileBackground.url
        ? "background"
        : "color"
      : false
  );
  const [bioType, setBioType] = useState(
    about?.bio
      ? artistSettings?.bioType
        ? artistSettings.bioType
        : "gravatar"
      : "custom"
  );
  const [profilePictureType, setProfilePictureType] = useState(
    artistSettings?.profilePictureType
      ? artistSettings?.profilePictureType
      : "gravatar"
  );

  const [bio, setBio] = useState(
    artistSettings?.bioType
      ? artistSettings?.bioType === "custom"
        ? artist?.bio
        : about?.bio
      : about?.bio
  );

  const [profilePicture, setProfilePicture] = useState(
    artistSettings?.profilePictureType
      ? artistSettings?.profilePictureType === "custom"
        ? artist.profilePicture
        : {}
      : {}
  );
  const [paymentPointer, setPaymentPointer] = useState(artist?.paymentPointer);
  const [walletAddress, setWalletAddress] = useState({ address: artist?.uid });
  const [devnetAddress, setDevnetAddress] = useState(
    artist?.devnetAddress ? artist?.devnetAddress : ""
  );
  const [nftStorageApiKey, setNftStorageApiKey] = useState(
    artist?.nftStorageAPIKey ? artist?.nftStorageAPIKey : ""
  );
  const toggleStyle = {
    display: "flex",
    color: "white",
  };
  const [storageApiKeys, setApiKeys] = useState(artist?.ipfsStorage?.apiKeys ? artist?.ipfsStorage?.apiKeys :{})

  useEffect(() => {
    if(artist?.email?.length > 0){
    getUserProfile(hashEmail(artist?.email)).then((profile) => {
      if(profile){
        setHasGravatar(true)
        return
      }
      setHasGravatar(false)
      return
    }).catch(err => {
      setHasGravatar(false)
    })
  }
       
    return () => {}
  }, [artist?.email]);

  useEffect(() => {
    if( fundsCurrency !== "XRP" ){
      if(fundsCurrency === "WYM"){
        setCurrencyRate("XRP")
        return
      }
      setRateLoaded(false)
      axiosInstance.get(`/api/${fundsCurrency}/getPrice`).then((rate) => {
        if(rate?.data?.price){
          setCurrencyRate(rate.data?.price)
          setRateLoaded(true)
          return
        }
        setCurrencyRate("XRP")
        setRateLoaded(true)
      }).catch(err => {
        setCurrencyRate("XRP")
        setRateLoaded(true)
      })
    }
  }, [fundsCurrency])

  useEffect(() => {
    getFunds()
    return () => {}
  }, [artistId])

  const getFunds = async () => {
    getArtistPendingMicropayments(artistId, setFunds)
    /* if(artistId && paymentChannel?.channel){
    const client = new Client(SERVERS.MAINNET[0]);
    let channels = [];
    await client.connect();
    try {
      let resp = await client.request({
        id: 1,
        command: "account_channels",
        account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
        destination_account: artist?.uid,
        ledger_index: "validated",
      });
  
      // Create a function to run on each API call.
      function printLedgerResult() {
        //console.log(resp["result"]);
      }
      const ledger_data_index = resp["result"]["ledger_index"];
      // Execute function at least once before checking for markers.
      do {
        //printLedgerResult();
  
        if (resp["result"]["marker"] === undefined) {
          break;
        }
  
        // Specify the same ledger and add the marker to continue querying.
        const ledger_marker = await client.request({
          command: "account_channels",
          account: PAYMENT_WALLETS.SUBSCRIPTION_MICROPAYMENTS,
          destination_account: artist?.uid,
          ledger_index: ledger_data_index,
          marker: resp["result"]["marker"],
          ledger_index: "validated",
        });
        channels.push(...ledger_marker?.result?.channels);
        resp = ledger_marker;
      } while (true);
      client.disconnect();
      if (channels?.length > 0) {
        let c = channels.filter((c) => c?.channel_id === paymentChannel?.channel)[0];
        setFunds(c?.amount - c?.balance)
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  } */
  };

  const enableMonetization = () => {
    openModal();
    modalContent(
      <>
        <OptionModal
          heading={"Enabling Web Monetization"}
          additionalText={
            "Note: To enable web monetization, you will need to sign a transaction with a fee of 0.1 XRP to fund your account"
          }
        >
          <HoverOption
            onClick={() =>
              noCloseModal(
                <QRCodeForm
                  heading={"Enabling Web Monetization"}
                  body={{
                    memo: `Artist (${artist?.uid } )is signing transaction to enable Web Monetization`,
                    instruction: `Artist (${artist?.uid }) is signing transaction to enable Web Monetization`,
                    sender: artist?.uid,
                    artistId: artistId,
                    type: "artist",
                  }}
                  type={TRANSACTION_OPTIONS.ENABLE_WEB_MONETIZATION}
                  onSuccess={onMonetizationEnableSuccess}
                  onFail={onMonetizationEnableFail}
                  cancelQr={onMonetizationEnableCancel}
                />
              )
            }
          >
            Enable Web Monetization
          </HoverOption>
          <HoverOption>Cancel</HoverOption>
        </OptionModal>
      </>
    );
  };

  const onMonetizationEnableSuccess = (data) => {
    closeModal();
    modalContent();
    setPaymentChannel(data.paymentChannel);
    setFunds(data.funds);
  };

  const onMonetizationEnableFail = () => {
    closeModal();
    modalContent();
  };

  const onMonetizationEnableCancel = () => {
    closeModal();
    modalContent();
  };

  const claimFunds = () => {
    openModal();
    modalContent(
      <MicropaymentsFundsClaimer
        funds={funds}
        paymentChannel={paymentChannel}
        name={artist?.uid}
        id={artistId}
        type={"Artist"}
        onSuccess={() => {
          closeModal();
          getFunds()
          modalContent();
        }}
      />
    );
  };

  const disableFunds = () => {
    openModal();
    modalContent(
      <MicropaymentFundsDisable
        funds={funds}
        paymentChannel={paymentChannel}
        name={artist?.uid}
        id={artistId}
        type={"Artist"}
        onSuccess={() => {
          closeModal();
          setPaymentChannel()
          modalContent();
        }}
      />
    );
  };

  const switchProfilePictureType = () => {
    if (profilePictureType === "gravatar") {
      setProfilePictureType("custom");
    } else {
      setProfilePictureType("gravatar");
    }
  };

  const switchBioType = () => {
    if (bioType === "gravatar") {
      setBioType("custom");
    } else {
      setBioType("gravatar");
    }
  };

  const switchHeaderType = () => {
    if (headerType === "color") {
      setHeaderType("background");
    } else {
      setHeaderType("color");
    }
  };

  const toggleTipping = () => {
    setTipping(!tipping);
  };
  const toggleDonation = () => {
    modalContent(<OptionModal heading={'Coming Soon'} additionalText={'Allow your fans to donate to a cause of your Choice'}>
      <HoverOption onClick={() => {
        closeModal()
        modalContent()
      }}>OK</HoverOption>
    </OptionModal>)
    //setDonating(!donating);
  };

  const handleInput = (e) => {
    let cErrors = errors;
    if (e.target.value?.trim() !== "") {
      switch (e.target.name) {
        case ARTIST_FORM_INPUT_TYPES.NAME:
          delete cErrors?.name;
          setName(e.target.value);
          break;
        case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
          delete cErrors?.fullName;
          setFullName(e.target.value);
          break;
        case ARTIST_FORM_INPUT_TYPES.EMAIL:
          delete cErrors?.email;
          setEmail(e.target.value);
          break;
        case ARTIST_FORM_INPUT_TYPES.ADDRESS:
          delete cErrors?.address;
          setWalletAddress({ address: e.target.value });
          break;
        case ARTIST_FORM_INPUT_TYPES.SUBSCRIPTION_FEE:
          setFee(parseInt(e.target.value));
          delete cErrors?.fee;
          break;
        case ARTIST_FORM_INPUT_TYPES.DONATION_ADDRESS:
          setDonation(prev => {return {
           ...prev,
           address: e.target.value
          }});
          delete cErrors?.donationAddress;
          break;
        case ARTIST_FORM_INPUT_TYPES.DONATION_CAUSE:
          setDonation(prev => {return {
            ...prev,
            cause: e.target.value
           }});
          delete cErrors?.donationCause;
          break;
        case ARTIST_FORM_INPUT_TYPES.DONATION_TARGET:
          setDonation(prev => {return {
            ...prev,
            target: parseFloat(e.target.value)
           }});
          delete cErrors?.donationTarget;
          break;
        case ARTIST_FORM_INPUT_TYPES.BIO:
          setBio(newlineFormatter(e.target.value));
          break;
        case ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER:
          setPaymentPointer(e.target.value);
          delete cErrors?.paymentPointer;
          break;
        case ARTIST_FORM_INPUT_TYPES.NFT_STORAGE_API_KEY:
          setNftStorageApiKey(e.target.value);
          delete cErrors?.nftStorageApiKey;
          break;
        case ARTIST_FORM_INPUT_TYPES.PROFILE_PICTURE:
          let currArt = profilePicture;
          if (e.target.value && e.target.files[0]) {
            toggleModal();
            modalContent();
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (e1) {
              //Initiate the JavaScript Image object.
              var image = new Image();

              //Set the Base64 string return from FileReader as source.
              image.src = e1.target.result;

              setProfilePicture({ file: e.target.files[0] });
              image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height < 1600 || width < 1600) {
                  alert("Height and Width must not be less than 1600px.");
                  setProfilePicture();
                  return false;
                }

                //let artBlob = convertFileToBytes(e.target.files[0])
                let imageFileName;
                if (!profilePicture?.name) {
                  imageFileName = `ProfilePicture.${
                    e.target.files[0].name.split(".")[
                      e.target.files[0].name.split(".").length - 1
                    ]
                  }`;
                  setProfilePicture((prev) => {
                    return { ...prev, name: imageFileName };
                  });
                } else {
                  imageFileName = profilePicture?.name;
                }
                const uploadTask = uploadBytes(
                  storageRef(storage, `artists/${artistId}/${imageFileName}`),
                  e.target.files[0],
                  { contentType: e.target.files[0].type }
                );

                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProfilePicture((prev) => {
                      return { ...prev, progress: progress };
                    });
                    /* if(isCancelSongUpload){
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                    } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                    }
                    } */
                    //console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case "paused":
                        break;
                      case "running":
                        /* if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        break;
                      default:
                        /*  if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                        //console.log("Upload is " + progress + "% done");
                        break;
                    }
                  },
                  (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                      case "storage/unauthorized":
                        // User doesn't have permission to access the object
                        break;
                      case "storage/canceled":
                        setProfilePicture(currArt);
                        // User canceled the upload
                        break;
                      // ...
                      case "storage/unknown":
                        setProfilePicture(currArt);
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                      default:
                        break;
                    }
                  },
                  () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadUrl(uploadTask.snapshot.ref).then(
                      (downloadURL) => {
                        setProfilePicture((prev) => {
                          return {
                            ...prev,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          };
                        });
                        //console.log("File available at", downloadURL);
                      }
                    );
                  }
                );
              };
            };
          }
          break;

        default:
          break;
      }
      setErrors(cErrors);
    }
  };

  const handleApiKeyInput = (e) => {
    switch (e.target.name) {
      case 'key':
        switch (storageType) {
          case NFT_STORAGE_TYPES.FILEBASE:
            let filebase = storageApiKeys?.filebase
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                filebase: {
                  ...prev?.filebase,
                  key: e.target.value
                }
              }
            })
          }else{
            delete filebase?.key
            setApiKeys(prev => {
              return {
                ...prev,
                filebase
              }
            })
          }
            break;
          case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
            let internetArchive = storageApiKeys?.internetArchive
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                internetArchive: {
                  ...prev?.internetArchive,
                  key: e.target.value
                }
              }
            })}else{
              delete internetArchive.key
              setApiKeys(prev => {
                return {
                  ...prev,
                  internetArchive
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.LIGHTHOUSE:
            let lighthouse = storageApiKeys?.lighthouse
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                lighthouse: {
                  ...prev?.lighthouse,
                  key: e.target.value
                }
              }
            })}else{
              delete lighthouse.key
              setApiKeys(prev => {
                return {
                  ...prev,
                  lighthouse
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.PINATA:
            let pinata = storageApiKeys?.pinata
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                pinata: {
                  ...prev?.pinata,
                  key: e.target.value
                }
              }
            })}else{
              delete pinata.key
              setApiKeys(prev => {
                return {
                  ...prev,
                  pinata
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.WEB3_STORAGE:
            let web3Storage = storageApiKeys?.web3Storage
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                web3Storage: {
                  ...prev?.web3Storage,
                  key: e.target.value
                }
              }
            })}else{
              delete web3Storage.key
              setApiKeys(prev => {
                return {
                  ...prev,
                  web3Storage
                }
              })
            }
            break;
        
          default:
            break;
        }
        
        break;
      case 'secret':
        switch (storageType) {
          case NFT_STORAGE_TYPES.FILEBASE:
            let filebase = storageApiKeys?.filebase
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                filebase: {
                  ...prev?.filebase,
                  secret: e.target.value
                }
              }
            })}else{
              delete filebase.secret
              setApiKeys(prev => {
                return {
                  ...prev,
                  filebase
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
            let internetArchive = storageApiKeys?.internetArchive
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                internetArchive: {
                  ...prev?.internetArchive,
                  secret: e.target.value
                }
              }
            })}else{
              delete internetArchive.secret
              setApiKeys(prev => {
                return {
                  ...prev,
                  internetArchive
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.LIGHTHOUSE:
            let lighthouse = storageApiKeys?.lighthouse
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                lighthouse: {
                  ...prev?.lighthouse,
                  secret: e.target.value
                }
              }
            })}else{
              delete lighthouse.secret
              setApiKeys(prev => {
                return {
                  ...prev,
                  lighthouse
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.PINATA:
            let pinata = storageApiKeys?.pinata
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                pinata: {
                  ...prev?.pinata,
                  secret: e.target.value
                }
              }
            })}else{
              delete pinata.secret
              setApiKeys(prev => {
                return {
                  ...prev,
                  pinata
                }
              })
            }
            break;
          case NFT_STORAGE_TYPES.WEB3_STORAGE:
            let web3Storage = storageApiKeys?.web3Storage
            if(e.target.value?.trim()?.length > 0){
            setApiKeys(prev => {
              return {
                ...prev,
                web3Storage: {
                  ...prev?.web3Storage,
                  secret: e.target.value
                }
              }
            })}else{
              delete web3Storage.secret
              setApiKeys(prev => {
                return {
                  ...prev,
                  web3Storage
                }
              })
            }
            break;
        
          default:
            break;
        }
        break;
      case 'jwt':
        setApiKeys(prev => {
          return {
            ...prev,
            pinata: {
              ...prev?.pinata,
              jwt: e.target.value
            }
          }
        })
        break;
      case 'bucketName':
        setApiKeys(prev => {
          return {
            ...prev,
            filebase: {
              ...prev?.filebase,
              bucketName: e.target.value
            }
          }
        })
        break;
    
      default:
        break;
    }
  
  }

  const validateStorageApiKeys = () => {
    let errors = {}
    
        if(storageType === NFT_STORAGE_TYPES.FILEBASE){
          if(getObjectLength(storageApiKeys?.filebase) < 3 ){
            errors = {
              ...errors,
              filebase: 'Missing API Data. Please fill in all the information'
            }
          }
        }
        if(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE){
          if(getObjectLength(storageApiKeys?.internetArchive) < 2 ){
            errors = {
              ...errors,
              internetArchive: 'Missing API Data. Please fill in all the information'
            }
          }
        }
        if(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE){
          if(getObjectLength(storageApiKeys?.lighthouse) < 1 ){
            errors = {
              ...errors,
              lighthouse: 'Missing API Data. Please fill in all the information'
            }
          }
        }
        if(storageType === NFT_STORAGE_TYPES.PINATA){
          if(getObjectLength(storageApiKeys?.pinata) < 3 ){
            errors = {
              ...errors,
              pinata: 'Missing API Data. Please fill in all the information'
            }
          }
        }

        if(getObjectLength(storageApiKeys?.filebase) > 0 && getObjectLength(storageApiKeys?.filebase) < 3 ){
          errors = {
            ...errors,
            filebase: 'Missing API Data. Fill in all the information, or clear it'
          }
        }

        if(getObjectLength(storageApiKeys?.internetArchive) > 0 && getObjectLength(storageApiKeys?.internetArchive) < 2 ){
          errors = {
            ...errors,
            internetArchive: 'Missing API Data. Fill in all the information, or clear it'
          }
        }

        if(getObjectLength(storageApiKeys?.pinata) > 0 && getObjectLength(storageApiKeys?.pinata) < 3 ){
          errors = {
            ...errors,
            pinata: 'Missing API Data. Fill in all the information, or clear it'
          }
        }
      console.log(errors)
      return errors
    }
  

  const validatePaymentPointer = (pp) => {
    return ppRegex.test(pp);
  };

  const onSuccess = async () => {
    setRequestUpdate(false);
    closeModal()
    modalContent()
    setUpdated(true);
    setErrors({ message: "Profile successfully updated" });
    await getProfile();
    navigate(`/artist/${artistId}`)
  };

  const onFailure = () => {
    setRequestUpdate(false);
    closeModal()
    modalContent()
    setUpdated(false);
    setErrors({ message: "Profile failed to update" });
  };

  const cancelUpload = () => {
    closeModal()
    modalContent()
    setRequestUpdate(false);
    setUpdated(false);
  };

  const validateInfo = () => {
    let cErrors = {};
    if (
      (artistName.length > 0 && artistName.trim() === "") ||
      artistName === ""
    ) {
      cErrors.name = "Artist Name cannot be empty";
    }
    if (fullName?.length > 0 && fullName?.trim() === "") {
      setFullName(artistName);
    }
    if (
      walletAddress.address.length > 0 &&
      validateAddress(walletAddress.address)
    ) {
      if (!walletAddress?.confirmed && walletAddress.address !== artist.uid) {
        cErrors.address = "You have not confirmed changing address";
      }
      if (walletAddress?.address?.trim() === "") {
        setWalletAddress({ address: artist.uid });
      }
    }
    if (walletAddress?.address?.trim() === "") {
      setWalletAddress({ address: artist.uid });
    }

    if (bioType === "custom" && (bio === about.bio || bio?.trim() === "")) {
      setBio();
    }
    let storageErrors = validateStorageApiKeys()
    if(getObjectLength(storageErrors) > 0){
      cErrors = {
        ...cErrors,
        storageApiKeys: storageErrors,
      }
    }
    if (Object.keys(cErrors).length === 0) {
      return true;
    } else if (Object.keys(cErrors).length > 0) {
      setErrors((prev) => {
        return { ...prev, ...cErrors };
      });
      return false;
    }
  };

  const updateProfile = () => {
    if (validateInfo() === true) {
      setErrors({});
      let profile = {
        artistSettings: {
          bioType,
          headerType,
          showTrips,
          showGallery,
          profilePictureType,
          tipping: tipping,
        },
        bio: bioType === "custom" && bio,
        name: artistName,
        fullName: fullName,
        profilePicture: profilePicture?.url
          ? {
              url: profilePicture?.url,
              ref: profilePicture?.ref,
            }
          : false,
        paymentPointer:
          paymentPointer?.trim()?.length > 0
            ? paymentPointer?.trim()
            : undefined,
        devnetAddress: devnetAddress,
        uid: walletAddress.address,
        email: email,
        ipfsStorage: { type: storageType,
          apiKeys: storageApiKeys
        },
        subscriptionFee: subscriptionFee > 0 ? subscriptionFee : undefined,
        currency: subscriptionFee > 0 ? currency : undefined,
      };
      openModal()
      modalContent(<QRCodeForm
            heading={`Updating ${artistName}'s Profile`}
            body={{
        artistId: artistId,
        sender: artist.uid,
        profile: profile,
        instruction: `Updating profile for artist ${artistName} (${artistId})`,
      }}
            cancelQr={cancelUpload}
            onSuccess={onSuccess}
            onFail={onFailure}
            type={TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE}
          ></QRCodeForm>)
      setRequestUpdate(true);
      return;
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          message: "Unable to update profile. Please review your information",
        };
      });
      return;
    }
  };

  return (
    <>
      
      <ContentWrapper heading={`${artist?.name} Settings`}>
        <NavContainer style={{marginBottom: '5%'}}><Nav>
          <NavLink active={currentTab === 'general'} onClick={() => setCurrentTab('general')}>General</NavLink>
          <NavLink active={currentTab === 'gravatar'} onClick={() => setCurrentTab('gravatar')}>Gravatar</NavLink>
          <NavLink active={currentTab === 'monetization'} onClick={() => setCurrentTab('monetization')}>Payments & Monetization</NavLink>
          <NavLink active={currentTab === 'tokenization'} onClick={() => setCurrentTab('tokenization')}>Tokenization</NavLink>
        </Nav></NavContainer>
        {currentTab === 'general' && <FormWrapper>
        
         
        <ArtistContentHeading>General</ArtistContentHeading>
    
      <FormWrapper border>
        <FormLabel>Artist Name</FormLabel>
        <FormInput1
          name={ARTIST_FORM_INPUT_TYPES.NAME}
          placeholder="Name"
          value={artistName}
          onChange={handleInput}
        />
        {errors?.name && <ErrorText>{errors.name}</ErrorText>}
      </FormWrapper>
      
      <FormWrapper border>
        <FormLabel>Full Name</FormLabel>
        <FormInput1
          name={ARTIST_FORM_INPUT_TYPES.FULL_NAME}
          placeholder="Full Name"
          value={fullName}
          onChange={handleInput}
        />
      </FormWrapper>
      
      <FormWrapper border>
        <FormLabel>Management address</FormLabel>
        <FormInput1
          name={ARTIST_FORM_INPUT_TYPES.ADDRESS}
          disabled={true}
          placeholder="Management address"
          value={walletAddress.address}
          onChange={handleInput}
        />
      </FormWrapper>
      <FormWrapper border>
        <SettingsWrapper><InputLabel>
          Devnet Wallet {" "}
        </InputLabel><XRPL setAddress={setDevnetAddress} /></SettingsWrapper>
        <FormInput1
          name={"Devnet Wallet"}
          placeholder="NFT Dev Net Wallet Address"
          value={devnetAddress}
          onChange={handleInput}
        />
        <FormText>
        A Dev Net wallet address to test out creating and selling NFToken
        collections on our platform.
      </FormText>
      <FormText>
        This address will be used to sign NFToken transactions on the XRPL
        Dev Net <b> ONLY</b>.
      </FormText>
      </FormWrapper>

    </FormWrapper>}
          {currentTab === 'gravatar' && <>
       <FormWrapper>
            <ArtistContentHeading>Gravatar Settings</ArtistContentHeading>
     
           { hasGravatarProfile && <>{profilePictureType === "custom" && 
          <FormWrapper border>
            <FormText>Profile Picture</FormText>
            <>
              <label
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleModal();
                  modalContent(
                    <OptionModal heading={"Profile Picture"}>
                      <HoverOption>
                        <label for={ARTIST_FORM_INPUT_TYPES.PROFILE_PICTURE}>
                          Upload New Image
                        </label>
                      </HoverOption>
                      {profilePicture?.url && (
                        <HoverOption
                          onClick={() => {
                            setProfilePicture();
                            toggleModal();
                            modalContent();
                          }}
                        >
                          Delete
                        </HoverOption>
                      )}
                    </OptionModal>
                  );
                }}
              >
                {
                  <AlbumArt
                    isRounded={true}
                    size={"18rem"}
                    alt={artistName}
                    albumArt={
                      profilePicture?.url
                        ? profilePicture?.url
                        : DEFAULT_IMAGE(600)
                    }
                  />
                }
                <FormInput1
                  style={{ display: "none" }}
                  id={ARTIST_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  name={ARTIST_FORM_INPUT_TYPES.PROFILE_PICTURE}
                  onChange={handleInput}
                  type={"file"}
                  accept="image/x-png,image/jpeg, image/jpg"
                  multiple="false"
                />
              </label>
            </>
          {profilePicture?.progress > 0 && !profilePicture?.url && (
            <>
              <PushSpinner loading={profilePicture?.url ? false : true} />
              {profilePicture.progress < 100
                ? `${profilePicture?.progress.toFixed(2)}%`
                : `Processing`}
            </>
          )}</FormWrapper>}
          <GravatarSettings type={'Artist'} hasGravatarProfile={hasGravatarProfile} email={email} options={{
            profilePictureType,
            switchProfilePictureType,
            headerType,
            switchHeaderType,
            bioType,
            bio,
            bioInputName: ARTIST_FORM_INPUT_TYPES.BIO,
            switchBioType,
            handleInput,
            tipping,
            setTipping,
            showGallery,
            setShowGallery,
            showTrips,
            setTrips,
            about,
            toggleTipping
          }}/>
         </>}
       </FormWrapper></>}
       {currentTab === 'monetization' && <FormWrapper>
          <ArtistContentHeading>Payments & Monetization</ArtistContentHeading>

          <FormWrapper border>
          <SettingsWrapper>
            <>
              <FormLabel>
                <>
                  Micropayments Subscription{" "}
                  <ToolTip
                    style={{ marginLeft: "2%" }}
                    text={
                      "Receive micropayments for music streams directly to your XRP wallet address."
                    }
                  >
                    <FaInfo
                      color={themeColors?.secondaryBg}
                      style={{
                        marginLeft: "1%",
                        border: "1px solid",
                        borderRadius: "50%",
                      }}
                    />
                  </ToolTip>
                </>
              </FormLabel>
              {!paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => {
                    enableMonetization();
                  }}
                >
                  Enable
                </ActionButton>
              )}
              {paymentChannel?.channel && (
                <ActionButton
                  style={{ padding: "1% 2%" }}
                  onClick={() => disableFunds()}
                >
                  Disable
                </ActionButton>
              )}
            </>
          </SettingsWrapper>
          {paymentChannel?.channel && funds / 1000000 > 0 && (
            <><SettingsWrapper style={{justifyContent: 'space-between'}}>
              <CurrencySelector setCurrency={setFundsCurrency} currency={fundsCurrency}/>
              <FormText>
                Available Funds:{" "}
                <div style={{ marginLeft: "2%" }}>
                  {fundsCurrency !== "XRP" && !isRateLoaded && 'Loading Balance...'}
                  {fundsCurrency !== "XRP" && isRateLoaded && parseFloat((funds / 1000000) * curencyRate).toFixed(6)}
                  {fundsCurrency === "XRP" && parseFloat(funds / 1000000).toFixed(6)} {fundsCurrency}
                </div>
              </FormText>

              
            </SettingsWrapper>
            <SettingsWrapper>
            {(funds / 1000000) < 1 && <CustomButton onClick={() => {getFunds()}}><small>Refresh Funds <MdRefresh color="white" /></small></CustomButton>}
            <ActionButtonsContainer>
            {(funds / 1000000) >= 1 && (
              <><CustomButton onClick={() => {getFunds()}}>Refresh Funds <MdRefresh color="white" /></CustomButton>
                <CustomButton1
                  onClick={() => {
                    claimFunds();
                  }}
                >
                  Claim Funds
                </CustomButton1></>
              )}</ActionButtonsContainer>
            </SettingsWrapper></>
          )}
          </FormWrapper>
          <FormWrapper><>{artist?.isVerified && (
            
            <FormWrapper border>
              
                <FormLabel>Yearly Subscription Fee <CurrencySelector setCurrency={setCurrency} currency={currency} /></FormLabel>
              
              <FormInput1
                name={ARTIST_FORM_INPUT_TYPES.SUBSCRIPTION_FEE}
                type={"number"}
                min={0}
                placeholder="Subscription Fee"
                value={subscriptionFee}
                onChange={handleInput}
              />
            </FormWrapper>
          )}
          
            <FormWrapper border>
            <FormLabel>Payment Pointer (ILP)</FormLabel>
            <FormInput1
              name={ARTIST_FORM_INPUT_TYPES.PAYMENT_POINTER}
              placeholder="Payment Pointer"
              value={paymentPointer}
              onChange={handleInput}
            /></FormWrapper>
            <FormWrapper border>
            <SettingsWrapper>
            <InputLabel>Enable Donations</InputLabel>
            <ToggleButton
              onText={"On"}
              offText={"Off"}
              toggle={donating}
              toggleClick={toggleDonation}
            />
          </SettingsWrapper>
          {donating && <ArtistContentWrapper>
            <SettingsWrapper>
            <InputLabel>Name of Cause</InputLabel>
            <FormInput1
              name={ARTIST_FORM_INPUT_TYPES.DONATION_CAUSE}
              disabled={true}
              placeholder="Name of Cause"
              value={donation.cause}
              onChange={handleInput}
            />
          </SettingsWrapper>
          <SettingsWrapper>
              <InputLabel>Donation Target</InputLabel>
              <CurrencySelector setCurrency={(val) => setDonation(prev => {
                return {
                  ...prev,
                  currency: val
                }
              })} currency={donation?.currency ? donation?.currency : "XRP"} />
              <FormInput1
                name={ARTIST_FORM_INPUT_TYPES.DONATION_TARGET}
                type={"number"}
                min={0}
                placeholder="Donation Target"
                value={donation?.target}
                onChange={handleInput}
              />
            </SettingsWrapper>
            <SettingsWrapper>
            <InputLabel>Donation address</InputLabel>
            <FormInput1
              name={ARTIST_FORM_INPUT_TYPES.DONATION_ADDRESS}
              disabled={true}
              placeholder="enter XRP donation address"
              value={donation?.address}
              onChange={handleInput}
            />
          </SettingsWrapper>
            </ArtistContentWrapper>}
            </FormWrapper>
          </></FormWrapper>
          
       </FormWrapper>}
        {currentTab === 'tokenization' &&<FormWrapper>
  <ArtistContentHeading>IPFS (NFT Minting Settings)</ArtistContentHeading>
  <FormText>Select supported storage option for music files and metadata. Sonar Muse will automatically upload audio files for NFT collections to selected option.</FormText>
  <FormText>Note: All NFT metadata files will be uploaded to IPFS, regardless of the selected option.</FormText>
  <FormText>By Default, Sonar Muse uploads files to it's IPFS storage bucket</FormText>
  <FormWrapper border>
  <SettingsWrapper>
          <div style={{justifyContent: 'flex-start', justifySelf: 'flex-start'}}>
          <Checkbox onClick={() => setStorageType('default')} label={'Sonar Muse Storage Bucket (Default)'} isChecked={storageType === 'default'}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.FILEBASE)} label={'Filebase'} isChecked={storageType === NFT_STORAGE_TYPES.FILEBASE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.INTERNET_ARCHIVE)} label={'Internet Archive'} isChecked={storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.LIGHTHOUSE)} label={'Lighthouse'} isChecked={storageType === NFT_STORAGE_TYPES.LIGHTHOUSE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.PINATA)} label={'Pinata'} isChecked={storageType === NFT_STORAGE_TYPES.PINATA}/>
          {/* <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.WEB3_STORAGE)} label={'Web3 Storage (Storacha)'} isChecked={storageType === NFT_STORAGE_TYPES.WEB3_STORAGE}/> */}

          </div>
  </SettingsWrapper>
     {(storageType === NFT_STORAGE_TYPES.FILEBASE || errors?.storageApiKeys?.filebase) && <FormWrapper border>
     <FormLabel>Filebase</FormLabel>
     <FormText>To learn more about and create a Filebase account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://filebase.com/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>Bucket Name</FormLabel>
      <FormInput1 placeholder="Bucket Name" name={'bucketName'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.bucketName}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1 placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.filebase && <ErrorText>{errors?.storageApiKeys?.filebase}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE || errors?.storageApiKeys?.internetArchive) && <FormWrapper border>
     <FormLabel>Internet Archive</FormLabel>
     <FormText>To learn more about and create an Internet Archive account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://archive.org/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.internetArchive && <ErrorText>{errors?.storageApiKeys?.internetArchive}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE || errors?.storageApiKeys?.lighthouse) && <FormWrapper border>
     <FormLabel>Lighthouse</FormLabel>
     <FormText>To learn more about and create a Lighthouse account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://files.lighthouse.storage/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.lighthouse?.key}/>
    </FormWrapper>
    {errors?.storageApiKeys?.lighthouse && <ErrorText>{errors?.storageApiKeys?.lighthouse}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.PINATA || errors?.storageApiKeys?.pinata) && <FormWrapper border>
     <FormLabel>Pinata</FormLabel>
     <FormText>To learn more about and create a Pinata account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://www.pinata.cloud/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1  placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.secret}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>JWT</FormLabel>
      <FormInput1  placeholder={'JWT'} name={'jwt'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.jwt}/>
    </FormWrapper>
    {errors?.storageApiKeys?.pinata && <ErrorText>{errors?.storageApiKeys?.pinata}</ErrorText>}
  </FormWrapper>}
     {/* (storageType === NFT_STORAGE_TYPES.WEB3_STORAGE || errors?.storageApiKeys?.web3storage) && <FormWrapper>
     <FormLabel>Internet Archive</FormLabel>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1/>
    </FormWrapper>
    {errors?.storageApiKeys?.web3Storage && <ErrorText>{errors?.storageApiKeys?.web3Storage}</ErrorText>}
  </FormWrapper> */}
  
  </FormWrapper> 
          
</FormWrapper>}
        <ArtistContentWrapper>
          {errors?.message && <ErrorText>{errors.message}</ErrorText>}
          <ActionButtonsContainer>
            <Button1 style={{ marginRight: "2%" }} onClick={() => {
               navigate(-1);
            }}>Cancel</Button1>
            <Button1 style={{ marginRight: "2%" }} onClick={updateProfile}>
              Update Profile
            </Button1>
          </ActionButtonsContainer>
        </ArtistContentWrapper>
      </ContentWrapper>
    </>
  );
}
