import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.footer`
    background-color: ${props => props.theme.mainBg};
    width: 100%;
    padding-bottom: 10%;
`
export const FooterWrap = styled.div`

padding: 48px 24px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 1100px;
margin: 0 auto;

`

export const FooterLinksContainer = styled.div`
display: flex;
justify-content: center;

@media screen and (max-width: 820px){
    padding-top:32px;
    flex-direction: column;
}

`

export const FooterLinkWrapper = styled.div`
display: flex;

@media screen and (max-width: 820px){
    flex-direction: column;
}
`

export const FooterLinkItems = styled.div`
display:flex;
flex-direction: column;
align-items: flex-start;
margin: 16px;
text-align: left;
width: 160px;
box-sizing: border-box;
color: ${props => props.theme.secondaryBg};


@media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%
}

`
export const FooterLinkTitle = styled.h1` 
font-size: 14px;
color: ${props => props.theme.secondaryColor};
margin-bottom: 16px;

`

export const FooterLink = styled(Link)`
color: ${props => props.theme.secondaryBg};

text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;

&:hover {
    color: ${props => props.theme.mainColor};
    transition: 0.3s ease-out;
}
`
export const SocialMedia = styled.section`
max-width: 1000px;
width: 100%;
`
export const SocialMediaWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 1100px;
margin: 40px auto 0 auto;

@media screen and (max-width: 820px){
    flex-direction: column;
}
`

export const SocialLogo = styled(Link)`
color: ${props => props.theme.secondaryColor};
justify-self: start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-bottom: 16px;
font-weight: bold;
`

export const WebsiteRights = styled.small`
color: ${props => props.theme.secondaryBg};
margin-bottom: 16px

`

export const SocialIcons = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
width: 240px;
`

export const Img = styled.img`
display: flex;
width: 10rem;
align-items: center;
justify-content: center;
  &:hover {
    transform: rotateY(360deg);
    transition: 0.2;
  }
`;

export const SocialIconLink = styled.a`
color: ${props => props.theme.secondaryColor};
font-size: 24px;
padding-right: 2%;
`