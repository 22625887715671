import React, { useEffect, useState } from "react";
import whitepaper from "../../files/Sonar_Muse_Whitepaper.pdf";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import useMusic from "../../hooks/useMusic";
import { useUser} from "../../hooks/useUser";
import {
  FormButton,
  FormButton1,
  FormContainer,
  FormH1,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";
import { HoverOption } from "./MenuOptionsElements";
import {
  downloadFile,
  getAlbumCredits,
  getAllArtists,
  getArtistsNames,
  getCCInfo,
  getExpirationTimestamp,
  getObjectLength,
  getSongTitle,
  getTimestamp,
  getUniqueListBy,
  timestampToDate,
  uploadSongsToIPFS,
} from "../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MINTING,
  MUSIC_PLAYING_FROM,
  PAYMENT_WALLETS,
  PAYOUT_TYPE,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import MusicInfo from "../MusicPage/MusicInfo";
import EligibilityViewer from "../EligibilityViewer";
import Notifications from "../Notifications";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcDislike, FcLike } from "react-icons/fc";
import { GoReport } from "react-icons/go";
import { FaShare } from "react-icons/fa";
import ScrollToTop from "../ScrollToTop";
import NFTokenBurner from "../NFTokenBurner";
import axiosInstance from "../Authorization/client";
import NFTokenCollectionGifter from "../NFTokenCollectionGifter";
import {
  CreditsHeading,
  CreditsText,
  DetailsWrapper,
  Img,
  MusicInfoContainer,
  MusicInfoWrapper,
  SubHeading,
  Text,
} from "../MusicPage/MusicInfo/MusicInfoElements";
import { getAddressName, uploadImageToIPFS1, uploadMetadata } from "../../utils/nfTokensUploads";
import { useAlert } from "../Alert/AlertContext";
import PlaylistViewer from "../PlaylistViewer";
import PlaylistSelector from "../PlaylistViewer/PlaylistSelector";
import { burnNFTokenS, getNFTs } from "../../utils/xrpl";
import NFTokenWhitelister from "../NFTokenWhitelister";
import {
  MintButtonConainer,
  SmallText,
} from "../NFTCollectionCreator/NFTCollectionCreatorElements";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import CurrencySelector from "../CurrencySelector";
import PlaylistList from "../ContentList/PlaylistList";
import ArtistList from "../ContentList/ArtistList";
import { getUserSubscriptionInfo } from "../../utils/dbUtils/userUtils";
import ContentWrapper from "../ContentWrapper";
import UserList from "../ContentList/UserList";
import useArtist from "../../hooks/useArtist";
import PodcasterList from "../ContentList/PodcasterList";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";


const MenuOptions = () => {
  const {
    currentUser,
    setPushNotifications,
    getUserLikes,
    getUserWatchlist,
    userLikes,
  } = useAuth();
  const {
    addPlaylist,
    deletePlaylist,
    unlikeSong,
    likeSong,
    likePlaylist,
    unlikePlaylist,
    likeAlbum,
    getEpisodeLike,
    unlikeEpisode,
    likeEpisode,
    unlikeAlbum,
    likeNFTokenCollection,
    unlikeNFTokenCollection,
    watchSong,
    unwatchSong,
    watchNFTokenCollection,
    unwatchNFTokenCollection,
    deleteDraft,
    getDraftCollections,
    getSongs,
    getAlbum,
    getAlbums,
  } = useMusic({});
  const {modalContent, isOpen, openModal, closeModal } =
    useModal();
    const {addAlert} = useAlert()
  const { goTo } = ScrollToTop();




  const onNotificationSuccess = (pushNotification) => {
    setPushNotifications(pushNotification);

    closeModal();
    modalContent();
  };

  const onNotificationCancel = () => {
    closeModal();
    modalContent();
  };

  const onNotificationFail = () => {
    closeModal();
    modalContent();
  };

  const enablePushNotifications = () => {
    openModal();

    return modalContent(
      <QRCodeForm
        body={{
          sender: currentUser?.uid,
          memo: `Enabling push notifications for ${currentUser?.uid}`,
          instruction: `Enabling push notifications for ${currentUser?.uid}`,
        }}
        heading={`Enabling push notifications for ${currentUser?.uid?.substring(
          0,
          5
        )}...${currentUser?.uid?.substring(
          currentUser?.uid?.length - 4,
          currentUser?.uid?.length
        )}`}
        onFail={onNotificationFail}
        onSuccess={onNotificationSuccess}
        cancelQr={onNotificationCancel}
        type={TRANSACTION_OPTIONS.ENABLE_PUSH_NOTIFICATIONS}
      />
    );
  };

  const disablePushNotifications = () => {
    openModal();

    return modalContent(
      <QRCodeForm
        body={{
          sender: currentUser?.uid,
          memo: `Disabling push notifications for ${currentUser?.uid}`,
          instruction: `Disabling push notifications for ${currentUser?.uid}`,
        }}
        heading={`Disabling push notifications for ${currentUser?.uid?.substring(
          0,
          5
        )}...${currentUser?.uid?.substring(
          currentUser?.uid?.length - 4,
          currentUser?.uid?.length
        )}`}
        onFail={onNotificationFail}
        onSuccess={onNotificationSuccess}
        cancelQr={onNotificationCancel}
        type={TRANSACTION_OPTIONS.DISABLE_PUSH_NOTIFICATIONS}
      />
    );
  };

  const options = (music) => {
    if (currentUser?.uid) {
      if(music?.songId){
        openModal()
      modalContent(
        <>
          <OptionModal
            isOpen={true}
            heading={music.songName}
            onCancel={() => {
              modalContent();
              closeModal();
            }}
          >
            {/*  <HoverOption
              onClick={() => {
                modalContent();
                closeModal();
                
              }}
            >
              Play
          </HoverOption> */}

            {/* isQueueLoaded && playlist[currentSong]?.songId !== music.songId && music?.isStreamable  && (
              <HoverOption
                onClick={async () => {
                  console.log(music)
                  await playNext(music);
                  modalContent();
                  closeModal();
                  
                }}
              >
                Play Next
              </HoverOption>
            ) */}

            <HoverOption
              onClick={() => {
                closeModal();
                modalContent();
                goTo(`/song/${music.songId}`);
              }}
            >
              View Song
            </HoverOption>
            <HoverOption onClick={() => {
                  modalContent(<ArtistTipper type={CONTENT_TYPES.SONG} content={music}/>)
                }}>Tip Artist</HoverOption>
            {currentUser?.uid &&
            userLikes?.length > 0 &&
            userLikes
              ?.filter((like) => like.songId)
              .find((s) => s.songId === music?.songId) ? (
              <HoverOption
                onClick={async () => {
                  await unlikeSong(music?.songId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcDislike style={{ marginRight: "2%" }} /> Remove from Likes
              </HoverOption>
            ) : (
              <HoverOption
                onClick={async () => {
                  await likeSong(music?.songId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcLike style={{ marginRight: "2%" }} /> Add to Likes
              </HoverOption>
            )} {currentUser?.uid &&
              currentUser?.watchlist &&
              currentUser?.watchlist
                .filter((list) => list.songId)
                .find((s) => s?.songId === music?.songId) ? (
                <HoverOption
                  onClick={async () => {
                    await unwatchSong(music?.songId);
                    await getUserWatchlist(currentUser?.uid);
                    modalContent();
                    closeModal();
                  }}
                >
                  <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                  Remove from Watchlist
                </HoverOption>
              ) : (
                <HoverOption
                  onClick={async () => {
                    await watchSong(music?.songId);
                    await getUserWatchlist(currentUser?.uid);
                    modalContent();
                    closeModal();
                  }}
                >
                  <AiOutlineEye style={{ marginRight: "2%" }} />
                  Add To Watchlist
                </HoverOption>
              )}
              {currentUser?.purchases?.songs?.length > 0 &&
                currentUser?.purchases?.songs?.find(
                  (s) => s?.songId === music?.songId
                ) && (
                  <HoverOption
                    onClick={() => {
                      goTo(`/song/${music?.songId}/download`);
                      modalContent();
                      closeModal();
                    }}
                  >
                    Download Song
                  </HoverOption>
                )}
            {music?.isStreamable && <HoverOption onClick={() => {
              openModal()
              modalContent(<PlaylistSelector songId={music?.songId}/>)
              }}>
              Add to playlist
            </HoverOption>}
          </OptionModal>
        </>
      );
    }
      if(music?.episodeId){
      return (
        <>
          <OptionModal
            isOpen={true}
            heading={music.episodeName}
            onCancel={() => {
              modalContent();
              closeModal();
            }}
          >
            {/*  <HoverOption
              onClick={() => {
                modalContent();
                closeModal();
                
              }}
            >
              Play
          </HoverOption> */}
            {/* isQueueLoaded && music?.isStreamable  && <>{ playlist?.length > 0 &&
            playlist?.find((s) => s?.episodeId === music.episodeId) ? (
              <HoverOption
                onClick={() => {
                  modalContent();
                  closeModal();
                  removeFromQueue(music);
                }}
              >
                Remove from queue
              </HoverOption>
            ) : (
              <HoverOption
                onClick={() => {
                  modalContent();
                  closeModal();
                  addToQueue([{...music,
                    playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.EPISODE,
                    id: music?.episodeId,
                  }}]);
                }}
              >
                Add to queue
              </HoverOption>
            )}</> */}

            {/* playlist[currentSong]?.episodeId !== music.episodeId && music?.isStreamable  && (
              <HoverOption
                onClick={() => {
                  playNext(music);
                  modalContent();
                  closeModal();
                  
                }}
              >
                Play Next
              </HoverOption>
            ) */}

            <HoverOption
              onClick={() => {
                
                //modalContent(<UnavailableFeature/>);
               // closeViewer();
                
                 goTo( `/episode/${music.episodeId}`);
              }}
            >
              View Episode
            </HoverOption>
            <HoverOption onClick={() => {
                  modalContent(<ArtistTipper type={CONTENT_TYPES.EPISODE} content={music}/>)
                }}>Tip Artist</HoverOption>
            {currentUser?.uid &&
            userLikes?.length > 0 &&
            userLikes
              ?.filter((like) => like.episodeId)
              .find((s) => s.episodeId === music?.episodeId) ? (
              <HoverOption
                onClick={async () => {
                  await unlikeEpisode(music?.episodeId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcDislike style={{ marginRight: "2%" }} /> Remove from Likes
              </HoverOption>
            ) : (
              <HoverOption
                onClick={async () => {
                  await likeEpisode(music?.episodeId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcLike style={{ marginRight: "2%" }} /> Add to Likes
              </HoverOption>
            )} {currentUser?.uid &&
              currentUser?.watchlist &&
              currentUser?.watchlist
                .filter((list) => list.songId)
                .find((s) => s?.episodeId === music?.episodeId) ? (
                <HoverOption
                  onClick={async () => {
                    await unwatchSong(music?.episodeId);
                    await getUserWatchlist(currentUser?.uid);
                    modalContent();
                    closeModal();
                  }}
                >
                  <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                  Remove from Watchlist
                </HoverOption>
              ) : (
                <HoverOption
                  onClick={async () => {
                    await watchSong(music?.episodeId);
                    await getUserWatchlist(currentUser?.uid);
                    modalContent();
                    closeModal();
                  }}
                >
                  <AiOutlineEye style={{ marginRight: "2%" }} />
                  Add To Watchlist
                </HoverOption>
              )}
              {currentUser?.purchases?.episodes?.length > 0 &&
                currentUser?.purchases?.episodes?.find(
                  (s) => s?.episodeId === music?.episodeId
                ) && (
                  <HoverOption
                    onClick={() => {
                      goTo(`/episode/${music?.episodeId}/download`);
                      modalContent();
                      closeModal();
                    }}
                  >
                    Download Episode
                  </HoverOption>
                )}
            {
            
            music?.isStreamable  &&<HoverOption onClick={() => {
              openModal()
              modalContent(<PlaylistSelector songId={`${music?.episodeId}.episode`}/>)
              }}>
              Add to playlist
            </HoverOption>}
          </OptionModal>
        </>
      );
    }
    if(music?.albumId){
      return(
        <OptionModal heading={`${music?.albumName}`}>
            {/* <HoverOption>
              Play
            </HoverOption> */}
            {/* isQueueLoaded && music?.isStreamable && <HoverOption onClick={async() => {
              addAlert({
                title: "Adding Album to Queue",
                type: ALERT_TYPES.INFO,
                message: `Adding Album Songs To Queue, please wait`,
              })
              modalContent();
                    closeModal();
                await getSongs(music.songs)
                .then((songs) => {
                    
                    addToQueue(songs.map(s => {
                      return {
                        ...s,
                        playingFrom : {
                          playingFrom: MUSIC_PLAYING_FROM.ALBUM,
                          id: music.albumId,
                        }
                      }
                    }));
                })
                .catch((err) => {
                  //console.log(err);
                  return;
                });
              
                
            }}>
              Add To Queue
            </HoverOption> */}
            <HoverOption onClick={() => {
              modalContent();
              closeModal();
              goTo(`/album/${music?.albumId}`)
            }}>
              View Album
            </HoverOption>
            {currentUser?.uid &&
            userLikes?.length > 0 &&
            userLikes
              ?.filter((like) => like.albumId)
              .find((s) => s.albumId === music?.albumId) ? (
              <HoverOption
                onClick={async () => {
                  await unlikeAlbum(music?.albumId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcDislike style={{ marginRight: "2%" }} /> Remove from Likes
              </HoverOption>
            ) : (
              <HoverOption
                onClick={async () => {
                  await likeAlbum(music?.albumId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcLike style={{ marginRight: "2%" }} /> Add to Likes
              </HoverOption>
            )}
            {currentUser?.uid &&
                currentUser?.watchlist &&
                currentUser?.watchlist
                  .filter((list) => list.albumId)
                  .find((a) => a?.albumId === music?.albumId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unwatchSong(music?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                    Remove from Watchlist
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await watchSong(music?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEye style={{ marginRight: "2%" }} />
                    Add To Watchlist
                  </HoverOption>
                )}
                {currentUser?.purchases?.albums?.length > 0 &&
                  currentUser?.purchases?.albums?.find(
                    (a) => a?.albumId === music?.albumId
                  ) && (
                    <HoverOption
                      onClick={() => {
                        goTo(`/album/${music?.albumId}/download`);
                        modalContent();
                        closeModal();
                      }}
                    >
                      Download Album
                    </HoverOption>
                  )}

        </OptionModal>
      )
    }
    if(music?.playlistId){
      return(
        <OptionModal heading={`${music?.playlistName}`}>
            {/* <HoverOption>
              Play
            </HoverOption> */}
            {/* isQueueLoaded && <HoverOption onClick={async() => {
              addAlert({
                title: "Adding Playlist to Queue",
                type: ALERT_TYPES.INFO,
                message: `Adding Playlist Songs To Queue, please wait`,
              })
              modalContent();
                    closeModal();
                await getSongs(music.songs)
                .then((songs) => {
                    
                  addToQueue(songs.map(s => {
                    return {
                      ...s,
                      playingFrom : {
                        playingFrom: MUSIC_PLAYING_FROM.PLAYLIST,
                        id: music.playlistId,
                      }
                    }
                  }));
                })
                .catch((err) => {
                  //console.log(err);
                  return;
                });
              
                
            }}>
              Add To Queue
            </HoverOption> */}
            <HoverOption onClick={() => {
              modalContent();
              closeModal();
              goTo(`/playlist/${music?.playlistId}`)
            }}>
              View Playlist
            </HoverOption>
            {currentUser?.uid &&
            userLikes?.length > 0 &&
            userLikes
              ?.filter((like) => like.playlistId)
              .find((s) => s.playlistId === music?.playlistId) ? (
              <HoverOption
                onClick={async () => {
                  await unlikePlaylist(music?.playlistId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcDislike style={{ marginRight: "2%" }} /> Remove from Likes
              </HoverOption>
            ) : (
              <HoverOption
                onClick={async () => {
                  await likePlaylist(music?.playlistId);
                  await getUserLikes(currentUser?.uid);
                  modalContent();
                  closeModal();
                }}
              >
                <FcLike style={{ marginRight: "2%" }} /> Add to Likes
              </HoverOption>
            )}
            
              <HoverOption onClick={() => {
                modalContent(<OptionModal>
                  <HoverOption onClick={() => {
                     deletePlaylist(music?.playlistId, currentUser?.uid)
                     .then((data) => {
                       modalContent(
                         <OptionModal isOpen={isOpen} heading={"Successfully Deleted"}>
                           <FormButton
                             to={`#`}
                             onClick={() => {
                               modalContent();
                               closeModal();
                               goTo(`/u/${currentUser?.uid}`)
                             }}
                           >
                             Ok
                           </FormButton>
                         </OptionModal>
                       );
                     })
                     .catch((err) => {
                       //console.log(err);
                       openModal()
                       modalContent(
                       <DeletePlaylistPrompt playlist={music?.playlistId}/>)
                     });
                  }}>Confirm</HoverOption>
                  <HoverOption onClick={() => {
                    closeModal()
                    modalContent()
                  }}>Cancel</HoverOption>
                </OptionModal>)
              }}>
                Delete Playlist
              </HoverOption>

        </OptionModal>
      )
    }
  }
  };

return {options}
};

export const BetaTesterSignInModal = () => {
  const {goTo} = ScrollToTop()
  const {openModal, closeModal, modalContent} = useModal()
  return (
    <OptionModal 
    heading={"Beta Access Only"} 
    additionalText={"Access to the website is only authorized to beta testers. If you are a beta tester, click continue or visit the music store to listen to music that is on sale"}>
      <HoverOption onClick={() => { goTo('/access/signin')
    closeModal()
    modalContent()}}>Continue</HoverOption>
      <HoverOption onClick={() => { 
        goTo('/store')
    closeModal()
    modalContent()}}>Visit Music Store</HoverOption>
    </OptionModal>
  )
}


export const MiscellaneousOptions = () => {
  const {
    currentUser,
    profiles,
    setPushNotifications,
    getUserSubscription,
    userSubscription,
  } = useAuth();
  const {modalContent, isOpen, openModal, closeModal } =
    useModal();

  const { goTo } = ScrollToTop();
  const [isLoaded, setLoaded] = useState(false);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    getProfileInfo()
  
    return () => {
    }
  }, [currentUser])
  


  const getProfileInfo = async () => {
    setLoaded(true);
    return;
  };

 

  const onNotificationSuccess = (pushNotification) => {
    setPushNotifications(pushNotification);

    closeModal();
    modalContent();
  };

  const onNotificationCancel = () => {
    closeModal();
    modalContent();
  };

  const onNotificationFail = () => {
    closeModal();
    modalContent();
  };

  const enablePushNotifications = () => {
    openModal();

    return modalContent(
      <QRCodeForm
        body={{
          sender: currentUser?.uid,
          memo: `Enabling push notifications for ${currentUser?.uid}`,
          instruction: `Enabling push notifications for ${currentUser?.uid}`,
        }}
        heading={`Enabling push notifications for ${currentUser?.uid?.substring(
          0,
          5
        )}...${currentUser?.uid?.substring(
          currentUser?.uid?.length - 4,
          currentUser?.uid?.length
        )}`}
        onFail={onNotificationFail}
        onSuccess={onNotificationSuccess}
        cancelQr={onNotificationCancel}
        type={TRANSACTION_OPTIONS.ENABLE_PUSH_NOTIFICATIONS}
      />
    );
  };

  const disablePushNotifications = () => {
    openModal();

    return modalContent(
      <QRCodeForm
        body={{
          sender: currentUser?.uid,
          memo: `Disabling push notifications for ${currentUser?.uid}`,
          instruction: `Disabling push notifications for ${currentUser?.uid}`,
        }}
        heading={`Disabling push notifications for ${currentUser?.uid?.substring(
          0,
          5
        )}...${currentUser?.uid?.substring(
          currentUser?.uid?.length - 4,
          currentUser?.uid?.length
        )}`}
        onFail={onNotificationFail}
        onSuccess={onNotificationSuccess}
        cancelQr={onNotificationCancel}
        type={TRANSACTION_OPTIONS.DISABLE_PUSH_NOTIFICATIONS}
      />
    );
  };

return (
<>
        <OptionModal
          isOpen={isOpen}
          heading={"More Options"}
          onCancel={() => {
            modalContent();
            closeModal();
          }}
        >
          

       {getObjectLength(profiles) < 3 && <HoverOption
            onClick={() => {
              goTo("/signUp");
              closeModal();
            }}
          >
            Add New Profile
          </HoverOption>}

          <HoverOption onClick={() => {
            return uploadImageToIPFS1().then(data => {
              console.log(data)
            }).catch(err => {
              console.log(err)
            })
          }}>
            Test Feature
          </HoverOption>

          <HoverOption
            onClick={() => {
              modalContent();
              closeModal();
              goTo("/createTL");
            }}
          >
            Add Supported Trustlines
          </HoverOption>   
          
          <HoverOption
            onClick={() => {
              goTo("#about");
              modalContent();
              closeModal();
            }}
          >
            About Us
          </HoverOption>

          <HoverOption
            onClick={() => {
              modalContent();
              closeModal();
              downloadFile(whitepaper);
            }}
          >
            Whitepaper
          </HoverOption>
         {/*  {!currentUser?.pushNotifications ? (
            <HoverOption
              onClick={() => {
                enablePushNotifications();
              }}
            >
              Enable Push Notifications (Xumm)
            </HoverOption>
          ) : (
            <HoverOption
              onClick={() => {
                disablePushNotifications();
              }}
            >
              Disable Push Notifications (Xumm)
            </HoverOption>
          )} */}
        </OptionModal>
      </>
)
};


export const ShowSongDetails = ({song}) => {
  const {modalContent, openModal, closeModal} = useModal()
  const {goTo} = ScrollToTop()

  return (
    <>
      <OptionModal heading={"Song Details"}>
        <MusicInfoWrapper>
          {" "}
          {song?.uploadedBy && (
            <MusicInfoContainer>
              <DetailsWrapper>
                <Img src={song?.albumArt} />
                <div style={{ fontSize: "0.7rem" }}>
                  Created by
                  <Text
                    onClick={() => goTo(`/u/${song?.uploadedBy}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {song?.uploadedBy}
                  </Text>
                </div>
              </DetailsWrapper>
            </MusicInfoContainer>
          )}
          <MusicInfoContainer>
            {song?.about?.trim()?.length > 0 && (
              <>
                {" "}
                <SubHeading>About</SubHeading>
                <CreditsText style={{ marginBottom: "1%" }}>
                  {song.about}
                </CreditsText>
              </>
            )}
            <SubHeading>Release Date</SubHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>{`${
              new Date(
                song?.releaseDate?._seconds
                  ? timestampToDate(song?.releaseDate)
                  : song.releaseDate
              ).getUTCDay() + 1
            }/${
              new Date(
                song?.releaseDate?._seconds
                  ? timestampToDate(song?.releaseDate)
                  : song.releaseDate
              ).getUTCMonth() + 1
            }/${new Date(
              song?.releaseDate?._seconds
                ? timestampToDate(song?.releaseDate)
                : song.releaseDate
            ).getFullYear()}`}</CreditsText>
          </MusicInfoContainer>
          <MusicInfoContainer>
            <SubHeading>Genre</SubHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>
              {song?.genre?.name}
            </CreditsText>
            {song?.subGenres && Object.keys(song)?.length > 0 && (
              <>
                <SubHeading>SubGenre(s)</SubHeading>
                {song.subGenres.map((subgenre, index) => {
                  return (
                    <CreditsText onClick={() => {/* closeViewer() */}}>
                      {subgenre.name}{" "}
                      {subgenre?.mainGenre?.name &&
                        ` (${subgenre?.mainGenre?.name})`}
                    </CreditsText>
                  );
                })}
              </>
            )}
          </MusicInfoContainer>
          <MusicInfoContainer>
            <SubHeading>Song Credits</SubHeading>

            <CreditsHeading>Performed by</CreditsHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>
              {getArtistsNames(getAllArtists(song), ARTIST_SEARCH_TYPES.NAME)}
            </CreditsText>

            {song?.songCredits &&
              Object.keys(song?.songCredits).length > 0 && (
                <>
                  {song?.songCredits?.composers && (
                    <>
                      <CreditsHeading>Composed by</CreditsHeading>
                      <CreditsText onClick={() => {/* closeViewer() */}}>
                        {getArtistsNames(
                          song?.songCredits?.composers,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      </CreditsText>
                    </>
                  )}
                  {song?.songCredits?.writers && (
                    <>
                      <CreditsHeading>Written by</CreditsHeading>
                      <CreditsText onClick={() => {/* closeViewer() */}}>
                        {getArtistsNames(
                          song?.songCredits?.writers,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      </CreditsText>
                    </>
                  )}
                  {song?.songCredits?.producers && (
                    <>
                      <CreditsHeading>Produced by</CreditsHeading>
                      <CreditsText onClick={() => {/* closeViewer() */}}>
                        {getArtistsNames(
                          song?.songCredits?.producers,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      </CreditsText>
                    </>
                  )}
                </>
              )}
          </MusicInfoContainer>
          <MusicInfoContainer>
            <CreditsHeading>Record Label</CreditsHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>
              {song?.recordLabel ? song?.recordLabel : "Independent"}
            </CreditsText>
          </MusicInfoContainer>
          {song?.licensing && (
            <MusicInfoContainer>
              <CreditsHeading>License</CreditsHeading>
              <CreditsText onClick={() => {/* closeViewer() */}}>
                {getCCInfo(song?.licensing)}
              </CreditsText>
            </MusicInfoContainer>
          )}
        </MusicInfoWrapper>
        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          Close
        </HoverOption>
      </OptionModal>
    </>
  );
};

export const ShowAlbumDetails = ({album}) => {
  const {modalContent, openModal, closeModal} = useModal()
  const {goTo} = ScrollToTop()

  return (
    <>
      <OptionModal heading={"Album Details"}>
        <MusicInfoWrapper>
          {" "}
          {album?.uploadedBy && (
            <MusicInfoContainer>
              <DetailsWrapper>
                <Img src={album.albumArt} />
                <div style={{ fontSize: "0.7rem" }}>
                  Created by
                  <Text
                    onClick={() => goTo(`/u/${album?.uploadedBy}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {album.uploadedBy}
                  </Text>
                </div>
              </DetailsWrapper>
            </MusicInfoContainer>
          )}
          <MusicInfoContainer>
            {album?.about?.trim()?.length > 0 && (
              <>
                {" "}
                <SubHeading>About</SubHeading>
                <CreditsText style={{ marginBottom: "1%" }}>
                  {album.about}
                </CreditsText>
              </>
            )}
            <SubHeading>Release Date</SubHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>{`${
              new Date(
                album?.releaseDate?._seconds
                  ? timestampToDate(album?.releaseDate)
                  : album.releaseDate
              ).getUTCDay() + 1
            }/${
              new Date(
                album?.releaseDate?._seconds
                  ? timestampToDate(album?.releaseDate)
                  : album.releaseDate
              ).getUTCMonth() + 1
            }/${new Date(
              album?.releaseDate?._seconds
                ? timestampToDate(album?.releaseDate)
                : album.releaseDate
            ).getFullYear()}`}</CreditsText>
          </MusicInfoContainer>
          <MusicInfoContainer>
            <SubHeading>Album Credits</SubHeading>

            <CreditsHeading>Performed by</CreditsHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>
              {getArtistsNames(
                getAllArtists(album),
                ARTIST_SEARCH_TYPES.NAME
              )}
            </CreditsText>

            {
              <>
                <CreditsHeading>Composers</CreditsHeading>
                <CreditsText onClick={() => {/* closeViewer() */}}>
                  {getArtistsNames(
                    getAlbumCredits(album?.songs, "composers"),
                    ARTIST_SEARCH_TYPES.NAME
                  )}
                </CreditsText>
              </>
            }
            {
              <>
                <CreditsHeading>Writers</CreditsHeading>
                <CreditsText onClick={() => {/* closeViewer() */}}>
                  {getArtistsNames(
                    getAlbumCredits(album?.songs, "writers"),
                    ARTIST_SEARCH_TYPES.NAME
                  )}
                </CreditsText>
              </>
            }
            {
              <>
                <CreditsHeading>Producers</CreditsHeading>
                <CreditsText onClick={() => {/* closeViewer() */}}>
                  {getArtistsNames(
                    getAlbumCredits(album?.songs, "producers"),
                    ARTIST_SEARCH_TYPES.NAME
                  )}
                </CreditsText>
              </>
            }
          </MusicInfoContainer>
          <MusicInfoContainer>
            <CreditsHeading>Record Label</CreditsHeading>
            <CreditsText onClick={() => {/* closeViewer() */}}>
              {album?.recordLabel ? album?.recordLabel : "Independent"}
            </CreditsText>
          </MusicInfoContainer>
          {album?.licensing && (
            <MusicInfoContainer>
              <CreditsHeading>License</CreditsHeading>
              <CreditsText onClick={() => {/* closeViewer() */}}>
                {getCCInfo(album?.licensing)}
              </CreditsText>
            </MusicInfoContainer>
          )}
        </MusicInfoWrapper>
        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          Close
        </HoverOption>
      </OptionModal>
    </>
  );
};

export const DraftOptions =({draft}) => {
  const {modalContent, openModal, closeModal} = useModal()
  const {deleteDraft} = useMusic()
  const {goTo} = ScrollToTop()
  const location = useLocation()

    return (
      <>
        <OptionModal
          isOpen={true}
          heading={
            draft?.songName
              ? draft?.songName
              : draft?.albumName
              ? draft?.albumName
              : "untitled"
          }
          onCancel={() => {
            modalContent();
            closeModal();
          }}
        >
          <HoverOption
            onClick={() => {
              goTo(`/edit/${draft?.type}?id=${draft?.draftId}`);
              modalContent();
              closeModal();
            }}
          >
            Edit
          </HoverOption>
          <HoverOption
            onClick={async () => {
              return await deleteDraft(draft?.draftId).then((data) => {
                if (data) {
                  const params = new URLSearchParams({ refresh: true });
                  goTo(`${location.pathname}?edit=true`, {
                    replace: true,
                  });
                  modalContent();
                  closeModal();
                }
              });
            }}
          >
            Delete
          </HoverOption>
        </OptionModal>
      </>
    );
};

export const PlaylistModal = () => {

  const {
    currentUser,
  } = useAuth();

  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
    return (<>
        {currentUser?.uid && <OptionModal
          isOpen={isOpen}
          heading={"My Playlists Options"}
          onCancel={() => {
            modalContent();
            closeModal();
          }}
        >
          <HoverOption onClick={() => {
            openModal() 
            modalContent(<CreatePlaylist/>)
            }}>
            Create New Playlist
          </HoverOption>
        
          <HoverOption onClick={() => {
             openModal();
             modalContent(
               <>
                 <PlaylistViewer />
               </>
             );
            }}>
            View My Playlists
          </HoverOption>
          <HoverOption onClick={() => {
            openModal() 
            modalContent(<DeletePlaylistModal/>)}}>
            Delete Playlist
          </HoverOption>
        </OptionModal>}
   
    
  {!currentUser?.uid && 
      <OptionModal
        heading={"Register successfully to create a playlist"}
      >
        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>}
    </>)
  
};

export const DeletePlaylistModal = () => {
  const {
    currentUser,
  } = useAuth();
  const [ playlists, setPlaylists] = useState([])
  const [isLoaded, setLoaded] = useState(false)
  const {getPlaylistsByUser} = useUser({})
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();


    useEffect(() => {
     getPlaylistsByUser(currentUser?.uid)
      .then((playlists) => {
        if (playlists && !playlists.error) {
          setPlaylists(playlists)
        }
        setLoaded(true)
      })
      .catch((err) => {
       setLoaded(true)
      });
    
    
      return () => {
       
      }
    }, [])
    

  return (
    <>
  {!isLoaded &&<OptionModal
    heading={"Delete Playlist"}
    onCancel={() => {
      modalContent();
      closeModal();
    }}
  >
    <ModalLoadingScreen transparent />
  </OptionModal>}
  {isLoaded &&  
  (<>
    <OptionModal
      isOpen={isOpen}
      heading={"Delete Playlist"}
      onCancel={() => {
        modalContent();
        closeModal();
      }}
    >
      {playlists?.length < 1 && "No Playlists"}
      {playlists?.length >= 1 && (
        <PlaylistList
          content={playlists}
          onClick={(index) => {
            openModal();
            modalContent(
              <>
                <DeletePlaylistPrompt playlistId={
              playlists[index].playlistId} />
              </>
            );
          }}
        />
      )}
    </OptionModal>
  </>
)}
</>
    )
};

export const CreatePlaylist = ({songId}) => {
 /*  return (<PlaylistCreator songId={songId} />); */
};

export const NotificationModal = () => {
  return (
    <>
      <Notifications />
    </>
  );
};

export const UnavailableFeature = () => {
  const { modalContent, openModal, closeModal } = useModal();
  return (
    <>
      <OptionModal heading={"This feature has been disabled or unavailable"}>
        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          OK
        </HoverOption>
      </OptionModal>
    </>
  );
};



export const DeletePlaylistPrompt = ({ playlistId }) => {
  const { openModal, modalContent, closeModal } = useModal();
  const { currentUser } = useAuth();
  const { deletePlaylist } = useMusic();
  const { goTo } = ScrollToTop();

  const confirmDelete = async ({ id, type }) => {
    switch (type) {
      case "playlist":
        deletePlaylist(id, currentUser?.uid)
          .then((data) => {
            modalContent(
              <OptionModal heading={"Successfully Deleted"}>
                <FormButton
                  to={`#`}
                  onClick={() => {
                    modalContent();
                    closeModal();
                    goTo(`/u/${currentUser?.uid}`);
                  }}
                >
                  Ok
                </FormButton>
              </OptionModal>
            );
          })
          .catch((err) => {
            //console.log(err);
            openModal();
            modalContent(<DeletePlaylistPrompt playlistId={playlistId} />);
          });
        break;

      default:
        break;
    }
  };
  return (
    <>
      <OptionModal heading={"Delete Playlist?"}>
        <HoverOption
          onClick={() => confirmDelete({ id: playlistId, type: "playlist" })}
        >
          Yes
        </HoverOption>
        <FormButton
          to="#"
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          No
        </FormButton>
      </OptionModal>
    </>
  );
};

export const MusicOptionsModal = ({ content, type }) => {
  const { modalContent, openModal, closeModal } = useModal();
  const { currentUser, getUserWatchlist, getUserLikes } = useAuth();
  const {goTo} = ScrollToTop()
  const {
    deletePlaylist,
    unlikeSong,
    likeSong,
    likeAlbum,
    likeEpisode,
    unlikeEpisode,
    unlikePlaylist,
    unlikeAlbum,
    watchEpisode,
    unwatchEpisode,
    unwatchSong,
    unwatchNFTokenCollection,
    watchSong,
    unlikeNFTokenCollection,
    likeNFTokenCollection,
    likePlaylist,
    watchNFTokenCollection,
  } = useMusic();
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    getUserWatchlist(currentUser?.uid).then(() => {
      getUserLikes(currentUser?.uid).then(() => {
        setLoaded(true);
      });
    });

    return () => {};
  }, [currentUser]);

  const displayMenu = () => {
    if (currentUser?.uid) {
      switch (type) {
        case CONTENT_TYPES.SONG:
          return (
            <>
              <OptionModal
                heading={"Song Options"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                {currentUser &&
                currentUser?.likes &&
                currentUser?.likes
                  ?.filter((like) => like.songId)
                  .find((song) => song.songId === content?.songId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unlikeSong(content?.songId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcDislike style={{ marginRight: "2%" }} /> Remove from
                    Likes
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await likeSong(content?.songId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcLike style={{ marginRight: "2%" }} /> Add to Likes
                  </HoverOption>
                )}
                {currentUser?.uid &&
                currentUser?.watchlist &&
                currentUser?.watchlist
                  .filter((list) => list.songId)
                  .find((s) => s?.songId === content?.songId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unwatchSong(content?.songId);
                      await getUserWatchlist(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                    Remove from Watchlist
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await watchSong(content?.songId);
                      await getUserWatchlist(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEye style={{ marginRight: "2%" }} />
                    Add To Watchlist
                  </HoverOption>
                )}
                <HoverOption
                  onClick={() => {
                    modalContent(
                      <ArtistTipper
                        type={CONTENT_TYPES.SONG}
                        content={content}
                      />
                    );
                  }}
                >
                  Tip Artist
                </HoverOption>
                {currentUser?.purchases?.songs?.length > 0 &&
                  currentUser?.purchases?.songs?.find(
                    (s) => s?.songId === content?.songId
                  ) && (
                    <HoverOption
                      onClick={() => {
                        goTo(`/song/${content?.songId}/download`);
                        modalContent();
                        closeModal();
                      }}
                    >
                      Download Song
                    </HoverOption>
                  )}
                <HoverOption
                  onClick={async () => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <GoReport style={{ marginRight: "2%" }} />
                  Report
                </HoverOption>
                <HoverOption
                  onClick={async () => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <FaShare style={{ marginRight: "2%" }} />
                  Share
                </HoverOption>
              </OptionModal>
            </>
          );

        case CONTENT_TYPES.ALBUM:
          return (
            <>
              <OptionModal
                heading={"Album Options"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                {currentUser?.uid &&
                currentUser?.likes &&
                currentUser?.likes
                  ?.filter((like) => like.albumId)
                  .find((album) => album?.albumId === content?.albumId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unlikeAlbum(content?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcDislike style={{ marginRight: "2%" }} /> Remove from
                    Likes
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await likeAlbum(content?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcLike style={{ marginRight: "2%" }} /> Add to Likes
                  </HoverOption>
                )}
                {currentUser?.uid &&
                currentUser?.watchlist &&
                currentUser?.watchlist
                  .filter((list) => list.albumId)
                  .find((a) => a?.albumId === content?.albumId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unwatchSong(content?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                    Remove from Watchlist
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await watchSong(content?.albumId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEye style={{ marginRight: "2%" }} />
                    Add To Watchlist
                  </HoverOption>
                )}
                {currentUser?.purchases?.albums?.length > 0 &&
                  currentUser?.purchases?.albums?.find(
                    (a) => a?.albumId === content?.albumId
                  ) && (
                    <HoverOption
                      onClick={() => {
                        goTo(`/album/${content?.albumId}/download`);
                        modalContent();
                        closeModal();
                      }}
                    >
                      Download Album
                    </HoverOption>
                  )}
                <HoverOption
                  onClick={async () => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <GoReport style={{ marginRight: "2%" }} />
                  Report
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <FaShare style={{ marginRight: "2%" }} />
                  Share
                </HoverOption>
              </OptionModal>
            </>
          );

        case CONTENT_TYPES.COLLECTION:
          return (
            <>
              <OptionModal
                heading={"Collection Options"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                {content?.tokenGate && (
                  <HoverOption
                    onClick={() => {
                      /* modalContent(
                        <TokenGatedContentDownloader collection={content} />
                      ); */
                    }}
                  >
                    Download Token Gated Content
                  </HoverOption>
                )}
                {currentUser &&
                currentUser?.likes &&
                currentUser?.likes
                  ?.filter((like) => like.collectionId)
                  .find(
                    (collection) =>
                      collection.collectionId === content?.collectionId
                  ) ? (
                  <HoverOption
                    onClick={async () => {
                      await unlikeNFTokenCollection(content?.collectionId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcDislike style={{ marginRight: "2%" }} /> Remove from
                    Likes
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await likeNFTokenCollection(content?.collectionId);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcLike style={{ marginRight: "2%" }} /> Add to Likes
                  </HoverOption>
                )}
                {currentUser &&
                currentUser?.watchlist &&
                currentUser?.watchlist
                  .filter((list) => list.collectionId)
                  .find((c) => c?.collectionId === content?.collectionId) ? (
                  <HoverOption
                    onClick={async () => {
                      await unwatchNFTokenCollection(content?.collectionId);
                      await getUserWatchlist(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                    Remove from Watchlist
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await watchNFTokenCollection(content?.collectionId);
                      await getUserWatchlist(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <AiOutlineEye style={{ marginRight: "2%" }} />
                    Add To Watchlist
                  </HoverOption>
                )}
                <HoverOption
                  onClick={() => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <GoReport style={{ marginRight: "2%" }} />
                  Report
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <FaShare style={{ marginRight: "2%" }} />
                  Share
                </HoverOption>
              </OptionModal>
            </>
          );

        case CONTENT_TYPES.PLAYLIST:
          return (
            <>
              <OptionModal
                heading={"Playlist Options"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                {currentUser &&
                currentUser?.likes &&
                currentUser?.likes
                  ?.filter((like) => like.playlistId)
                  .find((playlist) => playlist.playlistId === content?.id) ? (
                  <HoverOption
                    onClick={async () => {
                      await unlikePlaylist(content?.id);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcDislike style={{ marginRight: "2%" }} /> Remove from
                    Likes
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={async () => {
                      await likePlaylist(content?.id);
                      await getUserLikes(currentUser?.uid);
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcLike style={{ marginRight: "2%" }} /> Add to Likes
                  </HoverOption>
                )}
                <HoverOption
                  onClick={() => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <GoReport />
                  Report
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent(<UnavailableFeature />);
                  }}
                >
                  <FaShare />
                  Share
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    openModal();
                    modalContent(
                      <DeletePlaylistPrompt playlistId={content?.id} />
                    );
                  }}
                >
                  Delete Playlist
                </HoverOption>
              </OptionModal>
            </>
          );
        case CONTENT_TYPES.EPISODE:
          return (
            <>
              <OptionModal
                heading={"Podcast Options"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                {currentUser &&
                currentUser?.likes &&
                currentUser?.likes
                  ?.filter((like) => like.podcastId)
                  .find((podcast) => podcast.podcastId === content?.id) ? (
                  <HoverOption
                    onClick={() => {
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcDislike style={{ marginRight: "2%" }} /> Remove from
                    Likes
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={() => {
                      modalContent();
                      closeModal();
                    }}
                  >
                    <FcLike style={{ marginRight: "2%" }} /> Add to Likes
                  </HoverOption>
                )}
                {currentUser &&
                currentUser?.watchlist &&
                currentUser?.watchlist
                  .filter((list) => list.podcastId)
                  .find((p) => p?.podcastId === content?.id) ? (
                  <HoverOption
                    onClick={() => {
                      modalContent();
                      watchSong(content?.id);
                      closeModal();
                    }}
                  >
                    <AiOutlineEyeInvisible style={{ marginRight: "2%" }} />
                    Remove from Watchlist
                  </HoverOption>
                ) : (
                  <HoverOption
                    onClick={() => {
                      modalContent();
                      watchSong(content?.id);
                      closeModal();
                    }}
                  >
                    <AiOutlineEye style={{ marginRight: "2%" }} />
                    Add To Watchlist
                  </HoverOption>
                )}
                <HoverOption
                  onClick={() => {
                    modalContent(
                      <ArtistTipper
                        type={CONTENT_TYPES.EPISODE}
                        content={content}
                      />
                    );
                  }}
                >
                  Tip Artist
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent();
                    closeModal();
                  }}
                >
                  <GoReport style={{ marginRight: "2%" }} />
                  Report
                </HoverOption>
                <HoverOption
                  onClick={() => {
                    modalContent();
                    closeModal();
                  }}
                >
                  <FaShare style={{ marginRight: "2%" }} />
                  Share
                </HoverOption>
              </OptionModal>
            </>
          );
        default:
          break;
      }
    }
  };

  return (
    <>
      {!isLoaded && <ModalLoadingScreen text={"Opening Menu"} />}
      {isLoaded && displayMenu()}
    </>
  );
};

export const MusicUploadModal = () => {
 const {closeModal, modalContent,openModal} = useModal();
const {currentUser,isArtist, currentProfile} = useAuth()
const {goTo} = ScrollToTop()

const artistCheck = async (type) => {
  modalContent(
    <OptionModal>
      <ModalLoadingScreen transparent text={"Loading. Please wait"} />
    </OptionModal>
  );
  if (type === "drafts" && currentUser?.isPodcaster) {
    // modalContent(<UnavailableFeature/>);
    goTo("/edit/drafts");
    modalContent();
    closeModal();
  } else {
    return await isArtist(currentUser?.uid)
      .then((data) => {
        if (data?.isVerified) {
          switch (type) {
            case "song":
              goTo("/edit/song");
              modalContent();
              closeModal();
              break;
            case "album":
              // modalContent(<UnavailableFeature/>);
              goTo("/edit/album");
              modalContent();
              closeModal();
              break;
            case "drafts":
              // modalContent(<UnavailableFeature/>);
              goTo("/edit/drafts");
              modalContent();
              closeModal();
              break;
            case "video":
               //modalContent(<UnavailableFeature/>);
               goTo("/edit/video");
              modalContent();
              closeModal(); 
              break;

            default:
              modalContent(<UnavailableFeature />);
              break;
          }
        } else {
          modalContent(<UnavailableFeature />);
        }
        return false;
      })
      .catch((err) => {
        //console.log(err)
      });
  }
};

  return(
    <>
      <OptionModal
        heading={"Upload Type"}
        onCancel={() => {
          modalContent();
          closeModal();
        }}
      >
        {(currentProfile === 'artist' || currentProfile === 'label') && (currentUser?.isArtist || currentUser?.isLabel) && (
          <>
          <HoverOption onClick={() => artistCheck("song")}>
            Single
          </HoverOption>
          <HoverOption onClick={() => artistCheck("album")}>
            Album
          </HoverOption>
          <HoverOption onClick={() => artistCheck("video")}>
            Music Video
          </HoverOption>
        </>)}
        {currentProfile === 'podcaster' && currentUser?.isPodcaster && (
          <HoverOption
            onClick={() => {
              goTo(`/edit/episode`);
              modalContent();
              closeModal();
              /*modalContent(<UnavailableFeature/>)*/
            }}
          >
            Podcast Episode
          </HoverOption>
        )}
        {(currentProfile === 'artist' || currentProfile === 'label' || currentProfile === 'podcaster')  && (
          <HoverOption
            onClick={() => {
              artistCheck("drafts");
            }}
          >
            Drafts
          </HoverOption>
        )}
      </OptionModal>
    </>
  );
};

export const SelectCollection = ({type}) => {
  const {currentUser} = useAuth()
 const {isOpen, openModal, closeModal, modalContent} = useModal()
 const [isLoaded, setLoaded] = useState(false)
 const [collections, setCollections] = useState(false)
 const {getCollectionsByUser} = useUser({})

 useEffect(() => {
  getCollectionsByUser(currentUser?.uid, "all").then(
    (collections) => {
      if(collections && !collections?.error){
        setCollections(collections)
      }
      setLoaded(true)
    }).catch(err => {
      setLoaded(true)
    })
 
   return () => {
   }
 }, [])
 

  return(
    <>
      
      {!isLoaded && <ModalLoadingScreen transparent={true} />}
      {isLoaded && (<> 
        {collections && (collections.length > 0) ? 
        (
          <>
            <OptionModal
              isOpen={isOpen}
              heading={"Select Collection"}
              onCancel={() => {
                modalContent();
                closeModal();
              }}
            >
              {collections.collections.map((collection) => {
                return (
                  <HoverOption
                    key={collection?.collectionId}
                    onClick={() => {
                      switch (type) {
                        case TRANSACTION_OPTIONS.NFTOKEN_GIFT:
                          modalContent(
                            <>
                              <NFTokenCollectionGifter
                                collection={collection}
                              />
                            </>
                          );
                          break;
                        case TRANSACTION_OPTIONS.NFTOKEN_MINT:
                          modalContent(<MintCollection collection={collection}/>);
                          break;

                        case TRANSACTION_OPTIONS.COLLECTION_WHITELIST:
                          modalContent(
                            <>
                              <NFTokenWhitelister
                                collectionId={collection.collectionId}
                              />
                            </>
                          );
                          break;

                        default:
                          break;
                      }
                    }}
                  >
                    {`${collection.name} #${collection.mintInfo.taxon}`}
                  </HoverOption>
                );
              })}
            </OptionModal>
          </>)
  
        
       :  
          <OptionModal isOpen={isOpen} heading={"No Available Collections"}>
            <HoverOption
              onClick={() => {
                modalContent();
                closeModal();
              }}
            >
              OK
            </HoverOption>
          </OptionModal>
        
      }
      
      
      </>)}
  
      
      </>
  );
};

export const NftokenMintModal = ({data}) => {
 const { openModal, modalContent, closeModal} = useModal()
 const {goTo} = ScrollToTop()

  return (
    <>
      <OptionModal
        heading={"NFT Collection"}
        onCancel={() => {
          modalContent();
          closeModal();
        }}
      >
        <HoverOption
          onClick={() => {
            goTo(0);
            modalContent(< UnavailableFeature/>);
            //closeModal();
          }}
        >
          Create New NFT Collection
        </HoverOption>

        <HoverOption
          onClick={() => {
            openModal()
            modalContent(<SelectCollection type={TRANSACTION_OPTIONS.NFTOKEN_MINT}/>);
            goTo(`/home`);
          }}
        >
          Mint NFT Collection
        </HoverOption>
      </OptionModal>
    </>
  );
};

export const MintCollection = ({collection}) => {
  const { openModal, modalContent, closeModal} = useModal()
  const {currentUser} = useAuth()
  const {goTo} = ScrollToTop()

  const onMintSuccess = (data) => {
    modalContent(
      <OptionModal
        isOpen={true}
        heading={`NFToken ${data?.tokenId?.substring(
          0,
          5
        )}...${data?.tokenId?.substring(
          data?.tokenId?.length - 4,
          data?.tokenId?.length - 1
        )} Minted`}
      >
        <FormText>
          {" "}
          {`NFToken from Collection ${data?.collection?.name} #${data?.collection?.mintInfo?.taxon} has been Minted!`}
        </FormText>
        <HoverOption
          onClick={() => {
            goTo(`/nftoken/${data?.tokenId}`);
            closeModal();
            modalContent();
          }}
        >
          View Token
        </HoverOption>
        <HoverOption
          onClick={() => {
            goTo(`/collection/${data?.collectionId}`);
            closeModal();
            modalContent();
          }}
        >
          View Collection
        </HoverOption>
        <HoverOption
          onClick={() => {
            closeModal();
            modalContent();
          }}
        >
          Close
        </HoverOption>
      </OptionModal>
    );
  };

  return (
    <>
      <QRCodeForm
        heading={`Minting an NFToken from ${collection?.name} #${collection.mintInfo.taxon}`}
        type={TRANSACTION_OPTIONS.NFTOKEN_MINT}
        onFail={() => {
          modalContent(
            <OptionModal isOpen={true} heading={`Something Went Wrong`}>
              <FormText> {`Could Not Mint Token. Try Again?`}</FormText>
              <HoverOption
                onClick={() => {
                  modalContent(<MintCollection collection ={collection}/>);
                }}
              >
                Try Again
              </HoverOption>
              <HoverOption
                onClick={() => {
                  closeModal();
                  modalContent();
                }}
              >
                Cancel
              </HoverOption>
            </OptionModal>
          );
        }}
        onCancel={() => {
          closeModal();
          modalContent();
        }}
        onSuccess={onMintSuccess}
        body={{
          sender: currentUser?.uid,
          collection: collection,
          collectionId: collection?.collectionId,
          instruction: `${currentUser?.uid} is minting an NFToken from the collection ${collection?.collectionId} #${collection.mintInfo.taxon}`,
          memo: `${currentUser?.uid} is minting an NFToken from the collection ${collection?.collectionId} #${collection.mintInfo.taxon}`,
        }}
      />
    </>
  );
};

export const NftokenCollectionModal = () => {
  const {
    currentUser,
    getUserAddressInfo,
  } = useAuth();
  const {
    deleteDraft,
    getDraftCollections,

  } = useMusic({});
  const { toggleModal, modalContent, isOpen, openModal, closeModal } =
    useModal();
  const {goTo} = ScrollToTop()

  const draftCollectionOptions = async (draft) => {
    if (currentUser?.uid) {
      openModal();
      return modalContent(
        <>
          <OptionModal
            isOpen={true}
            heading={draft?.title ? draft?.title : "untitled"}
            onCancel={() => {
              getCollectionDrafts();
            }}
          >
            <HoverOption
              onClick={() => {
                goTo(`/edit/collection?id=${draft?.draftId}`);
                modalContent();
                closeModal();
              }}
            >
              Edit
            </HoverOption>
            <HoverOption
              onClick={async () => {
                return await deleteDraft(draft?.draftId).then((data) => {
                  if (data) {
                    getCollectionDrafts();
                  }
                });
              }}
            >
              Delete
            </HoverOption>
          </OptionModal>
        </>
      );
    }
  };


  const getCollectionDrafts = async () => {
    openModal();
    modalContent(
      <OptionModal heading={"Draft Collections"}>
        <ModalLoadingScreen transparent text={"Loading. Please wait"} />
      </OptionModal>
    );
    return await getDraftCollections(currentUser?.uid)
      .then((collections) => {
        //console.log(collections)
        if (collections && collections?.length > 0) {
          modalContent(
            <OptionModal
              onCancel={() => {
                openModal()
                modalContent(<NftokenCollectionModal/>)
              }}
              heading={"Draft Collections"}
            >
              {collections?.map((collection) => {
                return (
                  <HoverOption
                    onClick={() => {
                      draftCollectionOptions(collection);
                    }}
                  >{`${collection?.title} #${collection?.taxon}`}</HoverOption>
                );
              })}
            </OptionModal>
          );
          return;
        }
        if (!collections) {
          modalContent(
            <OptionModal heading={"No Drafts Found"}>
              <HoverOption onClick={() => {
              openModal()
              modalContent(<NftokenCollectionModal/>)
            }}>OK</HoverOption>
            </OptionModal>
          );
        }
      })
      .catch((err) => {
        console.error(err);
        modalContent(
          <OptionModal
            onCancel={() => {
              openModal()
              modalContent(<NftokenCollectionModal/>)
            }}
            heading={"No Drafts Found"}
          >
            <HoverOption onClick={() => {
              openModal()
              modalContent(<NftokenCollectionModal/>)
            }}>OK</HoverOption>
          </OptionModal>
        );
      });
  };

  const nFTokenMinter = () => {
    modalContent(
      <OptionModal
        heading={"Set NFToken Minter"}
        additionalText={
          "In order to continue, you will need to set Sonar Muse as your NFToken Minter"
        }
      >
        <HoverOption
          onClick={() => {
            goTo("/setNFTMinter");
            modalContent();
            closeModal();
          }}
        >
          Set Sonar Muse as NFToken Minter
        </HoverOption>
        <HoverOption
          onClick={() => {
            modalContent();
            closeModal();
          }}
        >
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  return(
    <>
      <OptionModal
        isOpen={isOpen}
        heading={"NFToken Collection Options"}
        onCancel={() => {
          modalContent();
          closeModal();
        }}
      >
        <HoverOption
          onClick={() => {
            //modalContent(<UnavailableFeature/>);
            goTo("/setNFTMinter");
            modalContent();
            closeModal();
            /*               selectCollection()
             */ //console.log('clicked')
          }}
        >
          Set Sonar Muse as Minter (Mint on your behalf)
        </HoverOption>
        <HoverOption
          onClick={async () => {
            /*modalContent(<UnavailableFeature/>); */
            modalContent(
              <OptionModal heading={"Collection Creator"}>
                <ModalLoadingScreen transparent />
              </OptionModal>
            );
            let nftokenMinter = await getUserAddressInfo(currentUser?.uid);
            if (nftokenMinter?.NFTokenMinter === MINTING.ADDRESS) {
              goTo("/edit/collection");
              //modalContent(<UnavailableFeature/>);
              closeModal();
            } else {
              nFTokenMinter();
            }
          }}
        >
          Create New NFT Collection
        </HoverOption>
        <HoverOption
          onClick={async () => {
            /*modalContent(<UnavailableFeature/>); */
            getCollectionDrafts();
          }}
        >
          View Draft NFT Collection
        </HoverOption>
        <HoverOption
          onClick={() => {
            /*               modalContent(<UnavailableFeature/>);
            
             */ 
            openModal()
            modalContent(<SelectCollection type={TRANSACTION_OPTIONS.NFTOKEN_MINT}/>);
            //console.log('clicked')
          }}
        >
          Mint NFT Collection
        </HoverOption>
        <HoverOption
          onClick={() => {
            /*               modalContent(<UnavailableFeature/>);
             */ goTo("/edit/collectionWhitelister");
            //console.log('clicked')
          }}
        >
          Collection Whitelister
        </HoverOption>
        <HoverOption
          onClick={async () => {
            //console.log('clicked')
            //modalContent(<UnavailableFeature/>);
            modalContent(
              <OptionModal heading={"Collection Creator"}>
                <ModalLoadingScreen transparent />
              </OptionModal>
            );
            let nftokenMinter = await getUserAddressInfo(currentUser?.uid);
            if (
              nftokenMinter?.NFTokenMinter ===
              "rJZAcSJLmTo22Fh4vFQ2L83jjqtr7JeSWQ"
            ) {
              
                openModal()
                modalContent(<SelectCollection type={TRANSACTION_OPTIONS.NFTOKEN_GIFT}/>)
              
            } else {
              nftokenMinter()
              ();
            }
          }}
        >
          Gift NFT From Collection
        </HoverOption>
      </OptionModal>
    </>
  );
};

export const OpenMusicStorePlayer = ({ content, type }) => {
  const { toggleModal, modalContent, isOpen, openModal } = useModal();




  const openMusicStorePlayer = () => {
    switch (type) {
      case CONTENT_TYPES.SONG:
        //console.log('Here2')
        return (
          <>
          {/*   <MusicStorePlayer
              name={getSongTitle(content)}
              music={[content]}
              type={CONTENT_TYPES.SONG}
            /> */}
          </>
        );
        break;
      case CONTENT_TYPES.ALBUM:
        return (
          <>
           {/*  <MusicStorePlayer
              name={content.albumName}
              music={content.songs}
              type={CONTENT_TYPES.ALBUM}
            /> */}
          </>
        );
        break;
      case CONTENT_TYPES.COLLECTION:
        //console.log(content);
        if (content?.songs?.length > 0 && content?.albums?.length > 0) {
          return (
            <>
              <OptionModal heading={"Select Music"}>
                <HoverOption
                  onClick={() => {
                    return (
                      <>
                      {/*   <MusicStorePlayer
                          name={content?.name}
                          music={content.songs}
                          type={CONTENT_TYPES.ALBUM}
                        /> */}
                      </>
                    );
                  }}
                >
                  Songs
                </HoverOption>
                <HoverOption>Albums</HoverOption>
              </OptionModal>
            </>
          );
        }
        if (content?.songs?.length > 0 && !content?.albums) {
          return (
            <>
             {/*  <MusicStorePlayer
                name={content?.name}
                music={content.songs}
                type={CONTENT_TYPES.ALBUM}
              /> */}
            </>
          );
        }
        if (!content?.songs && content?.albums?.length > 0) {
          return (
            <>
              {/* <MusicStorePlayer
                name={content?.name}
                music={content}
                type={CONTENT_TYPES.COLLECTION}
              /> */}
            </>
          );
        }

        break;
      case CONTENT_TYPES.NFTOKEN:
        if (content?.songs?.length > 0 && content?.albums?.length > 0) {
          return (
            <>
              {/* <MusicStorePlayer
                name={content?.name}
                music={content}
                type={CONTENT_TYPES.NFTOKEN}
              /> */}
            </>
          );
        }
        if (content?.songs?.length > 0 && content?.albums?.length === 0) {
          return (
            <>
              {/* <MusicStorePlayer
                name={content?.name}
                music={content}
                type={CONTENT_TYPES.NFTOKEN}
              /> */}
            </>
          );
        }
        if (content?.songs?.length === 0 && content?.albums?.length > 0) {
          return (
            <>
              {/* <MusicStorePlayer
                name={content?.name}
                music={content}
                type={CONTENT_TYPES.NFTOKEN}
              /> */}
            </>
          );
        }

        break;
      default:
        break;
    }
  };
  return <>{openMusicStorePlayer()}</>;
};

export const ProfileModal = () => {
  const [isLoaded, setLoaded] = useState(false);
  const {
    currentUser,
    isArtist,
    isLabel,
    isPodcaster,
    getUserSubscription,
    userSubscription,
    getUserMusicNFTokens,
    nfTokens
  } = useAuth();
  const { modalContent, openModal, closeModal, toggleModal } = useModal();
  const [subscription, setSubscription] = useState();
  const [numTokens, setNumTokens] = useState(0);
  const [userName, setUserName] = useState(currentUser?.uid);
  const [rate, setRate] = useState(0)
  const [isRateLoaded, setRateLoaded] = useState(false)
  const [currency, setCurrency] = useState("XRP")
  const {themeColors} = useWebsiteTheme()

  //const [isSubscriptionLoaded, setSubscriptionLoaded] = useState(false)
  const {goTo} = ScrollToTop()
  useEffect(() => {
    getProfileInfo();

    return () => {};
  }, []);

  useEffect(() => {
    getAddressName(currentUser?.uid).then((data) => {
      if (getObjectLength(data) > 0) {
        setUserName(data?.name);
      }
    });
    return () => {};
  }, [currentUser]);

  useEffect(() => {
    if(subscription?.subscription && currency !== "XRP" ){
      if(currency === "WYM"){
        setCurrency("XRP")
        return
      }
      setRateLoaded(false)
      axiosInstance.get(`/api/${currency}/getPrice`).then((rate) => {
        if(rate.data?.price){
          setRate(rate.data?.price)
          setRateLoaded(true)
          return
        }
        setCurrency("XRP")
        setRateLoaded(true)
      }).catch(err => {
        setCurrency("XRP")
        setRateLoaded(true)
      })
    }

  },[currency])

  const getProfileInfo = async () => {
    if (currentUser?.uid) {
      if (userSubscription) {
        let sub = await getUserSubscription(currentUser?.uid);
        setSubscription(sub);
        setLoaded(true);
        //setSubscriptionLoaded(true)
        getUserSubscriptionInfo(currentUser?.uid, setSubscription, getUserSubscription)
      }
      if (!currentUser?.subscription) {
        let sub = await getUserSubscription(currentUser?.uid);
        setSubscription(sub);
        setLoaded(true);
        //setSubscriptionLoaded(true)
      }
    }
    setLoaded(true);
    return;
  };

  useEffect(() => {
    let tokens = 0;
    if (nfTokens?.length > 0) {
      nfTokens?.map((nft) => {
        tokens += nft?.tokens?.length;
        return
      });
      setNumTokens(tokens);
    }
    return () => {};
  }, []);

  const goToArtistProfile = async () => {
    await isArtist(currentUser?.uid)
      .then((data) => {
        if (data?.artistId) {
          goTo(`/artist/${data.artistId}`);
          modalContent();
          closeModal();
        } else {
          
          goTo(`/u/${currentUser?.uid}`);
          modalContent();
          closeModal();
        }
        return false;
      })
      .catch((err) => {
        goTo(`/u/${currentUser?.uid}`);
        modalContent();
        closeModal();
        //console.log(err);
      });
  };

  const goToLabelProfile = async () => {
    await isLabel(currentUser?.uid)
      .then((data) => {
        if (data?.labelId) {
          
          goTo(`/label/${data.labelId}`);
          modalContent();
          closeModal();
        } else {
          goTo(`/u/${currentUser?.uid}`);
          modalContent();
          closeModal();
        }
        return false;
      })
      .catch((err) => {
        goTo(`/u/${currentUser?.uid}`);
        modalContent();
        closeModal();
        //console.log(err);
      });
  };

  const goToPodcasterProfile = async () => {
    await isPodcaster(currentUser?.uid)
      .then((data) => {
        if (data?.podcasterId) {
          
          goTo(`/podcaster/${data.podcasterId}`);
          modalContent();
          closeModal();
        } else {
          goTo(`/u/${currentUser?.uid}`);
          modalContent();
          closeModal();
        }
        return false;
      })
      .catch((err) => {
        goTo(`/u/${currentUser?.uid}`);
        modalContent();
        closeModal();
        //console.log(err);
      });
  };


  return (
    <>
      <OptionModal
        heading={
          <div style={{ flexDirection: "column" }}>
            <small>{userName}</small>
            <FormH1>
              {" "}
              <img
                src={
                  currentUser?.photoURL
                    ? currentUser?.photoURL
                    : DEFAULT_IMAGE()
                }
                alt={currentUser?.uid}
                style={{ borderRadius: "50%" }}
              />{" "}
            </FormH1>
          </div>
        }
        onCancel={() => {
          modalContent();
          closeModal();
        }}
      >
        <FormWrapper>
          {currentUser?.isArtist ||
          currentUser?.isPodcaster ||
          currentUser?.isLabel ? (
            <MintButtonConainer
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                background: "transparent",
              }}
            >
              <HoverOption
                onClick={() => {
                  goTo(`/u/${currentUser?.uid}`);
                  modalContent();
                  closeModal();
                  
                }}
              >
                User Profile
              </HoverOption>
              {currentUser?.isArtist && (
                <HoverOption
                  onClick={() => {
                    goToArtistProfile();
                  }}
                >
                  Artist Profile
                </HoverOption>
              )}
              {currentUser?.isPodcaster && (
                <HoverOption
                  onClick={() => {
                    goToPodcasterProfile();
                  }}
                >
                  Podcaster Profile
                </HoverOption>
              )}
              {currentUser?.isLabel && (
                <HoverOption
                  onClick={() => {
                    goToLabelProfile();
                  }}
                >
                  Label Profile
                </HoverOption>
              )}
            </MintButtonConainer>
          ) : (
            <HoverOption
              onClick={() => {
                goTo(`/u/${currentUser?.uid}`);
                modalContent();
                closeModal();
                
              }}
            >
              View My Profile
            </HoverOption>
          )}
          <FormWrapper>
            <FormText>
              Followers: {currentUser?.followers} | Following:{" "}
              {currentUser?.following}
            </FormText>
          </FormWrapper>

          {isLoaded && getObjectLength(subscription) > 0 && (
            <>
              <FormWrapper>
              <CurrencySelector currency={currency} setCurrency={setCurrency}/><FormText
                  style={{
                    color:
                      subscription.subscription / 1000000 >= 10
                        ? themeColors.successColor
                        : subscription.subscription / 1000000 < 5 &&
                          subscription.subscription / 1000000 >= 1
                        ? themeColors.warningColor
                        : subscription.subscription / 1000000 < 1 &&
                          themeColors.errorColor,
                  }}
                >
                  Streaming Subscription Balance:{" "}
                  {currency === "XRP" && parseFloat(subscription.subscription / 1000000).toFixed(5)} 
                  {currency !== "XRP" && !isRateLoaded && "Loading Balance"}
                  {currency !== "XRP" && isRateLoaded && (parseFloat((subscription?.subscription/ 1000000) * rate).toFixed(6))} {currency}
                </FormText>
                {new Date().getTime() <
                  new Date(subscription?.endDate).getTime() && (
                  <FormText>
                    Subscription Ends:{" "}
                    {new Date(subscription?.endDate).getUTCFullYear()}/
                    {new Date(subscription?.endDate).getUTCMonth() + 1}/
                    {new Date(subscription?.endDate).getUTCDate()} (
                    {getExpirationTimestamp(new Date(subscription?.endDate))})
                  </FormText>
                )}{" "}
              </FormWrapper>
            </>
          )}
          <FormWrapper>
            <FormText>
              Owned Music NFTokens: {numTokens}{" "}
              {currentUser?.nfTokens?.length > 0 &&
                `(${currentUser?.nfTokens?.length} Unique Collections)`}{" "}
            </FormText>
            <FormText>
              Owned Digital Music: {currentUser?.purchases?.albums?.length}{" "}
              Albums | {currentUser?.purchases?.songs?.length} Singles
            </FormText>
          </FormWrapper>
        </FormWrapper>{" "}
      </OptionModal>
    </>
  );
};

export function ArtistTipper({ type, content }) {
  const { currentUser } = useAuth();
  const { modalContent, openModal, closeModal } = useModal();
  const [selectedArtist, setSelectedArtist] = useState({});
  const [artists, setArtists] = useState();
  const [tip, setTip] = useState(0);
  const [memo, setMemo] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [currency, setCurrency] = useState("XRP");

  useEffect(() => {
    if (type === CONTENT_TYPES.EPISODE) {
      setArtists(content?.podcasters);
    } else {
      let a = [];
      a.push(...content?.artists);
      if (content?.featuredArtists?.length > 0) {
        a.push(...content?.featuredArtists);
      }

      if (getObjectLength(content?.songCredits) > 0) {
        let sC = content?.songCredits;
        if (sC?.composers?.length > 0) {
          a.push(...sC?.composers);
        }
        if (sC?.writers?.length > 0) {
          a.push(...sC?.writers);
        }
        if (sC?.producers?.length > 0) {
          a.push(...sC?.producers);
        }
      }
      setArtists([...getUniqueListBy(a, "")]);
    }
    setLoaded(true);

    return () => {};
  }, [type]);

  const formValidation = () => {
    //console.log(currency)

    if (tip > 0 && memo) {
      makePayment();
    } else if (tip > 0) {
      makePayment();
    }
  };

  const selectArtist = (artist) => {
    if (type === CONTENT_TYPES.EPISODE) {
      setSelectedArtist({
        name: artist?.podcasterName,
        address: artist?.address,
      });
    } else {
      setSelectedArtist({
        name: artist?.artistName,
        address: artist?.address,
      });
    }
    console.log(artist);
    console.log(selectedArtist);
  };

  const onSuccess = () => {
    modalContent(
      <>
        <OptionModal heading={`Successfully tipped ${selectedArtist?.name}`}>
          <HoverOption
            onClick={() => {
              closeModal();
              modalContent();
            }}
          >
            OK
          </HoverOption>
        </OptionModal>
      </>
    );
  };

  const onFailure = () => {
    modalContent(
      <>
        <OptionModal heading={`Successfully tipped ${selectedArtist?.name}`}>
          <HoverOption
            onClick={() => {
              makePayment();
            }}
          >
            Try again
          </HoverOption>
          <HoverOption
            onClick={() => {
              closeModal();
              modalContent();
            }}
          >
            OK
          </HoverOption>
        </OptionModal>
      </>
    );
  };

  const onCancel = () => {
    closeModal();
    modalContent();
  };

  const makePayment = () => {
    modalContent(
      <QRCodeForm
        body={{
          sender: currentUser?.uid ? currentUser.uid : undefined,
          receiver: selectedArtist?.address,
          value: tip,
          pathfinding: true,
          currency: currency,
          instruction: `${
            currentUser?.uid ? currentUser?.uid : "User"
          } is making the following transaction to tip ${
            type === CONTENT_TYPES.EPISODE
              ? ` Podcaster ${selectedArtist?.name}`
              : ` Artist ${selectedArtist?.name}`
          } for ${
            type === CONTENT_TYPES.EPISODE
              ? ` Episode ${content.episodeName} (id: ${content.episodeId})`
              : ` Song ${getSongTitle(content)} (id: ${content.songId})`
          }`,
          memo: memo
            ? `Sonar Muse Tipper: ${memo} (${
                type === CONTENT_TYPES.EPISODE
                  ? ` Episode ${content.episodeName} (id: ${content.episodeId})`
                  : ` Song ${getSongTitle(content)} (id: ${content.songId})`
              }`
            : `Sonar Muse Tipper: Tip for ${
                type === CONTENT_TYPES.EPISODE
                  ? ` Episode ${content.episodeName} (id: ${content.episodeId})`
                  : ` Song ${getSongTitle(content)} (id: ${content.songId})`
              }`,
        }}
        heading={`Tipping ${
          type === CONTENT_TYPES.EPISODE
            ? ` Podcaster ${selectedArtist?.name}`
            : ` Artist ${selectedArtist?.name}`
        }`}
        type={TRANSACTION_OPTIONS.TIP_USER}
        onSuccess={onSuccess}
        onFail={onFailure}
        cancelQr={onCancel}
      ></QRCodeForm>
    );
  };

  return (
    <>
      <OptionModal
        onCancel={closeModal}
        heading={`Tipping ${
          getObjectLength(selectedArtist) > 0
            ? type === CONTENT_TYPES.EPISODE
              ? ` Podcaster ${selectedArtist?.name}`
              : ` Artist ${selectedArtist?.name}`
            : type === CONTENT_TYPES.EPISODE
            ? ` Episode ${content?.episodeName}`
            : `Song ${getSongTitle(content)}`
        }`}
      >
        {!isLoaded && <ModalLoadingScreen transparent={true} />}
        {isLoaded && artists?.length > 0 && (
          <>
            {" "}
            {getObjectLength(selectedArtist) === 0 &&
              artists
                ?.filter((a) => a?.address)
                .map((artist) => {
                  return (
                    <>
                      <HoverOption
                        onClick={() => {
                          selectArtist(artist);
                        }}
                      >
                        {type === CONTENT_TYPES.EPISODE
                          ? artist?.podcasterName
                          : artist?.artistName}
                      </HoverOption>
                    </>
                  );
                })}
            {getObjectLength(selectedArtist) > 0 && (
              <>
                <FormWrapper row>
                  <FormLabel>Currency</FormLabel>
                  <CurrencySelector setCurrency={setCurrency} />
                </FormWrapper>

                <FormInput
                  min={1}
                  step={0.1}
                  onChange={(e) => setTip(e.target.value)}
                  type="number"
                  placeholder="Tip amount"
                  required
                  value={tip}
                />
                <FormInput
                  onChange={(e) => setMemo(e.target.value)}
                  placeholder={`Send custom message to ${selectedArtist?.name}`}
                  value={memo}
                />
                <HoverOption type="submit" to="#tip" onClick={formValidation}>
                  Tip
                </HoverOption>
                <FormButton
                  transparent={true}
                  to="#"
                  onClick={() => {
                    modalContent(
                      <ArtistTipper type={type} content={content} />
                    );
                  }}
                >
                  Cancel
                </FormButton>
              </>
            )}
          </>
        )}
      </OptionModal>
    </>
  );
}

export function ArtistSelectorModal({ artists }) {
  const { goTo } = ScrollToTop();
  const{closeModal, openModal, modalContent} = useModal()
  return (
    <>
      <OptionModal heading={"View Artist"}>
        {/* artists.map(artist => {
        return (<>
        <ArtistList/>
        </>)
      }) */}
        <ArtistList
          content={artists}
          onButtonClick={(artist) => {
            closeModal()
            goTo(`/artist/${artist?.artistId}`);
          }}
        />
      </OptionModal> 
    </>
  );
}

export function PodcasterSelectorModal({ podcasters }) {
  const { goTo } = ScrollToTop();
  const{closeModal, openModal, modalContent} = useModal()
  return (
    <>
      <OptionModal heading={"View Podcaster"}>
        {/* podcasters.map(podcaster => {
        return (<>
        <ArtistList/>
        </>)
      }) */}
        <PodcasterList
          content={podcasters}
          onButtonClick={(podcaster) => {
            closeModal()
            goTo(`/podcaster/${podcaster?.podcasterId}`);
          }}
        />
      </OptionModal> 
    </>
  );
}

export const PlaylistCollaboratorsViewer = ({ playlist }) => {
  const [curator, setCurator] = useState({});
  const {goTo} = ScrollToTop()
  const { closeModal, openModal, modalContent } = useModal();
  const [collaborators, setCollaborators] = useState([]);
  const { getUserProfile } = useUser({});
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    getProfiles();

    return () => {};
  }, [playlist]);

  const getProfiles = async () => {
    await getUserProfile(playlist?.curator?.curatorId ? playlist?.curator?.curatorId :playlist?.curatorId )
      .then((c) => {
        setCurator({
          uid: playlist?.curator?.curatorId ? playlist?.curator?.curatorId :playlist?.curatorId,
          gravatarInfo: c,
          photoUrl: c?.thumbnailUrl,
          ...c,
        });
      })
      .catch((err) => {
        setCurator({ uid: playlist?.curator?.curatorId ? playlist?.curator?.curatorId :playlist?.curatorId });
        return;
      });
    for (let i = 0; i < playlist?.collaborators?.length; i++) {
      let collabs = collaborators;
      await getUserProfile(playlist?.collaborators[i])
        .then((c) => {
          collabs.push({
            uid: playlist?.collaborators[i],
            gravatarInfo: c,
            photoUrl: c?.thumbnailUrl,
            ...c,
          });
          setCollaborators(collabs);
        })
        .catch((err) => {
          collabs.push({ uid: playlist?.collaborators[i] });
          setCollaborators(collabs);
          return;
        });
    }
    return setLoaded(true);
  };

  return (
    <OptionModal
      onCancel={() => {
        closeModal();
        modalContent();
      }}
      heading={`${playlist?.playlistName} Collaborators`}
    >
      {collaborators?.length > 0 && isLoaded && (
        <>
          <ContentWrapper heading={"Curator"}>
            <UserList
              content={[curator]}
              onButtonClick={(user) => {
                closeModal();
                modalContent();
                goTo(`/u/${user?.uid}`);
              }}
            />
          </ContentWrapper>
          <ContentWrapper heading={"Collaborators"}>
            <UserList
              content={collaborators}
              onButtonClick={(user) => {
                closeModal();
                modalContent();
                goTo(`/u/${user?.uid}`);
              }}
            />
          </ContentWrapper>
        </>
      )}
      {!isLoaded && (
        <ModalLoadingScreen text={"Loading Collaborators. Please Wait"} />
      )}
    </OptionModal>
  );
};

export default MenuOptions