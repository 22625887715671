import React from "react";
import { stringFormatter } from "../../../../utils";
import { ARTIST_FORM_TYPES } from "../../../../utils/Enum";
import ArtistForm from "../../ArtistForm";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormTextArea,
  FormWrapper,
  AlbumDetailsSection,
  SongDetailsText,
} from "../../MusicUploadsFormElements";

const Page2 = ({
  albumInfo,
  artists,
  handleInput,
  artistSearchRef,
  removeArtist,
  clearArtistForm,
  setCurrentArtist,
  handleArtistInput,
  setArtistEditing,
  searchInput,
  currentArtist,
  walletAddressRef,
  artistSearch,
  artistNameRef,
  artistFullNameRef,
  artistIdRef,
  selectArtist,
  validateArtistInfo,
  artistEditng,
  errors,
}) => {
  return (
    <>
      <AlbumDetailsSection>
        <FormWrapper>
          <ArtistForm
            title={"Artists"}
            type={ARTIST_FORM_TYPES.MAIN_ARTIST}
            artists={artists}
            artistSearchRef={artistSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentArtist}
            setArtistEditing={setArtistEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentArtist}
            walletAddressRef={walletAddressRef}
            artistSearch={artistSearch}
            artistNameRef={artistNameRef}
            artistFullNameRef={artistFullNameRef}
            artistIdRef={artistIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={artistEditng}
          />
        </FormWrapper>
        <ErrorWrapper>
          <SongDetailsText error>{errors?.artists}</SongDetailsText>
        </ErrorWrapper>

        <FormWrapper>
          <FormLabel>Release Date</FormLabel>
          <FormInput
            type="date"
            ref={albumInfo?.releaseDateRef}
            min={albumInfo?.isPreRelease && new Date(new Date().setDate( new Date().getDate() + 1)).toISOString().split("T")[0]}
            max={!albumInfo?.isPreRelease && new Date().toISOString().split("T")[0]}
            name={"album releaseDate"}
            value={albumInfo?.releaseDate}
            onChange={handleInput}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel>About Album</FormLabel>
          <FormTextArea ref={albumInfo?.aboutRef} value={stringFormatter(albumInfo?.about)} name={"album about"} onChange={handleInput} />
        </FormWrapper>
      </AlbumDetailsSection>
    </>
  );
};

export default Page2;
