import styled from "styled-components";
import { colorRandomizer, getRandomColor, hexToRgba } from "../HexToRGBA";


export const ChipList = styled.li`
    list-style-type: none; 
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    overflow-y: ${props => props.direction === 'column' ? 'auto' : 'none'};
    display: flex;
    max-height: ${props => props.maxheight && props.maxHeight};
    width: 100%;
    justify-content: ${props =>{
        switch (props.alignment) {
            case 'center':
                return 'center'
                break;

            case 'left' || 'flex-start':
                return 'flex-start'
                break;

            case 'right' || 'flex-end':
                return 'flex-end'
                break;
        
            default:
                return 'flex-start'
                break;
        }
        }};
    flex-wrap: wrap;
    padding: 2%;
    margin-bottom: 2%;
    
`

export const ChipContainer = styled.div`
    display: flex;
    border-radius: 15px;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    height: 32px;
    line-height: 20px;
    padding: 1% 2%;
    color: ${(props =>  props.background ?props.theme.secondaryBg  : props.theme.mainBg  )};
    margin: 1%;
    cursor: pointer;
    overflow: hidden;
    border: solid 1px;
    text-overflow: ellipsis;
    font-size: 0.8125rem;
    vertical-align: middle;
    box-sizing: border-box;
    white-space: nowrap;
    background: ${(props) => 
        props.error? props.theme.eroorColor: props.background ? 
        props.background :
        `linear-gradient(135deg,
            ${hexToRgba(props.theme.accentColor, 0.5)}, 
    ${hexToRgba(props.theme.secondaryColor, 0.7)})` };

    &:hover{
        transition: 0.1s ease-out;
        text-decoration: ${props => props.textDecoration ? props.textDecoration : 'none'}
    }
`

export const IconContainer = styled.div`
    display: flex;
    position: relative;
    border-radius: 50%;
    justify-content: center;
    padding: 3%;
    margin-right: 1%;
    height: 25px;
    width: 25px;
    
`
export const Img = styled.img`
    border-radius: 50%;
    display: flex;
    
    
`
export const Text = styled.div`
    display: flex;
    font-size: 0.8125rem;
    vertical-align: middle;
    box-sizing: border-box;
    white-space: nowrap;
    padding:1%;
    text-overflow: ellipsis;
    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
        font-size: 0.8rem
      }
    
`



export const CloseButton = styled.div`
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 4%;
    cursor: pointer;
    font-weight: 700;
    
  &:hover{
    transform: scale(1.2);
    transition: .5s ease-out;
}
`