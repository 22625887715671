import React, { useState } from 'react'
import useArtist from '../../hooks/useArtist'
import { NFT_STORAGE_TYPES, TRANSACTION_OPTIONS } from '../../utils/Enum'
import { FormInput, FormInput1, FormLabel, FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { useModal } from '../Modal/ModalContext'
import OptionModal from '../OptionModal'
import QRCodeForm from '../QRCodeForm'
import { getObjectLength } from '../../utils'
import { ArtistContentHeading, ErrorText, SettingsWrapper } from '../ArtistProfile/ArtistContent/ArtistContentElements'
import Checkbox from '../Checkbox'
import { TextRoute } from '../SignUp/SignUpElements'


const NFTStorageTokenForm = ({artistId, currentUser, setMintable}) => {
    const { toggleModal, modalContent, isOpen, openModal } = useModal();
    const {updateStorageToken} = useArtist({})
    const [errors, setErrors] = useState({})
    const [storageType, setStorageType] = useState( 'default')
    const [storageApiKeys, setApiKeys] = useState({})


    const handleApiKeyInput = (e) => {
        switch (e.target.name) {
          case 'key':
            switch (storageType) {
              case NFT_STORAGE_TYPES.FILEBASE:
                let filebase = storageApiKeys?.filebase
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase: {
                      ...prev?.filebase,
                      key: e.target.value
                    }
                  }
                })
              }else{
                delete filebase?.key
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase
                  }
                })
              }
                break;
              case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
                let internetArchive = storageApiKeys?.internetArchive
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive: {
                      ...prev?.internetArchive,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete internetArchive.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      internetArchive
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.LIGHTHOUSE:
                let lighthouse = storageApiKeys?.lighthouse
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse: {
                      ...prev?.lighthouse,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete lighthouse.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      lighthouse
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.PINATA:
                let pinata = storageApiKeys?.pinata
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata: {
                      ...prev?.pinata,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete pinata.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      pinata
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.WEB3_STORAGE:
                let web3Storage = storageApiKeys?.web3Storage
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage: {
                      ...prev?.web3Storage,
                      key: e.target.value
                    }
                  }
                })}else{
                  delete web3Storage.key
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      web3Storage
                    }
                  })
                }
                break;
            
              default:
                break;
            }
            
            break;
          case 'secret':
            switch (storageType) {
              case NFT_STORAGE_TYPES.FILEBASE:
                let filebase = storageApiKeys?.filebase
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    filebase: {
                      ...prev?.filebase,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete filebase.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      filebase
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.INTERNET_ARCHIVE:
                let internetArchive = storageApiKeys?.internetArchive
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    internetArchive: {
                      ...prev?.internetArchive,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete internetArchive.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      internetArchive
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.LIGHTHOUSE:
                let lighthouse = storageApiKeys?.lighthouse
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    lighthouse: {
                      ...prev?.lighthouse,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete lighthouse.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      lighthouse
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.PINATA:
                let pinata = storageApiKeys?.pinata
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    pinata: {
                      ...prev?.pinata,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete pinata.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      pinata
                    }
                  })
                }
                break;
              case NFT_STORAGE_TYPES.WEB3_STORAGE:
                let web3Storage = storageApiKeys?.web3Storage
                if(e.target.value?.trim()?.length > 0){
                setApiKeys(prev => {
                  return {
                    ...prev,
                    web3Storage: {
                      ...prev?.web3Storage,
                      secret: e.target.value
                    }
                  }
                })}else{
                  delete web3Storage.secret
                  setApiKeys(prev => {
                    return {
                      ...prev,
                      web3Storage
                    }
                  })
                }
                break;
            
              default:
                break;
            }
            break;
          case 'jwt':
            setApiKeys(prev => {
              return {
                ...prev,
                pinata: {
                  ...prev?.pinata,
                  jwt: e.target.value
                }
              }
            })
            break;
          case 'bucketName':
            setApiKeys(prev => {
              return {
                ...prev,
                filebase: {
                  ...prev?.filebase,
                  bucketName: e.target.value
                }
              }
            })
            break;
        
          default:
            break;
        }
      
      }

      const validateStorageApiKeys = () => {
        let errors = {}
        
            if(storageType === NFT_STORAGE_TYPES.FILEBASE){
              if(getObjectLength(storageApiKeys?.filebase) < 3 ){
                errors = {
                  ...errors,
                  filebase: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE){
              if(getObjectLength(storageApiKeys?.internetArchive) < 2 ){
                errors = {
                  ...errors,
                  internetArchive: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE){
              if(getObjectLength(storageApiKeys?.lighthouse) < 1 ){
                errors = {
                  ...errors,
                  lighthouse: 'Missing API Data. Please fill in all the information'
                }
              }
            }
            if(storageType === NFT_STORAGE_TYPES.PINATA){
              if(getObjectLength(storageApiKeys?.pinata) < 3 ){
                errors = {
                  ...errors,
                  pinata: 'Missing API Data. Please fill in all the information'
                }
              }
            }
    
            if(getObjectLength(storageApiKeys?.filebase) > 0 && getObjectLength(storageApiKeys?.filebase) < 3 ){
              errors = {
                ...errors,
                filebase: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
    
            if(getObjectLength(storageApiKeys?.internetArchive) > 0 && getObjectLength(storageApiKeys?.internetArchive) < 2 ){
              errors = {
                ...errors,
                internetArchive: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
    
            if(getObjectLength(storageApiKeys?.pinata) > 0 && getObjectLength(storageApiKeys?.pinata) < 3 ){
              errors = {
                ...errors,
                pinata: 'Missing API Data. Fill in all the information, or clear it'
              }
            }
          console.log(errors)
          return errors
        }


    const onSuccess = () => {
        setMintable(true)
        toggleModal() 
        modalContent()
    }

    const onFail = () => {
        setMintable(false)
        toggleModal() 
        modalContent()
    }
    
    const onCancel = () => {
        toggleModal() 
        modalContent()
    }


  return (
    <><OptionModal heading={"IPFS (NFT Minting Settings)API Key Required"} additionalText={<>  
    <FormText>Select supported storage option for music files and metadata. Sonar Muse will automatically upload audio files for NFT collections to selected option.</FormText>
        <FormText>Note: All NFT metadata files will be uploaded to IPFS, regardless of the selected option.</FormText>
        <FormText>By Default, Sonar Muse uploads files to it's IPFS storage bucket</FormText></>}>
     <FormWrapper>
  <ArtistContentHeading></ArtistContentHeading>

  <FormWrapper>
  <SettingsWrapper>
          <div style={{justifyContent: 'flex-start', justifySelf: 'flex-start'}}>
          <Checkbox onClick={() => setStorageType('default')} label={'Sonar Muse Storage Bucket (Default)'} isChecked={storageType === 'default'}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.FILEBASE)} label={'Filebase'} isChecked={storageType === NFT_STORAGE_TYPES.FILEBASE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.INTERNET_ARCHIVE)} label={'Internet Archive'} isChecked={storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.LIGHTHOUSE)} label={'Lighthouse'} isChecked={storageType === NFT_STORAGE_TYPES.LIGHTHOUSE}/>
          <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.PINATA)} label={'Pinata'} isChecked={storageType === NFT_STORAGE_TYPES.PINATA}/>
          {/* <Checkbox onClick={() => setStorageType(NFT_STORAGE_TYPES.WEB3_STORAGE)} label={'Web3 Storage (Storacha)'} isChecked={storageType === NFT_STORAGE_TYPES.WEB3_STORAGE}/> */}

          </div>
  </SettingsWrapper>
     {(storageType === NFT_STORAGE_TYPES.FILEBASE || errors?.storageApiKeys?.filebase) && <FormWrapper border>
     <FormLabel>Filebase</FormLabel>
     <FormText>To learn more about and create a Filebase account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://filebase.com/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>Bucket Name</FormLabel>
      <FormInput1 placeholder="Bucket Name" name={'bucketName'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.bucketName}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1 placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.filebase?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.filebase && <ErrorText>{errors?.storageApiKeys?.filebase}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.INTERNET_ARCHIVE || errors?.storageApiKeys?.internetArchive) && <FormWrapper border>
     <FormLabel>Internet Archive</FormLabel>
     <FormText>To learn more about and create an Internet Archive account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://archive.org/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.internetArchive?.secret}/>
    </FormWrapper>
    {errors?.storageApiKeys?.internetArchive && <ErrorText>{errors?.storageApiKeys?.internetArchive}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.LIGHTHOUSE || errors?.storageApiKeys?.lighthouse) && <FormWrapper border>
     <FormLabel>Lighthouse</FormLabel>
     <FormText>To learn more about and create a Lighthouse account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://files.lighthouse.storage/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1 placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.lighthouse?.key}/>
    </FormWrapper>
    {errors?.storageApiKeys?.lighthouse && <ErrorText>{errors?.storageApiKeys?.lighthouse}</ErrorText>}
  </FormWrapper>}
     {(storageType === NFT_STORAGE_TYPES.PINATA || errors?.storageApiKeys?.pinata) && <FormWrapper border>
     <FormLabel>Pinata</FormLabel>
     <FormText>To learn more about and create a Pinata account <TextRoute  target="_blank" rel="noopener noreferrer" to={'https://www.pinata.cloud/'}>click here</TextRoute></FormText>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1  placeholder={'API KEY'} name={'key'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.key}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1  placeholder={"API SECRET"} name={'secret'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.secret}/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>JWT</FormLabel>
      <FormInput1  placeholder={'JWT'} name={'jwt'} onChange={handleApiKeyInput} value={storageApiKeys?.pinata?.jwt}/>
    </FormWrapper>
    {errors?.storageApiKeys?.pinata && <ErrorText>{errors?.storageApiKeys?.pinata}</ErrorText>}
  </FormWrapper>}
     {/* (storageType === NFT_STORAGE_TYPES.WEB3_STORAGE || errors?.storageApiKeys?.web3storage) && <FormWrapper>
     <FormLabel>Internet Archive</FormLabel>
    <FormWrapper>
      <FormLabel>API KEY</FormLabel>
      <FormInput1/>
    </FormWrapper>
    <FormWrapper>
      <FormLabel>API SECRET</FormLabel>
      <FormInput1/>
    </FormWrapper>
    {errors?.storageApiKeys?.web3Storage && <ErrorText>{errors?.storageApiKeys?.web3Storage}</ErrorText>}
  </FormWrapper> */}
  
  </FormWrapper> 
          
</FormWrapper>
    <HoverOption onClick={() => {  
        if(validateStorageApiKeys())
            {
        modalContent(<>
            <QRCodeForm 
                heading={"Updating Artist Profile"}
                type={TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE}
                body={
                    {
                    artistId: artistId,
                    sender: currentUser,
                    memo: "User is signing this transaction to update artist IPFS Storage API Keys",
                    instruction: "User is signing this transaction to update artist IPFS Storage API Keys",
                    profile: {
                        ipfsStorage: { type: storageType,
                            apiKeys: storageApiKeys
                          },
    
                    }
                    }
                }
                onSuccess={onSuccess}
                onFail={onFail}
                cancelQr={onCancel}
            />
        </>)
    }}}>
      Continue
    </HoverOption>
  </OptionModal></>
  )
}

export default NFTStorageTokenForm