import { ARTIST_FORM_TYPES } from "../../../utils/Enum";
import ArtistForm from "../ArtistForm";
import { ErrorWrapper, FormWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from "../MusicUploadsFormElements";

const Page2 = ({
  artists,
  featArtists,
  artistSearchRef,
  fArtistSearchRef,
  removeArtist,
  clearArtistForm,
  setCurrentArtist,
  setCurrentFArtist,
  handleArtistInput,
  setArtistEditing,
  setFArtistEditing,
  searchInput,
  currentArtist,
  currentFArtist,
  walletAddressRef,
  fWalletAddressRef,
  artistSearch,
  featArtistSearch,
  artistNameRef,
  fArtistNameRef,
  artistFullNameRef,
  fArtistFullNameRef,
  artistIdRef,
  fArtistIdRef,
  selectArtist,
  validateArtistInfo,
  artistEditng,
  fArtistEditng,
  errors,
  composers,
  composerSearchRef,
  setComposerEditing,
  setCurrentComposer,
  currentComposer,
  composerEditing,
  composerWalletAddressRef,
  composerSearch,
  composerNameRef,
  composerIdRef,
  composerFullNameRef,
  writerEditing,
  writers,
  writerSearchRef,
  setCurrentWriter,
  setWriterEditing,
  currentWriter,
  writerWalletAddressRef,
  writerSearch,
  writerNameRef,
  writerFullNameRef,
  writerIdRef,
  producerSearchRef,
  producers,
  setCurrentProducer,
  setProducerEditing,
  currentProducer,
  producerWalletAddressRef,
  producerSearch,
  producerNameRef,
  producerFullNameRef,
  producerIdRef,
  producerEditing,
  index
}) => {
  return (
    <>
      <SongDetailsSection>
        <FormWrapper>
          <ArtistForm
            title={"Artists"}
            type={ARTIST_FORM_TYPES.MAIN_ARTIST}
            artists={artists}
            artistSearchRef={artistSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentArtist}
            setArtistEditing={setArtistEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentArtist}
            walletAddressRef={walletAddressRef}
            artistSearch={artistSearch}
            artistNameRef={artistNameRef}
            artistFullNameRef={artistFullNameRef}
            artistIdRef={artistIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={artistEditng}
          />
        </FormWrapper>
        <ErrorWrapper>
          <SongDetailsText error>{errors?.artists}</SongDetailsText>
        </ErrorWrapper>
      
      <SongDetailsSectionTitle>Credits</SongDetailsSectionTitle>
      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Composers"}
            type={ARTIST_FORM_TYPES.COMPOSER}
            artists={composers}
            artistSearchRef={composerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentComposer}
            setArtistEditing={setComposerEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentComposer}
            walletAddressRef={composerWalletAddressRef}
            artistSearch={composerSearch}
            artistNameRef={composerNameRef}
            artistFullNameRef={composerFullNameRef}
            artistIdRef={composerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={composerEditing}
          />
          {errors?.composers && (
            <SongDetailsText error>{errors?.composers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>

      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Written By"}
            type={ARTIST_FORM_TYPES.WRITER}
            artists={writers}
            artistSearchRef={writerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentWriter}
            setArtistEditing={setWriterEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentWriter}
            walletAddressRef={writerWalletAddressRef}
            artistSearch={writerSearch}
            artistNameRef={writerNameRef}
            artistFullNameRef={writerFullNameRef}
            artistIdRef={writerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={writerEditing}
          />
          {errors?.writers && (
            <SongDetailsText error>{errors?.writers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Producers"}
            type={ARTIST_FORM_TYPES.PRODUCER}
            artists={producers}
            artistSearchRef={producerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentProducer}
            setArtistEditing={setProducerEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentProducer}
            walletAddressRef={producerWalletAddressRef}
            artistSearch={producerSearch}
            artistNameRef={producerNameRef}
            artistFullNameRef={producerFullNameRef}
            artistIdRef={producerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={producerEditing}
          />
          {errors?.producers && (
            <SongDetailsText error>{errors?.producers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
    </SongDetailsSection>
    </>
  );
};

export default Page2;