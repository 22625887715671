import React from "react";
import {
  SONAR_MUSE_NFT_STORAGE_KEY,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import QRCodeForm from "../QRCodeForm";

const NFTStorageSonarAPIKeyForm = ({ artistId, currentUser, onSuccess }) => {
  const { modalContent, closeModal } = useModal();
  const onUpdateSuccess = () => {
    onSuccess();
    modalContent();
    closeModal();
  };
  return (
    <OptionModal>
      <HoverOption
        onClick={() => {
          modalContent(
            <>
              <QRCodeForm
                heading={"Updating Artist Profile"}
                type={TRANSACTION_OPTIONS.UPDATE_ARTIST_PROFILE}
                body={{
                  artistId: artistId,
                  sender: currentUser,
                  memo: "User is signing this transaction to update artist NFT.Storage API Key",
                  instruction:
                    "User is signing this transaction to update artist NFT.Storage API Key",
                  profile: {
                    ipfsStorage: { type: "default",
                      apiKeys: {}
                    },

                  },
                }}
                onSuccess={onUpdateSuccess}
                onFail={() => {
                  modalContent();
                  closeModal();
                }}
                cancelQr={() => {
                  modalContent();
                  closeModal();
                }}
              />
            </>
          );
        }}
      >
        Continue
      </HoverOption>
      <HoverOption>Cancel</HoverOption>
    </OptionModal>
  );
};

export default NFTStorageSonarAPIKeyForm;
