import React from "react";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormWrapper,
  ListContainer,
  SelectButton,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../MusicUploadsFormElements";
import ToggleButton from "../../ToggleButton";
import { MusicGenreSearchModal } from "../../SearchModal";
import { useModal } from "../../Modal/ModalContext";
import { ChipList } from "../../Chip/ChipElements";
import Chip from "../../Chip";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";
import ContentList from "../../ContentList";
import { CONTENT_TYPES } from "../../../utils/Enum";
import { stringFormatter } from "../../../utils";
import { FormTextArea } from "../../CustomForm/CustomFormElements";

const Page1 = ({
  videoTitle,
  errors,
  handleInput,
  isExistingSong,
  isAIGenerated,
  isExplicit,
  handleToggle,
  genreRef,
  addGenre,
  mainGenre,
  genre,
  removeGenre,
  filteredGenres,
  isrcType,
  isrcRef,
  isrc,
  releaseDate,
  releaseDateRef,
  about,
  aboutRef,
  index
}) => {
  const { openModal, modalContent, closeModal } = useModal();
  const { themeColors } = useWebsiteTheme();

  return (
    <>
      <SongDetailsSection>
        <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
        <FormWrapper>
          <FormLabel>Video Title</FormLabel>
          <ErrorWrapper>
            <FormInput
              placeholder={"Video Title"}
              name={"title"}
              readOnly={isExistingSong}
              value={videoTitle}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.videoTitle && (
              <SongDetailsText error>{errors?.videoTitle}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
        <FormWrapper>
          <FormLabel
            onClick={() => {
              handleToggle("exists");
            }}
          >
            From an Existing Song on The Platform
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isExistingSong}
            toggleClick={() => {
              handleToggle("exists");
            }}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel
            onClick={() => {
              handleToggle("explicit");
            }}
          >
            Explicit
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isExplicit}
            toggleClick={() => {
              handleToggle("explicit");
            }}
          />
        </FormWrapper>
        <FormWrapper>
          <FormLabel
            onClick={() => {
              handleToggle("ai");
            }}
          >
            AI Generated Video
          </FormLabel>
          <ToggleButton
            onText={"On"}
            offText={"Off"}
            toggle={isAIGenerated}
            toggleClick={() => {
              handleToggle("ai");
            }}
          />
        </FormWrapper>

        {/*                 <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("mintable");
                    }}
                  >
                    Mintable
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isMintable}
                  toggleClick={() => handleToggle("mintable")}/>
                </FormWrapper>
                {isMintable && <SongDetailsText background={themeColors.secondaryColor}>You will be able to make NFT collections with this song, once uploaded</SongDetailsText>} */}

        <FormWrapper>
          <FormLabel>Genre</FormLabel>
          <ErrorWrapper>
            <FormInput
              ref={genreRef}
              type={"search"}
              placeholder={"Select Song Main Genre"}
              readOnly
              onClick={() => {
                openModal();
                modalContent(
                  <MusicGenreSearchModal type={"Genre"} onClick={addGenre} />
                );
              }}
              name={"main genre"}
              value={genre}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.genre && (
              <SongDetailsText error>{errors?.genre}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
        <FormWrapper>
          <ChipList>
            {mainGenre && (
              <Chip
                label={mainGenre.name}
                closeButton
                onDelete={() => removeGenre()}
                background={themeColors.mainColor}
              />
            )}
          </ChipList>
        </FormWrapper>

        {filteredGenres && genre?.trim()?.length > 0 && (
          <FormWrapper>
            <ListContainer>
              <ContentList
                type={CONTENT_TYPES.GENRE}
                content={filteredGenres}
                onButtonClick={addGenre}
              />
            </ListContainer>
          </FormWrapper>
        )}
        
          <FormWrapper>
            <FormLabel>
              ISRC Code
              <SelectButton
                name={"isrcType"}
                value={isrcType}
                onChange={handleInput}
              >
                <option value={"auto"}>Auto generate</option>
                <option value={"custom"}>Custom</option>
              </SelectButton>{" "}
            </FormLabel>
            <ErrorWrapper>
              {isrcType !== "auto" && (
                <FormInput
                  ref={isrcRef}
                  readOnly={isrcType === "auto"}
                  type={"text"}
                  name={"isrc " + index}
                  placeholder={"Enter your unique ISRC code for the song"}
                  value={isrc}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
              )}
              {errors?.isrc && (
                <SongDetailsText error>{errors?.isrc}</SongDetailsText>
              )}
            </ErrorWrapper>
          </FormWrapper>
       
        <FormWrapper>
          <FormLabel>Release Date</FormLabel>
          <ErrorWrapper>
            <FormInput
              ref={releaseDateRef}
              type="date"
              name={"releaseDate " + index}
              value={releaseDate}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.releaseDate && (
              <SongDetailsText error>{errors?.releaseDate}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
        <FormWrapper>
          <FormLabel>About Song</FormLabel>
          <ErrorWrapper>
            <FormTextArea
              ref={aboutRef}
              name={"about " + index}
              value={stringFormatter(about)}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.about && (
              <SongDetailsText error>{errors?.about}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      </SongDetailsSection>
    </>
  );
};

export default Page1;
