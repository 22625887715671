import React, { useContext, useEffect, useState } from "react";

import {
  MusicPageContainer,
} from "./MusicPageElements";
import useMusic from "../../hooks/useMusic";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import AlbumPage from "./AlbumPage";
import SongPage from "./SongPage";
import { useAuth } from "../../contexts/AuthContext";
import CollectionPage from "./CollectionPage";
import { useUser } from "../../hooks/useUser";
import NFTokenPage from "./NFTokenPage";
import EpisodePage from "./EpisodePage";
import { getCollectionInfo } from "../../utils/dbUtils/nftokenUtils";
import { getAlbumInfo, getPlaylistInfo, getSongInfo, getVideoInfo } from "../../utils/dbUtils/musicUtils";
import { updateWebPageIcon } from "../../utils/MusicUtils";
import { CONTENT_TYPES } from "../../utils/Enum";
import RoyaltySharesPage from "./RoyaltySharesPage";
import { getRoyaltyInfo } from "../../utils/dbUtils/royaltyUtils";
import useVideos from "../../hooks/useVideos";
import VideoPage from "./VideoPage";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MusicPage({ type, id }) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const [isLoaded, setLoaded] = useState(false);
  const {
    getAlbum,
    getPlaylist,
    getSong,
    getEpisode,
    getMusicRoyaltyInfo,
    deleteSong,
    deletePlaylist,
    getNFTokenCollection,
    deleteEpisode,
  } = useMusic();
  const {
    getVideo,
  } = useVideos()
  const { getNFTokenDetails } = useUser({});
  const [musicInfo, setMusicInfo] = useState();
  const { isUserPayingOut, gravatarInfo } = useAuth();
  const [currentTab, setTab] = useState("music");
  const [reload, setReload] = useState(false);
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const updateCollectionInfo = (data) => {
    setMusicInfo((prev) => {
      return { ...data, songs: prev?.songs, albums: prev?.albums };
    });
  };


  useEffect(() => {
    setLoaded(false);
    switch (type) {
      case "album":
        getAlbum(id)
          .then((data) => {
            if (data && !data.error) {
              /*                     //console.log('album ',data)
               */ setMusicInfo(data);
              setLoaded(true);
              setReload(false);
              return getAlbumInfo(id, setMusicInfo)
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
     
      case "collection":
        getNFTokenCollection(id)
          .then((data) => {
            if (data && !data.error) {
              //console.log('collection ',data)
              setMusicInfo(data);
              setLoaded(true);
              setReload(false);
              getCollectionInfo(id, updateCollectionInfo);
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
      case "nftoken":
        getNFTokenDetails(id)
          .then((data) => {
            //console.log('tokenInfo: ',data)
            if (data && !data.error) {
              setMusicInfo(data);
              setLoaded(true);
              setReload(false);
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
      case "playlist":
        getPlaylist(id)
          .then(async (data) => {
            if (data && !data.error) {
              //console.log('playlist ',data)
              setMusicInfo(data);
              setLoaded(true);
              setReload(false);
              return getPlaylistInfo(id, setMusicInfo)
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
      case "song":
        getSong(id)
          .then((data) => {
            if (data && !data?.error) {
              //console.log('song ',data)
              setMusicInfo(data);
              setLoaded(true);
              setReload(false);
              return getSongInfo(id, setMusicInfo)
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
      case "episode":
        getEpisode(id)
          .then((data) => {
            if (data && !data.error) {
              setMusicInfo(data);
              setLoaded(true);
              setReload(false);
            } else {
              navigate(-1);
            }
          })
          .catch((err) => {
            navigate(-1);
            console.error(err);
          });
        break;
        case "video":
          getVideo(id)
            .then((data) => {
              if (data && !data.error) {
                /*                     //console.log('album ',data)
                 */ setMusicInfo(data);
                setLoaded(true);
                setReload(false);
                return getVideoInfo(id, setMusicInfo)
              } else {
                navigate(-1);
              }
            })
            .catch((err) => {
              navigate(-1);
              console.error(err);
            });
          break;
      case CONTENT_TYPES.ROYALTY_SHARE : 
      getMusicRoyaltyInfo(id)
      .then((data) => {
        if (data && !data.error) {
          setMusicInfo(data);
          setLoaded(true);
          setReload(false);
          return getRoyaltyInfo(id, setMusicInfo)
        } else {
          navigate(-1);
        }
      })
      .catch((err) => {
        navigate(-1);
        console.error(err);
      });
      break
        default:
          navigate(-1);
        break;
    }
    // //console.log(id + ' ' + type)
    return () => {};
  }, [id, type, reload]);

  useEffect(() => {
   
    if (params.has("refresh")) {
      setReload(true);
      navigate(`/${type}/${id}`, { replace: true });
    }
    return () => {};
  },[]);



  return (
    <>
      {!isLoaded && (
        <LoadingScreen
          isLoaded={isLoaded}
          mainColor={gravatarInfo?.profileBackground?.color}
        />
      )}
      <MusicPageContainer>
        {isLoaded && type === "episode" && (
          <EpisodePage
            location={location}
            query={query}
            id={id}
            updateWebPageIcon = {updateWebPageIcon}
            deleteEpisode={deleteEpisode}
            episode={musicInfo}
            
          />
        )}

        {isLoaded && type === "album" && (
          <AlbumPage
            location={location}
            query={query}
            id={id}
            setTab={setTab}
            updateWebPageIcon = {updateWebPageIcon}
            currentTab={currentTab}
            album={musicInfo}
           
          />
        )}

        {isLoaded && type === "collection" && (
          <CollectionPage
            location={location}
            query={query}
            id={id}
            setTab={setTab}
            currentTab={currentTab}
            updateWebPageIcon = {updateWebPageIcon}
            collection={musicInfo}
            
          />
        )}

        {isLoaded && type === "nftoken" && (
          <NFTokenPage
            location={location}
            query={query}
            id={id}
            setTab={setTab}
            currentTab={currentTab}
            updateWebPageIcon = {updateWebPageIcon}
            tokenInfo={musicInfo}
           
          />
        )}

        {isLoaded && type === "song" && (
          <SongPage
            location={location}
            query={query}
            id={id}
            song={musicInfo}
            updateWebPageIcon = {updateWebPageIcon}
            setTab={setTab}
            currentTab={currentTab}
            deleteSong={deleteSong}
          />
        )}

        {isLoaded && type === "video" && (
          <VideoPage
            location={location}
            query={query}
            id={id}
            video={musicInfo}
            updateWebPageIcon = {updateWebPageIcon}
            setTab={setTab}
            currentTab={currentTab}
            deleteSong={deleteSong}
          />
        )}
        {isLoaded && type === CONTENT_TYPES.ROYALTY_SHARE && (
          <RoyaltySharesPage
            location={location}
            query={query}
            id={id}
            updateWebPageIcon = {updateWebPageIcon}
            royalty={{...musicInfo, type: musicInfo?.type}}
          />
        )}
      </MusicPageContainer>
    </>
  );
}

export default MusicPage;
