import { getArtistsNames2, getSongTitle } from "../utils";
import {
  ARTIST_SEARCH_TYPES,
  MUSIC_PLAYER_ACTIONS,
  MUSIC_PLAYING_FROM,
} from "../utils/Enum";

const AudioReducer = (state, action) => {
  switch (action.type) {
    case MUSIC_PLAYER_ACTIONS.SET_CURRENT_SONG:
      let playType = state.playType;
      let playId = state.playId;
      if (
        state.playlist[action.data]?.songId !==
          state.playlist[state.currentSong]?.songId ||
        state.playlist[action.data]?.songId !==
          state.playlist[state.currentSong]?.songId ||
        state.playlist[action.data]?.playingFrom?.playingFrom !==
          state?.playlist[state?.currentSong]?.playingFrom?.playingFrom
      ) {
        playType = "skip";
        playId = 0;
      }
      if (
        state.playlist?.length > 0 &&
        state.playlist[action.data]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[action.data]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[action.data]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        if (state?.isPlaying) {
          document.title = state?.playlist[action.data]?.songName
            ? `${getSongTitle(
                state?.playlist[action.data]
              )} - ${getArtistsNames2(
                state?.playlist[action.data].artists,
                ARTIST_SEARCH_TYPES.NAME
              )}`
            : state?.playlist[action.data]?.episodeName
            ? state?.playlist[action.data]?.episodeName
            : "Sonar Muse";
        } else {
          document.title = "Sonar Muse";
        }
      }

      return {
        ...state,
        currentSong: action.data,
        playId: playId,
        playType: playType,
      };
    case MUSIC_PLAYER_ACTIONS.SET_PLAYLIST:

      if (
        action.data?.length > 0 &&
        action.data[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        action.data[state?.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        action.data[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        localStorage.setItem(
          "pl",
          JSON.stringify({
            playlist: action.data,
            currentSong: state?.currentSong,
            isRepeat: state?.isRepeat,
            isShuffle: state?.isShuffle,
            isMute: state?.isMute,
            volume: state?.volume,
          })
        );
      }

      return {
        ...state,
        playlist: action.data,
      };
    case MUSIC_PLAYER_ACTIONS.TOGGLE_PLAY:
      if (
        state.playlist?.length > 0 &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.NFT &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.COLLECTION &&
        state.playlist[state.currentSong]?.playingFrom?.playingFrom !==
          MUSIC_PLAYING_FROM.MUSIC_STORE
      ) {
        localStorage.setItem(
          "pl",
          JSON.stringify({
            playlist: state.playlist,
            currentSong: state?.currentSong,
            isRepeat: state?.isRepeat,
            isShuffle: state?.isShuffle,
            isMute: state?.isMute,
            volume: state?.volume,
          })
        );

        if (action.data) {
   document.title = state?.playlist[state?.currentSong]?.songName
            ? `${getSongTitle(
                state?.playlist[state?.currentSong]
              )} - ${getArtistsNames2(
                state?.playlist[state?.currentSong].artists,
                ARTIST_SEARCH_TYPES.NAME
              )}`
            : state?.playlist[state?.currentSong]?.episodeName
            ? state?.playlist[state?.currentSong]?.episodeName
            : "Sonar Muse";
        } else {
          document.title = "Sonar Muse";
        }
      }

      return {
        ...state,
        isPlaying: action.data,
      };
    case MUSIC_PLAYER_ACTIONS.TOGGLE_SHUFFLE:
      return {
        ...state,
        isShuffle: action.data,
        isRepeat: action.data && state.isRepeat === 2 ? 1 : state.isRepeat,
      };
    case MUSIC_PLAYER_ACTIONS.TOGGLE_REPEAT:
      if (action.data === 2) {
        return {
          ...state,
          isRepeat: action.data,
          isShuffle: false,
        };
      } else {
        return {
          ...state,
          isRepeat: action.data,
        };
      }
    case MUSIC_PLAYER_ACTIONS.TOGGLE_MUTE:
      return {
        ...state,
        isMute: action.data,
      };
    case MUSIC_PLAYER_ACTIONS.VOLUME_CHANGE:
      return {
        ...state,
        volume: action.data,
      };
    case MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_ID:
      return {
        ...state,
        playId: action.data,
      };
    case MUSIC_PLAYER_ACTIONS.UPDATE_PLAY_TYPE:
      return {
        ...state,
        playType: action.data,
      };

    default:
      break;
  }
};

export default AudioReducer;
