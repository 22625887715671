import React from 'react'
import { ErrorWrapper, FormLabel, FormWrapper, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../../MusicUploadsForm/MusicUploadsFormElements';
import { FormTextArea } from '../../CustomForm/CustomFormElements';
import { stringFormatter } from '../../../utils';
import { ARTIST_FORM_TYPES } from '../../../utils/Enum';
import ArtistForm from '../../MusicUploadsForm/ArtistForm';

const Page1 = ({aboutRef,about, index, handleInput, errors,
    clearArtistForm,
    handleArtistInput,
    validateArtistInfo,
    selectArtist,
    searchInput,
    removeArtist,
  composers,
  composerSearchRef,
  setComposerEditing,
  setCurrentComposer,
  currentComposer,
  composerEditing,
  composerWalletAddressRef,
  composerSearch,
  composerNameRef,
  composerIdRef,
  composerFullNameRef,
  writerEditing,
  writers,
  writerSearchRef,
  setCurrentWriter,
  setWriterEditing,
  currentWriter,
  writerWalletAddressRef,
  writerSearch,
  writerNameRef,
  writerFullNameRef,
  writerIdRef,
  producerSearchRef,
  producers,
  setCurrentProducer,
  setProducerEditing,
  currentProducer,
  producerWalletAddressRef,
  producerSearch,
  producerNameRef,
  producerFullNameRef,
  producerIdRef,
  producerEditing,
  currentPayoutArtist,
  payoutArtistFullNameRef,
  payoutArtistWalletAddressRef,
  payoutSearch,
  payoutArtistNameRef,
  payoutArtistIdRef,
  artistPayoutRef,
  payoutEditing,
  setPayoutEditing,
  setCurrentPayoutArtist,
  payoutArtistSearchRef,
  payoutArtists
}) => {
  return (
    <SongDetailsSection> 
        <FormWrapper>
    <FormLabel>About Song</FormLabel>
    <ErrorWrapper>
      <FormTextArea
        ref={aboutRef}
        name={"about " + index}
        value={stringFormatter(about)}
        onChange={(e) => {
          handleInput(e);
        }}
      />
      {errors?.about && (
        <SongDetailsText error>{errors?.about}</SongDetailsText>
      )}
    </ErrorWrapper>
  </FormWrapper>

  <SongDetailsSectionTitle>Credits</SongDetailsSectionTitle>
      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Composers"}
            type={ARTIST_FORM_TYPES.COMPOSER}
            artists={composers}
            artistSearchRef={composerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentComposer}
            setArtistEditing={setComposerEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentComposer}
            walletAddressRef={composerWalletAddressRef}
            artistSearch={composerSearch}
            artistNameRef={composerNameRef}
            artistFullNameRef={composerFullNameRef}
            artistIdRef={composerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={composerEditing}
          />
          {errors?.composers && (
            <SongDetailsText error>{errors?.composers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>

      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Written By"}
            type={ARTIST_FORM_TYPES.WRITER}
            artists={writers}
            artistSearchRef={writerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentWriter}
            setArtistEditing={setWriterEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentWriter}
            walletAddressRef={writerWalletAddressRef}
            artistSearch={writerSearch}
            artistNameRef={writerNameRef}
            artistFullNameRef={writerFullNameRef}
            artistIdRef={writerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={writerEditing}
          />
          {errors?.writers && (
            <SongDetailsText error>{errors?.writers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <ErrorWrapper>
          <ArtistForm
            title={"Producers"}
            type={ARTIST_FORM_TYPES.PRODUCER}
            artists={producers}
            artistSearchRef={producerSearchRef}
            removeArtist={removeArtist}
            clearArtistForm={clearArtistForm}
            setCurrentArtist={setCurrentProducer}
            setArtistEditing={setProducerEditing}
            handleInput={handleArtistInput}
            searchInput={searchInput}
            currentArtist={currentProducer}
            walletAddressRef={producerWalletAddressRef}
            artistSearch={producerSearch}
            artistNameRef={producerNameRef}
            artistFullNameRef={producerFullNameRef}
            artistIdRef={producerIdRef}
            selectArtist={selectArtist}
            validateArtistInfo={validateArtistInfo}
            artistEditng={producerEditing}
          />
          {errors?.producers && (
            <SongDetailsText error>{errors?.producers}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
      <ErrorWrapper>
        <ArtistForm
          title={"Artist Payouts (Royalties)"}
          type={ARTIST_FORM_TYPES.PAYOUT}
          artists={payoutArtists}
          artistSearchRef={payoutArtistSearchRef}
          removeArtist={removeArtist}
          clearArtistForm={clearArtistForm}
          setCurrentArtist={setCurrentPayoutArtist}
          setArtistEditing={setPayoutEditing}
          handleInput={handleArtistInput}
          searchInput={searchInput}
          currentArtist={currentPayoutArtist}
          walletAddressRef={payoutArtistWalletAddressRef}
          artistSearch={payoutSearch}
          artistNameRef={payoutArtistNameRef}
          artistFullNameRef={payoutArtistFullNameRef}
          artistIdRef={payoutArtistIdRef}
          artistPayoutRef={artistPayoutRef}
          selectArtist={selectArtist}
          validateArtistInfo={validateArtistInfo}
          artistEditng={payoutEditing}
        />
        {errors?.payoutArtists && (
          <SongDetailsText error>{errors?.payoutArtists}</SongDetailsText>
        )}
      </ErrorWrapper>
    </FormWrapper>
  </SongDetailsSection>
  )
}

export default Page1