import React, { useEffect, useRef, useState } from "react";
import { ChipList } from "../Chip/ChipElements";
import Chip from "../Chip";
import ContentWrapper from "../ContentWrapper";
import {
  FormButton1,
  FormInput,
  FormInput1,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import {
  Container,
  ContentWrapper as ContentsSection,
  MintButton,
  MintButtonConainer,
  MintDetailsContainer,
  MintDetailsSection,
  MintDetailsText,
  MintDetailsWrapper,
  MintNav,
  MintNavLink,
  MintSectionHeading,
  MintTitleContainer,
  MintTitleWrapper,
  SmallText,
} from "./NFTCollectionCreatorElements";
import ToggleButton from "../ToggleButton";
import { FaCheck, FaCheckSquare, FaSquare } from "react-icons/fa";
import PageIndexer from "../PageIndexer";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";

import { useAuth } from "../../contexts/AuthContext";
import useMusic from "../../hooks/useMusic";
import albumArtPlaceholder from "../../images/sm_album_art_placeholder.png";
import {
  ContentListContainer,
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  TableData,
  TableHeading,
  Table,
  TableRow,
} from "../ContentList/ContentListElements";
import { DEFAULT_IMAGE } from "../GravatarAPI";
import { getRandomColor } from "../HexToRGBA";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import { Option, Select } from "../MusicPage/MusicPageElements";
import AlbumArt from "../AlbumArt";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import QRCodeForm from "../QRCodeForm";
import NFTokenRevShare from "./NFTokenRevShare";
import { containsObject, getObjectLength, isNumber, validateAddress } from "../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  MINTING,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import { useNavigate } from "react-router-dom";
import { fileUploader, zipFiles } from "../../utils/FileUploadsUtils";
import {
  getDownloadUrl,
  storage,
  storageRef,
  uploadBytes,
} from "../Authorization/firebase";
import { PulseSpinner, PushSpinner } from "react-spinners-kit";
import {NftokenMintModal } from "../MenuOptions";
import CurrencySelector from "../CurrencySelector";
import ScrollToTop from "../ScrollToTop";
import NFTCoverArtSelector from "./NFTCoverArtSelector";
import {
  uploadImageToIPFS,
  uploadImageToIPFS1,
  uploadZipToIPFS,
} from "../../utils/nfTokensUploads";
import useArtist from "../../hooks/useArtist";
import { Nav, NavContainer, NavLink } from "../NavLink/NavLinkElements";
import NFTokenDiscounts from "./NFTokenDiscounts";
import { useLocation } from "react-router-dom";
import { useAlert } from "../Alert/AlertContext";
import { updateWebPageIcon } from "../../utils/MusicUtils";

const NFTCollectionCreator = ({ info }) => {
  const { currentUser, gravatarInfo, currentProfile } = useAuth();
  const navigate  = useNavigate();
  const { addAlert } = useAlert();
  const location = useLocation();
  const { goTo } = ScrollToTop();
  const { toggleModal, modalContent, isOpen, openModal, closeModal, noCloseModal } =
    useModal();
  const { getNFTStorageKey, searchArtists } = useArtist({});
  const mainColor = gravatarInfo?.profileBackground?.color;
  const {themeColors} = useWebsiteTheme();
  const {
    getSongsByUid,
    getAlbumsByUid,
    validateCollectionId,
    searchMintableSongsByNameAndUid,
    searchMintableAlbumsByNameAndUid,
    searchLabelArtistsMintableAlbumsByNameAndUid,
    searchLabelArtistsMintableSongsByNameAndUid,
    saveNFTokenCollectionAsDraft,
  } = useMusic({});
  const [page, setPage] = useState(1);
  const [searchLoader, setSearchLoaded] = useState(false); //Loader for searching music
  const [musicContent, setContent] = useState(); // Music content
  const [musicSearch, setSearch] = useState(); //Stores the music searched
  const [searchInput, setInput] = useState(""); //Input for searching music
  const [cId, setCId] = useState(
    info?.collectionId ? info?.collectionId : undefined
  );
  const [collectionId, setCollectionId] = useState(
    info?.taxon ? info?.taxon : 0
  ); // collection ID (Taxon)
  const [errors, setErrors] = useState({}); //Stores errors
  const [title, setTitle] = useState(info?.title ? info?.title : ""); // Collection Name
  const [description, setDescription] = useState(
    info?.description ? info?.description : ""
  ); //Describes the NFToken collection
  const [transferFee, setTransferFee] = useState(
    info?.transferFee ? info?.transferFee : 0
  ); // Transfer fee for the NFTokens of this collection
  const [price, setPrice] = useState(info?.price ? info?.price : 0); // Price for the NFToken
  const [discountType, selectDiscountType] = useState("");
  const [discountRedeemers, setRedeemers] = useState(
    info?.discountRedeemers
      ? info?.discountRedeemers 
      : { nftokens: [], music: {}, subscriptions: [] }
  );
  const [isDiscount, setDiscount] = useState(
    info?.isDiscount ? info?.isDiscount : false
  );
  const [isDiscountEditing, setDiscountEditing] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState({});
  const [currency, setCurrency] = useState(
    info?.currency ? info?.currency : "WYM"
  );
  const [coverArt, setCoverArt] = useState(
    info?.coverArt ? info?.coverArt : undefined
  );
  /*Cover Art for the NFToken collection - default to the selected music cover */
  const [additionalFiles, setAdditional] = useState([]); //Additional files to be included with the NFToken
  const [additionalFileInfo, setAdditionalFileInfo] = useState({});
  const [color, setColor] = useState(
    info?.color ? info?.color : gravatarInfo?.profileBackground?.color ? gravatarInfo?.profileBackground?.color : getRandomColor()
  ); // Color incase the NFToken Image does not load
  const [flag, setFlag] = useState(info?.flag ? info?.flag : 8); // Flag associated with the NFTokens of this collection
  const [isAttributes, setIsAttributes] = useState(
    info?.isAttributes ? info?.isAttributes : false
  ); // Allow for this collection to be purchased as a premint
  const [isPremint, setPremint] = useState(
    info?.isPremint ? info?.isPremint : false
  ); // Allow for this collection to be purchased as a premint
  const [premintAmount, setPremintAmount] = useState(0)
  const [mintDate, setMintDate] = useState(
    info?.mintDate ? info?.mintDate : undefined
  );
  const [attributes, setAttributes] = useState(
    info?.isAttributes ? info?.attributes : []
  );
  const [isFixedSupply, setFixedSupply] = useState(
    info?.isFixedSupply ? info?.isFixedSupply : false
  ); // Make a fixed supply of NFTokens for this collection
  const [mintQuantity, setQuantity] = useState(
    info?.isFixedSupply ? info?.mintQuantity : 1
  ); // The quantity allowed to be sold as NFTokens for this collection
  const [revShare, setRevShare] = useState(
    info?.revShare ? info?.revShare : false
  ); // Allow split of sales for the NFToken
  const [mintType, setMintType] = useState(
    info?.mintType ? info?.mintType : ""
  ); // The type for the NFToken Collection
  const [revenue, setRevenue] = useState(
    info?.revShare
      ? info?.revenue
      : [{ address: currentUser?.uid, percentage: 100 }]
  );
  /*If revshare is enabled, the wallet addresses and percentage 
  of those who will receive payments for each NFToken sold within this collection */
  const [currentRevenueId, setCurrentId] = useState(); //current ID position who has been selected for revshare
  const [isRevenueEditing, setRevenueEditing] = useState(false); //editing a user who will be receiving revshare
  const [currentAddress, setCurrentAddress] = useState({}); //current user position who has been selected for revshare
  const [unlimitedStreaming, setUnlimited] = useState(
    info?.unlimitedStreaming ? info?.unlimitedStreaming : false
  ); //Allow unlimited streaming for the music selected in this collection
  const [isTokenGate, setTokenGate] = useState(
    info?.isTokenGate ? info?.isTokenGate : false
  ); //Allow additional files to be token gated
  const [selectedMusic, setSelectedMusic] = useState(
    info?.selectedMusic ? info?.selectedMusic : {}
  ); // selected music to be minted as an NFToken for this collection
  const [hideButtons, setHideButtons] = useState(false)
  const addressRef = useRef("");
  const percentageRef = useRef(1);

  useEffect(() => {
    updateWebPageIcon('https://artists.sonarmuse.org/favicon.ico')
    getMusicContent();
    return () => {}
  }, [!mintType]);


  const setPageNum = (num) => {
    setPage(num);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const selectMintType = (type) => {
    setInput("");
    if (mintType === "type") {
      return;
    } else {
      setMintType(type);
      setSearch();
      setSelectedMusic({});
    }
  };

  const getMusicContent = async () => {
    setSearch();
    setSelectedMusic();
    setContent();
    setSearchLoaded(false);
    switch (mintType) {
      case MINTING.TYPE.SINGLE:
        let songs = await searchMintableSongsByNameAndUid(
          currentUser?.uid,
          searchInput
        );
        if (songs) {
          setContent(songs);
          setSearch(songs);
        }
        setSearchLoaded(true);
        break;
      case MINTING.TYPE.ALBUM:
        let albums = await searchMintableAlbumsByNameAndUid(
          currentUser?.uid,
          searchInput
        );
        if (albums) {
          setContent(albums);
          setSearch(albums);
        }
        setSearchLoaded(true);
        break;
      case MINTING.TYPE.CUSTOM:
        await Promise.all([
          searchMintableSongsByNameAndUid(currentUser?.uid),
          searchMintableAlbumsByNameAndUid(currentUser?.uid),
        ]).then((values) => {
          let discography = [];
          if (values[0]) {
            discography.push(values[0]);
          }
          if (values[1]) {
            discography.push(values[1]);
          }
          setContent({ songs: values[0], albums: values[1] });
          setSearch(...discography);
        });
        setSearchLoaded(true);
        break;
      default:
        break;
    }
  };

  const confirmCreate = async (addFiles) => {
    //console.log(additionalFileInfo)
    //console.log(errors)
      openModal();
      noCloseModal(
        <>
          <QRCodeForm
            heading={`Creating NFToken Collection ${title} by ${currentUser?.uid}`}
            type={TRANSACTION_OPTIONS.CREATE_NFTOKEN_COLLECTION}
            onSuccess={onSuccess}
            onFail={onFail}
            cancelQr={cancelUpload}
            body={{
              sender: currentUser?.uid,
              taxon: parseInt(collectionId),
              transferFee: parseFloat(transferFee).toFixed(2),
              flag: flag,
              uid: currentUser?.uid,
              coverArt: coverArt?.url && coverArt?.ipfs ? coverArt : undefined,
              attributes:
                isAttributes &&
                attributes.filter(
                  (a) =>
                    a?.traitType?.trim()?.length > 0 &&
                    a?.value.trim()?.length > 0
                ).length > 0
                  ? attributes.filter(
                      (a) =>
                        a?.traitType?.trim()?.length > 0 &&
                        a?.value.trim()?.length > 0
                    )
                  : undefined,
              tokenSupply: isFixedSupply ? mintQuantity : undefined,
              title: title?.trim() && title?.trim(),
              unlimitedStreaming: unlimitedStreaming,
              price: price,
              isPremint: isPremint ? isPremint : undefined,
              mintDate: isPremint ? new Date(mintDate).toISOString() : undefined,
              preMintAmount: isPremint ? premintAmount : undefined,
              discounts:
                price > 0 &&
                isDiscount &&
                (getObjectLength(discountRedeemers?.music) > 0 ||
                  discountRedeemers?.subscriptions?.length > 0 ||
                  discountRedeemers?.nftokens?.length > 0)
                  ? {
                      albums:
                        discountRedeemers?.music?.albums?.length > 0
                          ? discountRedeemers?.music?.albums.map((a) => {
                            return {albumId: a.albumId, percentage: a.percentage}
                          })
                          : undefined,
                      songs:
                        discountRedeemers?.music?.songs?.length > 0
                          ? discountRedeemers?.music?.songs.map(s => {
                            return {songId: s?.songId, percentage: s?.percentage}
                          })
                          : undefined,
                      nftokens:
                        discountRedeemers?.nftokens?.length > 0
                          ? discountRedeemers?.nftokens
                          : undefined,
                      subscriptions:
                        discountRedeemers?.subscriptions?.length > 0
                          ? discountRedeemers?.subscriptions.map(s => {
                            return {artistId: s?.artist?.artistId, percentage: s?.percentage}
                          })
                          : undefined,
                    }
                  : undefined,
              currency: currency,
              mintType: mintType,
              isTokenGate:
                isTokenGate && (getObjectLength(addFiles) > 0 || getObjectLength(additionalFileInfo))
                  ? isTokenGate
                  : undefined,
              bgColor: color,
              revShare: revShare === true ? revenue : undefined,
              description: description?.trim() !== "" ? description : undefined,
              music: selectedMusic?.music,
              additionalFiles:
                getObjectLength(addFiles) > 0
                  ? addFiles:
                getObjectLength(additionalFileInfo) > 0
                  ? additionalFileInfo
                  : undefined,
              memo: `${currentUser?.uid} is creating the collection ${title} to be minted as an NFToken`,
              instruction: `${currentUser?.uid} is creating the collection ${title} to be minted as an NFToken`,
            }}
          />
        </>
      );

  };

  const saveAsDraft = async () => {
    setHideButtons(true)

    addAlert({
      title: "Saving As Draft",
      type: ALERT_TYPES.INFO,
    });
    return await saveNFTokenCollectionAsDraft(currentUser?.uid, {
      collectionId: cId,
      taxon: collectionId,
      flag: flag,
      title: title,
      isAttributes: isAttributes,
      isPremint: isPremint,
      premintAmount: premintAmount,
      mintDate: mintDate,
      attributes: attributes,
      description: description,
      isFixedSupply: isFixedSupply,
      mintQuantity: mintQuantity,
      color: color,
      price: price,
      coverArt: coverArt,
      currency: currency,
      unlimitedStreaming: unlimitedStreaming,
      transferFee: transferFee,
      mintType: mintType,
      selectedMusic: selectedMusic,
      isDiscount: isDiscount,
      discountRedeemers: discountRedeemers,
      isTokenGate: isTokenGate,
      revShare: revShare,
      revenue: revenue,
    })
      .then((data) => {
        setHideButtons(false)
        if (!data.error && data !== false) {
          setCId(data?.message);
          const params = new URLSearchParams({ id: data?.message });
          navigate(`${location.pathname}?${params.toString()}`,{
            replace:true},
          );
          addAlert({
            title: "Draft Saved!",
            message: "Collection saved to drafts",
            type: ALERT_TYPES.INFO,
          });
          setErrors({ message: "Draft saved!" });
          return;
        }
        addAlert({
          title: "Draft Error",
          message: "Failed to save to drafts",
          type: ALERT_TYPES.DANGER,
        });
      })
      .catch((err) => {
        setHideButtons(false)
        addAlert({
          title: "Draft Error",
          message: "Failed to save to drafts",
          type: ALERT_TYPES.DANGER,
        });
      });
  };

  const validateInfo = async () => {
    let e = {};
    setErrors({});
    //let isValid = false
    return await validateCollectionId(collectionId, currentUser?.uid)
      .then((data) => {
        if (data.response) {
        }
        if (data.collectionId) {
          e = {
            ...e,
            id: `This collection ID is already in use. Select another one`,
          };
        }
        if (data?.error) {
          e = { ...e, id: `Something went wrong. please try again` };
        }

        if (title?.trim()?.length === 0) {
          e = { ...e, title: "Collection needs a title" };
        }
        if (
          isAttributes &&
          attributes?.some(
            (a) => a.traitType.trim() !== "" || a.value.trim() !== ""
          )
        ) {
          attributes.filter(
            (a) => a.traitType.trim() !== "" || a.value.trim() !== ""
          );
        }
        if (isTokenGate && additionalFiles?.length === 0) {
          e = { ...e, additionalFiles: "files required for token gating" };
        }
        if (selectedMusic?.music?.length === 0 || !selectedMusic?.music) {
          e = { ...e, music: "Cannot creat a collection without music" };
        }
        if (isTokenGate && additionalFiles?.length === 0) {
          e = { ...e, additionalFiles: "Please add files" };
        }
        if (isPremint && (!mintDate || (new Date(mintDate).getTime() < new Date().getTime()))) {
          e = { ...e, premint: "Invalid pre mint date" };
        }

        if (isPremint && isFixedSupply && premintAmount > mintQuantity) {
          e = { ...e, premintAmount: "Invalid pre mint amount" };
        }

        if (isFixedSupply && (mintQuantity === 0 || !mintQuantity)) {
          e = { ...e, premintAmount: "Invalid mint amount" };
        }

        if (revShare && revenue?.length === 1) {
          e = { ...e, revShare: "Rev share requires more than 1 person" };
        }

        if (getObjectLength(e) > 0) {
          setErrors(e);
          return false;
        } else {
          setErrors({});
          return true;
        }
      })
      .catch((err) => {
        e = { ...e, id: `Something went wrong. please try again` };
        setErrors(e);
        return false;
      });
  };

  const createCollection = async () => {
    setHideButtons(true)
    let isValid = await validateInfo();
    if (isValid) {
      setErrors()
      setHideButtons(false)
      if (additionalFiles?.length > 0) {
        handleAdditionalFiles();
      } else {
        confirmCreate();
      }
      return
    }else{
      setHideButtons(false)
    }
    
  };

  const onSuccess = (data) => {
    goTo("/home");
    openModal();
    modalContent(
      <>
        <OptionModal heading={`Successfully created the collection ${title}`}>
          <HoverOption
            onClick={() => {
              goTo(`/collection/${data.collectionId}`);
              closeModal();
              modalContent();
            }}
          >
            View Collection
          </HoverOption>
          <HoverOption onClick={() =>{ 
            openModal() 
            modalContent(<NftokenMintModal/>)}}>
            Mint NFToken from Collection
          </HoverOption>
        </OptionModal>
      </>
    );
  };

  const onFail = () => {
    openModal();
    setHideButtons(false)
    modalContent(
      <>
        <OptionModal
          onCancel={{}}
          heading={`Failed to create the collection ${title}`}
        >
          <HoverOption>Try Again</HoverOption>
        </OptionModal>
      </>
    );
  };

  const cancelUpload= () => {
    addAlert({
      title: 'Collection Creator',
      message: 'Collection Creator Cancelled',
      type: ALERT_TYPES.WARNING
    })
    setHideButtons(false)
    closeModal()
    modalContent()
  }

  const addDiscount = (type, discountInfo) => {
    let redeem
    switch (type) {
      case "DIGITAL":
        let music = getObjectLength(discountRedeemers?.music) > 0 ? discountRedeemers?.music : {}
        if(discountInfo?.type === "SONG"){
          let songs = music?.songs ? music?.songs : []
         redeem = songs?.findIndex(
          (s) =>
            s.songId === discountInfo?.music?.songId
        );
        if (redeem && redeem !== -1) {
          songs[redeem] = discountInfo?.music
        }else{
          songs.push(discountInfo?.music)
        }
        music = {...music, songs: songs}
        setRedeemers((prev) => {
          return {
            ...prev,
            music: music,
          };
        });
        }else{
          let albums = music?.albums ? music?.albums : []
          redeem = albums?.findIndex(
           (a) =>
             a.albumId === discountInfo?.music?.albumId  
         );
         if (redeem && redeem !== -1) {
           albums[redeem] = discountInfo?.music
         }else{
           albums.push(discountInfo?.music)
         }
         music = {...music, albums: albums}
         setRedeemers((prev) => {
           return {
             ...prev,
             music: music,
           };
         });
        }
        break;
      case "NFT_COLLECTION":
        let tokens = discountRedeemers?.nftokens?.length > 0 ? discountRedeemers?.nftokens : [];
        redeem = tokens?.findIndex(
          (t) =>
            t.issuer === discountInfo?.issuer && t.taxon === discountInfo.taxon
        );
        if (redeem && redeem !== -1) {
          tokens[redeem] = {
            issuer: discountInfo.issuer,
            taxon: discountInfo.taxon,
            numTokens: discountInfo.numTokens,
            percentage: discountInfo.percentage,
          };
        } else {
          tokens = [
            ...tokens,
            {
              issuer: discountInfo.issuer,
              taxon: discountInfo.taxon,
              numTokens: discountInfo.numTokens,
              percentage: discountInfo.percentage,
            },
          ];
        }
        setRedeemers((prev) => {
          return {
            ...prev,
            nftokens: tokens,
          };
        });
        break;
      case "SUBSCRIPTION":
        let subscriptions = discountRedeemers?.subscriptions?.length > 0 ? discountRedeemers?.subscriptions : [];
        redeem = subscriptions?.findIndex(
          (t) =>
            t.artist.artistId === discountInfo?.artist.artistId
        );
        if (redeem && redeem !== -1) {
          subscriptions[redeem] = {
            artist: discountInfo.artist,
            percentage: discountInfo.percentage,
          };
        } else {
          subscriptions.push({
              artist: discountInfo.artist,
              percentage: discountInfo.percentage,
            })
        }
        setRedeemers((prev) => {
          return {
            ...prev,
            subscriptions: subscriptions,
          };
        });

        break;

      default:
        break;
    }
    setCurrentDiscount({});
        setDiscountEditing(false);
        selectDiscountType();
  };

  const deleteDiscount = (type, id) => {
    switch (type) {
      case "DIGITAL":
        let music = discountRedeemers?.music
        if(id?.type === "SONG"){
          let songs = music?.songs
          songs = songs.filter((s, index) => index !== id?.index)
          music = {...music, songs : songs}
          setRedeemers((prev) => {
            return {
              ...prev,
              music: music,
            };
          });
        }else{
          let albums = music?.albums
          albums = albums.filter((s, index) => index !== id?.index)
          music = {...music, albums : albums}
          setRedeemers((prev) => {
            return {
              ...prev,
              music: music,
            };
          });
        }
        break;
      case "NFT_COLLECTION":
        let tokens = discountRedeemers?.nftokens;
        tokens = tokens.filter((t, index) => index !== id);
        setRedeemers((prev) => {
          return {
            ...prev,
            nftokens: tokens,
          };
        });
        break;
      case "SUBSCRIPTION":
        let subscriptions = discountRedeemers?.subscriptions;
        subscriptions = subscriptions.filter((t, index) => index !== id);
        setRedeemers((prev) => {
          return {
            ...prev,
            subscriptions: subscriptions,
          };
        });
        
        break;

      default:
        break;
    }
    setCurrentDiscount({});
        setDiscountEditing(false);
        selectDiscountType();
  };

  const toggleRevenue = () => {
    let rev = !revShare;
    if (rev === true) {
      setCurrentAddress(revenue[0])
      setRevenueEditing(true)
    } else {
      setRevenueEditing(false);
    }
    setRevShare(rev);
  };

  const clearRevenueForm = () => {
    let e = errors;
    delete e?.revenue;
    delete e?.percentage;
    delete e?.address;
    setErrors(e);
    if (
      currentAddress?.address?.trim() !== "" ||
      currentAddress?.percentage > 0
    ) {
      setCurrentAddress({ address: "", percentage: 0 });
      addressRef.current.value = "";
      percentageRef.current.value = 0;
      setCurrentId();
      setRevenueEditing(false);
    }
  };

  const addRevenue = (address, percentage) => {
    let e = errors;
    delete e.address;
    delete e.percentage;
    if (
      validateAddress(currentAddress?.address) &&
      currentAddress?.percentage &&
      currentAddress.percentage > 0
    ) {
      let exists = containsObject(currentAddress, revenue);
      if (exists === false) {
        if (validatePercentage(currentAddress?.percentage)) {
          let r = revenue;
          r.push({
            address: currentAddress?.address,
            percentage: currentAddress.percentage,
          });
          setRevenue(r);
          clearRevenueForm();
        }
      }
    } else {
      if (!validateAddress(currentAddress?.address)) {
        setErrors((prev) => {
          return { ...prev, address: "Invalid wallet address." };
        });
      }
      if (currentAddress.percentage === 0 || !currentAddress.percentage) {
        setErrors((prev) => {
          return { ...prev, percentage: "Enter a revenue split percentage." };
        });
      }
    }
  };

  const validatePercentage = (percentage) => {
    let e = errors;
    delete e?.revenue;
    setErrors(e);
    let percentages = 0;
    for (let i = 0; i <= revenue.length - 1; i++) {
      if (currentAddress.address !== revenue[i].address) {
        percentages += parseInt(revenue[i].percentage);
      }
    }
    let value = parseInt(percentages) + parseInt(percentage);
    if (value <= 100) {
      return true;
    } else {
      setErrors({ ...e, revenue: "Rev percentages exceedes 100% " });
      return false;
    }
  };

  const editRevenue = () => {
    let e = errors;
    delete e.address;
    delete e.percentage;
    let r = revenue;
    let updatedRevenue = [];
    if (
      validateAddress(currentAddress?.address) &&
      currentAddress?.percentage &&
      currentAddress.percentage > 0
    ) {
      if (validatePercentage(currentAddress?.percentage)) {
        updatedRevenue = r.map((royalty, index) =>
          index === currentRevenueId ? currentAddress : royalty
        );
        setRevenue(updatedRevenue);
        setRevenueEditing(false);
        clearRevenueForm();
      }
    } else {
      if (!validateAddress(currentAddress?.address)) {
        setErrors((prev) => {
          return { ...prev, address: "Invalid wallet address." };
        });
      }
      if (currentAddress.percentage === 0 || !currentAddress.percentage) {
        setErrors((prev) => {
          return { ...prev, percentage: "Enter a revenue split percentage." };
        });
      }
    }
  };

  const removeRevenue = (id) => {
    let r = [];
    let updatedRevenue = [];
    r = revenue;
    updatedRevenue = r.filter((royalty, index) => index !== id);
    if (revenue[id].address !== currentUser?.uid) {
      setRevenue(updatedRevenue);
    } else {
      setRevenue([
        { address: currentUser?.uid, percentage: 100 },
        ...updatedRevenue,
      ]);
    }
    setRevenueEditing(false);
    setCurrentId();
    setCurrentAddress();
  };

  const handleAdditionalFiles = async (type) => {
    openModal();
    modalContent(
      <>
        <OptionModal heading={"Compressing Additional File(s) - Please Wait"} additionalText={"Do not close"}>
          <div style={{ justifyContent: "center", marginLeft: " 50%" }}>
            <PulseSpinner loading={true} />
          </div>
        </OptionModal>
      </>
    );
    let zip = await zipFiles(additionalFiles).then((file) => {
      return file;
    });
    uploadZip(zip);
  };

  const uploadZip = async (zip) => {
    openModal();
    modalContent(
      <>
        <OptionModal heading={"Uploading Files..."} additionalText={"Do not close"}>
          <div style={{ justifyContent: "center", marginLeft: " 50%" }}>
            <PulseSpinner loading={true} />
          </div>
        </OptionModal>
      </>
    );
    let fileName = `${Math.round(Math.random() * 10000000000000)}.zip`;
    try {
      let artist = await searchArtists(
        ARTIST_SEARCH_TYPES.ADDRESS,
        currentUser.uid
      );
      let apiKey = await getNFTStorageKey(artist?.artistId);
      await uploadZipToIPFS(zip, `artists/${currentUser?.uid}/${fileName}`).then(
        (data) => {
          //console.log(data)
          if (data) {
            setAdditionalFileInfo(data);
            openModal();
            modalContent(
              <>
                <OptionModal heading={"Upload Complete"}>
                  <HoverOption onClick={() => confirmCreate(data)}>
                    Continue
                  </HoverOption>
                </OptionModal>
              </>
            );
            return confirmCreate(data);
          } else {
            openModal();
            modalContent(
              <>
                <OptionModal heading={"Something Went Wrong"}>
                  <HoverOption
                    onClick={() => {
                      uploadZip(zip);
                    }}
                  >
                    Try Again
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      setAdditionalFileInfo({});
                      setTokenGate(false);
                      closeModal();
                      modalContent();
                      confirmCreate();
                    }}
                  >
                    Create Collection without Additional Files
                  </HoverOption>
                </OptionModal>
              </>
            );
          }
        }
      );
    } catch (err) {
      console.error(err);
      openModal();
      modalContent(
        <>
          <OptionModal heading={"Something Went Wrong"}>
            <HoverOption
              onClick={() => {
                uploadZip(zip);
              }}
            >
              Try Again
            </HoverOption>
            <HoverOption
              onClick={() => {
                setAdditionalFileInfo({});
                setTokenGate(false);
                toggleModal();
                modalContent();
                confirmCreate();
              }}
            >
              Create Collection without Additional Files
            </HoverOption>
          </OptionModal>
        </>
      );
    }
  };

  const handleLabelSearch = (e) => {
    setSearchLoaded(false);
    switch (mintType) {
      case MINTING.TYPE.ALBUM:
        searchLabelArtistsMintableAlbumsByNameAndUid(currentUser?.uid, e.target.value)
          .then((data) => {
            if (data) {
              setContent(data);
              setSearch(data);
            }
            setSearchLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setSearchLoaded(true);
            return;
          });
        break;
      case MINTING.TYPE.SINGLE:
        searchLabelArtistsMintableSongsByNameAndUid(currentUser?.uid, e.target.value)
          .then((data) => {
            if (data) {
              setContent(data);
              setSearch(data);
            }
            setSearchLoaded(true);
          })
          .catch((err) => {
            console.error(err);
            setSearchLoaded(true);
            return;
          });

        break;

      case MINTING.TYPE.CUSTOM:
        Promise.all([
          searchLabelArtistsMintableSongsByNameAndUid(
            currentUser?.uid,
            e.target.value
          ),
          searchLabelArtistsMintableAlbumsByNameAndUid(
            currentUser?.uid,
            e.target.value
          ),
        ])
          .then((values) => {
            let discography = [];
            if (values[0]) {
              discography.push(values[0]);
            }
            if (values[1]) {
              discography.push(values[1]);
            }
            setContent({ songs: values[0], albums: values[1] });
            setSearch(...discography);
          })
          .catch((err) => {
            console.error(err);
          });
        setSearchLoaded(true);
        break;

      default:
        break;
    }
  }

  const handleInput = (e) => {
    let errs = errors;
    switch (e.target.name) {
      case "music search":
        setInput(e.target.value);
        if (e.target.value.trim() !== "") {
          if(currentProfile === 'label'){
            return handleLabelSearch(e)
          }else{
            setSearchLoaded(false);
          switch (mintType) {
            case MINTING.TYPE.ALBUM:

              searchMintableAlbumsByNameAndUid(currentUser?.uid, e.target.value)
                .then((data) => {
                  if (data) {
                    setContent(data);
                    setSearch(data);
                  }
                  setSearchLoaded(true);
                })
                .catch((err) => {
                  console.error(err);
                  setSearchLoaded(true);
                  return;
                });
              break;
            case MINTING.TYPE.SINGLE:
              searchMintableSongsByNameAndUid(currentUser?.uid, e.target.value)
                .then((data) => {
                  if (data) {
                    setContent(data);
                    setSearch(data);
                  }
                  setSearchLoaded(true);
                })
                .catch((err) => {
                  console.error(err);
                  setSearchLoaded(true);
                  return;
                });

              break;

            case MINTING.TYPE.CUSTOM:
              Promise.all([
                searchMintableSongsByNameAndUid(
                  currentUser?.uid,
                  e.target.value
                ),
                searchMintableAlbumsByNameAndUid(
                  currentUser?.uid,
                  e.target.value
                ),
              ])
                .then((values) => {
                  let discography = [];
                  if (values[0]) {
                    discography.push(values[0]);
                  }
                  if (values[1]) {
                    discography.push(values[1]);
                  }
                  setContent({ songs: values[0], albums: values[1] });
                  setSearch(...discography);
                })
                .catch((err) => {
                  console.error(err);
                });
              setSearchLoaded(true);
              break;

            default:
              setSearchLoaded(true);
              break;
          }
        }
        }

        break;

      case "color":
        setColor(e.target.value);
        break;

      case "collection price":
        
        if(e.target.value[0] === "0"){
         setPrice(e.target.value.substring(1,e.target.value.length)); 
        }else{
          setPrice(e.target.value)
        }
        
        if(!isNaN(e.target.value) && e.target.value > 0){
          delete errs.price
          setErrors(errs)
          return
        }
        if(e.target.value?.length === 0){
          
          setPrice(0)
        }
        if(isNaN(e.target.value)){
        /*   err = {
            ...err,
            price: "Invalid Price. Please enter price or leave 0"
          } */
          setPrice(parseFloat(e.target.value))
          /* setErrors(err) */
          return
        }
        
       
        break;

      case "fixed supply":
        if(e.target.value?.length > 0 && isNaN(e.target.value) === false){
        setQuantity(parseInt(e.target.value));
        return
        }if(e.target.value?.length === 0){
          setQuantity(1);
        }
        break;

      case "pre mint":
        console.log(e.target.value)
        if (
          new Date(e.target.value).getMonth() >= new Date().getMonth() &&
          new Date(e.target.value).getYear() >= new Date().getYear()
        ) {
          setMintDate(e.target.value);
        }
        break;

      case "pre mint amount":
        
      if(e.target.value?.length > 0 && isNaN(e.target.value) === false){
        setPremintAmount(parseInt(e.target.value));
        return
        }if(e.target.value?.length === 0){
          setPremintAmount(0);
        }
      break;

      case "transfer fee":
        errs = errors

        if(e.target.value[0] === "0"){
          setTransferFee(e.target.value.substring(1,e.target.value.length)); 
         }else{
          if(parseFloat(e.target.value) <= 50){

           setTransferFee(e.target.value)
          }
         }
         
         if(!isNaN(e.target.value) && e.target.value > 0){
           delete errs.price
           setErrors(errs)
           return
         }
         if(e.target.value?.length === 0){
           
           setTransferFee(0)
         }
         if(isNaN(e.target.value)){
         /*   err = {
             ...err,
             price: "Invalid TransferFee. Please enter price or leave 0"
           } */
           setTransferFee(parseFloat(e.target.value))
           /* setErrors(err) */
           return
         }
        break;

      case "NFT collection Title":
        if (e.target.value.trim() === "") {
        }
        setTitle(e.target.value);
        break;

      case "NFT collection ID":
        if(e.target.value?.length > 0 && isNaN(e.target.value) === false){
          //console.log(e.target.value)
        setCollectionId(parseInt(e.target.value));
        return validateCollectionId(parseInt(e.target.value), currentUser?.uid)
          .then((data) => {
            let err = errors;
            if (data.response) {
              delete err.id;
            }
            if (data.collectionId) {
              err = {
                ...err,
                id: `This collection ID is already in use. Select another one`,
              };
            }
            if (data?.error) {
              err = { ...err, id: `Something went wrong. please try again` };
            }
            setErrors(err);
          })
          .catch((err) => {
            setErrors((prev) => {
              return { ...prev, id: `Something went wrong. please try again` };
            });
            console.error(err);
          });}
          if(e.target.value?.length === 0){
            setCollectionId(0)
          }
        break;

      case "NFT description":
        setDescription(e.target.value);
        break;

      case "NFT flag":
        const doc = document.getElementById("flag description");
        switch (e.target.value) {
          case MINTING.FLAG.TRANSFERABLE:
            setFlag(8);
            doc.textContent = MINTING.FLAG_DESCRIPTION.TRANSFERABLE;
            break;

          case MINTING.FLAG.BURNABLE:
            setFlag(1);
            doc.textContent = MINTING.FLAG_DESCRIPTION.BURNABLE;
            break;

          case MINTING.FLAG.ONLY_XRP:
            setFlag(2);
            doc.textContent = MINTING.FLAG_DESCRIPTION.ONLY_XRP;
            break;

          default:
            break;
        }
        break;

      case "additional":
        let files = [];
        files = additionalFiles;
        setAdditional([]);
        if (e.target?.files?.length > 0 && e.target?.files[0]) {
          toggleModal();
          if (files.length > 0) {
            modalContent(
              <>
                <OptionModal heading={"Added more file(s)"}>
                  <HoverOption
                    onClick={() => {
                      toggleModal();
                      modalContent();
                    }}
                  >
                    OK
                  </HoverOption>
                </OptionModal>
              </>
            );
          } else {
            modalContent(
              <>
                <OptionModal heading={"Files Added"}>
                  <HoverOption
                    onClick={() => {
                      toggleModal();
                      modalContent();
                    }}
                  >
                    OK
                  </HoverOption>
                </OptionModal>
              </>
            );
          }
          files.push(...e.target.files);
        }
        setAdditional(files);
        break;

      case "rev address":
        delete errs.address;
        setCurrentAddress((prev) => {
          return { ...prev, address: e.target.value };
        });
        break;

      case "rev percentage":
        delete errs.percentage;
        if(e.target.value?.length > 0){
        setCurrentAddress((prev) => {
          return { ...prev, percentage: parseInt(e.target.value) };
        });
        return
      }if(e.target.value?.length === 0){
        setCurrentAddress((prev) => {
          return { ...prev, percentage: 0 };
        })
        }
        break;

      case "coverArt1":
        let currArt = coverArt;
        if (e.target.value && e.target.files[0]) {
          toggleModal();
          modalContent();
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setCoverArt({ file: e.target.files[0] });
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setCoverArt();
                return false;
              }

              //let artBlob = convertFileToBytes(e.target.files[0])
              let imageFileName;
              if (!coverArt?.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
                setCoverArt((prev) => {
                  return { ...prev, name: imageFileName };
                });
              } else {
                imageFileName = coverArt?.name;
              }
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `artists/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setCoverArt((prev) => {
                    return { ...prev, progress: progress };
                  });
                  /* if(isCancelSongUpload){
                      if (!uploadTask.isComplete()) {
                        //Upload is not complete yet, let's cancel
                        uploadTask.cancel();
                    } else {
                        //Upload is complete, but user wanted to cancel. Let's delete the file
                        uploadTask.snapshot.ref.delete();
                        // storageRef.delete(); // will delete all your files
                    }
                    } */
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      /* if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                      break;
                    default:
                      /*  if(isCancelSongUpload){
                          if (!uploadTask.isComplete()) {
                            //Upload is not complete yet, let's cancel
                            uploadTask.cancel();
                        } else {
                            //Upload is complete, but user wanted to cancel. Let's delete the file
                            uploadTask.snapshot.ref.delete();
                            // storageRef.delete(); // will delete all your files
                        }
                        } */
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setCoverArt(currArt);
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setCoverArt(currArt);
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                      let artist = await searchArtists(
                        ARTIST_SEARCH_TYPES.ADDRESS,
                        currentUser.uid
                      );
                      //console.log(artist)
                        uploadImageToIPFS1(currentUser?.uid, downloadURL, e.target.files[0])
                          .then((data) => {
                            //console.log(data)
                            if (data) {
                              setCoverArt((prev) => {
                                return {
                                  ...prev,
                                  url: downloadURL,
                                  file: e.target.files[0],
                                  ref: uploadTask.snapshot.ref.fullPath,
                                  ipfs: data,
                                };
                              });
                              return;
                            }
                            setCoverArt((prev) => {
                              return {
                                ...prev,
                                url: downloadURL,
                                file: e.target.files[0],
                                ref: uploadTask.snapshot.ref.fullPath,
                              };
                            });
                            return;
                          })
                          .catch((err) => {
                            setCoverArt((prev) => {
                              if(prev){
                              return {
                                ...prev,
                                url: prev?.url,
                                file: prev.file,
                                ref: prev?.ref,
                              }
                            }else{
                                return {}
                              }
                            });
                            console.error(err);
                          });
                      

                      //console.log("File available at", downloadURL);
                    }
                  );
                }
              );
            };
          };
        }
        break;
      default:
        break;
    }
    setErrors(errs);
  };

  const selectMusic = (music) => {
    try {
      switch (mintType) {
        case MINTING.TYPE.SINGLE:
          setSelectedMusic({ type: mintType, music: [music] });

          break;
        case MINTING.TYPE.ALBUM:
          setSelectedMusic({ type: mintType, music: [music] });
          break;
        case MINTING.TYPE.CUSTOM:
          if (music) {
            if (selectedMusic?.music?.length > 0) {
              setSelectedMusic((prev) => {
                return { type: mintType, music: [...prev?.music, music] };
              });
            } else {
              setSelectedMusic({ type: mintType, music: [music] });
            }
          }

          break;

        default:
          setSearch(musicContent);
          break;
      }
      setContent();
      setSearch();
      setInput("");
    } catch (err) {
      console.error(err);
    }
  };

  const deleteSelectedMusic = (id) => {
    switch (mintType) {
      case MINTING.TYPE.SINGLE:
        setSelectedMusic((prev) => {
          return {
            ...prev,
            music: prev?.music?.filter((m, index) => index !== id),
          };
        });
        break;

      case MINTING.TYPE.ALBUM:
        setSelectedMusic((prev) => {
          return {
            ...prev,
            music: prev?.music?.filter((m, index) => index !== id),
          };
        });
        break;

      case MINTING.TYPE.CUSTOM:
        setSelectedMusic((prev) => {
          return {
            ...prev,
            music: prev?.music?.filter((m, index) => index !== id),
          };
        });
        break;

      default:
        break;
    }
  };

  const displayAdditionalFiles = () => {
    return (
      <FormWrapper>
        {additionalFiles.length === 0 && "No Files Selected"}
        <ChipList alignment={"center"} maxHeight={"60%"}>
          {additionalFiles?.length > 0 &&
            additionalFiles.map((file, index) => {
              return (
                <Chip
                  background={color}
                  label={`${file.name} (${(file.size / (1024 * 1024)).toFixed(
                    2
                  )} MB)`}
                  onDelete={() => {
                    let fs = additionalFiles.filter(
                      (f, i) => index !== i && file.name !== f.name
                    );
                    setAdditional(fs);
                  }}
                  closeButton
                />
              );
            })}
        </ChipList>
      </FormWrapper>
    );
  };

  const displaySearch = () => {
    return (
      <FormWrapper>
        {!searchLoader ? (
          <ModalLoadingScreen text={'searching...'} transparent={true} />
        ) : (
          <ContentListContainer maxHeight={"80%"}>
            <>
              <Table>
                {mintType === MINTING.TYPE.SINGLE &&
                  musicSearch?.map((song, index) => {
                    return (
                      <>
                        <TableRow
                          background={
                            selectedMusic?.music?.length > 0 &&
                            selectedMusic?.music?.findIndex(
                              (m) => m.songId === song.songId
                            ) > -1 &&
                            color
                          }
                          key={index}
                          id={song.songId}
                          onClick={() => {
                            selectMusic(song);
                          }}
                        >
                          <TableData>
                            <ImageWrapper>
                              <Img
                                isCircular={true}
                                src={
                                  song?.albumArt
                                    ? song.albumArt
                                    : DEFAULT_IMAGE(800)
                                }
                                alt={DEFAULT_IMAGE}
                              />
                            </ImageWrapper>
                          </TableData>
                          <TableData>
                            <DetailsContainer>
                              <DetailsText>{song.songName}</DetailsText>
                            </DetailsContainer>
                          </TableData>
                        </TableRow>
                      </>
                    );
                  })}
                {mintType === MINTING.TYPE.ALBUM &&
                  musicSearch?.map((album, index) => {
                    return (
                      <>
                        <TableRow
                          background={
                            selectedMusic?.music?.length > 0 &&
                            selectedMusic.music.findIndex(
                              (m) => m.albumId === album.albumId
                            ) > -1 &&
                            color
                          }
                          key={index}
                          id={album.albumId}
                          onClick={() => {
                            selectMusic(album);
                          }}
                        >
                          <TableData>
                            <ImageWrapper>
                              <Img
                                isCircular={true}
                                src={
                                  album?.albumArt
                                    ? album.albumArt
                                    : DEFAULT_IMAGE(800)
                                }
                                alt={DEFAULT_IMAGE}
                              />
                            </ImageWrapper>
                          </TableData>
                          <TableData>
                            <DetailsContainer>
                              <DetailsText>{album.albumName}</DetailsText>
                            </DetailsContainer>
                          </TableData>
                        </TableRow>
                      </>
                    );
                  })}
                {mintType === MINTING.TYPE.CUSTOM &&
                  musicSearch?.map((music, index) => {
                    return (
                      <>
                        <TableRow
                          background={
                            selectedMusic?.music?.length > 0 &&
                            (selectedMusic?.music?.findIndex(
                              (m) => m.albumId === music.albumId
                            ) > -1 ||
                              selectedMusic.music.findIndex(
                                (m) => m.songId === music.songId
                              ) > -1) &&
                            color
                          }
                          key={index}
                          id={music.songId ? music.songId : music.albumId}
                          onClick={() => {
                            selectMusic(music);
                          }}
                        >
                          <TableData>
                            <ImageWrapper>
                              <Img
                                isCircular={true}
                                src={
                                  music?.albumArt
                                    ? music.albumArt
                                    : DEFAULT_IMAGE(800)
                                }
                                alt={DEFAULT_IMAGE}
                              />
                            </ImageWrapper>
                          </TableData>
                          <TableData>
                            <DetailsContainer>
                              <DetailsText>
                                {music?.songName
                                  ? `${music.songName} (Song)`
                                  : `${music.albumName} (Album)`}
                              </DetailsText>
                            </DetailsContainer>
                          </TableData>
                        </TableRow>
                      </>
                    );
                  })}
              </Table>
            </>
          </ContentListContainer>
        )}
      </FormWrapper>
    );
  };

  return (
    <>
      <Container>
        <ContentsSection>
          <MintTitleContainer background={color}>
            <MintTitleWrapper>
              <div style={{ fontWeight: 700, fontSize: "3rem" }}>
                NFT Collection Creator
              </div>
            </MintTitleWrapper>
          </MintTitleContainer>
          <MintDetailsContainer>
            <MintDetailsWrapper>
              {page === 1 && (
                <ContentWrapper heading={"Basic Info"}>
                  <FormWrapper>
                    <FormLabel>NFT Collection Title</FormLabel>
                    <FormInput1
                      name="NFT collection Title"
                      onChange={handleInput}
                      placeholder={"Collection Title"}
                      value={title}
                    />
                    <SmallText>
                      The name of the collection and all the NFTokens to be
                      minted within the collection.
                    </SmallText>
                    <SmallText error>{errors?.title}</SmallText>
                  </FormWrapper>
                  <FormWrapper>
                    <FormLabel>Collection ID (Taxon)</FormLabel>
                    <FormInput1
                      name="NFT collection ID"
                      onChange={handleInput}
                      placeholder={"Collection ID"}
                      value={collectionId}
                      min={0}
                      step={1}
                    />
                    <SmallText>
                      Select the collection ID. this will uniquely identify all
                      minted NFTokens within this collection over the ledger.
                    </SmallText>
                    <SmallText error>{errors?.id}</SmallText>
                  </FormWrapper>
                  <FormWrapper>
                    <FormLabel>Select Music</FormLabel>
                    <SmallText>
                      Select music to be minted as an NFToken. Only music that
                      is set as mintable can be searched for.
                    </SmallText>
                    <NavContainer>
                      <Nav>
                        <NavLink
                          active={mintType === MINTING.TYPE.SINGLE}
                          onClick={() => {
                            selectMintType(MINTING.TYPE.SINGLE);
                          }}
                        >
                          Single
                        </NavLink>
                        <NavLink
                          active={mintType === MINTING.TYPE.ALBUM}
                          onClick={() => {
                            selectMintType(MINTING.TYPE.ALBUM);
                          }}
                        >
                          Album
                        </NavLink>
                        <NavLink
                          active={mintType === MINTING.TYPE.CUSTOM}
                          onClick={() => {
                            selectMintType(MINTING.TYPE.CUSTOM);
                          }}
                        >
                          Custom
                        </NavLink>
                      </Nav>
                    </NavContainer>
                    <ChipList alignment="left">
                      {selectedMusic?.music?.length > 0 &&
                        selectedMusic?.music?.map((music, index) => {
                          return (
                            <>
                              <Chip
                                onDelete={() => deleteSelectedMusic(index)}
                                closeButton
                                image={music?.albumArt}
                                label={
                                  music?.albumId
                                    ? music?.albumName
                                    : music?.songName
                                }
                              />
                            </>
                          );
                        })}
                    </ChipList>
                    {mintType !== "" && (
                      <>
                        <FormInput1
                          name={"music search"}
                          type={"search"}
                          placeholder={
                            mintType === MINTING.TYPE.ALBUM
                              ? "Search mintable albums in discography. Select one"
                              : mintType === MINTING.TYPE.SINGLE
                              ? "Search mintable songs in discography. Select one"
                              : "Search mintable albums and songs in discography. select more than one"
                          }
                          onChange={handleInput}
                          value={searchInput}
                        />
                        {searchInput?.length > 0 && displaySearch()}
                        <SmallText error>{errors?.music}</SmallText>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: "2%",
                          }}
                        >
                          <SmallText
                            onClick={() => {
                              setUnlimited(!unlimitedStreaming);
                            }}
                          >
                            Provide Unlimited Streaming Rights
                          </SmallText>
                          <ToggleButton
                            toggle={unlimitedStreaming}
                            toggleClick={() => {
                              setUnlimited(!unlimitedStreaming);
                            }}
                            customOnIcon={<FaCheckSquare />}
                            customOffIcon={<FaSquare />}
                          />
                        </div>
                        <SmallText>
                          By checking this option, you will be providing owners
                          of this NFT/Collection unlimited streaming rights to
                          the selected music. By default, the music in this
                          collection can only be downloaded by the buyer.
                        </SmallText>
                      </>
                    )}
                  </FormWrapper>
                  <FormWrapper>
                    <FormLabel>
                      Price{" "}
                      <CurrencySelector
                        currency={currency}
                        setCurrency={setCurrency}
                      />
                    </FormLabel>
                    <FormInput1
                      name="collection price"
                      onChange={handleInput}
                      placeholder={`Set the price of this collection in ${currency}`}
                      value={price}
                      min={0}

                    />
                    <SmallText error> {errors?.price}</SmallText>
                    {price > 0 && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          padding: "2%",
                        }}
                      >
                        <MintDetailsText>Add Discounts</MintDetailsText>
                        <ToggleButton
                          toggle={isDiscount}
                          toggleClick={() => {
                            setDiscount(!isDiscount);
                          }}
                          customOnIcon={<FaCheckSquare />}
                          customOffIcon={<FaSquare />}
                        />
                      </div>
                    )}
                    <SmallText>
                      Set the price of your collection. Select from the
                      currencies we accept.
                    </SmallText>
                  </FormWrapper>
                  {isDiscount && price > 0 && (
                    <NFTokenDiscounts
                      price={price}
                      currency={currency}
                      setEditing={setDiscountEditing}
                      isEditing={isDiscountEditing}
                      currentDiscount={currentDiscount}
                      setCurrentDiscount={setCurrentDiscount}
                      deleteSelectedDiscount={deleteDiscount}
                      discountRedeemers={discountRedeemers}
                      addDiscount={addDiscount}
                      selectDiscountType={selectDiscountType}
                      discountType={discountType}
                      setDiscountType={selectDiscountType}
                    />
                  )}
                  <FormWrapper>
                    <FormLabel>NFT Collection Description</FormLabel>
                    <FormInput1
                      name="NFT description"
                      onChange={handleInput}
                      placeholder={"Describe your collection."}
                      value={description}
                    />
                    <SmallText>
                      Describe your collection. This will be included in the
                      metadata
                    </SmallText>
                  </FormWrapper>
                </ContentWrapper>
              )}

              {page === 2 && (
                <ContentWrapper heading={"Additional Information"}>
                  <FormWrapper>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>
                        <label
                          for={
                            coverArt?.progress > 0 || coverArt?.progress < 100
                              ? ""
                              : "coverArt1"
                          }
                        >
                          Cover Art
                        </label>
                      </MintDetailsText>
                      {((coverArt?.progress === 100 && coverArt?.url) ||
                        !coverArt) && (
                        <>
                          <label
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleModal();
                              modalContent(
                                <OptionModal heading={"Cover Art"}>
                                  {selectedMusic?.music?.length > 0 && (
                                    <HoverOption
                                      onClick={() => {
                                        modalContent(
                                          <OptionModal
                                            heading={"Select Cover Art"}
                                          >
                                            <NFTCoverArtSelector
                                              music={selectedMusic.music}
                                              setCoverArt={setCoverArt}
                                            />
                                          </OptionModal>
                                        );
                                      }}
                                    >
                                      Select Image
                                    </HoverOption>
                                  )}
                                  <HoverOption>
                                    <label for="coverArt1">
                                      Upload New Image
                                    </label>
                                  </HoverOption>
                                  {coverArt && coverArt?.url && (
                                    <HoverOption
                                      onClick={() => {
                                        setCoverArt();
                                        toggleModal();
                                        modalContent();
                                      }}
                                    >
                                      Delete
                                    </HoverOption>
                                  )}
                                </OptionModal>
                              );
                            }}
                          >
                            <AlbumArt
                              background={color}
                              size={"18rem"}
                              albumArt={
                                coverArt && coverArt?.url
                                  ? coverArt.url
                                  : selectedMusic &&
                                    selectedMusic?.music?.length > 0 &&
                                    selectedMusic?.music[0]?.albumArt
                                  ? selectedMusic?.music[0]?.albumArt
                                  : albumArtPlaceholder
                              }
                              alt={"cover art"}
                            />
                          </label>
                          <FormInput1
                            style={{ display: "none" }}
                            id="coverArt1"
                            name="coverArt1"
                            onChange={handleInput}
                            type={"file"}
                            accept="image/gif,image/jpeg, image/jpg"
                            multiple="false"
                          />
                        </>
                      )}
                      {coverArt?.progress > 0 && !coverArt?.url && (
                        <>
                          <PushSpinner loading={coverArt?.url ? false : true} />
                          {coverArt.progress < 100
                            ? `${coverArt?.progress.toFixed(2)}%`
                            : `Processing`}
                        </>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>
                        <label style={{ cursor: "pointer" }} for="color">
                          Alt Cover Art (Color)
                        </label>
                      </MintDetailsText>
                      <label
                        for="color"
                        style={{
                          display: "flex",
                          flex: "1 1 100%",
                          width: "100%",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            background: color,
                            width: "2rem",
                            height: "2rem",
                            display: "flex",
                            borderRadius: "0.2rem",
                          }}
                        ></div>
                      </label>
                      <FormInput1
                        style={{ display: "none" }}
                        id="color"
                        name="color"
                        onChange={handleInput}
                        type={"color"}
                        value={color}
                      />
                    </div>
                    <SmallText background={themeColors.secondaryColor}>
                      Background color placeholder if the cover art does not
                      load
                    </SmallText>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>Cover Art Attributes</MintDetailsText>
                      <ToggleButton
                        toggle={isAttributes}
                        toggleClick={() => {
                          setIsAttributes(!isAttributes);
                        }}
                        customOnIcon={<FaCheckSquare />}
                        customOffIcon={<FaSquare />}
                      />
                    </div>
                    {isAttributes && (
                      <>
                        {attributes.length > 0 &&
                          attributes.map((attribute, index) => {
                            return (
                              <FormWrapper>
                                <FormInput1
                                  onChange={(e) =>
                                    setAttributes((prev) => {
                                      return prev.map((a, i) => {
                                        if (i === index) {
                                          return {
                                            ...a,
                                            traitType: e.target.value,
                                          };
                                        }
                                        return a;
                                      });
                                    })
                                  }
                                  value={attribute.traitType}
                                  placeholder="Trait Type"
                                />
                                <FormInput1
                                  onChange={(e) =>
                                    setAttributes((prev) => {
                                      return prev.map((a, i) => {
                                        if (i === index) {
                                          return {
                                            ...a,
                                            value: e.target.value,
                                          };
                                        }
                                        return a;
                                      });
                                    })
                                  }
                                  value={attribute.value}
                                  placeholder="Value"
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: "2%",
                                    justifyContent: "center",
                                  }}
                                >
                                  <FormButton1
                                    onClick={() =>
                                      setAttributes((prev) => {
                                        return prev.filter(
                                          (a, i) => index !== i
                                        );
                                      })
                                    }
                                  >
                                    Delete
                                  </FormButton1>
                                </div>
                              </FormWrapper>
                            );
                          })}
                        <HoverOption
                          onClick={() => {
                            let a = [
                              ...attributes,
                              { traitType: "", value: "" },
                            ];
                            setAttributes(a);
                          }}
                        >
                          Add Attribute
                        </HoverOption>
                      </>
                    )}
                  </FormWrapper>

                  <FormWrapper>
                    <FormLabel>
                      <label style={{ cursor: "pointer" }} for="additional">
                        Additional Files
                      </label>
                    </FormLabel>
                    <label
                      for="additional"
                      style={{
                        display: "flex",
                        width: "100%",
                        flex: "1 1 100%",
                        cursor: "pointer",
                      }}
                    >
                      <MintDetailsText
                        style={{
                          whiteSpace: "normal",
                          justifyContent: "center",
                        }}
                      >
                        Select Files
                      </MintDetailsText>
                    </label>

                    <FormInput1
                      style={{ display: "none" }}
                      id="additional"
                      onChange={handleInput}
                      name="additional"
                      type={"file"}
                      multiple
                    />

                    <>{displayAdditionalFiles()}</>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        padding: "2%",
                      }}
                    >
                      <SmallText
                        onClick={() => {
                          setTokenGate(!isTokenGate);
                        }}
                      >
                        Token Gate Additional Files
                      </SmallText>
                      <ToggleButton
                        toggle={isTokenGate}
                        toggleClick={() => {
                          setTokenGate(!isTokenGate);
                        }}
                        customOnIcon={<FaCheckSquare />}
                        customOffIcon={<FaSquare />}
                      />
                    </div>
                    <SmallText error>
                      By checking this option, all the additional files uploaded
                      will be token gated; Only holders of this collection can
                      access these files
                    </SmallText>
                  </FormWrapper>
                </ContentWrapper>
              )}

              {page === 3 && (
                <ContentWrapper heading={"NFT Mint Settings"}>
                  <FormWrapper>
                    <FormLabel for="NFT flag">NFT Collection Flag</FormLabel>
                    <Select
                      name="NFT flag"
                      id="NFT flag"
                      onChange={handleInput}
                    >
                      <Option selected value={MINTING.FLAG.TRANSFERABLE}>
                        Transferable
                      </Option>
                      <Option value={MINTING.FLAG.BURNABLE}>Burnable</Option>
                      <Option value={MINTING.FLAG.ONLY_XRP}>
                        Sell Only XRP
                      </Option>
                    </Select>
                    <SmallText id="flag description" err></SmallText>
                  </FormWrapper>

                  {
                    <FormWrapper>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          padding: "2%",
                        }}
                      >
                        <MintDetailsText>Royalty Fee</MintDetailsText>
                        <FormInput1
                          name="transfer fee"
                          onChange={handleInput}
                          value={transferFee}
                          step={0.01}
                          min={0}
                          max={50}
                        />
                      </div>
                      <SmallText>
                        The percentage you will receive from every secondary
                        sale, as royalty payout
                      </SmallText>
                    </FormWrapper>
                  }

                  <FormWrapper>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>Pre-Mint / Pre-Order</MintDetailsText>
                      <ToggleButton
                        toggle={isPremint}
                        toggleClick={() => {
                          setPremint(!isPremint);
                        }}
                        customOnIcon={<FaCheckSquare />}
                        customOffIcon={<FaSquare />}
                      />
                    </div>
                    {isPremint && (
                      <FormWrapper>
                      <FormWrapper>
                      <FormLabel>Release Date</FormLabel>
                      <FormInput name="pre mint"
                          onChange={handleInput}
                          value={mintDate}
                          min={new Date(new Date().setDate( new Date().getDate() + 1)).toISOString().split("T")[0]}
                          type={"date"}/>
                      </FormWrapper>
                      {errors?.premint && <SmallText error>
                     {errors?.premint}
                    </SmallText>}
                      </FormWrapper>
                     
                    )}
                    <SmallText>
                      By checking this option, the collection will be available
                      for pre order/pre minting before the release date specified. 
                    </SmallText>
                  </FormWrapper>

                  <FormWrapper>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>Fixed Supply</MintDetailsText>
                      <ToggleButton
                        toggle={isFixedSupply}
                        toggleClick={() => {
                          setFixedSupply(!isFixedSupply);
                        }}
                        customOnIcon={<FaCheckSquare />}
                        customOffIcon={<FaSquare />}
                      />
                    </div>
                    {isFixedSupply && (
                      <><FormWrapper>
                        
                        <FormLabel>Quantity</FormLabel>
                        <FormInput1
                          name="fixed supply"
                          onChange={handleInput}
                          value={mintQuantity}
                          min={1}
                        />
                      </FormWrapper>
                      </>
                    )}
                    {isPremint && <>
                    <FormWrapper>
                      <FormWrapper>
                      <FormLabel>Pre Mint Amount</FormLabel>
                      <FormInput1
                        name="pre mint amount"
                        onChange={handleInput}
                        value={premintAmount}
                        min={1}
                      />
                      {errors?.premintAmount && <SmallText error>
                     {errors?.premintAmount}
                    </SmallText>}
                    </FormWrapper><SmallText>
                      Choose an amount that will be sold before the collection release date. 
                    </SmallText></FormWrapper></>}
                    <SmallText>
                      By checking this option, Sonar Muse will be allowed to
                      only sell the quatity specified (You can personally mint
                      more).
                    </SmallText>
                  </FormWrapper>

                  <FormWrapper>
                    <div
                      style={{ display: "flex", width: "100%", padding: "2%" }}
                    >
                      <MintDetailsText>NFT Rev Share (BETA)</MintDetailsText>
                      <ToggleButton
                        toggle={revShare}
                        toggleClick={toggleRevenue}
                        customOnIcon={<FaCheckSquare />}
                        customOffIcon={<FaSquare />}
                      />
                    </div>
                    {revShare && (
                      <NFTokenRevShare
                        addresses={revenue}
                        address={currentAddress?.address}
                        addressRef={addressRef}
                        percentage={currentAddress?.percentage}
                        percentageRef={percentageRef}
                        setCurrentId={setCurrentId}
                        setEditing={setRevenueEditing}
                        setCurrentRevenue={setCurrentAddress}
                        handleInput={handleInput}
                        onAdd={addRevenue}
                        isEditing={isRevenueEditing}
                        currentId={currentRevenueId}
                        errors={errors}
                        clearForm={clearRevenueForm}
                        onDelete={removeRevenue}
                        onEdit={editRevenue}
                      />
                    )}

                    <SmallText>
                      By checking this option, you agree to Sonar Muse selling
                      this collection on your behalf and split the sales of this
                      NFToken between the addresses specified. This is a test
                      feature. Select at your own risk
                    </SmallText>
                  </FormWrapper>
                </ContentWrapper>
              )}
            </MintDetailsWrapper>
          </MintDetailsContainer>
          <PageIndexer pages={3} currentPage={page} onClick={setPageNum} />
          <MintButtonConainer background={color}>
            {(page < 3 && page > 1) && <MintButton outline onClick={nextPage}>Next</MintButton>}
            <MintButton outline={!hideButtons} onClick={hideButtons ? () => {} : saveAsDraft}>Save As Draft</MintButton>
            <MintButton outline={!hideButtons} onClick={hideButtons ? () => {} : createCollection}>
              Create Collection
            </MintButton>
            <MintButton outline onClick={() => navigate( `/home`)}>
              Cancel
            </MintButton>
            {page > 1 && <MintButton outline onClick={prevPage}>Prev</MintButton>}
          </MintButtonConainer>
        </ContentsSection>
      </Container>
    </>
  );
};

export default NFTCollectionCreator;
