import React from 'react'
import { CONTENT_TYPES } from '../../../utils/Enum';
import Chip from '../../Chip';
import { ChipList } from '../../Chip/ChipElements';
import ContentList from '../../ContentList';
import CurrencySelector from '../../CurrencySelector';
import ToggleButton from '../../ToggleButton';
import { Theme } from '../../WebsiteThemeElements';
import { ErrorWrapper, FormInput, FormLabel, FormWrapper, ListContainer, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText } from '../MusicUploadsFormElements';
import { MusicGenreSearchModal } from '../../SearchModal';
import { useModal } from '../../Modal/ModalContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';

const Page1 = ({
    handleToggle,
    isExplicit,
    isCover,
    isStreamable,
    isSubscription,
    isDownloadable,
    isMintable,
    isUserRoyalties,
    isVideo,
    isVisualizer,
    isPreRelease,
    songNameRef,
    songName,
    handleInput,
    errors,
    setCurrency,
    songPriceRef,
    currency,
    price,
    genreRef,
    genre,
    mainGenre,
    removeGenre,
    filteredGenres,
    addGenre,
    subgenre,
    subGenres,
    filteredSubGenres,
    index,
    album,
    isPreview
}) => {

  const {openModal, modalContent, closeModal} = useModal()
  const {currentUser} = useAuth()
  const {themeColors} = useWebsiteTheme()

  return (
    <>
              <SongDetailsSection>
                <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>
                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("explicit");
                    }}
                  >
                    Explicit
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isExplicit}
                toggleClick={() => handleToggle("explicit")}/>
                </FormWrapper>
               {(album?.isPreRelease || !album) && <> <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("schedule");
                    }}
                  >
                    Schedule Release
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isPreRelease}
                toggleClick={() => { handleToggle("schedule")}}/>
                </FormWrapper> 
                {isPreRelease && <SongDetailsText background={themeColors.secondaryColor}>Song will be released on the date selected on page 3</SongDetailsText>}
                {album?.isPreRelease && isPreRelease && <><FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("preview");
                    }}
                  >
                    Preview Song
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isPreview}
                toggleClick={() => { handleToggle("preview")}}/>
                </FormWrapper> 
                {isPreview && <SongDetailsText background={themeColors.secondaryColor}>Song will be available for streaming before the release date</SongDetailsText>}</>}</>}
                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("cover");
                    }}
                  >
                    Cover
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isCover}
                toggleClick={() => handleToggle("cover")}/>
                </FormWrapper>
                {isCover && <SongDetailsText background={themeColors.secondaryColor}>This song is a remake/rendition of another artist's song </SongDetailsText>}

                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      /* handleToggle("streamable") */;
                    }}
                  >
                    Streamable
                  </FormLabel>
                <ToggleButton  
                onText={'On'}
                offText={'Off'}
                toggle={isStreamable}
                toggleClick={() => { /* handleToggle("streamable") */}}/>
                </FormWrapper>
                {isStreamable && <SongDetailsText background={themeColors.secondaryColor}>Song will be available for streaming, credited artists (page 6) will earn micropayments per stream</SongDetailsText>}

                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("downloadable");
                    }}
                  >
                     {isPreRelease ? ' Digital Pre-order' : 'Digital Sales'}
                  </FormLabel>
                  <ToggleButton 
                  onText={'On'}
                  offText={'Off'} 
                  toggle={isDownloadable}
                toggleClick={() => handleToggle("downloadable")}/>
                </FormWrapper>
                {isDownloadable && <SongDetailsText background={themeColors.secondaryColor}>Song will be available for {isPreRelease ? ' pre-order before release date' : ' sale digitally'}</SongDetailsText>}

                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      //handleToggle("subscription");
                    }}
                  >
                    Subscription Only
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isSubscription}
                  //toggleClick={() => handleToggle("subscription")}
                  />
                </FormWrapper>
                {isSubscription && <SongDetailsText background={themeColors.secondaryColor}>Only user's subscribed to one of the main artist(s) will have access to this song </SongDetailsText>}

<>  <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("royalties");
                    }}
                  >
                    User Royalty Shares
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isUserRoyalties}
                  toggleClick={() => handleToggle("royalties")}/>
                </FormWrapper>
                {isUserRoyalties && 
                  <><SongDetailsText background={themeColors.secondaryColor}>Allow your supporters to invest and earn from your music sales and royalty streams. See Page 7 </SongDetailsText>
 <SongDetailsText background={themeColors.secondaryColor}>Once enabled you cannot disable this feature.</SongDetailsText></> }</>
                

                <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("mintable");
                    }}
                  >
                    Mintable
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isMintable}
                  toggleClick={() => handleToggle("mintable")}/>
                </FormWrapper>
                {isMintable && <SongDetailsText background={themeColors.secondaryColor}>You will be able to make NFT collections with this song, once uploaded</SongDetailsText>}
                
             {/*   <FormWrapper>
                  <FormLabel
                    onClick={() => handleToggle("preorder")}
                  >
                    Pre-order
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isPreRelease}
                  toggleClick={() => handleToggle("preorder")}/>
                </FormWrapper>
                {isPreRelease && <SongDetailsText background={themeColors.secondaryColor}>Set release date for pre-order on page 3</SongDetailsText>}
        
                <br /> */}
                <>
                  <FormWrapper>
                  <FormLabel>Song Name</FormLabel>
                  <ErrorWrapper>
                    <FormInput
                    ref={songNameRef}
                    placeholder={"Name of Song"}
                    name={"name " + index}
                    value={songName}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                  {errors?.songName && <SongDetailsText error>{errors?.songName}</SongDetailsText>}
                  </ErrorWrapper>
                </FormWrapper>
                
                  </>
                {(isDownloadable === true) && (
                  <FormWrapper>
                    <FormLabel>Price <CurrencySelector currency={currency } setCurrency={setCurrency}/></FormLabel>
                    <ErrorWrapper>
                      <FormInput style={{flex: '1 1 50%'}}
                        ref={songPriceRef}
                        name={"price " + index}
                        placeholder={`Enter price of song in ${currency}`}
                        min={0}
                        value={price}
                        onChange={(e) => {
                        handleInput(e);
                      }}/>
                      {errors?.price && <SongDetailsText error>{errors?.price}</SongDetailsText>}
                    </ErrorWrapper>
                  </FormWrapper>
                )}

                <FormWrapper>
                  <FormLabel>Genre</FormLabel>
                  <ErrorWrapper>
                    <FormInput
                    ref={genreRef}
                    type={"search"}
                    placeholder={"Select Song Main Genre"}
                    readOnly
                    onClick={() => {
                      openModal()
                      modalContent(<MusicGenreSearchModal type={'Genre'} onClick={addGenre}/>)
                    }}
                    name={"main genre " + index}
                    value={genre}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                  {errors?.genre && <SongDetailsText error>{errors?.genre}</SongDetailsText>}
                  </ErrorWrapper>
                </FormWrapper>
                <FormWrapper>
                  <ChipList>
                    {mainGenre && (
                      <Chip
                        label={mainGenre.name}
                        closeButton
                        onDelete={() => removeGenre()}
                        background={themeColors.mainColor}
                      />
                    )}
                  </ChipList>
                </FormWrapper>
                
                {filteredGenres && genre?.trim()?.length > 0 && (
                  <FormWrapper>
                    <ListContainer>
                      <ContentList
                        type={CONTENT_TYPES.GENRE}
                        content={filteredGenres}
                        onButtonClick={addGenre}
                      />
                    </ListContainer>
                  </FormWrapper>
                )}
                {mainGenre && <FormWrapper>
                  <FormLabel>Sub-genres (Optional)</FormLabel>
                  <FormInput
                    ref={genreRef}
                    type={"search"}
                    placeholder={"Search Sub-genres"}
                    readOnly
                    onClick={() => {
                      openModal()
                      modalContent(<MusicGenreSearchModal type={'sub genre'} existingGenres={subGenres} onClick={addGenre}/>)
                    }}
                    name={"subGenre " + index}
                    value={subgenre}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                  </FormWrapper>}
                  {subGenres && subGenres?.length > 0 &&
                  <> 
                <ChipList>
                  {
                      subGenres?.map((sGenre, index) => {
                        return (
                          <>
                            <Chip
                              id={index}
                              label={sGenre.name}
                              closeButton
                              onDelete={() => removeGenre(index, sGenre)}
                            />
                          </>
                        );
                      })}
                
              
                
                  </ChipList>
                  
                  
                </>
               }
               {filteredSubGenres && subgenre?.trim()?.length > 0 && (
                  <FormWrapper>
                    <ListContainer>
                      <ContentList
                        type={CONTENT_TYPES.GENRE}
                        content={filteredSubGenres}
                        onButtonClick={addGenre}
                      />
                    </ListContainer>
                  </FormWrapper>
                )}
              </SongDetailsSection>
            </>
  )
}

export default Page1