import React from "react";
import CreativeCommonsSelector from "../../CreativeCommonsSelector";
import {
  SongDetailsSection,
  SongDetailsSectionTitle,
} from "../MusicUploadsFormElements";

const Page3 = ({ license, setCreativeCommons }) => {
  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Licensing</SongDetailsSectionTitle>
      <CreativeCommonsSelector
        creativeCommons={license}
        setCreativeCommons={setCreativeCommons}
      />
    </SongDetailsSection>
  );
};

export default Page3;