import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { animateScroll as scroll } from 'react-scroll'
import { useAuth } from '../../contexts/AuthContext'
import { hexToRgba } from '../HexToRGBA'

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: ${(props) => hexToRgba(props.theme.mainBg, 0.7)};
    display: grid;
    width: 100%;
    height: 100%;
    font-size: 1rem;

    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-150%')};
`

export const CloseIcon = styled(FaTimes)`
    color: ${(props) =>
        props.scrollNav ? props.theme.secondaryColor : props.theme.secondaryBg  };
    
    `

export const Icon = styled.div`
    position:absolute;
    top: 1.2rem;
    right:1.5rem;
    color: ${props => props.theme.secondaryBg};
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    display: flex;
    color:  ${props => props.theme.secondaryBg};
    padding: 24px;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    justify-content: center;
    @media screen and (max-width: 480px)
    {
        grid-template-rows: repeat(6, 60px);
    }
    padding: 2%;
`

export const SidebarLink = styled(NavLink)`
    color: ${(props) =>
    props.scrollNav ? props.theme.mainBg : props.theme.secondaryColor};
    display: grid;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: ${props => props.theme.secondaryBg};
    cursor:pointer;

    &:hover {
        border-radius: 25px;
        transition: all 0.2s ease-in-out;
        color:  ${props => props.theme.accentColor};
    }
    margin-bottom: 2%;
`

export const SidebarLinkA = styled.a`
    display: grid;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: ${props => props.theme.secondaryBg};
    cursor:pointer;

    &:hover {
        color:  ${props => props.theme.accentColor};
        transition: 0.2s ease-in-out;
    }
    margin-bottom: 2%;
`

export const SideBtnWrapper = styled.div`
    margin-top: 30px;
    justify-content: center;
`

export const SidebarRoute = styled(NavLink)`
    border-radius: 50px;
    background:  ${props => props.theme.mainColor};
    white-space: nowrap;
    padding: 5% 10%;
    color:  ${props => props.theme.secondaryBg};
    font-size; 16px;
    outline: none;
    border:none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background:  ${props => props.theme.accentColor};
        color:  ${props => props.theme.mainColor};
    }

`


export const SignOut = () =>{
    //const {logout} = useAuth()
    localStorage.removeItem('isLogged')
    localStorage.removeItem('user')
    localStorage.removeItem('AccInfo')
    //logout()
    scroll.scrollToTop();
}

export const LogOut = async() =>{
    const {logout} = useAuth()
    await logout()
    scroll.scrollToTop();
}