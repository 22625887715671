import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {Timestamp, getFirestore, initializeFirestore} from 'firebase/firestore'
import {getStorage, ref, uploadBytesResumable, getDownloadURL, } from 'firebase/storage'


const app = initializeApp(
    {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

}
)

export const auth = getAuth(app)
export default app
export const storage = getStorage(app)
export const db = initializeFirestore(app, {
    ignoreUndefinedProperties: true
}) 
export const timestamp = Timestamp
export const storageRef = ref
export const uploadBytes = uploadBytesResumable
export const getDownloadUrl = getDownloadURL