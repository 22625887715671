import React from "react";
import { stringFormatter } from "../../../../utils";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormTextArea,
  FormWrapper,
  SelectButton,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../../../MusicUploadsForm/MusicUploadsFormElements";

const Page3 = ({
  isStreamable,
  isDownloadable,
  isPreRelease,
  isMintable,
  isrcType,
  isrcRef,
  handleInput,
  isrc,
  errors,
  aboutRef,
  lyricsRef,
  releaseDateRef,
  about,
  lyrics,
  releaseDate,
  index,
  album
}) => {
  return (
    <SongDetailsSection>
      <SongDetailsSectionTitle>Additional Information</SongDetailsSectionTitle>
      <FormWrapper>
        <FormLabel>Release Date</FormLabel>
        <ErrorWrapper>
          <FormInput
            ref={releaseDateRef}
            type="date"
            min={(isPreRelease || album?.isPreRelease) && new Date(new Date().setDate( new Date().getDate() + 1)).toISOString().split("T")[0]}
            max={album?.isPreRelease && new Date(album?.releaseDate).toISOString().split("T")[0]}
            name={"releaseDateSong " + index}
            value={releaseDate}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.releaseDate && (
            <SongDetailsText error>{errors?.releaseDate}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>About Song</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            ref={aboutRef}
            name={"about " + index}
            value={stringFormatter(about)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.about && (
            <SongDetailsText error>{errors?.about}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>Song Lyrics</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            ref={lyricsRef}
            name={"lyrics " + index}
            value={stringFormatter(lyrics)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.lyrics && (
            <SongDetailsText error>{errors?.lyrics}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
    </SongDetailsSection>
  );
};

export default Page3;
