import React, { useState } from "react";
import { stringFormatter } from "../../../utils";
import CurrencySelector from "../../CurrencySelector";
import {
  ErrorWrapper,
  FormInput,
  FormLabel,
  FormTextArea,
  FormWrapper,
  SelectButton,
  SongDetailsSection,
  SongDetailsSectionTitle,
  SongDetailsText,
} from "../../MusicUploadsForm/MusicUploadsFormElements";
import ToggleButton from "../../ToggleButton";
import { Theme } from "../../WebsiteThemeElements";
import { FormButton1 } from "../../CustomForm/CustomFormElements";
import useMusic from "../../../hooks/useMusic";
import { useAuth } from "../../../contexts/AuthContext";
import { useModal } from "../../Modal/ModalContext";
import OptionModal from "../../OptionModal";
import LoadingScreen, { ModalLoadingScreen } from "../../LoadingScreen";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const Page1 = ({
  errors,
  isCover,
  isDownloadable,
  isExplicit,
  isMintable,
  isStreamable,
  handleInput,
  isUserRoyalties,
  handleToggle,
  currency,
  setCurrency,
  price,
  index,
  about,
  lyrics,
  isrcRef,
  isrcType,
  isrc,
  song
}) => {
  const {themeColors} = useWebsiteTheme()

  const {toggleMintable} = useMusic()
  const {modalContent, openModal, closeModal} = useModal()
  const {currentUser} = useAuth()
  const [isUploaded, setUploaded] = useState(false)


  const uploadToIPFS = async() => {
    openModal()
    modalContent(<OptionModal>
      <ModalLoadingScreen text={'Uploading to IPFS, Please Wait...'} transparent={true}/>
    </OptionModal>)
    await toggleMintable(currentUser?.uid, song?.songId).then(data => {
      if(data){
        setUploaded(true)
        modalContent(<OptionModal heading={'Upload Successful'}>
          <HoverOption onClick={() => closeModal()}>OK</HoverOption>
        </OptionModal>)
      }else{
        modalContent(<OptionModal heading={'Upload Failed'}>
          <HoverOption onClick={() => uploadToIPFS()}>Try Again</HoverOption>
          <HoverOption onClick={() => closeModal()}>OK</HoverOption>
        </OptionModal>)
      }
    })
  }

  return (
    <SongDetailsSection>
        <SongDetailsSectionTitle>Details</SongDetailsSectionTitle>

      <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("explicit");
          }}
        >
          Explicit
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isExplicit}
          toggleClick={() => handleToggle("explicit")}
        />
      </FormWrapper>
      <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("cover");
          }}
        >
          Cover
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isCover}
          toggleClick={() => handleToggle("cover")}
        />
      </FormWrapper>

      <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("streamable");
          }}
        >
          Streamable
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isStreamable}
          toggleClick={() => handleToggle("streamable")}
        />
      </FormWrapper>

      <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("downloadable");
          }}
        >
          Digital Sales
        </FormLabel>
        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isDownloadable}
          toggleClick={() => handleToggle("downloadable")}
        />
      </FormWrapper>
      {isDownloadable && (
        <SongDetailsText background={themeColors.secondaryColor}>
          Song will be available for sale digitally
        </SongDetailsText>
      )}

      {/*  <FormWrapper>
    <FormLabel
      onClick={() => {
        handleToggle("subscription");
      }}
    >
      Subscription Only
    </FormLabel>

    <ToggleButton
    onText={'On'}
    offText={'Off'}  
    toggle={isSubscription}
    toggleClick={() => handleToggle("subscription")}/>
  </FormWrapper> */}
{!song.isUserRoyalties && song?.songId !== "pew9UTungBOaIGCRTsCr" && <><FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("royalties");
                    }}
                  >
                    User Royalty Shares
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isUserRoyalties}
                  toggleClick={() => handleToggle("royalties")}/>
                </FormWrapper>
                {isUserRoyalties && <SongDetailsText background={themeColors.secondaryColor}>Allow your supporters to invest and earn from your music sales and royalty streams.</SongDetailsText>}
                    <SongDetailsText background={themeColors.secondaryColor}>Once enabled you cannot disable this feature.</SongDetailsText>
 </>}
      <FormWrapper>
        <FormLabel
          onClick={() => {
            handleToggle("mintable");
          }}
        >
          Mintable
        </FormLabel>

        <ToggleButton
          onText={"On"}
          offText={"Off"}
          toggle={isMintable}
          toggleClick={() => handleToggle("mintable")}
        />
      </FormWrapper>
      {isMintable && !isUploaded && (song?.isMintable && (!song?.ipfs?.albumArt && !song?.ipfs?.song)) &&
       <FormWrapper> 
        <FormLabel>Something went wrong uploading to IPFS</FormLabel>
        <FormButton1 onClick={uploadToIPFS}>Upload Song to IPFS</FormButton1>
       </FormWrapper>
      }

      <br />
      {song?.isrc?.trim()?.length > 0 && 
      <FormWrapper>
      <FormLabel>
        ISRC Code
      </FormLabel>
      <ErrorWrapper>
         <FormInput
          ref={isrcRef}
          readOnly={true}
          type={"text"}
          name={"isrc song"}
          value={song?.isrc}
        />
      </ErrorWrapper>
    </FormWrapper>
      }
      {(isStreamable || isDownloadable) && song?.isrc?.trim()?.length === 0 && (
        <FormWrapper>
          <FormLabel>
            ISRC Code
            <SelectButton
              name={"isrcType"}
              value={isrcType}
              onChange={handleInput}
            >
              <option value={"auto"}>Auto generate</option>
              <option value={"custom"}>Custom</option>
            </SelectButton>{" "}
          </FormLabel>
          <ErrorWrapper>
             <FormInput
              ref={isrcRef}
              readOnly={isrcType === "auto"}
              type={"text"}
              name={"isrc song"}
              placeholder={"Enter your unique ISRC code for the song"}
              value={isrc}
              onContextMenu={(e) =>
                isrcType === "auto" ? e.preventDefault() : {}
              }
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.isrc && (
              <SongDetailsText error>{errors?.isrc}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      )}
      <FormWrapper>
        <FormLabel>About Song</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            name={"about song"}
            value={stringFormatter(about)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.about && (
            <SongDetailsText error>{errors?.about}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      <FormWrapper>
        <FormLabel>Song Lyrics</FormLabel>
        <ErrorWrapper>
          <FormTextArea
            name={"lyrics song"}
            value={stringFormatter(lyrics)}
            onChange={(e) => {
              handleInput(e);
            }}
          />
          {errors?.lyrics && (
            <SongDetailsText error>{errors?.lyrics}</SongDetailsText>
          )}
        </ErrorWrapper>
      </FormWrapper>
      {isDownloadable === true && (
        <FormWrapper>
          <FormLabel>
            Price{" "}
            <CurrencySelector currency={currency} setCurrency={setCurrency} />
          </FormLabel>
          <ErrorWrapper>
            <FormInput
              style={{ flex: "1 1 50%" }}
              name={"price song"}
              placeholder={`Enter price of song in ${currency}`}
              value={price}
              onChange={(e) => {
                handleInput(e);
              }}
            />
            {errors?.price && (
              <SongDetailsText error>{errors?.price}</SongDetailsText>
            )}
          </ErrorWrapper>
        </FormWrapper>
      )}
    </SongDetailsSection>
  );
};

export default Page1;
