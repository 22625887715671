import React from 'react'
import ContentTable from '..'
import ScrollToTop from '../../ScrollToTop';
import { downloadFile } from '../../../utils';
import { ContentTableH2 } from '../ContentTableElements';

export const SinglesReleasesTable = ({releases, heading}) => {
  const {goTo} = ScrollToTop()
    const columns = [
      {id: 'albumArt', minWidth: 50 },
        { id: 'songName', label: 'Title', minWidth: 200 },
        {
          id: 'displayDate',
          label: 'Release Date',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'plays',
          label: 'Plays',
          minWidth: 170,
          align: 'left',
        },
    
      ];
  return (
    <><ContentTableH2>{heading}</ContentTableH2><ContentTable columns={columns} rows={releases} onCellClick={(index) => {
      goTo(`/song/${releases[index].songId}`)}}/></>
  )
}

export const VideosReleasesTable = ({releases, heading}) => {
  const {goTo} = ScrollToTop()
    const columns = [
      {id: 'thumbnail', minWidth: 50 },
        { id: 'title', label: 'Title', minWidth: 200 },
        {
          id: 'displayDate',
          label: 'Release Date',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'plays',
          label: 'Plays',
          minWidth: 170,
          align: 'left',
        },
    
      ];
  return (
    <><ContentTableH2>{heading}</ContentTableH2><ContentTable columns={columns} rows={releases} onCellClick={(index) => {
      goTo(`/video/${releases[index].videoId}`)}}/></>
  )
}

export const AlbumsReleasesTable = ({releases, heading}) => {
  const {goTo} = ScrollToTop()
    const columns = [
      {id: 'albumArt', minWidth: 50 },
        { id: 'albumName', label: 'Title', minWidth: 200 },
        {
          id: 'displayDate',
          label: 'Release Date',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'plays',
          label: 'Plays',
          minWidth: 170,
          align: 'left',
        },
    
      ];
  return (
    <><ContentTableH2>{heading}</ContentTableH2>
    <ContentTable columns={columns} rows={releases} onCellClick={(index) => {
      console.log(index)
      console.log(releases[index])
      goTo(`/album/${releases[index].albumId}`)
      }}/>
    </>
  )
}

export function TopPlayedTable({topSongs, heading}){
    const columns = [
      {id: 'albumArt', minWidth: 50 },
        { id: 'songName', label: 'Title', minWidth: 200 },
        { id: 'displayDate', label: 'Release Date', minWidth: 170 },
        {
          id: 'plays',
          label: 'Plays',
          minWidth: 170,
          align: 'left',
        },
        
      ];
    return(
      
        <><ContentTableH2>Top Played Music</ContentTableH2><ContentTable columns={columns} rows={topSongs}/></>
    )
}

export function PlaylistTable({playlists, heading}){
  const columns = [
    {id: 'playlistArt', minWidth: 50 },
      { id: 'playlistName', label: 'Title', minWidth: 200 },
      {
        id: 'datePublished',
        label: 'Release Date',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      /* {
        id: 'songs',
        label: 'Songs',
        minWidth: 170,
        align: 'left',
        format: (value) => value?.length
      }, */
  
    ];
return (
  <><ContentTableH2>{heading}</ContentTableH2>
  <ContentTable columns={columns} rows={playlists.map(p => {
    return {
      ...p,
      datePublished: p?.datePublished?.split('T')[0]
    }
  })}/>
  </>
)
}

export function CollectionsReleasesTable({collections, heading}){
  const {goTo} = ScrollToTop()
  const columns = [
    {id: 'albumArt', minWidth: 50 },
      { id: 'name', label: 'Title', minWidth: 200 },
      { id: 'taxon', label: 'Taxon (collection ID)', minWidth: 170 },
      {
        id: 'collectionType',
        label: 'Type',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'dateCreated',
        label: 'Mint Date',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'transferFee',
        label: 'Transfer Fee (%)',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      /* {
        id: 'songs',
        label: 'Songs',
        minWidth: 170,
        align: 'left',
        format: (value) => value?.length
      }, */
  
    ];
return (
  <><ContentTableH2>{heading}</ContentTableH2>
  <ContentTable columns={columns} rows={collections} onCellClick={(index) => goTo(`/collection/${collections[index]?.collectionId}`)}/>
  </>
)
}

export function RoyaltiesTable({royalties, heading}){
  const {goTo} = ScrollToTop()
  const columns = [
    {id: 'albumArt', minWidth: 50 },
      { id: 'ticker', label: 'Ticker', minWidth: 200 },
      {
        id: 'type',
        label: 'Type',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'createdAt',
        label: 'Release Date',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'nextPayout',
        label: 'Next Payout',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'percentage',
        label: 'Royalty Percentage Split (%)',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
      /* {
        id: 'songs',
        label: 'Songs',
        minWidth: 170,
        align: 'left',
        format: (value) => value?.length
      }, */
  
    ];
return (
  <><ContentTableH2>{heading}</ContentTableH2>
  <ContentTable columns={columns} rows={royalties} onCellClick={(index) => goTo(`/regium/${royalties[index]?.royaltyId}`)}/>
  </>
)
}

 