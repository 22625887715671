import React from 'react'
import { CONTENT_TYPES } from '../../../../utils/Enum';
import Chip from '../../../Chip';
import { ChipList } from '../../../Chip/ChipElements';
import ContentList from '../../../ContentList';
import CurrencySelector from '../../../CurrencySelector';
import ToggleButton from '../../../ToggleButton';
import { Theme } from '../../../WebsiteThemeElements';
import { ErrorWrapper, FormInput, FormWrapper, ListContainer, AlbumDetailsSection, AlbumDetailsSectionTitle, SongDetailsText, FormLabel } from '../../../MusicUploadsForm/MusicUploadsFormElements';
import { MusicGenreSearchModal } from '../../../SearchModal';
import { useModal } from '../../../Modal/ModalContext';
import { useAuth } from '../../../../contexts/AuthContext';
import { useWebsiteTheme } from '../../../WebsiteTheme/WebsiteThemeContext';

const Page2 = ({
    albumInfo,
    genre,
    handleToggle,
    handleInput,
    setCurrency,
    removeGenre,
    filteredGenres,
    addGenre,
}) => {
  const {currentUser} = useAuth()
  const {openModal, modalContent} = useModal()
  const {themeColors} = useWebsiteTheme()
  return (
    <>
              <AlbumDetailsSection>
              <AlbumDetailsSectionTitle>Album Details</AlbumDetailsSectionTitle>

              <FormWrapper>
                <FormLabel
                  onClick={() => {
                    /* handleToggle("streamable") */;
                  }}
                >
                  Streamable
                </FormLabel>
                <ToggleButton
                  onText={"On"}
                  offText={"Off"}
                  toggle={albumInfo?.isStreamable}
                  toggleClick={() => {/* handleToggle("streamable") */}}
                />
              </FormWrapper>

              <FormWrapper>
                <FormLabel
                  onClick={() => {
                    handleToggle("downloadable");
                  }}
                >
                  Digital Sales
                </FormLabel>
                <ToggleButton
                  onText={"On"}
                  offText={"Off"}
                  toggle={albumInfo?.isDownloadable}
                  toggleClick={() => handleToggle("downloadable")}
                />
              </FormWrapper>
              {albumInfo?.isDownloadable && (
                <SongDetailsText background={Theme({}).secondaryColor}>
                  Album will be available for sale digitally
                </SongDetailsText>
              )}

              {/*  <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("subscription");
                    }}
                  >
                    Subscription Only
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={isSubscription}
                  toggleClick={() => handleToggle("subscription")}/>
                </FormWrapper> */}
                   <>  <FormWrapper>
                  <FormLabel
                    onClick={() => {
                      handleToggle("royalties");
                    }}
                  >
                    User Royalty Shares
                  </FormLabel>

                  <ToggleButton
                  onText={'On'}
                  offText={'Off'}  
                  toggle={albumInfo?.isUserRoyalties}
                  toggleClick={() => handleToggle("royalties")}/>
                </FormWrapper>
                {albumInfo?.isUserRoyalties && 
                  <><SongDetailsText background={themeColors.secondaryColor}>Allow your supporters to invest and earn from your music sales and royalty streams. See Page 7 </SongDetailsText>
 <SongDetailsText background={themeColors.secondaryColor}>Once enabled you cannot disable this feature.</SongDetailsText></> }</>
              <FormWrapper>
                <FormLabel
                  onClick={() => {
                    handleToggle("mintable");
                  }}
                >
                  Mintable
                </FormLabel>

                <ToggleButton
                  onText={"On"}
                  offText={"Off"}
                  toggle={albumInfo?.isMintable}
                  toggleClick={() => handleToggle("mintable")}
                />
              </FormWrapper>

              <br />
              <FormWrapper>
                <FormLabel>Album Title</FormLabel>
                <FormInput
                  name={"title"}
                  ref={albumInfo?.albumNameRef}
                  value={albumInfo?.albumName}
                  placeholder={"Album Title"}
                  onChange={handleInput}
                />
              </FormWrapper>
              {albumInfo?.isDownloadable === true && (
                <FormWrapper>
                  <FormLabel>
                    Price <CurrencySelector currency={!albumInfo?.isUserRoyalties ? albumInfo?.currency : "XRP"} setCurrency={!albumInfo?.isUserRoyalties ? setCurrency : () => setCurrency("XRP")} />
                  </FormLabel>
                  <ErrorWrapper>
                    <FormInput
                      style={{ flex: "1 1 50%" }}
                      name={"album price"}
                      placeholder={`Enter price of album in ${albumInfo?.currency}`}
                      value={albumInfo?.price}
                      onChange={(e) => {
                        handleInput(e);
                      }}
                    />
                    {albumInfo?.errors?.price && (
                      <SongDetailsText error>
                        {albumInfo?.errors?.price}
                      </SongDetailsText>
                    )}
                  </ErrorWrapper>
                </FormWrapper>
              )}

                <FormWrapper>
                  <FormLabel>Genre</FormLabel>
                  <ErrorWrapper>
                    <FormInput
                    type={"search"}
                    placeholder={"Select Song Main Genre"}
                    name="album genre"
                    readOnly
                    onClick={() => {
                      openModal()
                      modalContent(<MusicGenreSearchModal type={'Genre'} onClick={addGenre}/>)
                    }}
                    value={genre}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                  {albumInfo?.errors?.genre && <SongDetailsText error>{albumInfo?.errors?.genre}</SongDetailsText>}
                  </ErrorWrapper>
                </FormWrapper>
                <FormWrapper>
                  <ChipList>
                    {albumInfo?.mainGenre && (
                      <Chip
                        label={albumInfo?.mainGenre?.name}
                        closeButton
                        onDelete={() => removeGenre()}
                        background={Theme({}).mainColor}
                      />
                    )}
                  </ChipList>
                </FormWrapper>
                
                {filteredGenres && genre?.trim?.length > 0 && (
                  <FormWrapper>
                    <ListContainer>
                      <ContentList
                        type={CONTENT_TYPES.GENRE}
                        content={filteredGenres}
                        onButtonClick={addGenre}
                      />
                    </ListContainer>
                  </FormWrapper>
                )}
                </AlbumDetailsSection>
              {/*   <AlbumDetailsSection>
              <AlbumDetailsSectionTitle>Album Credits</AlbumDetailsSectionTitle>
              <FormWrapper>
                <FormLabel>Written by</FormLabel>
              </FormWrapper>
              <FormWrapper>
                <FormLabel>Produced by</FormLabel>
              </FormWrapper>
              <FormWrapper>
                <FormLabel>Composed by</FormLabel>
              </FormWrapper>
            </AlbumDetailsSection> */}
                </>
  )
}

export default Page2