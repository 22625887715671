import React, { useEffect, useRef, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useSonarMusePlayer } from '../../hooks/AudioHook'
import { LyricLine } from './LyricSynchronizerElements'
import { MusicPlayPauseButton } from '../MusicPage/MusicPageElements'
import { ButtonWrapper } from '../Profile/ProfileDetails/ProfileDetailsElements'
import { addSyncLyrics } from '../../utils/dbUtils/musicUtils'
import { useAlert } from '../Alert/AlertContext'
import { ALERT_TYPES } from '../../utils/Enum'




const LyricSynchronizer = ({song}) => {
    const {timeJump,
        currentSong,
        isPlaying, 
        duration, 
        currentTime, 
        progressBar, 
        audioPlayer,
        changeRange,
        stopMainPlayer,
        volume,
        isRepeat,
        calcTime, 
        backThirty, 
        forwardThirty,
        isPodcast,
        isShuffle,
        nextSong,
        handleToggle,
        prevSong, 
        playlist,
        setPlaylist,
        togglePlayPause,
        toggleMute,
        toggleVolume,
        isOpen,
        timeTravel,
        handleEnd,
        isMute} = useSonarMusePlayer()
    const {addAlert} = useAlert()
    const [syncedLyrics, setSyncedLyrics] = useState([])
    const [currentLine, setCurrentLine] = useState()
    const currentLineRef = useRef(null);
    const buttonsRef = useRef(null);
    const linesRef = useRef(null);
    useEffect(() => {
        if (currentLineRef.current) {
          currentLineRef.current.scrollIntoView({
            behavior: "smooth",
            alignItems: "center",
            block:"center"
          });
        }
      
      /*    */
      }, [currentLine]);
      
      useEffect(() => {
        setPlaylist([song])
        return () => {
          
        }
      }, [song])


      
  return (
    <>
     

    <ContentWrapper>
    <ButtonWrapper style={{width: '100%'}}>{<MusicPlayPauseButton onClick={() => {
            setPlaylist([song])
            togglePlayPause()}}>{isPlaying ? 'Pause' : currentLine >= 0 ? 'Resume' : 'Start Sync' }</MusicPlayPauseButton>}
    {(isPlaying || currentLine >= 0) && <MusicPlayPauseButton transparent onClick={() => {
            setPlaylist([song])
            timeTravel(0)
            setSyncedLyrics([])
            setCurrentLine()
            !isPlaying && togglePlayPause()}}>Start Over</MusicPlayPauseButton>}
        {syncedLyrics?.length > 0 && <MusicPlayPauseButton onClick={async () => {
            addAlert({
                title: 'Lyrics Synchronizer',
                message: 'Synchronizing Lyrics',
                type: ALERT_TYPES.INFO
            })
            console.log(syncedLyrics)
            await addSyncLyrics(song?.songId, syncedLyrics).then((data) => {
                if(data){
                    return addAlert({
                        title: 'Lyrics Synchronizer',
                        message: 'Lyrics successfully synced',
                        type: ALERT_TYPES.SUCCESS
                    })
            }
            return addAlert({
                title: 'Lyrics Synchronizer Error',
                message: 'An error occurred. Try again',
                type: ALERT_TYPES.DANGER
            })
        })
        }}>Submit Synced Lyrics</MusicPlayPauseButton>}    
            </ButtonWrapper>
    <div ref={buttonsRef} style={{display: 'flex', width: '100%', justifyContent: 'space-between', position: '-webkit-sticky', zIndex: '120' }}>
            <HoverOption
            onClick={() => {
                if(syncedLyrics?.length > 0){
                    const lines = syncedLyrics.filter((l, index) => index !== currentLine)
                timeTravel(syncedLyrics[currentLine]?.startTime)
                setSyncedLyrics(lines)
                setCurrentLine(currentLine - 1 >= 0 ? currentLine - 1 : null )
                isPlaying && togglePlayPause()
            }
            }}
        >{'Prev Line '}<BsChevronUp/> 
        </HoverOption>
     
        

        <audio ref={audioPlayer} src={playlist[currentSong]?.mp3Url ? playlist[currentSong]?.mp3Url : playlist[currentSong]?.url} preload={"metadata"} onEnded={handleEnd}/>
        <div ref={linesRef} style={{ display: 'flex', flexDirection: 'column', flex: '1 1 100%', maxHeight: '60vh' , overflowY: 'auto', maxWidth: '50vh', alignItems: 'center'}}>
       
        {song?.lyrics.split('\n')?.map( (line, index) => {
            const isCurrentLine = index === currentLine
        return (
          <><LyricLine 
          ref={isCurrentLine ? currentLineRef : null}
          isActive={isCurrentLine}
          key={index}>
            {line}
          </LyricLine>
          <br/></>
        );
      })}
      </div>
      <HoverOption onClick={() => {
            if(isPlaying && currentLine !== song?.lyrics.split('\n').length - 1 ){
                let lines = syncedLyrics.length
            setSyncedLyrics(prev => {
                return [...prev, {
                    line: song?.lyrics.split('\n')[lines],
                    startTime: audioPlayer?.current?.currentTime,
                }]
            })
            setCurrentLine(lines)
        }else{
            if(!isPlaying){ 
                currentLine > 0 && timeTravel(syncedLyrics[currentLine]?.startTime)
            togglePlayPause()
        }
        }
        }}>{'Next Line '}<BsChevronDown/></HoverOption></div>
    </ContentWrapper>
    
    
    </>
  )
}

export default LyricSynchronizer