import React from 'react'
import CreativeCommonsSelector from '../../../CreativeCommonsSelector';
import { AlbumDetailsSection, AlbumDetailsSectionTitle } from '../../../MusicUploadsForm/MusicUploadsFormElements';

const Page5 = ({ license, setCreativeCommons }) => {
    return (
      <AlbumDetailsSection>
        <AlbumDetailsSectionTitle>Licensing</AlbumDetailsSectionTitle>
        <CreativeCommonsSelector
          creativeCommons={license}
          setCreativeCommons={setCreativeCommons}
        />
      </AlbumDetailsSection>
    );
  };

export default Page5