import React, { useEffect, useRef, useState } from "react";
import Page1 from "./Page1";
import { FormWrapper } from "../../../CustomForm/CustomFormElements";
import ContentWrapper from "../../../ContentWrapper";
import { DraggableSongList } from "../../../ContentList/SongList";
import {
  containsObject,
  getObjectLength,
  getUniqueListBy,
  newlineFormatter,
} from "../../../../utils";
import {
  getDownloadUrl,
  storage,
  storageRef,
  uploadBytes,
} from "../../../Authorization/firebase";
import useMusic from "../../../../hooks/useMusic";
import { Theme } from "../../../WebsiteThemeElements";
import { hexToRgba } from "../../../HexToRGBA";
import {
  CloseButton,
  MusicUploadContentTitle,
  MusicUploadDetailsContainer,
  MusicUploadInfoText,
  SongDetailsText,
  SongInfoContainer,
  SongInfoTextWrapper,
  SongInfoWrapper,
} from "../../../MusicUploadsForm/MusicUploadsFormElements";
import {
  ALERT_TYPES,
  ARTIST_FORM_INPUT_TYPES,
  ARTIST_FORM_TYPES,
  CONTENT_TYPES,
  TRANSACTION_OPTIONS,
} from "../../../../utils/Enum";
import { useAuth } from "../../../../contexts/AuthContext";
import { useAlert } from "../../../Alert/AlertContext";
import SongDetails from "../../../MusicUploadsForm/AlbumUploads/SongDetails";
import { useModal } from "../../../Modal/ModalContext";
import { HoverOption } from "../../../MenuOptions/MenuOptionsElements";
import OptionModal from "../../../OptionModal";
import { deleteObject } from "firebase/storage";
import { Nav, NavContainer, NavLink } from "../../../NavLink/NavLinkElements";
import QRCodeForm from "../../../QRCodeForm";
import { Button1 } from "../../../ButtonElement";
import { useLocation, useNavigate } from "react-router-dom";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import LoadingScreen from "../../../LoadingScreen";
import ImageUtils from "../../../../utils/ImageUtils";
import ScrollToTop from "../../../ScrollToTop";
import useRecordLabel from "../../../../hooks/useRecordLabel";
import useArtist from "../../../../hooks/useArtist";
import NFTStorageTokenForm from "../../../MusicUploadsForm/NFTStorageTokenForm";
import NFTStorageSonarAPIKeyForm from "../../../NFTStorageSonarAPIKeyForm";
import { useWebsiteTheme } from "../../../WebsiteTheme/WebsiteThemeContext";
import Page6 from "./Page6";
import { Small } from "../../../QRCodeForm/QrCodeFormElements";

const AlbumPreReleaseEdit = ({ album, submitChanges, immediateRelease }) => {
  const {themeColors} = useWebsiteTheme()
  const { currentUser, currentProfile } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const { getRecordLabelArtists } = useRecordLabel({});
  const location = useLocation();
  const { addAlert } = useAlert();
  const { uploadAlbum, searchGenres, deleteDraft } = useMusic();
  const {
    modalContent,
    isOpen,
    toggleModal,
    noCloseModal,
    closeModal,
    openModal,
  } = useModal();

  const { defaultAlbumArt } = ImageUtils();
  const { goTo } = ScrollToTop();
  const navigate = useNavigate();
  const genreRef = useRef();
  const aboutRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");
  const [currentArtist, setCurrentArtist] = useState({});
  const [artistSearch, setArtistSearch] = useState();
  const [artistEditing, setArtistEditing] = useState(false);
  const [filteredGenres, setGenreFilter] = useState([]);
  const [genre, setGenre] = useState("");
  const [submitting, setSubmit] = useState(false);
  const [userRoyalties, setUserRoyalties] = useState(album?.userRoyalties ? album?.userRoyalties : {
    percentage: 10,
    shares: 100,
    price: 1,
    shareReserve: 0,
    ticker: "",
    currency: "XRP",
  })
  const [songs, setSongs] = useState(album?.songs);
  const [newSongs, setNewSongs] = useState([]);
  const [openSongs, setOpenSongs] = useState([]);
  const [currentTab, setTab] = useState("general");
  const [artists, setArtists] = useState(
    album?.artists.map((a) => {
      return {
        ...a,
        photoUrl:
          a?.artistSettings?.profilePictureType === "custom"
            ? a?.profilePicture
            : a?.avatar_url,
        exists: true,
      };
    })
  );
  const [cancelAlbumArtUpload, setCancelAlbumArtUpload] = useState(false);
  const [albumInfo, setAlbumInfo] = useState({
    ...album,
    releaseDate: new Date(album?.displayDate).toISOString().split("T")[0],
    userArtist: {
      ...album?.artists[0],
      photoUrl:
        album?.artists[0]?.artistSettings?.profilePictureType === "custom"
          ? album?.artists[0]?.profilePicture
          : album?.artists[0]?.avatar_url,
      exists: true,
    },
    license: album?.licensing,
    mainGenre: {
      ...album?.genre,
      id: album?.mainGenre?.genreId
    },
    albumArt: {
      ref: album?.albumArtRef,
      url: album?.albumArt,
      name: album?.albumArtRef.split("/")[
        album?.albumArtRef.split("/").length - 1
      ],
      file: {
        name: album?.albumArtRef.split("/")[
          album?.albumArtRef.split("/").length - 1
        ],
      },
    },
  });
  /*    useEffect(() => {
      
        setMainArtist();
    
      return () => {}
    }, []);

    const setMainArtist = async () => {
      if(currentProfile === 'label'){
       if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) === 0 && !info?.userArtist){
        openModal()
        noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
        return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
          console.log(labelArtists)
          if(labelArtists?.length > 0){
            noCloseModal(<OptionModal onCancel={() => {
              toggleModal()
                modalContent()
                navigate(-1)
            }} heading={'Select Artist'}>
              {labelArtists?.map((artist) => {
                return (
                  <HoverOption onClick={() => {
                    setAlbumInfo((prev) => {
                      return {
                        ...prev,
                        artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                        userArtist: {
                          ...artist,
                          exists: true,
                        },
                      };
                    });
                    setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
                  });
                    setLoaded(true)
                  }}>{artist?.artistName}</HoverOption>
                )
              })}
            </OptionModal>)
          }else{
            noCloseModal(<OptionModal onCancel={() => {
              toggleModal()
                modalContent()
                navigate(-1)
            }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
              <HoverOption onClick={() => {
                toggleModal()
                modalContent()
                navigate(-1)}}>OK</HoverOption>
            </OptionModal>)
          }
        }).catch(err => {
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Something Went Wrong'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
              <HoverOption onClick={() => {
                setMainArtist()
              }}>Try Again</HoverOption>
          </OptionModal>)
        })
        
       
      }else{
        if (info?.userArtist){
          if(artists?.length > 0 &&
          !containsObject(artists, { ...info?.userArtist, exists: true })
        ) {
          setAlbumInfo((prev) => {
            return {
              ...prev,
              artists: getUniqueListBy([{ ...info?.userArtist, exists: true }], "exists"),
              userArtist: {
                ...info?.userArtist,
                exists: true,
              },
            };
          });
          setArtists((prev) => {
          return getUniqueListBy([{ ...info?.userArtist, exists: true }, ...prev], "exists");
        });
        }
      }
        else{
          if(artists?.length > 0 && getObjectLength(albumInfo?.userArtist) > 0 &&
            !containsObject(artists, { ...albumInfo?.userArtist, exists: true })
          ) {
            setAlbumInfo((prev) => {
              return {
                ...prev,
                artists: getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }], "exists"),
                userArtist: {
                  ...albumInfo?.userArtist,
                  exists: true,
                },
              };
            });
            setArtists((prev) => {
            return getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }, ...prev], "exists");
          });
          }
          if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) > 0){
            setAlbumInfo((prev) => {
              return {
                ...prev,
                artists: getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }], "exists"),
                userArtist: {
                  ...albumInfo?.userArtist,
                  exists: true,
                },
              };
            });
            setArtists((prev) => {
            return getUniqueListBy([{ ...albumInfo?.userArtist, exists: true }, ...prev], "exists");
          });
          }
          if(artists?.length === 0 && getObjectLength(albumInfo?.userArtist) === 0){
            openModal()
        noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
        return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
          console.log(labelArtists)
          if(labelArtists?.length > 0){
            noCloseModal(<OptionModal onCancel={() => {
              toggleModal()
                modalContent()
                navigate(-1)
            }} heading={'Select Artist'}>
              {labelArtists?.map((artist) => {
                return (
                  <HoverOption onClick={() => {
                    setAlbumInfo((prev) => {
                      return {
                        ...prev,
                        artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                        userArtist: {
                          ...artist,
                          exists: true,
                        },
                      };
                    });
                    setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
                  });
                    setLoaded(true)
                  }}>{artist?.artistName}</HoverOption>
                )
              })}
            </OptionModal>)
          }else{
            noCloseModal(<OptionModal onCancel={() => {
              toggleModal()
                modalContent()
                navigate(-1)
            }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
              <HoverOption onClick={() => {
                toggleModal()
                modalContent()
                navigate(-1)}}>OK</HoverOption>
            </OptionModal>)
          }
        }).catch(err => {
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Something Went Wrong'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
              <HoverOption onClick={() => {
                setMainArtist()
              }}>Try Again</HoverOption>
          </OptionModal>)
        })
          }
        }
      }
      setLoaded(true);
    }else{
      return await searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, currentUser?.uid).then(
        (artist) => {
          setLoaded(true);
          if (artists?.length === 0) {
            //console.log('doing this')
            setAlbumInfo((prev) => {
              return {
                ...prev,
                artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                userArtist: {
                  ...artist,
                  exists: true,
                },
              };
            });
            
            setArtists(getUniqueListBy([{ ...artist, exists: true }], "exists"));
            return
          }
          if (
            artists?.length > 0 &&
            containsObject(artists, { ...artist, exists: true })
          ) {
            setAlbumInfo((prev) => {
              return {
                ...prev,
                artists: getUniqueListBy([{ ...artist, exists: true }], "exists"),
                userArtist: {
                  ...artist,
                  exists: true,
                },
              };
            });
            setArtists((prev) => {
            return getUniqueListBy([{ ...artist, exists: true }, ...prev], "exists");
          });
          return
          }
          
        }
        
      );
      }
      
    }; */

  const validateAlbumInfo = () => {
    let albumErrors = {};
    if (!albumInfo?.mainGenre || getObjectLength(albumInfo?.mainGenre) === 0) {
      albumErrors = {
        ...albumErrors,
        genre: "Select album genre",
      };
    }
    if (artists?.length === 0) {
      albumErrors = {
        ...albumErrors,
        genre: "Select album artist",
      };
    }
    if (
      (albumInfo?.isPreRelease &&
        (new Date(albumInfo?.releaseDate).getTime() <= new Date()?.getTime() ||
          !albumInfo?.releaseDate)) ||
      (!albumInfo?.isPreRelease &&
        new Date(albumInfo?.releaseDate).getTime() > new Date()?.getTime())
    ) {
      albumErrors = {
        ...albumErrors,
        releaseDate: "Select a valid release date",
      };
    }
    if (albumInfo?.albumName?.trim() === "") {
      albumErrors = {
        ...albumErrors,
        albumName: "Cannot upload album without a title",
      };
    }
    if (
      albumInfo?.visualizerFile?.progress > 0 &&
      albumInfo?.visualizerFile?.progress < 100
    ) {
      albumErrors = {
        ...albumErrors,
        visuals: "Album visualizer still uploading...",
      };
    }
    if (
      !albumInfo?.albumArt ||
      getObjectLength(albumInfo?.albumArt) === 0 ||
      !albumInfo?.albumArt?.url
    ) {
      albumErrors = {
        ...albumErrors,
        albumArt: "Album artwork required",
      };
    }

    if (albumInfo?.isUserRoyalties && getObjectLength(userRoyalties) > 0) {
      let royaltyErrors = {};
      if (userRoyalties?.ticker.trim().length < 3) {
        royaltyErrors = {
          ...royaltyErrors,
          ticker: "Invalid Ticker. Please enter a valid tick. min 3 characters",
        };
      }
      if (userRoyalties.percentage < 10 || userRoyalties.percentage > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          percentage:
            "Invalid royalty share percentage. please input value between 10% - 50%",
        };
      }
      if (userRoyalties.shareReserve < 0 || userRoyalties.shareReserve > 50) {
        royaltyErrors = {
          ...royaltyErrors,
          reserve:
            "Invalid royalty share reserve. Please enter a valid reserve amount of 50 or less",
        };
      }
      if (getObjectLength(royaltyErrors) > 0) {
        albumErrors = {
          ...albumErrors,
          userRoyalty: royaltyErrors,
        };
      }
    }


    if (getObjectLength(albumErrors) > 0) {
      setAlbumInfo((prev) => {
        return {
          ...prev,
          errors: albumErrors,
        };
      });
      addAlert({
        title: "Upload Error",
        type: ALERT_TYPES.WARNING,
        message: "Unable to upload album. Please update album information",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleToggle = (type) => {
    switch (type) {
      case "explicit":
        let explicit = !albumInfo?.isExplicit;
        setAlbumInfo((prev) => {
          return { ...prev, isExplicit: explicit };
        });
        break;
      case "streamable":
        let streamable = !albumInfo?.isStreamable;
        if (streamable) {
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isStreamable: streamable };
        });
        break;

      case "schedule":
        let preRelease = !albumInfo?.isPreRelease;
        setAlbumInfo((prev) => {
          return { ...prev, isPreRelease: preRelease };
        });
        break;

      case "downloadable":
        let downloadable = !albumInfo?.isDownloadable;

        if (downloadable) {
          setAlbumInfo((prev) => {
            return { ...prev, isSubscription: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isDownloadable: downloadable };
        });
        break;
      case "visualizer":
        let visualizer = !albumInfo?.isVisualizer;
        setAlbumInfo((prev) => {
          return { ...prev, isVisualizer: visualizer };
        });

        break;
      case "subscription":
        let subscription = !albumInfo?.isSubscription;

        if (subscription) {
          setAlbumInfo((prev) => {
            return { ...prev, isDownloadable: false, isStreamable: false };
          });
        }
        setAlbumInfo((prev) => {
          return { ...prev, isSubscription: subscription };
        });
        break;
      case "mintable":
        let mintable = !albumInfo?.isMintable;
        if (mintable) {
          toggleModal();
          modalContent(
            <OptionModal
              onCancel={() => {
                toggleModal();
                modalContent();
              }}
              heading={"Set Mintable?"}
            >
              <SongDetailsText>{`By clicking 'OK', you agree to upload your music \n 
        on IPFS and allow for it to be minted as an NFT on our platform.`}</SongDetailsText>
              <HoverOption
                onClick={async () => {
                  /*setAlbumInfo((prev) => {
                    return { ...prev, isMintable: true };
                })
                 toggleModal()
                modalContent() */
                  //modalContent(< UnavailableFeature/>)
                  modalContent(
                    <>
                      <LoadingScreen transparent={true} />
                    </>
                  );
                  await validateNFTStorageKey(albumInfo?.userArtist?.artistId)
                    .then((data) => {
                      if (data) {
                        setAlbumInfo((prev) => {
                          return { ...prev, isMintable: true };
                        });
                        toggleModal();
                        modalContent();
                      } else {
                        modalContent(
                          <>
                            <OptionModal heading={"IPFS API Key Not Found"}>
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <>
                                      <NFTStorageTokenForm
                                        artistId={
                                          albumInfo?.userArtist?.artistId
                                        }
                                        currentUser={currentUser?.uid}
                                        setMintable={(value) => {
                                          setAlbumInfo((prev) => {
                                            return {
                                              ...prev,
                                              isMintable: value,
                                            };
                                          });
                                        }}
                                      />
                                    </>
                                  );
                                }}
                              >
                                Add API Key
                              </HoverOption>
                              <HoverOption
                                onClick={() => {
                                  modalContent(
                                    <NFTStorageSonarAPIKeyForm
                                      artistId={albumInfo?.userArtist?.artistId}
                                      currentUser={currentUser?.uid}
                                      onSuccess={() => {
                                        setAlbumInfo((prev) => {
                                          return { ...prev, isMintable: true };
                                        });
                                      }}
                                    />
                                  );
                                }}
                              >
                                {" "}
                                Use Sonar Muse API Key
                              </HoverOption>
                              <HoverOption
                                onClick={() => {
                                  toggleModal();
                                  modalContent();
                                }}
                              >
                                Cancel
                              </HoverOption>
                            </OptionModal>
                          </>
                        );
                      }
                    })
                    .catch((err) => {
                      setAlbumInfo((prev) => {
                        return { ...prev, isMintable: false };
                      });
                      modalContent(
                        <>
                          <OptionModal
                            heading={"Something Went Wrong. Please try again"}
                          >
                            <HoverOption
                              onClick={() => {
                                toggleModal();
                                modalContent();
                              }}
                            >
                              Close
                            </HoverOption>
                          </OptionModal>
                        </>
                      );
                    });
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        } else {
          setAlbumInfo((prev) => {
            return { ...prev, isMintable: false };
          });
        }

        break;


        case "royalties":
          let royalties = !albumInfo?.isUserRoyalties;
          if (albumInfo?.isUserRoyalties === false) {
            openModal();
            modalContent(
              <OptionModal
                onCancel={() => {
                  closeModal();
                  modalContent();
                }}
                heading={"Enable User Royalties Share"}
                additionalText={
                  "Allow your supporters to invest and earn from your music sales and royalty streams."
                }
              >
                <Small>Note: Once enabled, it cannot be disabled.</Small>
                <HoverOption
                  onClick={() => {
                    if (royalties) {
                      setAlbumInfo(prev => {
                        return {
                          ...prev,
                          isUserRoyalties: true,
                          isSubscription: false,
                          isStreamable: true
                        }
                      })
                    }
                   
                    closeModal();
                    modalContent();
                  }}
                >
                  OK
                </HoverOption>
              </OptionModal>
            );
          } else {
            setAlbumInfo(prev => {
              return {
                ...prev,
                isUserRoyalties: false
              }
            })
          }
          break;
      default:
        break;
    }
  };

  const setCurrency = (currency) => {
    setAlbumInfo((prev) => {
      return { ...prev, currency: currency };
    });
  };

  const selectArtist = (type, artist) => {
    let a = [];
    a = artists;
    setCurrentArtist({ ...artist, exists: true });
    a.push({ ...artist, exists: true });
    setArtists(a);
    updateArtists(a);
    setArtistSearch();
    clearArtistForm(type);
  };

  //Add specific artist type to list
  const addArtist = (type) => {
    let a = [];
    a = artists;
    a.push({ ...currentArtist, exists: false });
    setArtists(a);
    updateArtists(a);
    setCurrentArtist();
  };

  const clearArtistForm = (type) => {
    setArtistSearch();
    setCurrentArtist();
    if (artistSearchRef?.current?.value) {
      artistSearchRef.current.value = "";
    }
    if (artistIdRef?.current?.value) {
      artistIdRef.current.value = "";
    }
    if (artistNameRef?.current?.value) {
      artistNameRef.current.value = "";
    }
    if (walletAddressRef?.current?.value) {
      walletAddressRef.current.value = "";
    }
    if (artistFullNameRef.current?.value) {
      artistFullNameRef.current.value = "";
    }
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const validateArtistInfo = (id, type) => {
    if (validateArtistInput(currentArtist)) {
      artistEditing ? editArtist(id, type) : addArtist(type);
    } else {
    }

    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        updateArtists(updateArtists);
        setArtistEditing(false);
        break;

      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];

    a = artists;
    updatedArtists = a.filter((artist, index) => {
      if (index !== id) return artist;
    });
    if (containsObject(albumInfo?.userArtist, updatedArtists)) {
      setArtists(updatedArtists);
    } else {
      setArtists([albumInfo?.userArtist, ...updatedArtists]);
    }
    setArtistEditing(false);
    updateArtists(updateArtists);
    setArtistEditing(false);

    clearArtistForm(type);
  };

  const updateArtists = (data) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        artists: data,
      };
    });
  };

  const addGenre = (musicGenre) => {
    setGenre();

    setAlbumInfo((prev) => {
      return { ...prev, mainGenre: musicGenre };
    });

    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
    setAlbumInfo((prev) => {
      return { ...prev, mainGenre: undefined };
    });
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        if (e.target.value.trim() !== "") {
          delete cArtist?.errors?.name;
          setCurrentArtist({ ...cArtist, artistName: e.target.value });
        } else {
          setCurrentArtist({
            ...cArtist,
            errors: {
              ...cArtist?.errors,
              name: "Artist name cannot be empty",
            },
            artistName: e.target.value,
          });
        }
        AddNEArtistsToSearch(e);
        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        setCurrentArtist({ ...cArtist, fullName: e.target.value });
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        setCurrentArtist({ ...cArtist, artistId: e.target.value });

        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        setCurrentArtist({ ...cArtist, address: e.target.value });
        break;
      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }
    if (e.target.value.length === 0) {
      setArtistSearch();
    }
  };

  const setVisualizerFile = (data) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        visualizerFile: data,
      };
    });
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  //Add newly added (Non-existing) artists to searches
  const AddNEArtistsToSearch = (e) => {
    const a = [...artists];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const setCreativeCommons = (id) => {
    setAlbumInfo((prev) => {
      return {
        ...prev,
        license: id,
      };
    });
  };

  const closeSongInfo = (i) => {
    if (
      (albumInfo.songs[i]?.songUpload?.progress > 0 &&
        albumInfo.songs[i]?.songUpload?.progress < 100) ||
      (albumInfo.songs[i]?.songUpload?.progress === 100 &&
        !albumInfo.songs[i]?.songUpload?.url)
    ) {
      addAlert({
        title: "Song Upload",
        type: ALERT_TYPES.WARNING,
        message: "Song still uploading, please wait",
      });
      return;
    } else {
      setOpenSongs((prev) => {
        if (prev.includes(i)) {
          return prev.filter((s) => s !== i);
        } else {
          return [...prev];
        }
      });
    }
  };

  const deleteSong = (i) => {
    toggleModal();
    modalContent(
      <OptionModal heading={`Are you sure you want to remove this track`}>
        <HoverOption
          onClick={() => {
            let s = newSongs.filter((song, index) => index !== i);
            newSongs[i]?.songUpload?.url &&
              deleteObject(storageRef(storage, songs[i]?.songUpload?.ref));
            let open = openSongs.filter((song) => song !== i);
            setOpenSongs(open);
            setNewSongs(s);
            toggleModal();
            modalContent();
          }}
        >
          Yes
        </HoverOption>
        <HoverOption
          onClick={() => {
            toggleModal();
            modalContent();
          }}
        >
          No
        </HoverOption>
      </OptionModal>
    );
  };

  const onSuccess = (id) => {
    setSongs((prev) => {
      return [
        ...prev,
        {
          songId: id,
          ...newSongs[0],
        },
      ];
    });
    toggleModal();
    modalContent();
    setNewSongs([]);
    setOpenSongs([]);
  };

  const cancelUpload = () => {
    toggleModal();
    modalContent();
  };

  const onFailure = () => {
    toggleModal();
    modalContent();
    setOpenSongs([0]);
  };

  const filterSongInfo = (song) => {
    return {
      songName: song?.songName?.trim(),
      price: song?.isDownloadable ? song.price : undefined,
      isrc: song?.isrc?.trim(),
      artists: song.artists,
      featuredArtists: song?.featArtists,
      songCredits: {
        composers: song?.composers,
        producers: song?.producers,
        writers: song?.writers,
      },
      genre: song.mainGenre,
      subGenres: song.subGenres,
      isStreamable: song.isStreamable,
      isUserRoyalties: song?.isUserRoyalties,
      userRoyalties: song?.isUserRoyalties ? song?.userRoyalties : undefined,
      isDownloadable: song.isDownloadable,
      isSubscription: song.isSubscription,
      isPreRelease: albumInfo?.isPreRelease ? song?.isPreRelease : undefined,
      isPreview: albumInfo?.isPreRelease ? song?.isPreview : false,
      isCover: song.isCover,
      currency: song.isDownloadable
        ? song.currency
          ? song?.currency
          : "XRP"
        : undefined,
      isMintable: song.isMintable,
      explicit: song?.isExplicit,
      royalties: song?.payoutArtists,
      songUrl: song?.songUpload?.url,
      mp3Url: song?.songUpload?.mp3Url,
      mp3Ref: song?.songUpload?.mp3Ref,

      duration: song?.duration ? song?.duration : song?.songUpload?.duration,
      video:
        getObjectLength(song?.videoFile) > 0
          ? {
              url: song?.videoFile?.url,
              duration: song?.videoFile?.duration,
              ref: song?.videoFile?.ref,
            }
          : undefined,
      videoResolutions:
        getObjectLength(song?.videoFile) > 0 &&
        song?.videoFile?.videoResolutions?.length > 0
          ? song?.videoFile?.videoResolutions
          : undefined,
      visualizer:
        getObjectLength(song?.visualizerFile) > 0
          ? {
              url: song?.visualizerFile?.url,
              duration: song?.visualizerFile?.duration,
              ref: song?.visualizerFile?.ref,
            }
          : undefined,
      songRef: song?.songUpload?.ref,
      albumArt: albumInfo?.albumArt.url,
      albumArtRef: albumInfo?.albumArt.ref,
      about: song?.about?.trim() !== "" ? song?.about?.trim() : undefined,
      uploadedBy: currentUser?.uid,
      songId: song?.songId,
      licensing: song?.license ? song?.license : albumInfo?.license,
      lyrics: song?.lyrics?.trim() !== "" ? song?.lyrics?.trim() : undefined,
      releaseDate: albumInfo?.isPreRelease
        ? song?.releaseDate
          ? song?.releaseDate
          : albumInfo?.releaseDate
        : undefined,
      displayDate: albumInfo?.isPreRelease
        ? song?.releaseDate
          ? new Date(song?.releaseDate).toISOString()
          : new Date(albumInfo?.releaseDate).toISOString()
        : new Date().toISOString(),
    };
  };

  const updateSong = (data, index) => {
    let newS = newSongs.map((s, i) => {
      if (i === index) {
        return filterSongInfo(s);
      } else {
        return s;
      }
    });
    setNewSongs(newS);
    toggleModal();
    noCloseModal(
      <>
        {" "}
        <QRCodeForm
          heading={`Release of ${data?.songName} by ${data?.artists[0].artistName}`}
          body={{
            ...filterSongInfo(data),
            albumId: album?.albumId,
            sender: currentUser?.uid,
            memo: `${currentUser?.uid} is submitting the song ${data?.songName} to be added to album ${album?.albumName}`,
            instruction: `${currentUser?.uid} is submitting the song ${data?.songName} to be added to album ${album?.albumName}`,
            type: "publish",
          }}
          cancelQr={cancelUpload}
          onSuccess={onSuccess}
          onFail={onFailure}
          type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.ALBUM_SONG}
        ></QRCodeForm>
      </>
    );

    /* let newS = newSongs.map((s, i) => {
          if (i === index) {
            return {
              ...data,
              albumArt: albumInfo?.albumArt?.url,
              albumArtRef: albumInfo?.albumArt?.ref,
            };
          } else {
            return s;
          }
        });
        setNewSongs(newS)
        setAlbumInfo((prev) => {
          
          return { ...prev, songs: newS };
        });
        setArtists(albumInfo?.artists); */
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case "title":
        setAlbumInfo((prev) => {
          return { ...prev, albumName: e.target.value };
        });
        break;
      case "album price":
        setAlbumInfo((prev) => {
          return { ...prev, price: e.target.value };
        });
        if (e.target.value[0] === "0") {
          setAlbumInfo((prev) => {
            return {
              ...prev,
              price: e.target.value.substring(1, e.target.value.length),
            };
          });
        } else {
          setAlbumInfo((prev) => {
            return { ...prev, price: e.target.value };
          });
        }

        /*          if(!isNaN(e.target.value) && e.target.value > 0){
               delete errs.price
               setErrors(errs)
               return
             } */
        if (e.target.value?.length === 0) {
          setAlbumInfo((prev) => {
            return { ...prev, price: 0 };
          });
        }
        if (isNaN(e.target.value)) {
          /*   err = {
                 ...err,
                 price: "Invalid Price. Please enter price or leave 0"
               } */

          setAlbumInfo((prev) => {
            return { ...prev, price: parseFloat(e.target.value) };
          });
          /* setErrors(err) */
          return;
        }
        break;

      case "album genre":
        if (e.target.value.trim() !== "" && e.target.value.length > 0) {
          searchGenres(e.target.value).then((g) => {
            setGenreFilter(g.genres);
          });
        } else {
          setGenreFilter();
        }
        setGenre(e.target.value.trim());
        break;

      case "album releaseDate":
        setAlbumInfo((prev) => {
          return { ...prev, releaseDate: e.target.value };
        });
        break;
      case "album about":
        setAlbumInfo((prev) => {
          return { ...prev, about: newlineFormatter(e.target.value) };
        });
        break;
      case "albumArt":
        const currArt = albumInfo?.albumArt;
        if (e.target.value && e.target.files[0]) {
          var reader = new FileReader();

          //Read the contents of Image File.
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function (e1) {
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e1.target.result;

            setAlbumInfo((prev) => {
              return {
                ...prev,
                albumArt: currArt,
              };
            });
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (height < 1600 || width < 1600) {
                alert("Height and Width must not be less than 1600px.");
                setAlbumInfo((prev) => {
                  return {
                    ...prev,
                    albumArt: {
                      ...prev.albumArt,
                      file: undefined,
                    },
                  };
                });
                return false;
              }

              let imageFileName;
              if (!albumInfo?.albumArt?.name) {
                imageFileName = `${Math.round(
                  Math.random() * 10000000000000
                )}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                }`;
              } else {
                imageFileName = `${albumInfo?.albumArt?.name.split(".")[0]}.${
                  e.target.files[0].name.split(".")[
                    e.target.files[0].name.split(".").length - 1
                  ]
                } `;
              }
              setAlbumInfo((prev) => {
                return {
                  ...prev,
                  albumArt: {
                    ...prev?.albumArt,
                    name: imageFileName,
                  },
                };
              });
              const uploadTask = uploadBytes(
                storageRef(
                  storage,
                  `artists/${currentUser?.uid}/${imageFileName}`
                ),
                e.target.files[0],
                { contentType: e.target.files[0].type }
              );

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setAlbumInfo((prev) => {
                    return {
                      ...prev,
                      albumArt: {
                        ...prev?.albumArt,
                        progress: progress,
                      },
                    };
                  });

                  if (cancelAlbumArtUpload) {
                    if (!uploadTask.isComplete()) {
                      //Upload is not complete yet, let's cancel
                      uploadTask.cancel();
                    } else {
                      //Upload is complete, but user wanted to cancel. Let's delete the file
                      uploadTask.snapshot.ref.delete();
                      // storageRef.delete(); // will delete all your files
                    }
                  }
                  //console.log("Upload is " + progress + "% done");
                  switch (snapshot.state) {
                    case "paused":
                      break;
                    case "running":
                      if (cancelAlbumArtUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      break;
                    default:
                      if (cancelAlbumArtUpload) {
                        if (!uploadTask.isComplete()) {
                          //Upload is not complete yet, let's cancel
                          uploadTask.cancel();
                        } else {
                          //Upload is complete, but user wanted to cancel. Let's delete the file
                          uploadTask.snapshot.ref.delete();
                          // storageRef.delete(); // will delete all your files
                        }
                      }
                      //console.log("Upload is " + progress + "% done");
                      break;
                  }
                },
                (error) => {
                  // A full list of error codes is available at
                  // https://firebase.google.com/docs/storage/web/handle-errors
                  switch (error.code) {
                    case "storage/unauthorized":
                      // User doesn't have permission to access the object
                      break;
                    case "storage/canceled":
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: currArt,
                        };
                      });
                      // User canceled the upload
                      break;
                    // ...
                    case "storage/unknown":
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: currArt,
                        };
                      });
                      // Unknown error occurred, inspect error.serverResponse
                      break;
                    default:
                      break;
                  }
                },
                () => {
                  // Upload completed successfully, now we can get the download URL
                  getDownloadUrl(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      //console.log("File available at", downloadURL);
                      setAlbumInfo((prev) => {
                        return {
                          ...prev,
                          albumArt: {
                            ...prev?.albumArt,
                            url: downloadURL,
                            file: e.target.files[0],
                            ref: uploadTask.snapshot.ref.fullPath,
                          },
                          songs: prev.songs?.map((s) => {
                            return {
                              ...s,
                              albumArt: downloadURL,
                              albumArtRef: uploadTask.snapshot.ref.fullPath,
                            };
                          }),
                        };
                      });
                      //updateAlbumInfo()
                    }
                  );
                }
              );
            };
          };
        }
        break;
      default:
        break;
    }
  };

  const openSongInfo = (i) => {
    setOpenSongs((prev) => {
      if (prev.includes(i)) {
        return [...prev];
      } else {
        return [...prev, i];
      }
    });
  };

  const addTrack = () => {
    if (albumInfo?.isPreRelease && albumInfo?.releaseDate) {
      openSongInfo(0);
      return setNewSongs((prev) => [
        ...prev,
        {
          currency: albumInfo?.isDownloadable ? albumInfo?.currency : "XRP",
          albumArt: albumInfo?.albumArt,
          artists: artists,
          duration: 0,
          isPreRelease: albumInfo?.isPreRelease,
          releaseDate: albumInfo?.releaseDate
            ? new Date(albumInfo?.releaseDate).toISOString().split("T")[0]
            : new Date().toISOString().split("T")[0],
          isDownloadable: albumInfo?.isDownloadable,
          mainGenre: albumInfo?.mainGenre,
          isStreamable: albumInfo?.isStreamable,
          isMintable: albumInfo?.isMintable,
          isSubscription: albumInfo?.isSubscription,
          uploadedBy: currentUser.uid,
          license: albumInfo?.license,
        },
      ]);

      // console.log(songs)
      /*   if(s?.length === newLength){
          setSongs((prev) => 
          [...prev,
            {
                 currency: albumInfo?.isDownloadable
                   ? albumInfo?.currency
                   : "WYM",
                 albumArt: albumInfo?.albumArt,
                 artists: artists,
                 duration: 0,
                 isDownloadable: albumInfo?.isDownloadable,
                 isStreamable: albumInfo?.isStreamable,
                 isMintable: albumInfo?.isMintable,
                 isSubscription: albumInfo?.isSubscription,
                 uploadedBy: currentUser.uid,
                 license: albumInfo?.license,
               }
            
             ]);
    
        } */
    } else {
      addAlert({
        title: "Release Date",
        message: "Cannot add tracks without a pre release date",
        type: ALERT_TYPES.DANGER,
      });
    }
  };

  return (
    <>
      <FormWrapper>
        <FormWrapper row border>
          <Page1
            albumInfo={albumInfo}
            artists={albumInfo?.artists}
            setCancelAlbumArtUpload={setCancelAlbumArtUpload}
          />
        </FormWrapper>
        <NavContainer style={{ marginBottom: "2%" }}>
          <Nav>
            <NavLink onClick={() => setTab("general")}>
              Album Information
            </NavLink>
            <NavLink onClick={() => setTab("tracks")}>Tracklist</NavLink>
          </Nav>
        </NavContainer>

        {currentTab === "general" && (
          <>
            <FormWrapper>
              <Page2
                albumInfo={albumInfo}
                handleInput={handleInput}
                genre={genre}
                handleToggle={handleToggle}
                setCurrency={setCurrency}
                removeGenre={removeGenre}
                filteredGenres={filteredGenres}
                addGenre
              />
            </FormWrapper>
            <FormWrapper>
              <Page3
                albumInfo={albumInfo}
                artistEditng={artistEditing}
                walletAddressRef={walletAddressRef}
                artists={artists}
                artistFullNameRef={artists}
                artistIdRef={artistIdRef}
                artistNameRef={artistNameRef}
                validateArtistInfo={validateArtistInfo}
                artistSearchRef={artistSearchRef}
                artistSearch={artistSearch}
                searchInput={searchInput}
                clearArtistForm={clearArtistForm}
                handleArtistInput={handleArtistInput}
                errors={albumInfo?.errors}
                setArtistEditing={setArtistEditing}
                selectArtist={selectArtist}
                removeArtist={removeArtist}
                currentArtist={currentArtist}
                setCurrentArtist={setCurrentArtist}
                handleInput={handleInput}
              />
            </FormWrapper>
            <FormWrapper>
              <Page4
                albumInfo={albumInfo}
                visualizerFile={albumInfo?.visualizerFile}
                setVisualizerFile={setVisualizerFile}
              />
            </FormWrapper>
            <FormWrapper>
              <Page5
                albumInfo={albumInfo}
                license={albumInfo?.license}
                setCreativeCommons={setCreativeCommons}
              />
            </FormWrapper>
         { albumInfo?.isUserRoyalties &&  <FormWrapper>
              <Page6 
              isUserRoyalties={albumInfo?.isUserRoyalties}
              userRoyalties={userRoyalties}
              setUserRoyalties={setUserRoyalties}
              errors={albumInfo?.errors}
              setErrors={(err) => setAlbumInfo(prev => {
                return {
                  ...prev,
                  errors: err
                }
              })}
              />
            </FormWrapper>}
          </>
        )}

        {currentTab === "tracks" && (
          <FormWrapper
            row
            style={{ alignItems: "flex-start", justifyContent: "space-around" }}
          >
            {openSongs.length === 0 && (
              <FormWrapper border>
                <ContentWrapper heading={"Songs"}>
                  <DraggableSongList content={songs} setContent={setSongs} />
                </ContentWrapper>
              </FormWrapper>
            )}
            <FormWrapper border>
              <MusicUploadDetailsContainer>
                {newSongs?.map((song, index) => {
                  return (
                    <SongInfoContainer
                      style={{
                        padding: "1%",
                        borderRadius: "20px",
                        color: themeColors.secondaryBg,
                      }}
                    >
                      {index + 1}
                      {!openSongs.includes(index) && (
                        <div style={{ display: "flex" }}>
                          <CloseButton onClick={() => openSongInfo(index)}>
                            Open
                          </CloseButton>
                        </div>
                      )}

                      <div style={{ display: "flex" }}>
                        <SongInfoWrapper
                          style={{ flex: "1 1 100%" }}
                          onClick={() => openSongInfo(index)}
                        >
                          {!openSongs.includes(index) && (
                            <SongInfoTextWrapper style={{ padding: "2%" }}>
                              <MusicUploadContentTitle>
                                {song?.songName
                                  ? song?.songName
                                  : `Track ${index + 1}`}
                              </MusicUploadContentTitle>
                              <MusicUploadInfoText>
                                <>
                                  {song?.artists?.length > 0
                                    ? song?.artists.map((artist, index) => {
                                        if (
                                          index ===
                                          song?.artists.length - 2
                                        ) {
                                          return artist.artistName + " & ";
                                        } else if (
                                          index ===
                                          song?.artists.length - 1
                                        ) {
                                          return artist.artistName;
                                        } else if (
                                          index <=
                                          song?.artists.length - 1
                                        ) {
                                          return artist.artistName + ", ";
                                        }
                                      })
                                    : "Artist(s)"}
                                </>
                              </MusicUploadInfoText>
                            </SongInfoTextWrapper>
                          )}
                        </SongInfoWrapper>
                      </div>

                      {openSongs.includes(index) && (
                        <>
                          {
                            <SongDetails
                              id={index}
                              edit={true}
                              deleteSong={deleteSong}
                              cancelSong={closeSongInfo}
                              song={song}
                              index={index}
                              albumInfo={albumInfo}
                              setAlbumSong={updateSong}
                            />
                          }
                        </>
                      )}
                    </SongInfoContainer>
                  );
                })}
              </MusicUploadDetailsContainer>
              {openSongs?.length === 0 && (
                <SongInfoContainer
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    color: themeColors.secondaryBg,
                    borderRadius: "15px",
                    padding: "2%",
                    width: "100%",
                    background: hexToRgba(themeColors.secondaryColor, 0.2),
                  }}
                  onClick={addTrack}
                >
                  Add Track
                </SongInfoContainer>
              )}
            </FormWrapper>
          </FormWrapper>
        )}
      </FormWrapper>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Button1
          onClick={() => {
            /* //console.log({
        isDownloadable: albumInfo?.isDownloadable,
        isStreamable: albumInfo?.isStreamable,
        isMintable: albumInfo?.isMintable,
        about: albumInfo?.about,
        price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
        currency: albumInfo?.isDownloadable ? albumInfo?.currency : undefined,
        songs : songs.map(s => {
          return s.songId
        })
      }) */
            if (openSongs?.length > 0) {
              return addAlert({
                title: "Release Error",
                message: "Cannot release while a song is being added",
                type: ALERT_TYPES.DANGER,
              });
            }
            if (validateAlbumInfo()) {
              submitChanges(CONTENT_TYPES.ALBUM, {
                isDownloadable: albumInfo?.isDownloadable,
                isStreamable: albumInfo?.isStreamable,
                isMintable: albumInfo?.isMintable,
                about: albumInfo?.about,
                userRoyalties: albumInfo?.isUserRoyalties ? userRoyalties : undefined,
                genre: albumInfo?.mainGenre?.id,
                artists: artists,
                licensing: albumInfo?.license,
                isPreRelease: true,
                releaseDate: albumInfo?.releaseDate,
                displayDate: new Date(albumInfo?.releaseDate)
                  .toISOString()
                  .split("T")[0],
                price: albumInfo?.isDownloadable ? albumInfo?.price : undefined,
                currency: albumInfo?.isDownloadable
                  ? albumInfo?.currency
                  : undefined,
                songs: songs.map((s) => {
                  return s.songId;
                }),
              });
            }
          }}
        >
          Submit Changes
        </Button1>
        <Button1
          onClick={() => {
            if (openSongs?.length > 0) {
              return addAlert({
                title: "Release Error",
                message: "Cannot release while a song is being added",
                type: ALERT_TYPES.DANGER,
              });
            }
            if (validateAlbumInfo()) {
              openModal();
              modalContent(
                <OptionModal>
                  <HoverOption
                    onClick={() => {
                      immediateRelease(CONTENT_TYPES.ALBUM, {
                        isDownloadable: albumInfo?.isDownloadable,
                        isStreamable: albumInfo?.isStreamable,
                        isMintable: albumInfo?.isMintable,
                        about: albumInfo?.about,
                        genre: albumInfo?.mainGenre?.id,
                        artists: artists,
                        userRoyalties: albumInfo?.isUserRoyalties ? userRoyalties : undefined,
                        licensing: albumInfo?.license,
                        releaseDate: new Date().toISOString().split("T")[0],
                        displayDate: new Date().toISOString().split("T")[0],
                        price: albumInfo?.isDownloadable
                          ? albumInfo?.price
                          : undefined,
                        currency: albumInfo?.isDownloadable
                          ? albumInfo?.currency
                          : undefined,
                        songs: songs.map((s) => {
                          return s.songId;
                        }),
                      });
                    }}
                  >
                    Continue
                  </HoverOption>
                </OptionModal>
              );
            }
          }}
        >
          Release Immediately
        </Button1>
        <Button1
          onClick={() => {
            navigate(`/album/${album?.albumId}`, { replace: true });
          }}
        >
          Cancel
        </Button1>
      </div>
    </>
  );
};

export default AlbumPreReleaseEdit;
