import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaDownload, FaFire, FaPause, FaPlay } from "react-icons/fa";
import { FcLike, FcLikePlaceholder } from "react-icons/fc";
import { MdExplicit, MdRemoveCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  convertDuration,
  downloadFile,
  getArtistsNames,
  getPodcastersNames,
  getSongTitle,
} from "../../../utils";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MUSIC_PURCHASE_OPTIONS,
  PODCASTER_SEARCH_TYPES,
} from "../../../utils/Enum";
import ImageUtils from "../../../utils/ImageUtils";
import { useAlert } from "../../Alert/AlertContext";
import MenuOptions from "../../MenuOptions";
import { useModal } from "../../Modal/ModalContext";
import ScrollToTop from "../../ScrollToTop";
import ToolTip from "../../ToolTip";
import { Theme } from "../../WebsiteThemeElements";
import {
  Button,
  Button1,
  DetailsContainer,
  DetailsText,
  ImageWrapper,
  Img,
  NumButton,
  PlayButton,
  PlayingDisc,
  Table,
  TableData,
  TableRow,
} from "../ContentListElements";
import Marquee from "react-text-marquee";
import OptionModal from "../../OptionModal";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { RiVideoFill } from "react-icons/ri";
import { useWebsiteTheme } from "../../WebsiteTheme/WebsiteThemeContext";

const SongList = ({
  content,
  toggleLike,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {options} = MenuOptions()

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId && currentUser?.uid){
                toggleModal();
                options(song)}
              }}
              id={song.songId ? song.songId : song.episodeId }
            >
              <TableData
                onClick={() => {
                  if ((song?.songId || song?.episodeId) && currentUser?.uid) {
                    if (currentUser?.uid) {
                    }
                  } else {
                      addAlert({
                        title: "Not Logged In",
                        type: ALERT_TYPES.WARNING,
                        message: "You need to log in to play this song",
                      });
                    }
                }}
              >
                <NumButton
                  
                >
                  {
                    index + 1
                  }
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
               
              >
                <ImageWrapper>
                  <Img
                    src={
                      song?.albumArt
                        ? song.albumArt
                        : song?.thumbnail
                        ? song?.thumbnail
                        : defaultAlbumArt
                    }
                    alt={song?.songName ? song?.songName : song?.episodeName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                    onClick={() => {
                      if (song?.songId) {
                        goTo(`/song/${song?.songId}`);
                        return;
                      }
                      if (song?.episodeId) {
                        goTo(`/episode/${song?.episodeId}`); 
                       
                    /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })
                   
                        closeViewer();
                        return; */
                      }
                    }}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                    {song?.episodeId && (
                      song?.episodeName?.length > 30 ? (
                        `${song?.episodeName?.substring(0, 30)}...`
                      ) : (
                        song?.episodeName
                      )
                    )} 
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      {(song?.podcasters || song?.mainPodcasters) &&
                        getPodcastersNames(
                          song?.podcasters
                            ? song?.podcasters
                            : song?.mainPodcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                      onClick={() => {
                        goTo(`/album/${song?.album?.albumId}`);
                      }}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText 
                  >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && <TableData>
                <div>
                  <Button
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      userLikes
                        ?.filter((like) => like.songId)
                        .find((s) => s.songId === song?.songId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.songId)
                          .find((s) => s.songId === song?.songId)) ||
                      (userLikes?.length > 0 &&
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
              {song?.isDownloadable && <TableData>
              </TableData>}
              <TableData>
                <Button
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (song) {
                        toggleModal();
                        options(song)
                        return;
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>
              </TableData>
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};

export const AudioPlayerQueueList = ({
  content,
  toggleLike,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {options} = MenuOptions()
  




  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId && currentUser?.uid){
                  
                  options(song);
                }
              }}
              id={song.songId ? song.songId : song.episodeId }
            >
              <TableData
             
              >
                <NumButton
                  
                >
                  {
                    index + 1
                  }
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
               
              >
                <ImageWrapper>
                  <PlayButton>
                 
                   
                  </PlayButton>
                  <Img
                    src={
                      song?.albumArt
                        ? song.albumArt
                        : song?.thumbnail
                        ? song?.thumbnail
                        : defaultAlbumArt
                    }
                    alt={song?.songName ? song?.songName : song?.episodeName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                    onClick={() => {
                      if (song?.songId) {
                        goTo(`/song/${song?.songId}`);
                        return;
                      }
                      if (song?.episodeId) {
                        goTo(`/episode/${song?.episodeId}`); 
                       
                    /* addAlert({
                      title: "Not Enabled",
                      type: ALERT_TYPES.WARNING,
                      message: "Coming Soon",
                    })
                   
                        closeViewer();
                        return; */
                      }
                    }}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                    {song?.episodeId && (
                      song?.episodeName?.length > 30 ? (
                        `${song?.episodeName?.substring(0, 30)}...`
                      ) : (
                        song?.episodeName
                      )
                    )} 
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                      {(song?.podcasters || song?.mainPodcasters) &&
                        getPodcastersNames(
                          song?.podcasters
                            ? song?.podcasters
                            : song?.mainPodcasters,
                          PODCASTER_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                      onClick={() => {
                        goTo(`/album/${song?.album?.albumId}`);
                      }}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && <TableData>
                <div style={{opacity: 0}}>
                  <Button 
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      userLikes
                        ?.filter((like) => like.songId)
                        .find((s) => s.songId === song?.songId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.songId)
                          .find((s) => s.songId === song?.songId)) ||
                      (userLikes?.length > 0 &&
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
              
              {/* <TableData>
                <Button
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (song) {
                        toggleModal();
                        options(song)
                        return;
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>
              </TableData> */}
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};

export const CollectionSongList = ({
  content,
  toggleLike,
  showAll,
  setShowAll,
  onButtonClick,
  type,
  playlist,
  currentSong,
  isPlaying,
}) => {
  const { toggleModal, modalContent, isOpen } = useModal();
  const { currentUser, gravatarInfo,userLikes } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const {options} = MenuOptions()

  return (
    <>
      <Table>
        {content?.length > 0 && content?.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              id={song.songId}
            >
              <TableData
                onClick={
                  song?.songId
                    ? () => {
                        onButtonClick(index);
                      }
                    : {}
                }
              >
                <NumButton>
                  
                    {index + 1}
                  
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}
                onClick={() => {
                  onButtonClick(index);
                }}
              >
                <ImageWrapper>
                  <Img
                    onClick={() => onButtonClick(index)}
                    src={song?.albumArt ? song?.albumArt : defaultAlbumArt}
                    alt={song?.songName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                                      isPlaying={(currentSong && playlist[currentSong]?.songId === song?.songId)}

                    onClick={
                      song?.songId
                        ? () => {
                            goTo(`/song/${song?.songId}`);
                          }
                        : {}
                    }
                  >
                    {song?.songId
                      ? getSongTitle(song)?.length > 30
                        ? `${getSongTitle(song)?.substring(0, 30)}...`
                        : getSongTitle(song)
                      : "Song Name"}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={
                      song?.artists && song?.artists[0]?.artistId
                        ? () => {
                          }
                        : {}
                    }
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                      onClick={() => goTo(`/album/${song?.album?.albumId}`)}
                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText                     
                  
                  >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const MusicStoreSongList = ({
  content,
  showAll,
  setShowAll,
  type,
  playlist,
  currentSong,
  isPlaying,
}) => {
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { currentUser, gravatarInfo, userLikes } = useAuth();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              background={
                (currentSong && playlist[currentSong]?.songId === song?.songId) && true
              }
              id={song.songId}
            >
              <TableData onClick={song?.songId ? () => {} : {}}>
                <NumButton
                  
                >
                  {
                    index + 1
                  }
                </NumButton>
              </TableData>
              <TableData style={{ width: "10%" }}>
                <ImageWrapper>
                  <Img
                    src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                    alt={song?.songName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText                     
                 
>
                    {song?.songId
                      ? getSongTitle(song)?.length > 30
                        ? `${getSongTitle(song)?.substring(0, 30)}...`
                        : getSongTitle(song)
                      : "Song Name"}
                  </DetailsText>
                  <DetailsText alt={true}>
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}
                    {content?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                  {song?.album && (
                    <DetailsText
                   

                      style={{
                        justifyContent: "flex-start",
                        cursor: "pointer",
                      }}
                    >
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText
                  >
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              <TableData>
                <div></div>
              </TableData>
              <TableData>
                <Button></Button>
              </TableData>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
};

export const DraggableSongList = ({ content, setContent, deleteContent }) => {
  const navigate  = useNavigate();
  const {themeColors} = useWebsiteTheme()
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { currentUser, gravatarInfo, userLikes } = useAuth();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const [isDragging, setDragging] = useState(false);
  let draggedItem = useRef();
  let dragOver = useRef();

  useEffect(() => {return () => {}}, [content?.length > 0]);

  const removeSong = (index) => {
    setContent((prev) => {
      return prev?.filter((s, i) => index !== i);
    });
  };

  const dragStart = (e, index) => {
    draggedItem.current = index;
    setDragging(true);
  };

  const dragEnter = (e, index) => {
    dragOver.current = index;
    let sArr = content;
    sArr = sArr.map((s) => {
      return {
        ...s,
        isDragging: false,
      };
    });
    sArr[index].isDragging = true;
    setContent(sArr);
  };

  const dragEnd = (e, index) => {
    const sArr = content;
    const mainItem = sArr[draggedItem.current];

    sArr.splice(draggedItem.current, 1);
    sArr.splice(dragOver.current, 0, mainItem);

    draggedItem.current = null;
    dragOver.current = null;

    setContent(sArr);
    setDragging(false);
  };

  return (
    <>
      <Table class={"container"} onDragOver={() => {}}>
        {content.map((song, index) => {
          return (
            <>
              <TableRow
                key={index}
                draggable={true}
                droppable
                onDragStart={(e) => {
                  dragStart(e, index);
                }}
                onDragEnter={(e) => {
                  dragEnter(e, index);
                }}
                onDragEnd={(e) => {
                  dragEnd(e, index);
                }}
                class={"draggable"}
                id={song.songId}
                style={{ cursor: "move" }}
              >
                <TableData onClick={song?.songId ? () => {} : {}}>
                  <NumButton>{index + 1}</NumButton>
                </TableData>
                <TableData style={{ width: "10%" }}>
                  <ImageWrapper>
                    <Img
                      src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                      alt={song?.songName}
                    />
                  </ImageWrapper>
                </TableData>
                <TableData>
                  <DetailsContainer>
                    <DetailsText>
                      {song?.songId
                        ? getSongTitle(song)?.length > 30
                          ? `${getSongTitle(song)?.substring(0, 30)}...`
                          : getSongTitle(song)
                        : "Song Name"}
                    </DetailsText>
                    <DetailsText alt={true}>
                      {song?.explicit && (
                        <MdExplicit style={{ marginLeft: "1%" }} />
                      )}
                      <small>
                        {(song?.artists || song?.mainArtists) &&
                          getArtistsNames(
                            song?.artists ? song?.artists : song?.mainArtists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                      </small>
                    </DetailsText>
                    {song?.album && (
                      <DetailsText
                        style={{
                          justifyContent: "flex-start",
                          cursor: "pointer",
                        }}
                      >
                        <small>from {song?.album?.albumName}</small>
                      </DetailsText>
                    )}
                  </DetailsContainer>
                </TableData>
                <TableData>
                  {song?.duration && (
                    <DetailsText>
                      <small>{convertDuration(song?.duration)}</small>
                    </DetailsText>
                  )}
                </TableData>
                <TableData>
                  <div></div>
                </TableData>
                <TableData>
                  {deleteContent && (
                    <Button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        removeSong(index);
                      }}
                    >
                      <ToolTip text={"Delete"}>
                        <MdRemoveCircle color="Red" size={30} />
                      </ToolTip>
                    </Button>
                  )}
                  <Button style={{cursor: 'pointer', color: themeColors.errorColor}} onClick={() => downloadFile(`/song/${song?.songId}?edit=true`) }>
                    <small>Edit</small>
                  </Button>
                </TableData>
              </TableRow>
              {index === dragOver?.current && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "3px",
                    background: themeColors.secondaryColor,
                  }}
                ></div>
              )}
            </>
          );
        })}
      </Table>
    </>
  );
};

export const AlbumSongList = ({
  content,
  toggleLike,
  showAll,
  setShowAll,
  onButtonClick,
  type,
}) => {
  const {options} = MenuOptions()
  const { toggleModal, modalContent, isOpen, openModal, closeModal } = useModal();
  const { currentUser, gravatarInfo, userLikes, isUserSubscribed } = useAuth();
  const navigate  = useNavigate();
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const { goTo } = ScrollToTop();
  const { addAlert } = useAlert();
  const [songs, setSongs] = useState(content)
 const [hotSongs, sethotSongs] = useState(songs?.length > 3 && songs.filter(s => s?.plays > 0).sort((a, b) => b?.plays - a?.plays).filter((s,index) => index < 3 ))
  

  

  const isHotSong = (i) => {
    let song = content[i]
     if(hotSongs?.length > 0 && hotSongs?.filter(s => s?.songId === song?.songId)?.length > 0){
      return true
    }
    return false 
  }

  return (
    <>
      <Table>
        {content.map((song, index) => {
          return (
            <TableRow
              key={index}
              onContextMenu={(e) => {
                e.preventDefault();
                if(song?.songId && currentUser?.uid){
                toggleModal();
                options(song)}
              }}
      
              id={song.songId ? song.songId : song.episodeId }
            >
              <TableData
              >
                <NumButton
               
                >
                 (isHotSong(index) ? <FaFire color={"#db6400"}/>  : index + 1)
                </NumButton>
              </TableData>
              <TableData
                style={{ width: "10%" }}

              >
                <ImageWrapper>
                  <PlayButton>
                 
                  </PlayButton>
                  <Img
                    src={
                      song?.albumArt
                        ? song.albumArt
                        : song?.thumbnail
                        ? song?.thumbnail
                        : defaultAlbumArt
                    }
                    alt={song?.songName ? song?.songName : song?.episodeName}
                  />
                </ImageWrapper>
              </TableData>
              <TableData>
                <DetailsContainer>
                  <DetailsText
                    onClick={() => {
                      if (song?.songId) {
                        goTo(`/song/${song?.songId}`);
                        return;
                      }
                    }}
                  >
                    {song?.songId && (
                      getSongTitle(song)?.length > 30 ? (
                        `${getSongTitle(song)?.substring(0, 30)}...`
                      ) : (
                        getSongTitle(song)
                      )
                    )} 
                  
                    {!song?.episodeId && !song?.songId && (
                      'Music Title'
                    )}
                  </DetailsText>
                  <DetailsText
                    alt={true}
                    onClick={() => {
                      /* if (song?.artists && song?.artists[0]?.artistId) {
                        //Modal
                        goTo(`/artist/${song?.artists[0]?.artistId}`);
                      }
                      if (
                        song?.podcasters &&
                        song?.podcasters[0]?.podcasterId
                      ) {
                        //Modal
                        goTo(`/podcaster/${song?.podcasters[0]?.podcasterId}`);
                      } */
                    }}
                  >
                    {song?.explicit && (
                      <MdExplicit style={{ marginLeft: "1%" }} />
                    )}{song?.video?.url && <RiVideoFill style={{ marginLeft: "1%" }}/>}
                    <small>
                      {(song?.artists || song?.mainArtists) &&
                        getArtistsNames(
                          song?.artists ? song?.artists : song?.mainArtists,
                          ARTIST_SEARCH_TYPES.NAME
                        )}
                    </small>
                  </DetailsText>
                </DetailsContainer>
              </TableData>
              <TableData>
                {song?.duration && (
                  <DetailsText>
                    <small>{convertDuration(song?.duration)}</small>
                  </DetailsText>
                )}
              </TableData>
              {currentUser?.uid && <TableData>
                <div>
                  <Button
                    onClick={() => {
                      if (currentUser?.uid) {
                        toggleLike(index);
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to like this song",
                        });
                      }
                    }}
                  >
                    {currentUser?.uid &&
                    ((userLikes?.length > 0 &&
                      userLikes
                        ?.filter((like) => like.songId)
                        .find((s) => s.songId === song?.songId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.songId)
                          .find((s) => s.songId === song?.songId)) ||
                      (userLikes?.length > 0 &&
                        userLikes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId)) ||
                      (currentUser?.likes?.length > 0 &&
                        currentUser?.likes
                          ?.filter((like) => like.episodeId)
                          .find((s) => s.episodeId === song?.episodeId))) ? (
                      <FcLike />
                    ) : (
                      <FcLikePlaceholder />
                    )}
                  </Button>
                </div>
              </TableData>}
             <TableData>
                <Button
                  onClick={() => {
                    if (currentUser?.uid) {
                      if (song) {
                        toggleModal();
                        options(song)
                        return;
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  }}
                >
                  <BsThreeDotsVertical />
                </Button>
              </TableData>
            </TableRow>
          );
        })}
        {/*  {(displayFullList === undefined || displayFullList === null) && (
          <>
            {!showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  setVisibleContent(content);
                  setShowAll(true);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Show All
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
            {showAll && content?.length - 1 > 5 && (
              <TableRow
                onClick={() => {
                  //setVisibleContent(content.filter((c, index) => index < 5));
                  setShowAll(false);
                }}
              >
                <TableData></TableData>
                <TableData></TableData>
                <TableData>
                  <DetailsText
                    style={{ justifySelf: "center", justifyContent: "center" }}
                  >
                    Hide
                  </DetailsText>
                </TableData>
                <TableData></TableData>
                <TableData></TableData>
                <TableData></TableData>
              </TableRow>
            )}
          </>
        )} */}
      </Table>
    </>
  );
};


export default SongList;
