import React, { useEffect, useRef, useState } from 'react'
import { AlbumArtPreviewContainer, AlbumUploadsContainer, ErrorWrapper, MusicTitleInfoContainer, MusicUploadAlbumArt, MusicUploadInfoText, MusicUploadsButton, MusicUploadsButtonContainer, MusicUploadTitleArtContainer, SongArtCoverWrapper, SongDetailsContainer, SongDetailsSection, SongDetailsSectionTitle, SongDetailsText, SongDetailsWrapper, UploadProgressWrapper } from '../MusicUploadsFormElements'
import FileUploader from '../../FileUploader';
import LoadingScreen, { ModalLoadingScreen } from '../../LoadingScreen';
import { AlbumArtPreview } from '../../AlbumArt';
import ImageUtils from '../../../utils/ImageUtils';
import Page1 from './Page1';
import { FormWrapper } from '../../CustomForm/CustomFormElements';
import { containsObject, getObjectLength, getUniqueListBy, newlineFormatter } from '../../../utils';
import { ALERT_TYPES, ARTIST_FORM_INPUT_TYPES, ARTIST_FORM_TYPES, ARTIST_SEARCH_TYPES, TRANSACTION_OPTIONS } from '../../../utils/Enum';
import useMusic from '../../../hooks/useMusic';
import { HoverOption } from '../../MenuOptions/MenuOptionsElements';
import { useAlert } from '../../Alert/AlertContext';
import { useAuth } from '../../../contexts/AuthContext';
import useRecordLabel from '../../../hooks/useRecordLabel';
import useArtist from '../../../hooks/useArtist';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWebsiteTheme } from '../../WebsiteTheme/WebsiteThemeContext';
import { useModal } from '../../Modal/ModalContext';
import OptionModal from '../../OptionModal';
import { VideoSongSearchModal } from '../../SearchModal';
import { PushSpinner } from 'react-spinners-kit';
import Page2 from './Page2';
import { isrcReg } from '../../../utils/Regex';
import Page3 from './Page3';
import Page4 from './Page4';
import PageIndexer from '../../PageIndexer';
import QRCodeForm from '../../QRCodeForm';
import { saveVideoAsDraft } from '../../../utils/dbUtils/draftsUtils';

const VideoUploads = ({info}) => 
 { 
  const { addAlert } = useAlert();
  const { currentUser, profiles, currentProfile } = useAuth();
  const { searchArtists, validateNFTStorageKey } = useArtist({});
  const {getRecordLabelArtists} = useRecordLabel({})
  const navigate = useNavigate();
  const location = useLocation();
  const {themeColors} = useWebsiteTheme()
  const {
    modalContent,
    isOpen,
    toggleModal,
    noCloseModal,
    openModal,
    closeModal,
  } = useModal();
  const {
    createMp3File,
    searchGenres,
    uploadSong,
    getAllGenres,
    generateISRCCode,
    deleteDraft,
  } = useMusic();

  const releaseDateRef = useRef();
  const genreRef = useRef();
  const isrcRef = useRef();
  const aboutRef = useRef();
  const lyricsRef = useRef();
  const composersRef = useRef();
  const producersRef = useRef();
  const writersRef = useRef();
  const artistNameRef = useRef("");
  const artistIdRef = useRef("");
  const walletAddressRef = useRef("");
  const artistSearchRef = useRef("");
  const artistFullNameRef = useRef("");

  const fArtistNameRef = useRef("");
  const fArtistIdRef = useRef("");
  const fWalletAddressRef = useRef("");
  const fArtistSearchRef = useRef("");
  const fArtistFullNameRef = useRef("");

  const writerNameRef = useRef("");
  const writerIdRef = useRef("");
  const writerWalletAddressRef = useRef("");
  const writerFullNameRef = useRef("");
  const writerSearchRef = useRef("");

  const composerNameRef = useRef("");
  const composerIdRef = useRef("");
  const composerWalletAddressRef = useRef("");
  const composerFullNameRef = useRef("");
  const composerSearchRef = useRef("");

  const producerNameRef = useRef("");
  const producerIdRef = useRef("");
  const producerWalletAddressRef = useRef("");
  const producerFullNameRef = useRef("");
  const producerSearchRef = useRef("");

  const payoutArtistNameRef = useRef("");
  const payoutArtistIdRef = useRef("");
  const payoutArtistWalletAddressRef = useRef("");
  const payoutArtistFullNameRef = useRef("");
  const payoutArtistSearchRef = useRef("");
  const artistPayoutRef = useRef();

  const { defaultAlbumArt } = ImageUtils();
  const [thumbnail, setThumbnail] = useState({})
  const [currentUserArtist, setUserArtist] = useState(
    info?.userArtist ? info?.userArtist : {}
  );
  const [isExistingSong, setExisting] = useState(false)
  const [isAIGenerated, setAIGenerated] = useState(false)
  const [videoTitle, setVideoTitle] = useState(info?.title ? info?.title : "")
  const [isrcType, setIsrcType] = useState(
    info?.isrcType ? info?.isrcType : "auto"
  );
  const [autoIsrcCode, setAutoIsrcCode] = useState(
    info?.autoIsrcCode ? info?.autoIsrcCode : undefined
  );
  const [releaseDate, setReleaseDate] = useState(
    info?.releaseDate ? info?.releaseDate : undefined
  );
  const [artistSearch, setArtistSearch] = useState();
  const [featArtistSearch, setFArtistSearch] = useState();
  const [composerSearch, setComposerSearch] = useState();
  const [producerSearch, setProducerSearch] = useState();
  const [writerSearch, setWriterSearch] = useState();
  const [payoutSearch, setPayoutSearch] = useState();
  const [artistEditng, setArtistEditing] = useState(false);
  const [fArtistEditng, setFArtistEditing] = useState(false);
  const [writerEditing, setWriterEditing] = useState(false);
  const [composerEditing, setComposerEditing] = useState(false);
  const [producerEditing, setProducerEditing] = useState(false);
  const [payoutEditing, setPayoutEditing] = useState(false);

  const [artists, setArtists] = useState(info?.artists ? info?.artists : []);
  const [videoId, setVideoId] = useState(info?.videoId ? info?.videoId : null)
  const [currentArtist, setCurrentArtist] = useState({});
  const [featArtists, setFeatArtists] = useState(
    info?.featuredArtists ? info?.featuredArtists : []
  );
  const [mainGenre, setMainGenre] = useState(
    info?.mainGenre ? info?.mainGenre : undefined
  );
  const [genre, setGenre] = useState("");
  const [filteredGenres, setGenreFilter] = useState([]);
  const [currentFArtist, setCurrentFArtist] = useState({});
  const [isrc, setISRC] = useState(info?.isrc ? info?.isrc : "");
  const [about, setAbout] = useState(info?.about ? info?.about : "");
  const [isIsrcValid, setIsrcValid] = useState(false);
  const [videoUpload, setVideoUpload] = useState(info?.videoUpload ? info?.videoUpload : {})
  const [duration, setDuration] = useState(info?.videoUpload ? info?.videoUpload : null)
  const [payoutArtists, setPayoutArtists] = useState(
    info?.payoutArtists ? info?.payoutArtists : []
  );
  const [currentPayoutArtist, setCurrentPayoutArtist] = useState({});
  const [composers, setComposers] = useState(
    info?.songCredits?.composers ? info?.songCredits?.composers : []
  );
  const [currentComposer, setCurrentComposer] = useState({});
  const [writers, setWriters] = useState(
    info?.songCredits?.writers ? info?.songCredits?.writers : []
  );
  const [currentWriter, setCurrentWriter] = useState({});
  const [producers, setProducers] = useState(
    info?.songCredits?.producers ? info?.songCredits?.producers : []
  );
  const [isExplicit, setExplicit] = useState(
    info?.explicit ? info?.explicit : false
  );
  const [currentProducer, setCurrentProducer] = useState({});
  const [license, setLicense] = useState(info?.license ? info?.license : "ARR");
  const [prevChanges, setPrevChanges] = useState({});
  const [errors, setErrors] = useState({});
  const [pageNumber, setPageNum] = useState(1);
  const [isSubmitted, setSubmit] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [videoInfo, setVideoInfo] = useState(null)
  const [song, setSong] = useState({})


  useEffect(() => {
    if (!isLoaded) {
      setMainArtist();
    }
    if (!autoIsrcCode || autoIsrcCode?.length === 0) {
      getISRCCode();
    }
    return () => {};
    /*     const params = new URLSearchParams({id: 'test' });
    navigate( {pathname: location.pathname, search: params.toString()} )  
 */
  }, []);

  const setMainArtist = async () => {
    if(currentProfile === 'label'){
     if(artists?.length === 0 && getObjectLength(currentUserArtist) === 0 && !info?.userArtist){
      openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
                  });
                  setUserArtist({ ...artist, exists: true });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
      
     
    }else{
      if (info?.userArtist){
        if(artists?.length > 0 &&
        !containsObject(artists, { ...info?.userArtist, exists: true })
      ) {
        setArtists((prev) => {
          return getUniqueListBy([{ ...info?.userArtist, exists: true }, ...prev]);
        });
        setUserArtist({ ...info?.userArtist, exists: true });
      }
    }
      else{
        if(artists?.length > 0 && getObjectLength(currentUserArtist) > 0 &&
          !containsObject(artists, { ...currentUserArtist, exists: true })
        ) {
          setArtists((prev) => {
            return getUniqueListBy([{ ...currentUserArtist, exists: true }, ...prev]);
          });
          setUserArtist({ ...currentUserArtist, exists: true });
        }
        if(artists?.length === 0 && getObjectLength(currentUserArtist) > 0){
          setArtists((prev) => {
            return getUniqueListBy([{ ...currentUserArtist, exists: true }, ...prev]);
          });
          setUserArtist({ ...currentUserArtist, exists: true });
        }
        if(artists?.length === 0 && getObjectLength(currentUserArtist) === 0){
          openModal()
      noCloseModal(<ModalLoadingScreen text={'Loading. Please wait...'}/>)
      return await getRecordLabelArtists(currentUser?.profiles?.label?.labelId).then((labelArtists) => {
        if(labelArtists?.length > 0){
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'Select Artist'}>
            {labelArtists?.map((artist) => {
              return (
                <HoverOption onClick={() => {
                  setArtists((prev) => {
                    return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
                  });
                  setUserArtist({ ...artist, exists: true });
                  setLoaded(true)
                }}>{artist?.artistName}</HoverOption>
              )
            })}
          </OptionModal>)
        }else{
          noCloseModal(<OptionModal onCancel={() => {
            toggleModal()
              modalContent()
              navigate(-1)
          }} heading={'No Artists Found'} additionalText={'Add artists to Roster before you can upload music'}>
            <HoverOption onClick={() => {
              toggleModal()
              modalContent()
              navigate(-1)}}>OK</HoverOption>
          </OptionModal>)
        }
      }).catch(err => {
        noCloseModal(<OptionModal onCancel={() => {
          toggleModal()
            modalContent()
            navigate(-1)
        }} heading={'Something Went Wrong'}>
          <HoverOption onClick={() => {
            toggleModal()
            modalContent()
            navigate(-1)}}>OK</HoverOption>
            <HoverOption onClick={() => {
              setMainArtist()
            }}>Try Again</HoverOption>
        </OptionModal>)
      })
        }
      }
    }
    setLoaded(true);
  }else{
      return await searchArtists(ARTIST_SEARCH_TYPES.ADDRESS, currentUser?.uid).then(
        (artist) => {
          if (artists?.length === 0) {
            setUserArtist({
              ...artist,
              exists: true,
            });
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }]);
            });
          }
          if (
            artists?.length > 0 &&
            !containsObject(artists, { ...artist, exists: true })
          ) {
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
            });
            setUserArtist({ ...artist, exists: true });
          }else{
            setArtists((prev) => {
              return getUniqueListBy([{ ...artist, exists: true }, ...prev]);
            });
            setUserArtist({ ...artist, exists: true });
          }
          setLoaded(true);
        }
      );
    }
    
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'title':
        setVideoTitle(e.target.value)
        break;
        case "releaseDate video":
          setReleaseDate(e.target.value);
          break;
        case "about video":
          setAbout(newlineFormatter(e.target.value));
          break;
          case "isrc video":
            setISRC(e.target.value.toUpperCase());
            if (isrcReg.test(e.target.value)) {
              setIsrcValid(true);
            } else {
              setIsrcValid(false);
            }
            break;
    
          case "isrcType":
            switch (e.target.value) {
              case "auto":
                setIsrcType("auto");
                //isrcRef.current.value = autoIsrcCode;
                setISRC(autoIsrcCode);
                break;
    
              case "custom":
                setISRC("");
                setIsrcType("custom");
                isrcRef.current.value = "";
                break;
    
              default:
                setIsrcType("auto");
                //isrcRef.current.value = autoIsrcCode;
                setISRC(autoIsrcCode);
                break;
            }
            break;
    
    
      default:
        break;
    }
  }

 const setSongInfo = (s) => {
    setVideoTitle(s?.songName)
    setMainGenre(s?.genre)
    setArtists(prev => {
      return [
        ...prev,
        ...s?.artists.filter(a => a?.artistId !== currentUserArtist?.artistId).map(a => {
          return {
            ...a,
            exist: true,
            photoUrl: a?.avatar_url
          }
        })
      ]
    })
    setWriters(s?.songCredits?.writers?.length > 0 ? s?.songCredits?.writers?.map(w => {
      return {
        ...w,
        exists: true,
        photoUrl: w?.avatar_url
      }}) : [] )
    setComposers(s?.songCredits?.composers?.length > 0 ? s?.songCredits?.composers?.map(c => {
      return {
        ...c,
        exists: true,
        photoUrl: c?.avatar_url
      }
    }) : [] )
    setProducers(s?.songCredits?.producers?.length > 0 ? s?.songCredits?.producers?.map(p => {
      return {
        ...p,
        exists: true,
        photoUrl: p?.avatar_url
      }}) : [] )

      setPayoutArtists( s?.royalties
        ? Object.keys(s?.royalties).map((p, index) => {
            let artists = [...s?.artists];
            if (s?.songCredits?.producers?.length > 0) {
              artists.push(...s?.songCredits?.producers);
            }
            if (s?.featuredArtists?.length > 0) {
              artists.push(...s?.featuredArtists);
            }
            if (s?.songCredits?.writers?.length > 0) {
              artists.push(...s?.songCredits?.writers);
            }
            if (s?.songCredits?.composers?.length > 0) {
              artists.push(...s?.songCredits?.composers);
            }
            artists = getUniqueListBy(artists, "artistId");
            let artist = artists.filter((a) => p === a?.artistId); // search for current payout artist from list
            return {
              ...artist[0],
              exists: true,
              payoutPercentage: s?.royalties[p],
              photoUrl: artist[0]?.avatar_url
            }; //return in payout artist format
          })
        : [])
      
  }

  const handleToggle = (type) => {
    switch (type) {
      case 'ai':
        setAIGenerated(!isAIGenerated)
        break;
      case 'exists':
        if(!isExistingSong){
        openModal()
        modalContent(<VideoSongSearchModal onClick={ (s) => 
        {
          
           setSong(s)
           setSongInfo(s)
          setExisting(true)
                }        } />)
      }else{
        setExisting(false)
        setSong({})
      }
        break;
      case 'explicit':
        setExplicit(!isExplicit)
        break;
    
      default:
        break;
    }
  }

  const getISRCCode = async () => {
    return await generateISRCCode()
      .then((code) => {
        if (!code?.error || code !== false) {
          setAutoIsrcCode(code);
          setISRC(code);
        } else {
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validateArtistInput = (currentArtist) => {
    if (currentArtist && currentArtist?.artistName?.trim() !== "") {
      return true;
    }
    return false;
  };

  const addArtist = (type) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        a.push({ ...currentArtist, exists: false });
        setArtists(a);
        setCurrentArtist({});
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        a.push({ ...currentFArtist, exists: false });
        setFeatArtists(a);
        setCurrentFArtist({});
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        a.push({ ...currentComposer, exists: false });
        setComposers(a);
        setCurrentComposer({});
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        a.push({ ...currentProducer, exists: false });
        setProducers(a);
        setCurrentProducer({});
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        a.push({ ...currentWriter, exists: false });
        setWriters(a);
        setCurrentWriter({});
        break;
      /* case ARTIST_FORM_TYPES.PAYOUT:
      a = writers
      a.push({...currentPayoutArtist, exists: false});
      setPayoutArtists(a);
      setCurrentPayoutArtist()
        break; */
      default:
        break;
    }
  };

  const clearArtistForm = (type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        setArtistSearch();
        setCurrentArtist({});
        if (artistSearchRef?.current?.value) {
          artistSearchRef.current.value = "";
        }
        if (artistIdRef?.current?.value) {
          artistIdRef.current.value = "";
        }
        if (artistNameRef?.current?.value) {
          artistNameRef.current.value = "";
        }
        if (walletAddressRef?.current?.value) {
          walletAddressRef.current.value = "";
        }
        if (artistFullNameRef.current?.value) {
          artistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        setFArtistSearch();
        setCurrentFArtist({});
        if (fArtistSearchRef?.current?.value) {
          fArtistSearchRef.current.value = "";
        }
        if (fArtistIdRef?.current?.value) {
          fArtistIdRef.current.value = "";
        }
        if (fArtistNameRef?.current?.value) {
          fArtistNameRef.current.value = "";
        }
        if (fWalletAddressRef?.current?.value) {
          fWalletAddressRef.current.value = "";
        }
        if (fArtistFullNameRef.current?.value) {
          fArtistFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        setComposerSearch();
        setCurrentComposer({});
        if (composerSearch?.current?.value) {
          composerSearch.current.value = "";
        }
        if (composerIdRef?.current?.value) {
          composerIdRef.current.value = "";
        }
        if (composerNameRef?.current?.value) {
          composerNameRef.current.value = "";
        }
        if (composerWalletAddressRef?.current?.value) {
          composerWalletAddressRef.current.value = "";
        }
        if (composerFullNameRef.current?.value) {
          composerFullNameRef.current.value = "";
        }

        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        setProducerSearch();
        setCurrentProducer({});
        if (producerSearchRef?.current?.value) {
          producerSearchRef.current.value = "";
        }
        if (producerIdRef?.current?.value) {
          producerIdRef.current.value = "";
        }
        if (producerNameRef?.current?.value) {
          producerNameRef.current.value = "";
        }
        if (producerWalletAddressRef?.current?.value) {
          producerWalletAddressRef.current.value = "";
        }
        if (producerFullNameRef.current?.value) {
          producerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        setCurrentWriter({});
        setWriterSearch();
        if (writerSearchRef?.current?.value) {
          writerSearchRef.current.value = "";
        }
        if (writerIdRef?.current?.value) {
          writerIdRef.current.value = "";
        }
        if (writerNameRef?.current?.value) {
          writerNameRef.current.value = "";
        }
        if (writerWalletAddressRef?.current?.value) {
          writerWalletAddressRef.current.value = "";
        }
        if (writerFullNameRef.current?.value) {
          writerFullNameRef.current.value = "";
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        setCurrentPayoutArtist();
        setPayoutSearch();
        if (payoutArtistSearchRef?.current?.value) {
          payoutArtistSearchRef.current.value = "";
        }
        if (payoutArtistIdRef?.current?.value) {
          payoutArtistIdRef.current.value = "";
        }
        if (payoutArtistNameRef?.current?.value) {
          payoutArtistNameRef.current.value = "";
        }
        if (payoutArtistWalletAddressRef?.current?.value) {
          payoutArtistWalletAddressRef.current.value = "";
        }
        if (payoutArtistFullNameRef.current?.value) {
          payoutArtistFullNameRef.current.value = "";
        }
        if (artistPayoutRef.current?.value) {
          artistPayoutRef.current.value = 0.1;
        }
        break;
      default:
        break;
    }
  };

  const validateArtistInfo = (id, type) => {
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        if (validateArtistInput(currentArtist)) {
          artistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }

        break;
      case ARTIST_FORM_TYPES.FEATURE:
        if (validateArtistInput(currentFArtist)) {
          fArtistEditng ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        if (validateArtistInput(currentComposer)) {
          composerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        if (validateArtistInput(currentProducer)) {
          producerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.WRITER:
        if (validateArtistInput(currentWriter)) {
          writerEditing ? editArtist(id, type) : addArtist(type);
        } else {
        }
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        let total = 0;
        payoutArtists.map((a) => {
          total = parseFloat(a.payoutPercentage) + parseFloat(total);
          return;
        });
        //console.log(total)
        if (
          currentPayoutArtist?.payoutPercentage <= 100 &&
          currentPayoutArtist?.payoutPercentage > 0 &&
          parseFloat(total) +
            parseFloat(currentPayoutArtist.payoutPercentage) <=
            100
        ) {
          editArtist(id, type);
          clearArtistForm(type);
        } else {
          setCurrentArtist((prev) => {
            return { ...prev, error: "Invalid payout split" };
          });
        }
        break;
      default:
        break;
    }
    setCurrentArtist({});
    clearArtistForm(type);
  };

  const editArtist = (id, type) => {
    let a = {};
    let updatedArtists = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentArtist : artist
        );

        setArtists(updatedArtists);
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentFArtist : artist
        );

        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentComposer : artist
        );

        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentProducer : artist
        );

        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentWriter : artist
        );

        setWriters(updatedArtists);
        setWriterEditing(false);
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;

        updatedArtists = a.map((artist, index) =>
          index === id ? currentPayoutArtist : artist
        );

        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const removeArtist = (id, type) => {
    let a = [];
    let updatedArtists = [];
    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        if (containsObject(currentUserArtist, updatedArtists)) {
          setArtists(updatedArtists);
        } else {
          setArtists([currentUserArtist, ...updatedArtists]);
        }
        setArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setFeatArtists(updatedArtists);
        setFArtistEditing(false);
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setComposers(updatedArtists);
        setComposerEditing(false);
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setProducers(updatedArtists);
        setProducerEditing(false);
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setWriters(updatedArtists);
        setWriterEditing(false);

        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        updatedArtists = a.filter((artist, index) => {
          if (index !== id) return artist;
        });
        setPayoutArtists(updatedArtists);
        setPayoutEditing(false);
        break;
      default:
        break;
    }

    clearArtistForm(type);
  };

  const AddNEArtistsToSearch = (e) => {
    const a = [
      ...artists,
      ...featArtists,
      ...composers,
      ...producers,
      ...writers,
    ];
    const nEArtists = getUniqueListBy(
      a.filter((artist) => !artist.exists),
      "artistName"
    );
    let filteredArtists = [];

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.ID:
        filteredArtists = nEArtists.filter((artist) =>
          artist.id.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        filteredArtists = nEArtists.filter((artist) =>
          artist.address.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.artistName.includes(e.target.value)
        );
        break;

      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        filteredArtists = nEArtists.filter((artist) =>
          artist.fullName.includes(e.target.value)
        );
        break;
      default:
        break;
    }
    if (filteredArtists.length > 0) {
      return filteredArtists;
    } else return [];
  };

  const addGenre = (musicGenre) => {
    setGenre();
   
      setMainGenre(musicGenre);
  
    setGenreFilter();
  };

  const removeGenre = (id, subGenre) => {
 
      setMainGenre();
    
  };

  const selectArtist = (type, artist) => {
    let a = [];

    switch (type) {
      case ARTIST_FORM_TYPES.MAIN_ARTIST:
        a = artists;
        setCurrentArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setArtists(a);
        setArtistSearch();
        break;
      case ARTIST_FORM_TYPES.FEATURE:
        a = featArtists;
        setCurrentFArtist({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setFeatArtists(a);
        setFArtistSearch();
        break;
      case ARTIST_FORM_TYPES.COMPOSER:
        a = composers;
        setCurrentComposer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setComposers(a);
        setComposerSearch();
        break;
      case ARTIST_FORM_TYPES.PRODUCER:
        a = producers;
        setCurrentProducer({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setProducers(a);
        setProducerSearch();
        break;
      case ARTIST_FORM_TYPES.WRITER:
        a = writers;
        setCurrentWriter({ ...artist, exists: true });
        a.push({ ...artist, exists: true });
        setWriters(a);
        setWriterSearch();
        break;
      case ARTIST_FORM_TYPES.PAYOUT:
        a = payoutArtists;
        setCurrentPayoutArtist({
          ...artist,
          payoutPercentage: 0,
        });
        a.push({ ...artist, payoutPercentage: 0 });
        setPayoutArtists(a);

        setPayoutSearch();
        break;
      default:
        break;
    }
    clearArtistForm(type);
  };

  const handleArtistInput = (e, type) => {
    const cArtist = currentArtist;
    const cFArtist = currentFArtist;
    const cWriter = currentWriter;
    const cProducer = currentProducer;
    const cComposer = currentComposer;
    const cPayout = currentPayoutArtist;

    switch (e.target.name) {
      case ARTIST_FORM_INPUT_TYPES.NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }

            AddNEArtistsToSearch(e);
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cFArtist?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cComposer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cProducer?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, artistName: e.target.value });
            if (e.target.value.trim() !== "") {
              delete cWriter?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                },
                artistName: e.target.value,
              });
            }
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({
              ...cPayout,
              artistName: e.target.value,
            });
            if (e.target.value.trim() !== "") {
              delete cPayout?.errors?.name;
            } else {
              setCurrentArtist({
                ...cArtist,
                errors: {
                  ...cArtist?.errors,
                  name: "Artist name cannot be empty",
                  artistName: e.target.value,
                },
              });
            }
            break;
          default:
            break;
        }

        break;
      case ARTIST_FORM_INPUT_TYPES.FULL_NAME:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, fullName: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, fullName: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ID:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, artistId: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, artistId: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.ADDRESS:
        switch (type) {
          case ARTIST_FORM_TYPES.MAIN_ARTIST:
            setCurrentArtist({ ...cArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.FEATURE:
            setCurrentFArtist({ ...cFArtist, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.COMPOSER:
            setCurrentComposer({ ...cComposer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PRODUCER:
            setCurrentProducer({ ...cProducer, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.WRITER:
            setCurrentWriter({ ...cWriter, address: e.target.value });
            break;
          case ARTIST_FORM_TYPES.PAYOUT:
            setCurrentPayoutArtist({ ...cPayout, address: e.target.value });
            break;
          default:
            break;
        }
        break;
      case ARTIST_FORM_INPUT_TYPES.PAYOUT_PERCENTAGE:
        if (e.target.value?.length > 0 && e.target.value <= 100) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: parseInt(e.target.value),
          });
          return;
        }
        if (e.target.value?.length === 0) {
          setCurrentPayoutArtist({
            ...cPayout,
            payoutPercentage: 0,
          });
        }

        break;

      default:
        break;
    }
  };

  const searchInput = (e, type) => {
    if (e.target.value.length > 0 && type !== ARTIST_FORM_TYPES.PAYOUT) {
      searchArtists(e.target.name, e.target.value).then((data) => {
        if (data && (!data.error || data !== false)) {
          switch (type) {
            case ARTIST_FORM_TYPES.MAIN_ARTIST:
              setArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(artists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.FEATURE:
              setFArtistSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (featArtists) {
                    if (filterExistingArtist(featArtists, artist) === false) {
                      return artist;
                    }
                  } else {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.COMPOSER:
              setComposerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(composers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PRODUCER:
              setProducerSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(producers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.WRITER:
              setWriterSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(writers, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            case ARTIST_FORM_TYPES.PAYOUT:
              setPayoutSearch({
                type: e.target.name,
                artists: data.filter((artist) => {
                  if (filterExistingArtist(payoutArtists, artist) === false) {
                    return artist;
                  }
                }),
              });
              break;
            default:
              break;
          }
        } else {
          //Error check
        }
        return;
      });
    }

    if (type === ARTIST_FORM_TYPES.PAYOUT && e.target.value.length > 0) {
      setPayoutSearch({
        type: e.target.name,
        artists: displaySelectedArtists().filter((artist) => {
          let a = false;
          let pArtists = payoutArtists;

          switch (e.target.name) {
            case ARTIST_SEARCH_TYPES.NAME:
              if (
                artist?.artistName &&
                artist.artistName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                pArtists.some((a) =>
                  a.artistName.includes(artist.artistName)
                ) === false
              ) {
                a = true;
              }

              break;
            case ARTIST_SEARCH_TYPES.FULL_NAME:
              if (
                artist?.fullName &&
                artist?.fullName
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ADDRESS:
              if (
                artist?.address &&
                artist?.address
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            case ARTIST_SEARCH_TYPES.ID:
              if (
                artist?.artistId &&
                artist?.artistId
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()) &&
                filterExistingArtist(payoutArtists, artist) === false
              ) {
                a = true;
              }
              break;
            default:
              break;
          }
          if (a === true) {
            return artist;
          }
        }),
      });
    }
    if (e.target.value.length === 0) {
      switch (type) {
        case ARTIST_FORM_TYPES.MAIN_ARTIST:
          setArtistSearch();
          break;
        case ARTIST_FORM_TYPES.FEATURE:
          setFArtistSearch();
          break;
        case ARTIST_FORM_TYPES.COMPOSER:
          setComposerSearch();
          break;
        case ARTIST_FORM_TYPES.PRODUCER:
          setProducerSearch();
          break;
        case ARTIST_FORM_TYPES.WRITER:
          setWriterSearch();
          break;
        case ARTIST_FORM_TYPES.PAYOUT:
          setPayoutSearch();
          break;
        default:
          break;
      }
    }
  };

  const nextPage = () => {
    const currPage = pageNumber;
    if (currPage < 7) {
      setPageNum(currPage + 1);
    }
    window.scrollTo(0, 100);
  };

  const prevPage = () => {
    const currPage = pageNumber;
    if (currPage > 0) {
      setPageNum(currPage - 1);
    }
  };

  const cancelMusicUpload = () => {
    let vInfo = {
      title: videoTitle,
      sender: currentUser?.uid,
      isrc: isrc.trim(),
      artists,
      autoIsrcCode: autoIsrcCode,
      isrcType: isrcType,
      featuredArtists: featArtists,
      songCredits: {
        composers,
        producers,
        writers,
      },
      mainGenre: mainGenre,
      type: "draft",
      //isMintable: isMintable,
      explicit: isExplicit,
      licensing: license,
      duration: videoUpload.duration,
      payoutArtists: payoutArtists,
      videoUpload: videoUpload,
      thumbnail: thumbnail,
      about: about,
      uploadedBy: currentUser?.uid,
      videoId: videoId,
      releaseDate: releaseDate,
    };
    if (videoId?.length > 0 ||
      vInfo?.title?.length > 0 ||
      getObjectLength(vInfo?.videoUpload) > 0 ||
      getObjectLength(vInfo?.albumArt) > 0
    ) {
      if (!isOpen) {
        toggleModal();
      }
      modalContent(
        <>
          <OptionModal heading={"Are you sure you want to leave uploading"}>
            <HoverOption
              onClick={() => {
                navigate(-1);
                toggleModal();
                modalContent();
              }}
            >
              Leave
            </HoverOption>
            <HoverOption
              onClick={() => {
                toggleModal();
                modalContent();
              }}
            >
              Cancel
            </HoverOption>
            {videoId?.length > 0 && (
              <HoverOption
                onClick={() => {
                  toggleModal();
                  modalContent();
                  navigate(-1);
                  deleteDraft(videoId);
                }}
              >
                Leave and Delete Draft
              </HoverOption>
            )}
          </OptionModal>
        </>
      );
      return;
    }
    navigate(-1);
  };

  //Filter out existing artists in list when searching
  const filterExistingArtist = (artists1, searchArtist) => {
    let artistExists = false;
    artists1.map((artist) => {
      if (artist?.artistId === searchArtist.artistId) {
        artistExists = true;
      }
    });
    return artistExists;
  };

  const displaySelectedArtists = () => {
    let allEArtists = [
      ...artists.filter((a) => a.exists),
      ...featArtists.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...composers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...producers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
      ...writers.filter(
        (a) => a.exists && currentUserArtist?.artistId !== a?.artistId
      ),
    ];
    let allNEArtists = [
      ...artists.filter((a) => !a.exists),
      ...featArtists.filter((a) => !a.exists),
      ...composers.filter((a) => !a.exists),
      ...producers.filter((a) => !a.exists),
      ...writers.filter((a) => !a.exists),
    ];
    let allArtists = [
      ...getUniqueListBy(allEArtists, "artistId"),
      ...getUniqueListBy(allNEArtists, "artistName"),
    ];
    return allArtists;
  };

  const setCreativeCommons = (id) => {
    setLicense(id);
  };


  const validateSongInfo = () => {
    
    let vErrors = {};
    if ((videoTitle && videoTitle?.trim() === "") || !videoTitle) {
      vErrors = {
        ...vErrors,
        title: "You can't release a song without a name",
      };
    }
    if (
      !videoUpload ||
      Object.keys(videoUpload).length === 0 ||
      !videoUpload?.url ||
      (videoUpload?.progress > 0 && videoUpload?.progress < 100)
    ) {
      vErrors = {
        ...vErrors,
        videoUpload: "Upload a video before releasing",
      };
    }

    if(isrcType !== 'auto' && !isrcReg.test(isrc)){
      vErrors = {
        ...vErrors,
        isrc: "invalid ISRC code. Enter a valid ISRC or choose auto-generate option" ,
      };
    }
    if (!thumbnail || !thumbnail?.url || thumbnail?.url === false) {
      vErrors = {
        ...vErrors,
        thumbnail: "Upload thumbnail before releasing ",
      };
    }
    if (
      
        getObjectLength(payoutArtists) > 0 &&
        payoutArtists?.some((artist) => artist.payoutPercentage === 0)
    ) {
      vErrors = {
        ...vErrors,
        payoutArtists: "Please enter payout percentage for Artists",
      };
    }
    if (getObjectLength(payoutArtists) > 0 && payoutArtists?.balance > 100) {
      vErrors = {
        ...vErrors,
        payoutArtists: "payouts exceed 100%. please redistribute payouts",
      };
    }

    if (!mainGenre || Object.keys(mainGenre)?.length === 0 || !mainGenre?.id) {
      vErrors = {
        ...vErrors,
        genre: "Select a main genre",
      };
    }

    //console.log(vErrors)
    if (getObjectLength(vErrors) > 0) {
      setErrors(vErrors);
      return false;
    } else {
      return true;
    }
  };

  const cancelUpload = () => {
    modalContent();
    toggleModal();
    addAlert({
      title: "Video Upload",
      message: "Upload Cancelled",
      type: ALERT_TYPES.WARNING,
    });
    setSubmit(false);
    setHideButtons(false);
  };

  const onSuccess = (id) => {
    modalContent();
    toggleModal();
    setSubmit(true);
    setHideButtons(false);
    setVideoId(id);
    setErrors({ message: "Video Successfully Released" });
  };

  const onFailure = () => {
    modalContent();
    toggleModal();
    setSubmit(false);
    setHideButtons(false);
    setErrors({
      message: "Your submission was not successful, please try again",
    });
  };


  const uploadData = (type) => {
    let videoData = {};
    setHideButtons(true);
    try {
      setErrors({})
      if (videoTitle && videoTitle?.trim()?.length > 0 && artists?.length > 0) {
        switch (type) {
          case "draft":
            videoData = {
              title: videoTitle,
              sender: currentUser?.uid,
              isrc: isrc.trim(),
              artists,
              autoIsrcCode: autoIsrcCode,
              isrcType: isrcType,
              featuredArtists: featArtists,
              credits: {
                composers,
                producers,
                writers,
              },
              mainGenre: mainGenre,
              type: "draft",
              //isMintable: isMintable,
              explicit: isExplicit,
              license: license,
              duration: duration,
              payoutArtists: payoutArtists,
              videoUpload: videoUpload,
              song: song,
              about: about,
              uploadedBy: currentUser?.uid,
              videoId: videoId,
              releaseDate: releaseDate,
            };
            setVideoInfo({
              title: videoTitle,
              sender: currentUser?.uid,
              isrc: isrc.trim(),
              artists,
              autoIsrcCode: autoIsrcCode,
              isrcType: isrcType,
              featuredArtists: featArtists,
              songCredits: {
                composers,
                producers,
                writers,
              },
              mainGenre: mainGenre,
              type: "draft",
              //isMintable: isMintable,
              explicit: isExplicit,
              license: license,
              duration: duration,
              payoutArtists: payoutArtists,
              videoUpload: videoUpload,
              thumbnail: thumbnail,
              about: about,
              uploadedBy: currentUser?.uid,
              videoId: videoId,
              releaseDate: releaseDate,
            });
            addAlert({
              title: "Saving Draft",
              message: "Saving video to drafts...",
              type: ALERT_TYPES.INFO,
            });
            return saveVideoAsDraft(videoData)
              .then((data) => {
                if (data) {
                  setHideButtons(false);
                  setVideoId(data);
                  setPrevChanges(videoInfo);
                  const url = new URL(window.location.href);
                  const params = new URLSearchParams(url.search);
                  params.set("id", data);
                  navigate(`${location.pathname}?${params.toString()}`, {
                    replace: true,
                  });
                  addAlert({
                    title: "Draft Saved!",
                    message: "Video saved to drafts",
                    type: ALERT_TYPES.SUCCESS,
                  });
                  setErrors({ message: "Draft saved!" });
                  return;
                }
                setHideButtons(false);
                addAlert({
                  title: "Draft Error",
                  message: "Failed to save to drafts",
                  type: ALERT_TYPES.DANGER,
                });
              })
              .catch((err) => {
                console.error(err);
                setHideButtons(false);
                addAlert({
                  title: "Draft Error",
                  message: "Failed to save to drafts",
                  type: ALERT_TYPES.DANGER,
                });
              });
            break;
          case "publish":
            openModal();
            noCloseModal(
              <ModalLoadingScreen text={"Validating Upload. Please Wait..."} />
            );
            if (validateSongInfo()) {
             console.log({
              title: videoTitle.trim(),
              sender: currentUser?.uid,
              memo: `${currentUser?.uid} is submitting the video ${videoTitle} to be released`,
              instruction: `${currentUser?.uid} is submitting the video ${videoTitle} to be released`,
              isrc:
                isrcType === "custom"
                  ? isrc?.trim()?.length > 0
                    ? isrc?.trim()
                    : autoIsrcCode
                  : autoIsrcCode,
              artists,
              credits: {
                composers,
                producers,
                writers,
              },
              genre: mainGenre,
              //isMintable: isMintable,
              explicit: isExplicit ? isExplicit : undefined,
              royalties: payoutArtists,
              url: videoUpload.url,
              song: song?.songId ? song?.songId : undefined,
              duration: duration ? duration : videoUpload?.duration,
              videoRef: videoUpload.ref,
             thumbnail:thumbnail.url,
              thumbnailRef: thumbnail.ref,
         
              videoResolutions:
                getObjectLength(videoUpload) > 0 &&
                videoUpload?.videoResolutions?.length > 0
                  ? videoUpload?.videoResolutions
                  : undefined,
              about: about.trim(),
              uploadedBy: currentUser?.uid,
              videoId: videoId,
              licensing: license,
              releaseDate: releaseDate ? releaseDate : new Date()?.toISOString().split('T')[0],
              displayDate: releaseDate
                ? new Date(releaseDate).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0],
              type: "publish",
            })
              toggleModal();
              modalContent(
                <>
                  {" "}
                  <QRCodeForm
                    heading={`Release of ${videoTitle} by ${artists[0].artistName}`}
                    body={{
                      title: videoTitle.trim(),
                      sender: currentUser?.uid,
                      memo: `${currentUser?.uid} is submitting the video ${videoTitle} to be released`,
                      instruction: `${currentUser?.uid} is submitting the video ${videoTitle} to be released`,
                      isrc:
                        isrcType === "custom"
                          ? isrc?.trim()?.length > 0
                            ? isrc?.trim()
                            : autoIsrcCode
                          : autoIsrcCode,
                      artists,
                      credits: {
                        composers,
                        producers,
                        writers,
                      },
                      genre: mainGenre,
                      //isMintable: isMintable,
                      explicit: isExplicit ? isExplicit : undefined,
                      royalties: payoutArtists,
                      url: videoUpload.url,
                      song: song?.songId ? song?.songId : undefined,
                      duration: duration ? duration : videoUpload?.duration,
                      videoRef: videoUpload.ref,
                     thumbnail:thumbnail.url,
                      thumbnailRef: thumbnail.ref,
                 
                      videoResolutions:
                        getObjectLength(videoUpload) > 0 &&
                        videoUpload?.videoResolutions?.length > 0
                          ? videoUpload?.videoResolutions
                          : undefined,
                      about: about.trim(),
                      uploadedBy: currentUser?.uid,
                      videoId: videoId,
                      licensing: license,
                      releaseDate: releaseDate ? releaseDate : new Date()?.toISOString().split('T')[0],
                      displayDate: releaseDate
                        ? new Date(releaseDate).toISOString().split('T')[0]
                        : new Date().toISOString().split('T')[0],
                      type: "publish",
                    }}
                    cancelQr={cancelUpload}
                    onSuccess={onSuccess}
                    onFail={onFailure}
                    type={TRANSACTION_OPTIONS.MUSIC_UPLOAD.VIDEO}
                  ></QRCodeForm>
                </>
              );
            } else {
              closeModal();
              modalContent();
              setHideButtons(false);
              setErrors((prev) => {
                return {
                  ...prev,
                  message:
                    "Unable to release song. Please review your song information",
                };
              });
            }
            break;
          default:
            break;
        }
      } else {
        if (!artists || artists?.length === 0) {
          setErrors((prev) => {
            return { ...prev, artists: "No artist selected for video" };
          });
        }
        if (!videoTitle || videoTitle?.trim() === "") {
          setErrors((prev) => {
            return {
              ...prev,
              title: "You can't release a video witout a name",
            };
          });
        }
        setErrors((prev) => {
          return {
            ...prev,
            message: "Unable to upload. Please review your video information",
          };
        });
      }
    } catch (err) {
      console.error(err);
      setHideButtons(false);
      
    }
  };

  return (
    <>
    <MusicTitleInfoContainer>
    <MusicUploadTitleArtContainer>
            <MusicUploadAlbumArt
              src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
              alt={'thumbnail'}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />
          </MusicUploadTitleArtContainer>
          <SongDetailsSection>
          <AlbumUploadsContainer
            for={
              videoUpload?.progress &&
              videoUpload.progress > 0 &&
              !videoUpload?.url
                ? ""
                : "video"
            }
          >
            {videoUpload?.url && (
              <span style={{ display: "flex", flexDirection: "column" }}>
                <SongDetailsText>Replace Audio File</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Upload Complete
                </SongDetailsText>
              </span>
            )}
            {!videoUpload?.url && !videoUpload.progress && (
              <>
                <SongDetailsText>Add Video File</SongDetailsText>
                <SongDetailsText style={{ fontStyle: "italic" }}>
                  Select any video format .mp4, .mpeg etc.
                </SongDetailsText>
              </>
            )}
            {!videoUpload?.url && videoUpload.progress > 0 && (
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <SongDetailsText>Uploading...</SongDetailsText>
                <SongDetailsText
                  style={{ padding: "2%", textDecoration: "underline" }}
                  onClick={() => {}}
                >
                  Cancel
                </SongDetailsText>
              </span>
            )}
           <FileUploader
                  id="video"
                  fileUpload={videoUpload}
                  userType={"artists"}
                  setFileUpload={setVideoUpload}
                  setDuration={setDuration}
                  name="video"
                  fileTypes={"video/mp4,video/x-m4v,video/*"}
                  acceptMultiple="false"
                />
          </AlbumUploadsContainer>
          {videoUpload?.progress > 0 && !videoUpload?.url && (
            <>
              <UploadProgressWrapper>
                <PushSpinner loading={videoUpload?.url ? false : true} />

                <SongDetailsText style={{ fontStyle: "italic", padding: "2%" }}>
                  {videoUpload?.progress > 0 &&
                    videoUpload?.progress < 100 &&
                    !videoUpload?.url &&
                    `${videoUpload?.progress.toFixed(2)}%`}
                  {videoUpload?.progress === 100 &&
                    !videoUpload?.url &&
                    `Processing...`}
                </SongDetailsText>
              </UploadProgressWrapper>
            </>
          )}
          <MusicUploadInfoText>
            {videoUpload?.file?.name ? videoUpload?.file?.name : videoUpload?.name ? videoUpload?.name : ""}
          </MusicUploadInfoText>
        </SongDetailsSection>
    </MusicTitleInfoContainer>
    <SongDetailsContainer><SongDetailsWrapper>
      <FormWrapper>
       { pageNumber === 1 && <Page1 
      isExistingSong={isExistingSong} 
      videoTitle={videoTitle} 
      handleInput={handleInput}
      isAIGenerated={isAIGenerated}
      isExplicit={isExplicit} 
      handleToggle={handleToggle} 
      genre={genre}
      addGenre={addGenre}
      removeGenre={removeGenre}
      mainGenre={mainGenre}
      filteredGenres={filteredGenres}
      genreRef={genreRef}
      isrc={isrc}
      errors={errors}
      aboutRef={aboutRef}
      isrcType={isrcType}
      isrcRef={isrcRef}
      releaseDate={releaseDate}
      releaseDateRef={releaseDateRef}
      index={'video'}
      />
   }
      { pageNumber === 2 && <Page2  index={"video"}
                  artists={artists}
                  artistSearchRef={artistSearchRef}
                  removeArtist={removeArtist}
                  clearArtistForm={clearArtistForm}
                  setCurrentArtist={setCurrentArtist}
                  setArtistEditing={setArtistEditing}
                  handleInput={handleArtistInput}
                  searchInput={searchInput}
                  currentArtist={currentArtist}
                  walletAddressRef={walletAddressRef}
                  artistSearch={artistSearch}
                  artistNameRef={artistNameRef}
                  artistFullNameRef={artistFullNameRef}
                  artistIdRef={artistIdRef}
                  selectArtist={selectArtist}
                  validateArtistInfo={validateArtistInfo}
                  artistEditng={artistEditng}
                  composers={composers}
                  composerSearchRef={composerSearchRef}
                  setComposerEditing={setComposerEditing}
                  setCurrentComposer={setCurrentComposer}
                  handleArtistInput={handleArtistInput}
                  currentComposer={currentComposer}
                  composerEditing={composerEditing}
                  composerWalletAddressRef={composerWalletAddressRef}
                  composerSearch={composerSearch}
                  composerNameRef={composerNameRef}
                  composerIdRef={composerIdRef}
                  composerFullNameRef={composerFullNameRef}
                  writerEditing={writerEditing}
                  writers={writers}
                  writerSearchRef={writerSearchRef}
                  setCurrentWriter={setCurrentWriter}
                  setWriterEditing={setWriterEditing}
                  currentWriter={currentWriter}
                  writerWalletAddressRef={writerWalletAddressRef}
                  writerSearch={writerSearch}
                  writerNameRef={writerNameRef}
                  writerFullNameRef={writerFullNameRef}
                  writerIdRef={writerIdRef}
                  producerSearchRef={producerSearchRef}
                  producers={producers}
                  setCurrentProducer={setCurrentProducer}
                  setProducerEditing={setProducerEditing}
                  currentProducer={currentProducer}
                  producerWalletAddressRef={producerWalletAddressRef}
                  producerSearch={producerSearch}
                  producerNameRef={producerNameRef}
                  producerFullNameRef={producerFullNameRef}
                  producerIdRef={producerIdRef}
                  producerEditing={producerEditing}
                  errors={errors}/>
      }
 
      { pageNumber === 3 && <Page3  license={license}
                  setCreativeCommons={setCreativeCommons}/>}
      
    
      { pageNumber === 4 && <Page4    payoutArtists={payoutArtists}
              
                  errors={errors}
                  setErrors={setErrors}
                  payoutArtistSearchRef={payoutArtistSearchRef}
                  removeArtist={removeArtist}
                  clearArtistForm={clearArtistForm}
                  setCurrentPayoutArtist={setCurrentPayoutArtist}
                  setPayoutEditing={setPayoutEditing}
                  handleArtistInput={handleArtistInput}
                  searchInput={searchInput}
                  currentPayoutArtist={currentPayoutArtist}
                  payoutArtistFullNameRef={payoutArtistFullNameRef}
                  payoutArtistWalletAddressRef={payoutArtistWalletAddressRef}
                  payoutSearch={payoutSearch}
                  payoutArtistNameRef={payoutArtistNameRef}
                  payoutArtistIdRef={payoutArtistIdRef}
                  artistPayoutRef={artistPayoutRef}
                  selectArtist={selectArtist}
                  validateArtistInfo={validateArtistInfo}
                  payoutEditing={payoutEditing}
                  
                  />}
      
      </FormWrapper>
      

    </SongDetailsWrapper>
    {(
          <SongArtCoverWrapper>
            <>
              <SongDetailsSectionTitle>Thumbnail</SongDetailsSectionTitle>

              <AlbumUploadsContainer
                for={
                  thumbnail?.progress > 0 && !thumbnail?.url ? "" : "thumbnail"
                }
              >
                <span style={{ display: "flex" }}>
                  <SongDetailsText>
                    {thumbnail?.url ? (
                      `${thumbnail?.file?.name}`
                    ) : (
                      <small style={{ fontStyle: "italic" }}>
                        Click here to add thumbnail (Size should be at least
                        1600 x 1600 pixels){" "}
                      </small>
                    )}
                  </SongDetailsText>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Cancel
                    </SongDetailsText>
                  )}
                  {thumbnail?.url && (
                    <SongDetailsText style={{ textDecoration: "underline" }}>
                      Replace
                    </SongDetailsText>
                  )}
                </span>
                <AlbumArtPreviewContainer thumbnail={thumbnail?.url}>
                  {thumbnail?.progress > 0 && !thumbnail?.url && (
                    <>
                      <LoadingScreen
                        transparent
                        isLoaded={thumbnail.url ? true : false}
                      />
                      {thumbnail.progress < 100
                        ? `${thumbnail?.progress.toFixed(2)}%`
                        : `Processing`}
                    </>
                  )}
                  {(thumbnail?.url || !thumbnail?.progress) && (
                    <>
                      <AlbumArtPreview
                        src={thumbnail?.url ? thumbnail.url : defaultAlbumArt}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </>
                  )}
                </AlbumArtPreviewContainer>
                <FileUploader
                  id="thumbnail"
                  type={"file"}
                  name="thumbnail"
                  fileTypes={"image/x-png,image/gif,image/jpeg, image/jpg"}
                  acceptMultiple="false"
                  minHeight={720}
                  minWidth={1280}
                  fileUpload={thumbnail}
                  setFileUpload={setThumbnail}
                  userType={"artist"}
                />
              </AlbumUploadsContainer>
            </>
          </SongArtCoverWrapper>
        )}</SongDetailsContainer>
            {errors.message && (
        <ErrorWrapper
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <SongDetailsText error>{errors.message}</SongDetailsText>
        </ErrorWrapper>
      )}
      <PageIndexer pages={4} currentPage={pageNumber} onClick={setPageNum} />
      <MusicUploadsButtonContainer>
        {!isSubmitted && (
          <MusicUploadsButton
            backgroundColor={hideButtons && themeColors.secondaryColor}
            outline={!hideButtons}
            to={"#"}
            onClick={() => (hideButtons ? {} : uploadData("draft"))}
          >
            Save Draft
          </MusicUploadsButton>
        )}

        {pageNumber > 1 && !isSubmitted && (
          <MusicUploadsButton outline onClick={() => prevPage()}>
            Prev
          </MusicUploadsButton>
        )}
        {pageNumber < 4 && pageNumber > 1 && !isSubmitted && (
          <MusicUploadsButton
            outline
            onClick={() => {
              nextPage();
              return;
            }}
          >
            Next
          </MusicUploadsButton>
        )}
        {pageNumber === 4 && (
          <MusicUploadsButton
            outline={!hideButtons}
            onClick={() => {
            if (isSubmitted === true && videoId) {
              
                navigate(`/video/${videoId}`);
                return;
              } else {
                if (hideButtons) {
                  return;
                }
                uploadData("publish");
                return;
              } 
            }}
          >
            {isSubmitted === true ? "View Release" :  "Release Video"}
          </MusicUploadsButton>
        )}
        {!isSubmitted && (
          <MusicUploadsButton outline onClick={() => cancelMusicUpload()}>
            Cancel
          </MusicUploadsButton>
        )}
      </MusicUploadsButtonContainer>
    </>
  )
}

export default VideoUploads