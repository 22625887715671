import React from 'react'
import { AlbumUploadsContainer, MusicUploadAlbumArt, MusicUploadContentTitle, MusicUploadInfoText, MusicUploadTitleArtContainer, MusicUploadTitleTextWrapper, SongDetailsSection, SongDetailsText, UploadProgressWrapper } from '../../../MusicUploadsForm/MusicUploadsFormElements'
import albumArtPlaceholder from "../../../../images/sm_album_art_placeholder.png";
import { MdExplicit } from 'react-icons/md';
import FileUploader from '../../../FileUploader';
import { PushSpinner } from 'react-spinners-kit';

const Page0 = ({
    albumArt,
    songName,
    featArtists,
    artists,
    isExplicit,
    releaseDate,
    isDownloadable,
    price,
    currency,
    songUpload,
    setSong,
    setDuration,

}) => {
  return (
    <>
    <>
    <MusicUploadTitleArtContainer>
      <MusicUploadAlbumArt
        src={albumArt?.url ? albumArt.url : albumArtPlaceholder}
        alt={songName}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
    </MusicUploadTitleArtContainer>
    <MusicUploadTitleTextWrapper>
      <MusicUploadContentTitle style={{ fontStyle: "italic" }}>
        {songName.trim().length > 0
          ? featArtists.length > 0
            ? songName +
              " (ft. " +
              featArtists.map((artist, index) => {
                if (index === featArtists.length - 2) {
                  return artist.artistName + " & ";
                } else if (index < featArtists.length - 2) {
                  return artist.artistName + ", ";
                } else if (index === featArtists.length - 1) {
                  return artist.artistName;
                }
              }) +
              ")"
            : songName
          : "Song Name"}{" "}
        {isExplicit && <MdExplicit />}
      </MusicUploadContentTitle>
      <>
        <MusicUploadInfoText>
          <>
            {artists?.length > 0 &&
              artists.map((artist, index) => {
                if (index === artists.length - 2) {
                  return artist.artistName + " & ";
                } else if (index === artists.length - 1) {
                  return artist.artistName;
                } else if (index <= artists.length - 1) {
                  return artist.artistName + ", ";
                }
              })}
          </>
        </MusicUploadInfoText>
      </>
      <MusicUploadInfoText>
        {releaseDate
          ? new Date(releaseDate).toDateString()
          : "Release Date"}
      </MusicUploadInfoText>
      {isDownloadable === true && (
        <MusicUploadInfoText>
          {price ? `${price} ${currency}` : "Price"}
        </MusicUploadInfoText>
      )}
    </MusicUploadTitleTextWrapper>
  </>
  <SongDetailsSection>
    <AlbumUploadsContainer
      for={
        songUpload?.progress &&
        songUpload.progress > 0 &&
        !songUpload?.url
          ? ""
          : "songUpload"
      }
    >
      {songUpload?.url && (
        <span style={{ display: "flex", flexDirection: "column" }}>
          <SongDetailsText>Replace Audio File</SongDetailsText>
          <SongDetailsText style={{ fontStyle: "italic" }}>
            Upload Complete
          </SongDetailsText>
        </span>
      )}
      {!songUpload?.url && !songUpload.progress && (
        <>
          <SongDetailsText>Add Track</SongDetailsText>
          <SongDetailsText style={{ fontStyle: "italic" }}>
            Select lossless format .wav, .aif or .flac
          </SongDetailsText>
        </>
      )}
      {!songUpload?.url && songUpload.progress > 0 && (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SongDetailsText>Uploading...</SongDetailsText>
          <SongDetailsText
            style={{ padding: "2%", textDecoration: "underline" }}
            onClick={() => {}}
          >
            Cancel
          </SongDetailsText>
        </span>
      )}
      <FileUploader
        id={"songUpload"}
        name={"songUpload"}
        fileTypes={".wav"}
        userType={"artists"}
        setDuration={setDuration}
        fileUpload={songUpload}
        setFileUpload={setSong}
        acceptMultiple={false}
      />
    </AlbumUploadsContainer>
    {songUpload?.progress > 0 && !songUpload?.url && (
      <>
        <UploadProgressWrapper>
          <PushSpinner loading={songUpload?.url ? false : true} />

          <SongDetailsText style={{ fontStyle: "italic", padding: "2%" }}>
            {songUpload?.progress > 0 &&
              songUpload?.progress < 100 &&
              !songUpload?.url &&
              `${songUpload?.progress.toFixed(2)}%`}
            {songUpload?.progress === 100 &&
              !songUpload?.url &&
              `Processing...`}
          </SongDetailsText>
        </UploadProgressWrapper>
      </>
    )}
    <MusicUploadInfoText>
      {songUpload?.file?.name ? songUpload?.file?.name : songUpload?.name}
    </MusicUploadInfoText>
  </SongDetailsSection>
  </>
  )
}

export default Page0