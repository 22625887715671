import React from 'react'
import { AlbumDetailsSection, AlbumDetailsSectionTitle } from '../../../MusicUploadsForm/MusicUploadsFormElements'
import UserRoyaltyForm from '../../../MusicUploadsForm/UserRoyaltyForm'

const Page6 = ({isUserRoyalties, userRoyalties, setUserRoyalties, errors, setErrors}) => {
  return (
    <>
    <AlbumDetailsSection>
        <AlbumDetailsSectionTitle>
            User Royalties
        </AlbumDetailsSectionTitle>
        {isUserRoyalties && <UserRoyaltyForm royaltyInfo={userRoyalties} setRoyaltyInfo={setUserRoyalties} errors={errors} setErrors={setErrors} index={'album'}/>}
    </AlbumDetailsSection>
    
    </>
  )
}

export default Page6