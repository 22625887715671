import React, { useEffect, useState } from 'react'
import { ContentListContainer, DetailsContainer, DetailsText,  Img as Img1, ImageWrapper, NumButton, Table, TableData, TableHeading, TableRow } from '../ContentList/ContentListElements'
import { FormInput, FormWrapper } from '../CustomForm/CustomFormElements'
import { Img } from '../Chip/ChipElements'
import { DEFAULT_IMAGE } from '../GravatarAPI'
import { ARTIST_SEARCH_TYPES, CONTENT_TYPES, PODCASTER_SEARCH_TYPES } from '../../utils/Enum'
import { ModalLoadingScreen } from '../LoadingScreen'
import useArtist from '../../hooks/useArtist'
import OptionModal from '../OptionModal'
import { useModal } from '../Modal/ModalContext'
import useMusic from '../../hooks/useMusic'
import { ListContainer } from '../MusicUploadsForm/MusicUploadsFormElements'
import ContentList from '../ContentList'
import usePodcaster from '../../hooks/usePodcaster'
import { convertDuration, getArtistsNames2, getSongTitle } from '../../utils'
import { Theme } from '../WebsiteThemeElements'
import { MdExplicit } from 'react-icons/md'
import ImageUtils from '../../utils/ImageUtils'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import { useAuth } from '../../contexts/AuthContext'

const filterExisting = (type, content, val) => {
    let contentExists = false;
    switch (type) {
        case "artist":
            content.map((content) => {
                if (content?.artistId === val.artistId) {
                  contentExists = true;
                }
              });
            break;
        case "sub genre":
            content.map((content) => {
                if (content?.id === val.id) {
                  contentExists = true;
                }
              });
            break;
        case 'podcaster':
            content.map((content) => {
                if (content?.podcasterId === val.podcasterId) {
                  contentExists = true;
                }
              });
            break;
        case 'podcast genre':
            content.map((content) => {
                if (content?.id === val.id) {
                  contentExists = true;
                }
              });
            break;
    
        default:
            break;
    }
    
    return contentExists;
}

export const ArtistSearchModal = ({type,onClick, existingArtists}) => {
    const [isLoaded, setLoaded] = useState(false)
    const [artists, setArtists] = useState({})
    const [artistSearch, setArtistSearch] = useState({})
    const [searchInput, setSearchInput] = useState('')
    const {getArtists} = useArtist({})
    const {modalContent, closeModal} = useModal()


    useEffect(() => {
        getArtists('all').then((a) => {
            setArtists({artists: a.artists.filter(artist => !filterExisting('artist', existingArtists, artist))})
            setArtistSearch({artists: a.artists.filter(artist => !filterExisting('artist', existingArtists, artist))})
            setLoaded(true)
        }).catch(err => {
            setLoaded(true)
        })
    
      return () => {
        
      }
    }, [type])

    const handleInput = (e) => {
        setSearchInput(e.target.value)
        if(e?.target?.value?.length > 0){
            switch (type) {
                case ARTIST_SEARCH_TYPES.NAME:
                    setArtistSearch({artists: artists?.artists?.filter(a => a?.artistName?.toLowerCase().includes(e.target.value?.toLowerCase()) && !filterExisting('artist', existingArtists, a))})
                    break;
                case ARTIST_SEARCH_TYPES.ADDRESS:
                    setArtistSearch({artists:artists?.artists?.filter(a => a?.uid === e.target.value && !filterExisting('artist', existingArtists, a))})
                    break;
                case ARTIST_SEARCH_TYPES.FULL_NAME:
                    setArtistSearch({artists:artists?.artists?.filter(a => a?.fullName?.toLowerCase()?.includes(e.target.value?.toLowerCase()) && !filterExisting('artist', existingArtists, a))})

                    break;
                case ARTIST_SEARCH_TYPES.ID:
                    setArtistSearch({artists:artists?.artists?.filter(a => a?.artistId === e.target.value && !filterExisting('artist', existingArtists, a))})

                    break;
            
                default:
                    break;
            }
            
        }
    }
    
    const displaySearch = () => {
        return (<FormWrapper>
            
                 <ContentListContainer >
                 <>
                <Table>
                    <TableRow>
                        <TableHeading>

                        </TableHeading>
                        <TableHeading>
                            </TableHeading>
                        <TableHeading>
                        </TableHeading>
                            
                    </TableRow>
                {artistSearch?.artists?.length > 0 && artistSearch?.artists.map((artist, index) => {
                    return(
                    <>
                        <TableRow key={index} id={artist.artistId} onClick={() => onClick(artist)}>
                    <TableData>
                        <Img isCircular={true} src={artist?.photoUrl ? artist.photoUrl : DEFAULT_IMAGE(50)}  alt={artist?.artistName} />
                    </TableData>
                    <TableData>
                        <DetailsContainer>
                            <DetailsText>{artist.artistName}</DetailsText>
                        </DetailsContainer>
                    </TableData>
    
                </TableRow></>)
                })}
                {
                  artistSearch?.artists?.length === 0 &&   <TableRow>No Artist Found</TableRow>
                }
                </Table>
                </>
                </ContentListContainer>
         </FormWrapper>)
    }

    return (
        <OptionModal heading={'Artist Search'}>
        {isLoaded && <>
        <FormInput name={type} placeholder={`search for artist by ${type}`} onChange={handleInput} value={searchInput} />
        {displaySearch()}
        </>}
        {!isLoaded && <>
        <ModalLoadingScreen text={'Loading Artists'}/>
        </>}
        </OptionModal>
      )
}

export const ArtistMusicSearchModal = ({onClick}) => {
    const [isLoaded, setLoaded] = useState(false)
    const [music, setMusic] = useState([])

    useEffect(() => {
        
      
        return () => {
          
        }
      }, [])

  return (
    <>{isLoaded && <>
    </>}
    {!isLoaded && <>
    </>}
    </>
  )
}

export const MusicGenreSearchModal = ({type, onClick, existingGenres}) => {
    const [isLoaded, setLoaded] = useState(false)
    const [genres, setGenres] = useState([])
    const [filteredGenres, setFilteredGenres] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const {getAllGenres} = useMusic()
    const {modalContent, closeModal} = useModal()

    useEffect(() => {
        getAllGenres().then(data => {
            if(type === 'sub genre'){
                setGenres(data?.subGenres)
                setFilteredGenres(data?.subGenres.filter(g => !filterExisting('podcast genre', existingGenres, g)))
            }else{
                setGenres(data?.genres)
                setFilteredGenres(data?.genres)
            }
        
        setLoaded(true)
      }).catch(err => {
        setGenres()
        setLoaded(true)
    })
    return () => {}
      }, [type])

    const handleInput = (e) => {
        if (e.target.value?.trim()?.length > 0) {
            if(type === 'sub genre'){
                setFilteredGenres(genres.filter(g => g.name?.toLowerCase().includes(e.target?.value?.toLowerCase()) && !filterExisting(type, existingGenres, g)));
            }else{
                setFilteredGenres(genres.filter(g => g.name?.toLowerCase().includes(e.target?.value?.toLowerCase())));

            }
          }
          setSearchInput(e.target.value);
      }

    const displaySearch = () => {
        return(<FormWrapper>
                    <ListContainer>
                      <ContentList
                        type={CONTENT_TYPES.GENRE}
                        content={filteredGenres}
                        onButtonClick={(genre) => {
                            closeModal()
                            modalContent()
                            onClick(genre)
                        }}
                      />
                    </ListContainer>
                  </FormWrapper>)
      }

      return (
        <OptionModal heading={'Genre Search'}>{isLoaded &&  <>
        <FormInput value={searchInput} placeholder={`search for ${type}`} onChange={handleInput}/>
        {filteredGenres?.length > 0 && displaySearch()}
        </>}
        {!isLoaded && <>
            <ModalLoadingScreen text={'Loading Genres'}/>

        </>}
        </OptionModal>
      )

}

export const PodcasterSearchModal = ({onClick, existingPodcaster, type}) => {
    const [isLoaded, setLoaded] = useState(false)
    const [podcasters, setPodcasters] = useState([])
    const [podcasterSearch, setPodcasterSearch] = useState([])
    const [searchInput, setSearchInput] = useState()
    const {getPodcasters} = usePodcaster({})
    const {modalContent, closeModal} = useModal()

    useEffect(() => {
        getPodcasters('all').then((p) => {
            //console.log(p)
            if(p){
            setPodcasters(p)
            setPodcasterSearch(p)
        }
            setLoaded(true)
        }).catch(err => {
            setLoaded(true)
        })
    
      return () => {
        
      }
    }, [])

    const handleInput = (e) => {
        setSearchInput(e.target.value)
        if(e?.target?.value?.length > 0){
            switch (type) {
                case PODCASTER_SEARCH_TYPES.NAME:
                    setPodcasterSearch(podcasters?.filter(a => a?.podcasterName?.toLowerCase().includes(e.target.value?.toLowerCase()) && !filterExisting('podcaster', existingPodcaster, a)))
                    break;
                case PODCASTER_SEARCH_TYPES.ADDRESS:
                    setPodcasterSearch(podcasters?.filter(a => a?.uid === e.target.value && !filterExisting('podcaster', existingPodcaster, a)))
                    break;
                case PODCASTER_SEARCH_TYPES.ID:
                    setPodcasterSearch(podcasters?.filter(a => a?.podcasterId === e.target.value && !filterExisting('podcaster', existingPodcaster, a)))

                    break;
            
                default:
                    break;
            }
            
        }
    }

    const displaySearch = () => {
        return (<FormWrapper>
                
            <ContentListContainer >
            <>
           <Table>
               <TableRow>
                   <TableHeading>

                   </TableHeading>
                   <TableHeading>
                       </TableHeading>
                   <TableHeading>
                   </TableHeading>
                       
               </TableRow>
           {podcasterSearch?.map((podcaster, index) => {
               return(
               <>
                   <TableRow key={index} id={podcaster.podcasterId} onClick={() => {
                    onClick(podcaster)
                    closeModal()
                    modalContent()
                    }}>
               <TableData>
                   <Img isCircular={true} src={podcaster?.photoUrl ? podcaster.photoUrl : DEFAULT_IMAGE(50)}  alt={podcaster?.podcasterName} />
               </TableData>
               <TableData>
                   <DetailsContainer>
                       <DetailsText>{podcaster.podcasterName}</DetailsText>
                   </DetailsContainer>
               </TableData>

           </TableRow></>)
           })}
           </Table>
           </>
           </ContentListContainer>
    </FormWrapper>)

    }

    return (
        <OptionModal heading={'Podcaster Search'}>
        {isLoaded && <>
        <FormInput name={type} placeholder={`search for podcaster by ${type}`} onChange={handleInput} value={searchInput} />
        {displaySearch()}
        </>}
        {!isLoaded && <>
        <ModalLoadingScreen text={'Loading Podcasters'}/>
        </>}
        </OptionModal>
      )

}

export const PodcasterGenreSearchModal = ({onClick, existingGenres}) => {
    const [isLoaded, setLoaded] = useState(false)
    const [genres, setGenres] = useState([])
    const [filteredGenres, setFilteredGenres] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const {modalContent, closeModal} = useModal()
    const {getPodcastGenres} = useMusic({})

    useEffect(() => {
        getPodcastGenres().then(data => {
            //console.log(data)
                setGenres(data.genres.filter(g =>!filterExisting('podcast genre', existingGenres, g)))
                setFilteredGenres(data.genres.filter(g =>!filterExisting('podcast genre', existingGenres, g)))
           
        setLoaded(true)
      }).catch(err => {
        setGenres()
        setLoaded(true)
    })
}, [])


    const handleInput = (e) => {
        if (e.target.value?.trim()?.length > 0) {
            
            setFilteredGenres(genres.filter(g => g.name?.toLowerCase().includes(e.target?.value?.toLowerCase()) && !filterExisting('podcast genre', existingGenres, g)));
            
          }
          setSearchInput(e.target.value);
      }

      const displaySearch = () => {
        return (
            <>
            {
                    <FormWrapper>
                      <ListContainer>
                        <ContentList
                          type={CONTENT_TYPES.GENRE}
                          content={filteredGenres}
                          onButtonClick={(genre) => {
                            closeModal()
                            modalContent()
                            onClick(genre)
                        }}
                        />
                      </ListContainer>
                    </FormWrapper>
                  }
              </>
    )
      };

    return (
        <OptionModal heading={'Genre/Topic Search'}>{isLoaded && <>
        <FormInput value={searchInput} placeholder={`Search for genre`} onChange={handleInput}/>
        {
            filteredGenres?.length > 0 && displaySearch()
        }
        </>}
        {!isLoaded && <>
            <ModalLoadingScreen text={'Loading Genres'}/>
        </>}
        </OptionModal>
      )

}

export const PodcastMusicSearchModal = ({searchType, onClick, selectedSongs}) => {
  const {themeColors} = useWebsiteTheme()

    const [isLoaded, setLoaded] = useState(false)
    const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
    const [searchedSongs, setSearchedSongs] = useState([])
    const [searchedPlaylists, setSearchedPlaylists] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const {modalContent, closeModal} = useModal()
    const { searchSongsByArtist, searchSongsByName, searchPlaylistsByName, getSongs } = useMusic();



    const handleInput = (e) => {
        setSearchInput(e.target.value);
        setLoaded(false)
        if (e.target.value?.trim().length > 0) {
          switch (searchType) {
            case "Song":
              setLoaded(false);
              searchSongsByName(e.target.value)
                .then((songs) => {
                  if (songs?.length > 0) {
                    setSearchedSongs(songs.filter(song => !selectedSongs.some(sSongs => sSongs?.songId === song?.songId)));
                  } else {
                    return;
                  }
                })
                .catch((err) => {
                  console.error(err);
                  return;
                });
              break;
            case "Playlist":
              setLoaded(false);
              searchPlaylistsByName(e.target.value)
                .then((playlists) => {
                   if (playlists?.length > 0) {
                    setSearchedPlaylists(playlists?.filter(p => p?.songs?.length > 0));
                  } else {
                    return;
                  }
                })
                .catch((err) => {
                  console.error(err);
                  return;
                });
              break;
            case "ARTIST":
              searchSongsByArtist(e.target.value)
                .then((songs) => {
                  if (songs?.length > 0) {
                    setSearchedSongs(songs);
                  } else {
                    return;
                  }
                })
                .catch((err) => {
                  console.error(err);
                  return;
                });
              break;
    
            default:
              break;
          }
          setLoaded(true)
          return
        }
        setSearchedSongs([])
      };

      const displaySearch = () => {
        return (
            <>
            <Table>
              {searchedSongs.map((song, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={
                        () => 
                            {closeModal()
                            onClick(song)
                        }
                      }
                   
                    id={song.songId}>
                    <TableData
                      
                    >
                      <NumButton
                        
                      >
                        {
                          index + 1
                        }
                      </NumButton>
                    </TableData>
                    <TableData
                      style={{ width: "10%" }}
                    >
                      <ImageWrapper>
                        <Img1
                          src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                          alt={song?.songName}
                        />
                      </ImageWrapper>
                    </TableData>
                    <TableData>
                      <DetailsContainer>
                        <DetailsText>
                          {song?.songId
                            ? getSongTitle(song)?.length > 25
                              ? `${getSongTitle(song)?.substring(0, 25)}...`
                              : getSongTitle(song)
                            : "Song Name"}
                          
                        </DetailsText>
                        <DetailsText
                          style={{ color: themeColors.secondaryColor }}>
                            {song?.explicit && (
                            <MdExplicit style={{ marginLeft: "1%" }} />
                          )}
                          <small>
                            {(song?.artists || song?.mainArtists) &&
                              getArtistsNames2(
                                song?.artists ? song?.artists : song?.mainArtists,
                                ARTIST_SEARCH_TYPES.NAME
                              )}
                          </small>
                        </DetailsText>
                        {song?.album && (
                        <DetailsText style={{ justifyContent: "flex-start", cursor: 'pointer' }}>
                          <small>from {song?.album?.albumName}</small>
                        </DetailsText>
                      )}
                      </DetailsContainer>
                    </TableData>
                    <TableData>
                      
      
                      {song?.duration && (
                        <DetailsText>
                          <small>{convertDuration(song?.duration)}</small>
                        </DetailsText>
                      )}
                    </TableData>
                    <TableData>
                      <div>
                        
                      </div>
                    </TableData>
                    <TableData>
                      
                    </TableData>
                  </TableRow>
                );
              })}
              </Table>
              </>
    )
      };

      const displayPlaylistSearch = () => {
        return (
            <>
            <Table>
              {searchedPlaylists.map((playlist, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={
                        () => {onClick(playlist)}
                      }
                   
                    id={playlist.playlistId}>
                    <TableData
                      
                    >
                      <NumButton
                        
                      >
                        {
                          index + 1
                        }
                      </NumButton>
                    </TableData>
                    <TableData
                      style={{ width: "10%" }}
                    >
                      <ImageWrapper>
                        <Img1
                          src={playlist?.playlistArt ? playlist.playlistArt : defaultAlbumArt}
                          alt={playlist?.playlistName}
                        />
                      </ImageWrapper>
                    </TableData>
                    <TableData>
                      <DetailsContainer>
                        <DetailsText>
                          {playlist?.playlistId
                            ? playlist?.playlistName
                            : "Playlist Name"}
                          
                        </DetailsText>
                        <DetailsText
                          style={{ color: themeColors.secondaryColor }}>
                            {playlist?.explicit && (
                            <MdExplicit style={{ marginLeft: "1%" }} />
                          )}
                          <small>
                            {playlist?.curatorId}
                          </small>
                        </DetailsText>
                        {playlist?.collaborators && (
                        <DetailsText style={{ justifyContent: "flex-start", cursor: 'pointer' }}>
                          <small>and {playlist?.collaborators?.length} other(s)</small>
                        </DetailsText>
                      )}
                      </DetailsContainer>
                    </TableData>
                    <TableData>
                      
      
                      {playlist?.songs?.length > 0 && (
                        <DetailsText>
                          <small>{playlist?.songs?.length} Songs</small>
                        </DetailsText>
                      )}
                    </TableData>
                    <TableData>
                      <div>
                        
                      </div>
                    </TableData>
                    <TableData>
                      
                    </TableData>
                  </TableRow>
                );
              })}
              </Table>
              </>
    )
      };

    return (
        <OptionModal heading={`Search for ${searchType === 'Song' ? 'a Song' : 'Playlist With Songs'} Featured in Episode`}>
        <FormInput value={searchInput} placeholder={`Search for a song`} onChange={handleInput}/>
        {
            isLoaded && searchInput?.trim()?.length > 0 && searchType === 'Song' && searchedSongs?.length > 0 && displaySearch()
        }
        {
            isLoaded && searchInput?.trim()?.length > 0 && searchType === 'Playlist' && searchedPlaylists?.length > 0 && displayPlaylistSearch()
        }
        
        {!isLoaded && searchInput?.trim()?.length > 0 && <>
            <ModalLoadingScreen text={'Loading...'}/>
        </>}
        </OptionModal>
      )
}



export const VideoSongSearchModal = ({onClick}) => {
  const {currentUser, profiles, currentProfile} = useAuth()
  const [isLoaded, setLoaded] = useState(false)
  const { defaultAlbumArt, DEFAULT_IMAGE } = ImageUtils();
  const [searchedSongs, setSearchedSongs] = useState([])
  const [songs, setSongs] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const {modalContent, closeModal} = useModal()
  const { searchSongsByArtist, searchSongsByName, searchPlaylistsByName, getSongs } = useMusic();

  const handleInput = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value?.trim().length > 0) {
      setSearchedSongs(songs.filter(s => s?.songName.toLowerCase().includes(e.target.value.toLowerCase())))
      return
    }
    setSearchedSongs([])
  };

  useEffect(() => {
    setLoaded(false)
    if(currentProfile === 'artist'){
      searchSongsByArtist(profiles?.artist?.artistName).then((s) => {
        console.log(s)
        if (s?.length > 0) {
          setSongs(s);
        } else {
          return;
        }
        setLoaded(true)
      })
      .catch((err) => {
        console.error(err);
        setLoaded(true)
        return;
      });
   
     
    }
  
    return () => {
    }
  }, [])
  

  const displaySearch = () => {
    return (
        <>
        <Table>
          {searchedSongs.map((song, index) => {
            return (
              <TableRow
                key={index}
                onClick={
                    () => 
                        {closeModal()
                        onClick(song)
                    }
                  }
               
                id={song.songId}>
                <TableData
                  
                >
                  <NumButton
                    
                  >
                    {
                      index + 1
                    }
                  </NumButton>
                </TableData>
                <TableData
                  style={{ width: "10%" }}
                >
                  <ImageWrapper>
                    <Img1
                      src={song?.albumArt ? song.albumArt : defaultAlbumArt}
                      alt={song?.songName}
                    />
                  </ImageWrapper>
                </TableData>
                <TableData>
                  <DetailsContainer>
                    <DetailsText>
                      {song?.songId
                        ? getSongTitle(song)?.length > 25
                          ? `${getSongTitle(song)?.substring(0, 25)}...`
                          : getSongTitle(song)
                        : "Song Name"}
                      
                    </DetailsText>
                    <DetailsText
                      style={{ color: Theme({}).secondaryColor }}>
                        {song?.explicit && (
                        <MdExplicit style={{ marginLeft: "1%" }} />
                      )}
                      <small>
                        {(song?.artists || song?.mainArtists) &&
                          getArtistsNames2(
                            song?.artists ? song?.artists : song?.mainArtists,
                            ARTIST_SEARCH_TYPES.NAME
                          )}
                      </small>
                    </DetailsText>
                    {song?.album && (
                    <DetailsText style={{ justifyContent: "flex-start", cursor: 'pointer' }}>
                      <small>from {song?.album?.albumName}</small>
                    </DetailsText>
                  )}
                  </DetailsContainer>
                </TableData>
                <TableData>
                  
  
                  {song?.duration && (
                    <DetailsText>
                      <small>{convertDuration(song?.duration)}</small>
                    </DetailsText>
                  )}
                </TableData>
                <TableData>
                  <div>
                    
                  </div>
                </TableData>
                <TableData>
                  
                </TableData>
              </TableRow>
            );
          })}
          </Table>
          </>
)
  };

  return (
    <>
      <OptionModal heading={`Search for a Song that is part of the video`}>
        {isLoaded && songs?.length > 0 && <FormInput value={searchInput} placeholder={`Search for a song`} onChange={handleInput}/>}
        {
            isLoaded && searchInput?.trim()?.length > 0 && searchedSongs?.length > 0 && displaySearch()
        }
     {!isLoaded && <>
            <ModalLoadingScreen text={'Loading Songs'}/>
        </>}
    </OptionModal>
    </>
  )

}