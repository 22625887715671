import { FaCompactDisc, FaPlay } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { CustomButton } from "../ButtonElement";
import { hexToRgba, lightOrDark } from "../HexToRGBA";


export const ContentListContainer = styled.ul`
display: flex;
width: 100%;
max-height: ${props => props.maxHeight && props.maxHeight };
overflow: hidden;
overflow-y: auto;
padding: 2%;
transition: 0.8s all ease;
justify-content: space-evenly;
flex: 1 1 100%;

`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

`


export const TableHeading = styled.th`
  text-align: left;
  border: none;
`

export const TableRow = styled.tr`
text-decoration: none;
flex-direction: column;
width: 100%;
border: none;
align-items: left;
border-radius: 5px;
justify-content: flex-start;
transition: 0.2s all ease;
cursor:pointer;
color: ${props => props.background ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props.theme.secondaryBg};
&:hover {
  transition: all 0.2s ease-in-out;
  background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.2)};
  border-radius: 5px;
}
@media screen and (max-width: 480px)
{
  font-size: 1rem;
}

`

export const TableData = styled.td`
border: none;
padding: 1% 1%;

`

export const PlayButton = styled.div`
display: none;
font-size: 0.8rem;
position: absolute;
justify-self: center;
align-self: center;
color: ${props => props.isPlaying ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor :  props.theme.secondaryBg };
background: transparent;
transition: 0.8s all ease;
cursor: pointer;
&:hover {
    color: ${props => props.isPlaying ? props.theme.secondaryBg : props.theme.mainColor };
}

${TableRow}:hover & {
  display: flex;
  z-index: 150;
}

@media screen and (max-width: 960px) {
  font-size: 0.75em;
}

`


export const NumButton = styled.div`
display: flex;
font-size: 0.8rem;
padding: 4% 2%;
color: ${props => props.isPlaying ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor :  props.theme.secondaryBg };
justify-content: space-between;
background: transparent;
transition: 0.8s all ease;
cursor: pointer;
&:hover {
    color: ${(props) => (props.isPlaying ? props.theme?.mainColor ? ((lightOrDark(props.theme?.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme?.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme?.mainColor : props.theme.accentColor : props.theme.secondaryBg : props.theme.secondaryColor )};
}

@media screen and (max-width: 960px) {
  font-size: 0.75em;
}

`


export const ContentListButton = styled(NavLink)`
display: flex;
align-items: center;
background-color: ${props => props.isFocused ? hexToRgba(props.theme.secondaryBg, 0.2) : 'transparent'};
justify-content: flex-start;
align-content: flex-start;
text-decoration: none;
list-style: none;
width: 100%;
padding:  1% 5%;
margin: 0.3% 0%;
transition: 0.2s all ease;
color: ${props => props.background ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props.theme.secondaryBg};
cursor:pointer;
border-radius: ${props => props.isFocused ? '5px' : '0px'};
border-bottom: 0.5px solid ${props => hexToRgba(props.theme.secondaryBg,0.1)};
&:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${props => hexToRgba(props.theme.secondaryBg, 0.2)};
    border-radius: 5px;
  }

@media screen and (max-width: 480px)
{
  font-size: 1rem;
}


`
export const DetailsWrapper = styled.div`
display: flex;
align-items: center;
flex: 1 1;
`

export const DetailsContainer = styled.div`
display: flex;
font-size: 0.8rem;
flex-direction: column;
justify-content: flex-start;
z-index: 150;

`
export const DetailsText = styled.text`
  display: flex;
  padding: 0.5% 0%;
  white-space: nowrap;
  overflow: hidden;
  color: ${props => props?.isPlaying ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props?.alt ? props.theme.secondaryColor : props?.theme.secondaryBg };
  inline-size: min-content;
  align-items: center;
  text-overflow: ellipsis;
  @media screen and (max-width: 960px) {
    font-size: 0.6rem;
    overflow: hidden;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;

 
  @media screen and (max-width: 960px)
  {
    width: 2.5rem;
    height: 2.5rem;
  }
   
`

export const Img = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    transition: 1s ease-out;
    border-radius: ${props => props.isCircular ? '50%': '0px'};
    background: ${props => hexToRgba(props.theme.mainBg, 0.9)} url(${props => props.src});
    background-size: cover;
    &:hover {
      transition: all 0.2s ease-in-out;
      opacity: 0.5;
      border-radius: 10px;
  }

  ${TableRow}:hover & {
    transition: all 0.2s ease-in-out;
      opacity: 0.5;
      border-radius: border-radius: ${props => props.isCircular ? '50%': '10px'};;
  }

   

`

export const AdditionalDetailsText = styled.text`
  display: flex;
  font-size: 0.8rem;
  color: ${props => props.background ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props.theme.secondaryBg};
  white-space: nowrap;
  padding: 0% 1%;
  justify-content: flex-end;
  flex: 1 1;
  @media screen and (max-width: 960px) {
    font-size: 0.8rem;
  }
`

export const ButtonWrapper = styled.div`
display: flex;
right: 10%;
color: ${(props) => props.background ? props.background : props.theme.accentColor};
margin: 2%;
transition: 0.8s all ease;
justify-content: space-between;
`

export const Button = styled.div`
display: flex;
right: ${props => props.isVisible ? '-150%': '0'};
top: ${props => props.isVisible ? '150%': '0'};
font-size: 1rem;
opacity: 0;
padding: 4% 2%;
margin: 0 1%;
color: ${props => props.active? props.background ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props.theme.secondaryBg: props.theme.secondaryBg};
justify-content: center;
background: transparent;
transition: 0.8s all ease;
&:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
    border-radius: 10px;
    color: ${(props) => (props.active ? props.background ? ((lightOrDark(props.theme.mainColor) === "light" && lightOrDark(props.theme.mainBg) === "dark") || ((lightOrDark(props.theme.mainColor) === "dark") && (lightOrDark(props.theme.mainBg) === "light")  )) ? props.theme.mainColor : props.theme.accentColor : props.theme.secondaryBg: props.theme.secondaryColor )};
}

${TableRow}:hover & {
  
  z-index: 150;
  opacity: 1;
  right: 10%;
}

@media screen and (max-width: 960px) {
  font-size: 0.75rem;
}

`
export const Button1 = styled.div`
display: flex;
border-radius: 5px;
padding: 2% 5%;
font-size: 0.75rem;
text-align: center;
justify-content: center;
justify-self: center;
z-index: 5;
white-space: nowrap;
border: 1px solid ${props => props.theme.secondaryBg};
color: ${props => props.theme.secondaryBg};
background: ${props =>props.isActive ?   props.theme.mainColor: 'transparent'};
&: hover {
  border: 1px solid ${props => props.theme.mainColor};
  background: ${props => props.isActive ?  'transparent' : props.theme.mainColor};
  color: ${props => props.isActive ? ( lightOrDark(props.theme.mainColor) === 'light' ? 'black' : 'white' ): props.theme.secondaryBg};
}

@media screen and (max-width: 960px) {
  font-size: 0.5rem;
}

`

const Rotate = keyframes`
from {
  transform: rotate(0 deg);
}
to {
  transform rotate(360deg);
}
`

export const PlayingDisc = styled(FaCompactDisc)`
animation: ${Rotate} 4s linear infinite;

`
