import { Link } from "react-router-dom";
import styled from "styled-components";
import { hexToRgba } from "../../HexToRGBA";

export const CommentContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 2%;
flex-direction: column;
border-radius: 15px;
justify-content: space-evenly;
background: transparent;
border: 1px solid ${(props) => hexToRgba(props.theme.accentColor, 1)};
`

export const CommentHeader = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 0% 1%;
font-weight: bold;
color: ${(props) => props.theme.secondaryBg};
justify-content: space-between;
margin-bottom: 2%;
border-bottom: solid 0.5px ${(props) => hexToRgba(props.theme.secondaryBg, 0.1)};
`

export const CommentsHeaderText = styled(Link)`
display: flex;
    text-decoration: none;
    padding: 0 1%;
    color: ${(props) => props.theme.secondaryBg};
    justify-content: space-evenly;
    align-content: center;
    &:hover {
        text-decoration: underline;
        transition: all 0.2s ease-in-out;
        opacity: 1;
      }

`

export const CommentMessage  = styled.div`
display: flex;
width: 100%;
height: 100%;
padding: 2%;
color: ${(props) => props.theme.secondaryBg};
border-radius: 5px;
background: ${(props) => hexToRgba(props.theme.accentColor, 0.05)};

`

export const CommentFooter  = styled.div`
    display: flex;
`

export const NestedCommentStack  = styled.div`
    display: flex;
`

export const NestedComments  = styled.div`
    display: flex;
`


